import React from 'react';
import PostHomeErrorDivs from './postHomeErrorDivs';
import postSectionHomeErrorStyles from './postSectionHomeErrorStyles.module.css';
import { PostSectionHomeErrorProps } from './postsSectionHomeErrorTypes';
import UniversalNav from '../../../sharedComponents/universalNav/universalNav';

export default function PostsSectionHomeError({ setOrder, order }: PostSectionHomeErrorProps) {
    return (
        <section id={postSectionHomeErrorStyles.postSectionHomeErrorContainer}>
            <UniversalNav setOrder={setOrder} order={order} typeOfSearch='posts' />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
            <PostHomeErrorDivs />
        </section>
    )
}
