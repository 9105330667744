import { useQuery } from 'react-query';
import { API_URL } from '../../../utilities/utils';
import { PostType, OrderType } from '../../../universalTypes/universalTypes';

function getRequestURL(order: OrderType) {
    switch (order.order) {
        case 'fetchNew':
            return `${API_URL}/cardsSection/get-cards-list`;
        case 'fetchHot':
            return `${API_URL}/cardsSection/get-cards-list-hot`;
        case 'fetchTop':
            return `${API_URL}/cardsSection/get-cards-list-top`;
        case 'search':
            return `${API_URL}/cardsSection/search/${order.value}`;
        default:
            return `${API_URL}/cardsSection/get-cards-list`;
    }
}

async function fetchCards(ac: AbortController, order: OrderType) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(getRequestURL(order), fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as PostType[];
}

const useCardsQuery = (ac: AbortController, order: OrderType) =>
    useQuery(['cardsSectionQuery',order.order,order.value],
        () => fetchCards(ac, order),
        { refetchOnWindowFocus: false });

export default useCardsQuery;