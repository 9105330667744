import { useQuery } from 'react-query';
import { PostType } from "../../../../universalTypes/universalTypes";
import { API_URL } from "../../../../utilities/utils";

async function fetchUserProfileBookmarkedPosts(ac: AbortController, userProfileId: string) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/user-profile/get-user-profile-bookmarked-posts/${userProfileId}`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as PostType[];
}

const useUserProfileBookmarkedPosts = (ac: AbortController, userProfileId: string) => useQuery(['userProfileBookmarkedPosts'],
    () => fetchUserProfileBookmarkedPosts(ac, userProfileId),
    { refetchOnWindowFocus: false });

export default useUserProfileBookmarkedPosts;

export async function fetchUserProfileBookmarks(ac: AbortController,
     page: number,
      setHasMore: React.Dispatch<React.SetStateAction<boolean>>,
       setPostsList: React.Dispatch<React.SetStateAction<PostType[] | undefined>>,
         userProfileId: string) {
    if (page < 2) return null;
    const token = window.localStorage.token as string;
    const fetchOptions = {
      method: 'GET',
      mode: 'cors',
      headers: {
        Accept: 'application/json',
        Authorization: `Bearer ${token}`
      },
      signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/user-profile/get-user-profile-bookmarked-posts/infinite-scroll/${userProfileId}/${page}`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) return console.log("------>", parsedResponse.message);
    if (parsedResponse.length < 1) return setHasMore(false);
    return setPostsList((prevPostsList) => prevPostsList && [...prevPostsList, ...parsedResponse]);
  };