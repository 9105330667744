/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import postsStyle from './postsSectionHomeStyles.module.css';
import usePostsHomeQuery from './postsSectionHomeCustomHooks/usePostsHomeQuery';
import Post from './postHome/postHome';
import PostsSectionHomeLoading from './postsSectionHomeLoading/postsSectionHomeLoading';
import PostsSectionHomeError from './postSectionHomeError/postsSectionHomeError';
import { PostType, PostPollCardOrThreadContextType } from '../../../universalTypes/universalTypes';
import fetchPostsInfiniteScroll from './postsSectionHomeUtils';
import PostHomeSectionEndOfList from './postHomeSectionEndOfList/postHomeSectionEndOfList';
import ZoomImagePortal from '../../sharedComponents/reactPortals/zoomImagePortal/zoomImagePortal';
import BlockUserPortal from '../../sharedComponents/reactPortals/blockUserPortal/blockUserPortal';
import DeletePost from '../../sharedComponents/reactPortals/deletePost/deletePost';
import BanUserFromThreadPortal from '../../sharedComponents/reactPortals/banUserFromThreadPortal/banUserFromThreadPortal';
import ReportPost from '../../sharedComponents/reactPortals/reportPost/reportPost';
import UniversalNav from '../../sharedComponents/universalNav/universalNav';

export const PostsSectionHome = createContext<PostPollCardOrThreadContextType>(null);

export default function PostsSection() {
  const [postsList, setPostsList] = useState<PostType[]>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [currentPost, setCurrentPost] = useState<PostType | null>(null);
  const [order, setOrder] = useState({ order: 'fetchNew' });
  const [page, setPage] = useState(1);
  const ac = new AbortController();
  const { data, refetch, remove, isError } = usePostsHomeQuery(ac, order);
  useEffect(() => setPostsList(() => data && [...data]), [data]);
  useEffect(() => () => remove(), []);
  useEffect(() => {
    if (postsList?.length) setPage((page) => page + 1);
  }, [postsList]);
  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [order.order]);
  useEffect(() => console.log("hasMore???--->", hasMore), [hasMore]);
  useEffect(() => console.log("page???--->", page), [page]);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
  if (isError) return <PostsSectionHomeError setOrder={setOrder} order={order} />;

  return (
    <section id={postsStyle.postsSectionHomeContainer}>
      <UniversalNav setOrder={setOrder} order={order} typeOfSearch='posts' />
      <PostsSectionHome.Provider value={{ setCurrentPost, currentPost, refetch }}>
        <InfiniteScroll
          style={{ borderRadius: '.5rem', overflow: 'initial' }}
          dataLength={postsList?.length || 0}
          next={() => fetchPostsInfiniteScroll(ac, page, setHasMore, setPostsList, order)}
          hasMore={hasMore}
          loader={<p>Loading...</p>}
          endMessage={<PostHomeSectionEndOfList />}
        >
          {
            postsList ?
              postsList.map((post) =>
                <Post post={post} key={post._id} />
              ) : <PostsSectionHomeLoading />
          }
          <ZoomImagePortal queryKey="zoomImagePortal" />
          {
            currentPost && (
              <>
                <DeletePost
                  post={currentPost}
                  typeOfPost={currentPost.isPost ? 'POST' : 'POLL'}
                  setPostsList={setPostsList} calledFrom='home' />
                <BlockUserPortal currentPost={currentPost} refetch={refetch} />
                <BanUserFromThreadPortal currentPost={currentPost} />
                <ReportPost post={currentPost} />
              </>
            )
          }
        </InfiniteScroll>
      </PostsSectionHome.Provider>
    </section >
  )
}
