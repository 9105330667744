/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react'
import { faBan, faUserSlash, faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from 'react-query';
import postOptionsStyle from '../postHomeOptionsStyles.module.css';
import { PostsSectionHome } from '../../../postsSectionHome';
import {
    handleOnBlurPostHomeOptions
} from './optionsUtils';
import { PostType } from '../../../../../../universalTypes/universalTypes';
import { ThreadContext } from '../../../../../thread/threadPosts/threadPosts';
import CopyLink from './optionsActions/link';
import Delete from './optionsActions/delete';
import Ban from './optionsActions/ban';
import Share from './optionsActions/share';
import Block from './optionsActions/block';
import { UserProfileBookmarksContext } from '../../../../../userProfile/userProfileSections/userProfileBookmarks/userProfileBookmarks';
import { whichContextGlobal } from '../../../../../../utilities/utils';

export default function ThreadAdminNotPostAuthor({ post, setVisible }: { post: PostType, setVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
    const context = whichContextGlobal(PostsSectionHome, ThreadContext, UserProfileBookmarksContext);
    if (!context) return null;
    const queryClient = useQueryClient();
    const optionsRef = useRef<HTMLDivElement>(null);

    return (
        <div className={postOptionsStyle.postsHomeOptionsContainer}
            onBlur={(event) => handleOnBlurPostHomeOptions(event, optionsRef, setVisible)} ref={optionsRef}>
            <CopyLink post={post} setVisible={setVisible} />
            <Share />
            <Delete
                post={post}
                context={context}
                queryClient={queryClient}
            />
            <Ban context={context} post={post} queryClient={queryClient} />
            <Block context={context} post={post} queryClient={queryClient} />
        </div>
    )
}
