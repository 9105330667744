import React, { useEffect, useState } from 'react';
import { faVolumeUp, faPause } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import postReadThisForMeStyles from './postReadThisForMeStyles.module.css';
import { PostTitleProps } from '../postTitle/postTitleTypes';

export default function PostReadThisForMe({ post }: PostTitleProps) {

    const [playing, setPlaying] = useState<boolean>(false);

    useEffect(() => {
        const speech = window.speechSynthesis;
        document.body.onkeyup = (e) => {
            if (e.key === 'Escape' && speech.speaking) {
                speech.cancel();
                setPlaying(false);
            }
        };
        return () => speech.cancel();
    }, []);

    const handleTextToSpeech = () => {
        const speech = window.speechSynthesis;
        const msg = new SpeechSynthesisUtterance(`${post.title}... ${post.postBody ? post.postBody : ''}`);
        if (playing) {
            speech.cancel();
            return setPlaying(false);
        }
        msg.lang = window.navigator.language;
        speech.speak(msg);
        return setPlaying(true);
    };

    return (
        <div id={postReadThisForMeStyles.textToSpeech} className={postReadThisForMeStyles.tooltip}>
            <div>Read this for me!</div>
            {!playing
                ? (
                    <FontAwesomeIcon
                        icon={faVolumeUp}
                        onClick={handleTextToSpeech}
                    />
                )
                : (
                    <FontAwesomeIcon
                        icon={faPause}
                        onClick={handleTextToSpeech}
                        id={postReadThisForMeStyles.pauseIcon}
                    />
                )}
        </div>
    )
}
