/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { QueryClient } from 'react-query';
import { handleBlockUser } from '../optionsUtils';
import { PostType } from '../../../../../../../universalTypes/universalTypes';

export default function Block({ context, post, queryClient }: { context: any, post: PostType, queryClient: QueryClient }) {
    return (
        <div onClick={() => handleBlockUser(context, post, queryClient)} role='button' tabIndex={0}>
            <FontAwesomeIcon icon={faUserSlash} />
            <span>Block</span>
        </div>
    )
}
