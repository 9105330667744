/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faEllipsisV, faFlag, faUserPlus, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import userProfileStyles from '../userProfileStyles.module.css';
import maqueta from '../../../assets/images/maqueta.png'
import { UserProfileType } from '../userProfileTypes';
import { API_URL, acceptedImageFormats } from '../../../utilities/utils';
import { notificationsSocket } from '../../nav/notifications/notifications';
import useUserQuery from '../../nav/user/useUsers';
import sharedCSS from '../../sharedCSS/sharedCSS.module.css';
import BlockUserFromUserProfileWall from '../../sharedComponents/reactPortals/blockUserPortal/blockUserFromUserProfileWall';

export default function UserProfileBackgroundImageAndThumbnail({ userProfile, refetch }: { userProfile: UserProfileType, refetch: any }) {
    const queryClient = useQueryClient();
    const menuRef = useRef<HTMLDivElement>(null);
    const [visible, setVisible] = useState(false);
    const { data: thisUser } = useUserQuery();
    const [isSaved, setIsSaved] = useState<{ ok: boolean, file: File | null }>({ ok: true, file: null });
    const [isSavedBackground, setIsSavedBackground] = useState<{ ok: boolean, file: File | null }>({ ok: true, file: null });
    const userThumbnailRef = useRef<HTMLImageElement>(null);
    const userProfileBackgroundRef = useRef<HTMLImageElement>(null);

    const handleMenuOff = (e: MouseEvent) => {
        const { target } = e;
        if (menuRef.current && !menuRef.current.contains(target as Node)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('click', handleMenuOff, true);
        return () => {
            document.body.removeEventListener('click', handleMenuOff, true);
        };
    }, []);

    const handleAddFriend = (isAdding: boolean) => {
        fetch(`${API_URL}/user-profile/send-friend-request`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    userProfileId: userProfile._id,
                    userProfileNickname: userProfile.nickname,
                    isAdding: JSON.stringify(isAdding)
                })
            })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                if (isAdding)
                    notificationsSocket.emit('sendMessageToSpecificUser', {
                        notificationId: response.friendRequestNotificationId,
                        userId: userProfile._id,
                        postUrl: `profile/${userProfile.nickname}/friends`,
                        message: `${thisUser?.nickname} sent you a friend request!`
                    });
                refetch();
            })
            .catch(e => notificationsSocket.emit('sendErrorMessage', e.message));
    }

    const handleChangeThumbnail = () => {
        const inputDiv = document.createElement('input');
        inputDiv.setAttribute("type", "file");
        inputDiv.setAttribute("accept", acceptedImageFormats);
        inputDiv.click();
        inputDiv.onchange = (event) => {
            const target = event.currentTarget as HTMLInputElement;
            if (!target?.files?.length) return;
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const thumbnail = userThumbnailRef.current;
                if (!thumbnail) return;
                thumbnail.src = readerEvent.target?.result as string;
            }
            reader.readAsDataURL(target.files[0]);
            setIsSaved({ ok: false, file: target.files[0] });
        }
    }

    const handleCancel = () => {
        setIsSaved({ ok: true, file: null });
        const thumbnail = userThumbnailRef.current;
        if (!thumbnail) return;
        thumbnail.src = thumbnail.dataset.source as string;
    };

    const handleSave = () => {
        const thumbnail = userThumbnailRef.current;
        if (!thumbnail) return;
        const fd = new FormData();
        fd.append('thumbnail', isSaved.file as Blob);
        fetch(`${API_URL}/user-profile/change-user-profile-thumbnail`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Authorization": `Bearer ${window.localStorage.token}`
            },
            body: fd
        })
            .then(e => e.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                thumbnail.dataset.source = thumbnail.src as string;
                setIsSaved({ ok: true, file: null });
            })
            .catch(error => {
                if (error) notificationsSocket.emit('sendErrorMessage', error.message);
                thumbnail.src = thumbnail.dataset.source as string;
            });
    }

    const handleUpdateBackground = () => {
        const inputDiv = document.createElement('input');
        inputDiv.setAttribute("type", "file");
        inputDiv.setAttribute("accept", acceptedImageFormats);
        inputDiv.click();
        inputDiv.onchange = (event) => {
            const target = event.currentTarget as HTMLInputElement;
            if (!target?.files?.length) return;
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const backgroundImage = userProfileBackgroundRef.current;
                if (!backgroundImage) return;
                if (readerEvent.target)
                    backgroundImage.src = readerEvent.target.result as string;
            }
            reader.readAsDataURL(target.files[0]);
            setIsSavedBackground({ ok: false, file: target.files[0] });
        }
    }

    const handleUpdateBackgroundCancel = () => {
        setIsSavedBackground({ ok: true, file: null });
        const background = userProfileBackgroundRef.current;
        if (!background) return;
        background.src = background.dataset.source as string;
    }

    const handleBackgroundSave = () => {
        const background = userProfileBackgroundRef.current;
        if (!background) return;
        const fd = new FormData();
        fd.append('background', isSavedBackground.file as Blob);
        fetch(`${API_URL}/user-profile/update-user-profile-background`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Authorization": `Bearer ${window.localStorage.token}`
            },
            body: fd
        })
            .then(e => e.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                background.dataset.source = background.style.backgroundImage as string;
                setIsSavedBackground({ ok: true, file: null });
            })
            .catch(error => {
                if (error) notificationsSocket.emit('sendErrorMessage', error.message);
                background.style.backgroundImage = `url('${background.dataset.source as string}')`;
            });
    }

    const handleUnfriendUser = () => {
        fetch(`${API_URL}/user-profile/unfriend-user`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    userProfileId: userProfile._id,
                })
            })
            .then(e => e.json())
            .then(e => {
                if (e.error) throw new Error(e.message);
                refetch();
            })
            .catch(err => console.log(err));
    }

    const handleUserDotsMenu = () => { setVisible(e => !e); }
    const handleUserBlock = () => {
        queryClient.setQueryData("blockUserFromUserProfileWall", true);
    }
    const handleUserReport = () => {
        queryClient.setQueryData("reportPost", true);
    }

    return (
        <div id={userProfileStyles.userProfileBackgroundAndThumbImages}>
            <img loading="lazy" decoding="async"
                src={userProfile?.backgroundImage || maqueta}
                alt={userProfile?.backgroundImage || maqueta}
                id={userProfileStyles.userProfileBackgroundImage}
                data-source={userProfile?.backgroundImage || maqueta}
                ref={userProfileBackgroundRef} />
            <img loading="lazy" decoding="async"
                src={userProfile?.thumbnail || maqueta}
                alt={userProfile?.thumbnail || maqueta}
                id={userProfileStyles.userProfileThumbnailImage}
                data-source={userProfile?.thumbnail || maqueta}
                ref={userThumbnailRef} />
            {
                userProfile && userProfile.isThisUserTheProfileOwner && <div id={userProfileStyles.changeImageWrapper}
                    onClick={handleChangeThumbnail}
                    role='button'
                    tabIndex={0}>
                    <FontAwesomeIcon icon={faCamera} />
                </div>
            }
            {
                !isSaved.ok && <div id={sharedCSS.thumbnailSaveDivWrapper}>
                    <div onClick={handleCancel} role='button' tabIndex={0}>cancel</div>
                    <div onClick={handleSave} role='button' tabIndex={0}>save</div>
                </div>
            }
            {
                userProfile && userProfile.isThisUserTheProfileOwner && <div id={userProfileStyles.updateBackgroundPicture}
                    onClick={handleUpdateBackground}
                    role='button'
                    tabIndex={0}>
                    <div
                    >
                        <FontAwesomeIcon icon={faCamera} />
                        <span> update picture</span>
                    </div>
                </div>
            }
            {
                !isSavedBackground.ok && <div id={sharedCSS.backgroundSaveDivWrapper}>
                    <div onClick={handleUpdateBackgroundCancel} role='button' tabIndex={0}>cancel</div>
                    <div onClick={handleBackgroundSave} role='button' tabIndex={0}>save</div>
                </div>
            }
            <div id={userProfileStyles.userProfileNickname}>{userProfile?.nickname}</div>
            {
                userProfile && !userProfile.isThisUserTheProfileOwner ?
                    userProfile.hasFriendRequestBeingSentByThisUser ?
                        <div id={userProfileStyles.addAsFriend}
                            role='button'
                            tabIndex={0}
                            onClick={() => handleAddFriend(false)} > Request Sent</div> :
                        !userProfile.isThisUserAFriend ? // agregar otra condición acá para verificar si es amigo o no
                            <>
                                <div id={userProfileStyles.addAsFriend}
                                    role='button'
                                    tabIndex={0}
                                    onClick={() => handleAddFriend(true)} > +add as a friend</div>
                                <FontAwesomeIcon id={userProfileStyles.addAsFriendMobile}
                                    icon={faUserPlus}
                                    role='button'
                                    tabIndex={0}
                                    onClick={() => handleAddFriend(true)} />
                            </> :

                            <div id={userProfileStyles.friendDiv}
                                role='button'
                                tabIndex={0}
                                onClick={handleUnfriendUser} > Friend</div>
                    : ''
            }

            {
                userProfile && !userProfile.isThisUserTheProfileOwner ?
                    <div style={{ position: 'absolute', right: '10px', bottom: '13px', cursor: 'pointer' }}
                        onClick={handleUserDotsMenu}
                        tabIndex={0}
                        role='button'
                        ref={menuRef}
                    >
                        <FontAwesomeIcon icon={faEllipsisV} />
                        {
                            visible && <div id={userProfileStyles.threeDotsUserMenu}>
                                <div onClick={handleUserBlock} tabIndex={0} role='button'><FontAwesomeIcon icon={faUserSlash} /> Block</div>
                                <div onClick={handleUserReport} tabIndex={0} role='button'><FontAwesomeIcon icon={faFlag} /> Report</div>
                            </div>
                        }
                    </div> : ''
            }
            {userProfile && <BlockUserFromUserProfileWall userProfile={userProfile} />}
        </div >
    )
}
