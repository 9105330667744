import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react'
import { Link } from 'react-router-dom'
import { PostType } from '../../../../../../../universalTypes/universalTypes'

export default function Edit({ post }: { post: PostType }) {
    return (
        <Link to={`/thread/${post.postThreadData.name}/post/${post.titleId}/true`}>
            <FontAwesomeIcon icon={faEdit} />
            <span>Edit</span>
        </Link>
    )
}
