import React, { useEffect, useRef } from 'react';
import {
  Outlet, useParams,
} from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import Nav from './nav/nav';
import Sidebar from './sidebar/sidebar';
import { blockInspector } from '../utilities/utils';

function Root() {
  // if (process.env.NODE_ENV === 'production') blockInspector(); // THIS WILL BLOCK INSPECTOR IN PRODUCTION!
  const queryClient = new QueryClient();
  const scrollRef = useRef<HTMLDivElement>(null);
  const params = useParams();
  useEffect(() => window.scrollTo(0, 0), [params]); // preventing infinite scroll to not get fucked up by scrolling by setting scroll at the start everytime url changes

  return (
    <QueryClientProvider client={queryClient}>
      <Nav />
      <Sidebar />
      <div id="detail" ref={scrollRef}>
        <Outlet />
      </div>
    </QueryClientProvider>
  );
}

export default Root;
