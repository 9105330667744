/* eslint-disable no-underscore-dangle */
import { useQuery } from "react-query";
import { API_URL } from "../../../../utilities/utils";
import { UserProfileType } from "../../userProfileTypes";
// import { NotificationsType } from "./notificationsType";

async function fetchUserProfileFriends(ac: AbortController, userProfile: UserProfileType, toggleFriendsAndRequests: boolean) {
    if (!userProfile) return null;
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
        signal: ac.signal,
    } as RequestInit;
    const response = toggleFriendsAndRequests ? await fetch(`${API_URL}/user-profile/get-all-friends/${userProfile?._id}`, fetchOptions) :
        await fetch(`${API_URL}/user-profile/get-all-friend-requests/${userProfile?._id}`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as any[];
}

const useUserProfileFriends = (ac: AbortController, userProfile: UserProfileType, toggleFriendsAndRequests: boolean) => useQuery('userProfileFriends',
    () => fetchUserProfileFriends(ac, userProfile, toggleFriendsAndRequests),
    { refetchOnWindowFocus: false });

export default useUserProfileFriends;