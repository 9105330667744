/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import postFooterStyles from './postFooterOptionsComponentsStyles.module.css';
import CopyLinkPost from './commonOptions/copyLink/copyLinkPost';
import DeletePost from './commonOptions/deletePost/deletePost';
import EditPost from './commonOptions/editPost/editPost';
import SharePost from './commonOptions/sharePost/sharePost';
import { PostType, UseQueryRefetch } from '../../../../universalTypes/universalTypes';
import SavePost from './commonOptions/savePost/savePost';

export default function PostFooterOptionsPostAuthor({ post, refetch, setIsEditable }: { post: PostType, refetch: UseQueryRefetch, setIsEditable: React.Dispatch<React.SetStateAction<boolean>> | null }) {
    return (
        <div id={postFooterStyles.postOptionsContainer}>
            <CopyLinkPost />
            <SharePost />
             { post.isPost && <EditPost setIsEditable={setIsEditable} /> }
            <SavePost post={post} refetch={refetch} />
            <DeletePost />
        </div>
    )
}
