/* eslint-disable import/no-cycle */
/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { MouseEvent, useCallback, useEffect, useRef } from 'react';
import { Editor } from 'tinymce';
import emojisStyles from './emojisStyles.module.css';
import { tinymceExecCommand } from '../../../../utilities/utils';

export default function Emojis({
    emojisButtonRef,
    setAreEmojisVisible,
    editorRef,
}: {
    emojisButtonRef: SVGElement,
    setAreEmojisVisible: any,
    editorRef: any,
}) {
    const emojisContainerRef = useRef<HTMLDivElement>(null);
    const smileys = ['🙂', '😁', '😂', '🤣', '😃', '😄', '😅', '😆', '😉', '😊', '😋', '😎', '😏', '🙃', '😇'];
    const love = ['❤️', '💋', '😍', '🥰', '😘', '😗', '😙', '😚', '🤗', '🤩', '😻', '💏', '💌', '🌹', '💔'];
    const sadness = ['🥺', '😔', '😥', '😐', '😑', '😶', '😭', '😢', '😓', '😦', '😧', '😩', '☹️', '🙁', '😞'];
    const weird = ['🤯', '🤨', '🙄', '😮', '🤐', '😪', '😴', '😖', '🥴', '🤥', '🤤', '😕', '🤑', '😲', '😵'];
    const crazy = ['😛', '😜', '😝', '😨', '😰', '😱', '😳', '🤪', '🤠', '🤡', '🥳', '🧐', '🤓', '👀', '👻'];
    const mad = ['😤', '😬', '😡', '😠', '🤬', '😈', '👿', '😣', '😫', '😒', '😼', '👺', '👹', '💢', '🗯️'];
    const others = ['🤔', '😷', '🤒', '🤕', '🤢', '🤮', '🤧', '🥵', '🥶', '🤫', '🤭', '🔥', '⚽', '✔', '🦠'];
    const hands = ['🤲', '🙌', '👏', '🤝', '✋', '🤚', '🖐', '🖖', '👋', '👊', '✊', '🤛', '🤜', '✍️', '🙏'];
    const fingers = ['👍', '👎', '🤞', '✌️', '🤟', '🤘', '👌', '👈', '👉', '👆', '👇', '☝️', '🤙', '🖕', '💪'];
    const chess = ['♙', '♗', '♘', '♖', '♕', '♔', '☘'];
    const randomAscii = ['❱', '❰', '❤', '✿', '☁', '☀', '☂', '☠'];
    const types = [smileys, love, sadness, weird, crazy, mad, others, hands, fingers, chess, randomAscii];
    enum EmojiEnum {
        Smileys,
        Love,
        Sadness,
        Weird,
        Crazy,
        Mad,
        Others,
        Hands,
        Fingers,
        Chess,
        RandomAscii
    }
    let counter = 0;
    const insertEmoji = useCallback((ev: MouseEvent<HTMLSpanElement>) => {
        if (!editorRef) return;
        tinymceExecCommand(
            editorRef as Editor,
            'emoticons',
            { textData: (ev.target as HTMLSpanElement).innerText }
        )
    }, [editorRef]);

    useEffect(() => {
        document.body.onkeydown = (e) => {
            if (e.key === "Escape") {
                setAreEmojisVisible(false);
            };
        };
        document.body.onmousedown = (e) => {
            const targetElement = e.target as Node;
            if (emojisContainerRef.current?.contains(targetElement)) return;
            if (emojisButtonRef?.contains(targetElement)) return;
            setAreEmojisVisible(false);
        };
        return () => { document.body.onkeydown = null };
    }, []);

    function getCaretCoordinatesInTinyMCE() {
        const { selection } = editorRef;

        if (!selection || !selection.getRng()) return null;

        const range = selection.getRng().cloneRange();
        range.collapse(true);

        const rect = range.getClientRects()[0];
        if (rect) {
            // Coordenadas del caret relativas a la página
            const x = rect.left + 15;
            const y = rect.top + 35;
            return { x, y };
        }

        return null;
    }

    console.log("coordenadas caret -->", getCaretCoordinatesInTinyMCE());

    return (
        <div className={emojisStyles.emojisBox}
            style={{ top: `${getCaretCoordinatesInTinyMCE()?.y}px`, left: `${getCaretCoordinatesInTinyMCE()?.x}px` }}
            ref={emojisContainerRef}>
            <div>
                {
                    types.map((_, index) => <React.Fragment key={++counter}>
                        <div className={emojisStyles.emojiTitles} key={++counter}>
                            {
                                EmojiEnum[index]
                            }
                        </div>
                        {
                            types[index].map((emoji, _) => <div onClick={insertEmoji} key={++counter} className={emojisStyles.emojiDiv}>
                                <span role='img' aria-label='emoticon'>{emoji}</span>
                            </div>
                            )
                        }
                    </React.Fragment>)
                }
            </div>
        </div >
    )
}


