/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useContext } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { PostCommentsType } from '../../../../../postCommentsType'
import { handleDeletePostComment } from '../optionsUtils'
import { ReplyContext } from '../../../comentReply'
import { whichContextGlobal } from '../../../../../../../../../utilities/utils';
import { CommentsSectionContext as UserProfileCommentsSectionContext } from '../../../../../../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';
import { PostCommentsContext } from '../../../../../postComments'


export default function Delete({ comment, isComment, setReplyList }: { comment: PostCommentsType, isComment: boolean, setReplyList: any }) {
    const context = whichContextGlobal(PostCommentsContext, UserProfileCommentsSectionContext, null) as any;
    const replyContext = useContext(ReplyContext);
    if (!context) return null;
    return (
        <div onClick={() => handleDeletePostComment(
            isComment ? comment._id : replyContext?.reply._id as string,
            context,
            isComment,
            setReplyList
        )} role='button' tabIndex={0}>
            <FontAwesomeIcon icon={faTrash} />
            <span>Delete</span>
        </div>
    )
}
