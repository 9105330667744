/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import BasePortal from "../basePortal";
import imgTrashbin from '../../../../assets/images/trashbin10.png';
import maqueta from '../../../../assets/images/maqueta.png';
import { PostType } from '../../../../universalTypes/universalTypes';
import { API_URL } from '../../../../utilities/utils';
import deletePostStyles from './deletePostStyles.module.css'
import { notificationsSocket } from '../../../nav/notifications/notifications';

type DeletePostType = {
    post: PostType,
    typeOfPost: string,
    setPostsList: React.Dispatch<React.SetStateAction<PostType[] | undefined>> | null,
    calledFrom: string
}

function DeletePost({ post, typeOfPost, setPostsList, calledFrom }: DeletePostType) {
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    function handleDeletePostPollOrCard() {
        fetch(`${API_URL}/post/process-delete-post`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    postId: post._id,
                    threadId: post.postThreadData._id
                }),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                switch (calledFrom) {
                    case 'home':
                    case 'cardsSection':
                    case 'threadsSection':
                    case 'thread':
                        queryClient.setQueryData("deletePost", false);
                        setPostsList?.((postList) => {
                            const newPostList = postList?.filter((item) => item._id !== post._id);
                            return newPostList;
                        });
                        break;
                    case 'post':
                        queryClient.setQueryData("deletePost", false);
                        navigate('/');
                        break;
                    case 'card':
                        queryClient.setQueryData("deletePost", false);
                        navigate('/cards');
                        break;
                    default:
                        queryClient.setQueryData("deletePost", false);
                        navigate('/');
                }

            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));;
    }

    const handleCancel = () => queryClient.setQueryData("deletePost", false);

    return <BasePortal queryKey='deletePost'>
        <div id={deletePostStyles.deletePostPortalWrapper}>
            <img
                src={"https://w3s.link/ipfs/bafkreidw7lyomqo433nii5mimy3xumx4rzchspic7yqgvjx52pudqcqcoa" || imgTrashbin || maqueta}
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div>
                <p>YOU ARE ABOUT TO <span>DELETE</span> THIS {typeOfPost} AND ALL THE COMMENTS AND ANSWERS WITHIN IT</p>
                <p> <span>This action is irreversible</span> </p>
                <p>Do you wish to continue?</p>
            </div>
            <div id={deletePostStyles.cancelAndDeleteButtons}>
                <div
                    id={deletePostStyles.cancelPostPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}
                >no, wait!</div>
                <div
                    id={deletePostStyles.deletePostPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleDeletePostPollOrCard}
                >yeah, fuck it!</div>
            </div>
        </div>
    </BasePortal>
}

function areEqual(prevProps: Readonly<{ post: PostType; }>, nextProps: Readonly<{ post: PostType; }>) {
    return prevProps.post._id === nextProps.post._id;
}

export default memo(DeletePost, areEqual);