import React from 'react';
import threadLoadingStyles from './threadsLoadingStyles.module.css';

export default function ThreadsLoading() {
    return (
        <>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
            <div className={threadLoadingStyles.threadLoading} >
                <div />
                <div />
                <div />
            </div>
        </>
    )
}
