import React from 'react';
import { faHeart, faShare, faBookmark, faReply, faEllipsis, faEllipsisV } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import postCommentsStyles from '../postComments/postCommentsStyles.module.css';
import maqueta from '../../../assets/images/maqueta.png';
import commentVotesStyles from '../postComments/commentVotesStyles.module.css'

export default function UserProfileLoadingPublicationTemplate() {
    return (
        <>
            <div style={{ display: 'grid', rowGap: '1rem', paddingLeft: '1rem' }}>
                <div className={postCommentsStyles.comment}>
                    <img className={postCommentsStyles.commentsProfileImage}
                        src={maqueta}
                        alt={maqueta}
                        loading="lazy"
                        decoding="async"
                    />
                    <div style={{
                        display: 'grid', gridTemplateColumns: '150px min-content 100px min-content', columnGap: '.25rem', alignItems: 'center', fontSize: '.8rem',
                        color: 'var(--info)'
                    }}>
                        <span style={{ backgroundColor: 'var(--loadingTextColor)', height: '20px', borderRadius: 'var(--fullBorderRadius)' }} /> <span style={{
                            overflow: 'hidden',

                        }}>•</span>
                        <span style={{ backgroundColor: 'var(--loadingTextColor)', height: '15px', borderRadius: 'var(--fullBorderRadius)' }} />
                        <FontAwesomeIcon icon={faEllipsisV} />
                    </div>
                    <div style={{ backgroundColor: 'var(--loadingTextColor)', width: '100%', height: '100px', margin: '0.5rem 0', borderRadius: 'var(--fullBorderRadius)' }} />
                    <div className={postCommentsStyles.commentVotesAndRepliesWrapper}>
                        <div className={commentVotesStyles.commentVotesAndOptionsContainer}>
                            <div className={commentVotesStyles.commentOptions}>
                                <div className={commentVotesStyles.commentVotesContainer}>
                                    <FontAwesomeIcon icon={faHeart} className={commentVotesStyles.optionHeart} />
                                    <div className={commentVotesStyles.numberOfVotes}>0</div>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faShare} /> Share
                                </div>
                                <div>
                                    <FontAwesomeIcon
                                        icon={faBookmark}
                                    /> <span> Save</span>
                                </div>
                                <div>
                                    <FontAwesomeIcon icon={faReply} /> Reply
                                </div >
                            </div >
                        </div >
                    </div>
                </div>
            </div>
            <hr style={{ width: '100%', border: '1px solid var(--bitofgrey)', margin: 0 }} />
        </>
    )
}
