/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper'
import useNotifications from '../nav/notificationsBell/notificationsBellCustomHooks';
import { NotificationsType } from '../nav/notificationsBell/notificationsType';
import notificationsBellStyle from '../nav/notificationsBell/notificationsBellStyles.module.css';
import timeSincePosted, { API_URL } from '../../utilities/utils';
import useUserQuery from '../nav/user/useUsers';

export default function MobileNotifications() {
    const ac = new AbortController();
    const { data, refetch } = useNotifications(ac);
    const [notifications, setNotifications] = useState<NotificationsType>();
    const { data: user } = useUserQuery();
    const navigate = useNavigate();

    const handleMarkNotifAsSeen = async (event: React.MouseEvent, notificationId: string, notificationUrl: string) => {
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/mark-notification-as-seen`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            refetch();
            navigate(notificationUrl);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const handleNavigateToAllNotifications = () => {
        navigate(`/profile/${user?.nickname}/notifications`);
    }

    const handleDeleteNotification = async (event: React.MouseEvent, notificationId: string) => {
        event.stopPropagation();
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/delete-notification`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            refetch();
        } catch (error: any) {
            console.log(error.message);
        }
    }

    useEffect(() => {
        if (data) setNotifications(data);
    }, [data]);

    return (
        <MainWrapper>
            {
                notifications?.notifications?.map((item, _) =>
                    <div onClick={(event) => handleMarkNotifAsSeen(event, item._id, item.notificationLink)}
                        className={notificationsBellStyle.notificationDiv}
                        key={item._id} tabIndex={0} role='button'>
                        <span>{item.notificationContent}</span>&nbsp;
                        <span className={notificationsBellStyle.timeSinceNotifications}>•&nbsp;
                            {timeSincePosted(item.timestamp)}</span>&nbsp;
                        <FontAwesomeIcon icon={faClose} onClick={(e: React.MouseEvent) => handleDeleteNotification(e, item._id)}
                            className={notificationsBellStyle.deleteNotification} />
                        {item.isNotificationNew && <span className={notificationsBellStyle.isNotificationNew}>•</span>}
                    </div>)
            }
            <div id={notificationsBellStyle.showMeAllNotif} onClick={handleNavigateToAllNotifications} tabIndex={0} role='button' >Show me all notifications</div>
        </MainWrapper>
    )
}
