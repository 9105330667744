/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import { QueryClient } from "react-query";
import { PostCommentsRepliesType, PostCommentsType } from "../../../../postCommentsType";
import { PostType, PostPollCardOrThreadContextType } from "../../../../../../../../universalTypes/universalTypes";
import { API_URL } from "../../../../../../../../utilities/utils";
import { notificationsSocket } from "../../../../../../../nav/notifications/notifications";

export function handleDeletePostComment(commentId: string, context: any, isComment: boolean,
    setReplyList: React.Dispatch<React.SetStateAction<PostCommentsRepliesType[]>>) {

    if (context.userProfile) {
        fetch(isComment ? `${API_URL}/user-profile/process-delete-user-profile-comment` : `${API_URL}/user-profile/process-delete-user-profile-comment-reply`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({ commentId }),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                if (isComment) context.setCommentsList((commentList: any) => commentList.filter((comment: any) => comment._id !== e._id));
                else setReplyList((replyList) => replyList.filter((reply) => reply._id !== e._id));
            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));
        return;
    };

    fetch(isComment ? `${API_URL}/post/process-delete-post-comment` : `${API_URL}/post/process-delete-post-comment-reply`,
        {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${window.localStorage.token}`
            },
            body: JSON.stringify({ commentId, threadId: context.post.postThreadData._id }),
        })
        .then(e => e.json())
        .then((e: Record<string, unknown>) => {
            if (e.error) throw new Error(e.message as string);
            if (isComment) context.setCommentsList((commentList: any) => commentList.filter((comment: any) => comment._id !== e._id));
            else setReplyList((replyList) => replyList.filter((reply) => reply._id !== e._id));
        })
        .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));
}

export function handleCopyLinkPost(post: PostType, setVisible: React.Dispatch<React.SetStateAction<boolean>>) {
    if (post.isPost) {
        navigator.clipboard.writeText(`${window.location.origin}/thread/${post.postThreadData?.name}/post/${post.titleId}`);
        setVisible(false);
        return;
    }
    navigator.clipboard.writeText(`${window.location.origin}/thread/${post.postThreadData?.name}/poll/${post.titleId}`);
    setVisible(false);
}

export function handleOnBlurPostCommentOptions(event: React.FocusEvent<HTMLDivElement>, optionsRef: React.RefObject<HTMLDivElement>, setVisible: React.Dispatch<React.SetStateAction<boolean>>) {
    const { relatedTarget: target } = event;
    const orc = optionsRef.current;
    if (target !== orc && !orc?.contains(target)) setVisible(false);
}

export const handleReportPost = (context: PostPollCardOrThreadContextType, comment: PostCommentsType, queryClient: QueryClient) => {
    if (!context) return;
    console.log("comment???? que és1?? -->", comment);
    const currentPost = comment;
    context.setCurrentPost(currentPost as any);
    queryClient.setQueryData("reportPost", true);
}

export const handleReportComment = (context: any, comment: PostCommentsType, queryClient: QueryClient) => {
    if (!context) return;
    console.log("comment???? que és2?? -->", comment);
    const currentPost = comment;
    context.setCurrentComment(currentPost as any);
    queryClient.setQueryData("reportComment", true);
}


