/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useCallback } from 'react';
import { API_URL } from '../../../../utilities/utils';
import postVotesStyles from './postVotesStyles.module.css';
import postVotesPathDString from './postVotesPathDString';
import { PostVotesProps } from './postVotesTypes';
import { notificationsSocket } from '../../../nav/notifications/notifications';

export default function PostVotes({ post, refetch }: PostVotesProps) {
    const handleVote = useCallback((vote: boolean) => {
        fetch(`${API_URL}/post/process-post-votes`,
            {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    postId: post._id,
                    isVotePositive: vote,
                    threadId: post.postThreadData._id
                })
            }
        )
            .then(e => e.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                if (response.ok) { // TENGO EL MISMO PROBLEMA DE TODAS LAS NOTIFICACIONES EN TIEMPO REAL
                    const postType = post.isPost ? 'post' : post.isPoll ? 'poll' : 'card';
                    notificationsSocket.emit('sendMessageToSpecificUser', {
                        notificationId: response.notificationId,
                        userId: post.postAuthorData.id,
                        postUrl: `/thread/${post.postThreadData.name}/${postType}/${post.titleId}`,
                        message: `${response.thisUserName} ${vote ? 'upvoted' : 'downvoted'} your ${postType}!`
                    });
                }

                refetch();
            })
            .catch(error => {
                notificationsSocket.emit('sendErrorMessage', error.message);
            })
    }, [refetch, post._id]);

    const votesOutcome = () => {
        if (post.totalVotes > 0) return postVotesStyles.positiveVotesOutcome;
        if (post.totalVotes < 0) return postVotesStyles.negativeVotesOutcome;
        return null;
    }

    const hasVotedPositive = () => {
        if (post.hasThisUserVoted && post.hasThisUserVotedPositive) return postVotesStyles.postUpVoted;
        return '';
    }

    const hasVotedNegative = () => {
        if (post.hasThisUserVoted && !post.hasThisUserVotedPositive) return postVotesStyles.postDownVoted;
        return '';
    }

    return (
        <div id={postVotesStyles.postVotesContainer}>
            <svg version="1.1" width="22" height="22" viewBox="0 0 24 24" onClick={() => handleVote(true)}>
                <path className={hasVotedPositive()}
                    fill='transparent'
                    stroke='#5d666b'
                    d={postVotesPathDString} />
            </svg>
            <div className={`${postVotesStyles.numberOfVotes} ${votesOutcome()}`}>{post.totalVotes}</div>
            <svg version="1.1" width="22" height="22" viewBox="0 0 24 24" onClick={() => handleVote(false)} >
                <path className={hasVotedNegative()}
                    fill='transparent'
                    stroke='#5d666b'
                    d={postVotesPathDString} />
            </svg>
        </div>
    )
}
