/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import postCommentsStyles from '../../postCommentsStyles.module.css';
import EditPostComment from '../comment/editPostComment/editPostComment';
import CommentVotesAndReplies from '../comment/commentVotesAndReplies';
import { PostCommentsRepliesType, PostCommentsType } from '../../postCommentsType';
import timeSincePosted from '../../../../../../utilities/utils';

export const ReplyContext = createContext<{ setIsReplyEditable: React.Dispatch<React.SetStateAction<boolean>>, reply: PostCommentsRepliesType } | null>(null);

export default function Reply({ reply, setReplyList, comment, setIsEditable }: { reply: PostCommentsRepliesType, setReplyList: any, comment: PostCommentsType, setIsEditable: any }) {
    const [isReplyEditable, setIsReplyEditable] = useState(false);
    const navigate = useNavigate();
    const handleVisitProfile = () => navigate(`/profile/${reply.commentAuthorData.nickname}` as string);

    return (
        <ReplyContext.Provider value={{ setIsReplyEditable, reply }}>
            <div className={postCommentsStyles.reply} id={reply._id}>
                <img className={postCommentsStyles.commentsProfileImage}
                    src={reply.commentAuthorData.lowResThumbnail}
                    alt={reply.commentAuthorData.lowResThumbnail}
                    loading="lazy"
                    decoding="async"
                    onClick={handleVisitProfile}
                />
                <div className={postCommentsStyles.commentAuthorNickname}>
                    <span onClick={handleVisitProfile} role='button'
                        tabIndex={0}>{reply.commentAuthorData.nickname}</span> <span> • {` ${timeSincePosted(reply.timestamp)}`}
                    </span>
                </div>
                {
                    !isReplyEditable ?
                        <div ref={(element) => {
                            const tempElement = element;
                            if (!tempElement) return;
                            tempElement.innerHTML = reply.commentContent;
                        }} className={postCommentsStyles.commentContent} />
                        :
                        <EditPostComment isComment={false} comment={comment} setReplyList={setReplyList} setIsEditable={setIsEditable} />
                }
                <CommentVotesAndReplies reply={reply} isComment={false} comment={comment} setReplyList={setReplyList} setIsEditable={setIsEditable} />
            </div>
        </ReplyContext.Provider>
    )
}
