/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import pollWithImagesBody from './pollWithImagesBody.module.css';
import { PollWithImagesBodyProps } from './pollWithImagesBodyTypes';
import './pollWithImagesBodyStyles.css';
import { API_URL } from '../../../utilities/utils';
import { PollOptionsWithImages } from '../../../universalTypes/universalTypes';
import { notificationsSocket } from '../../nav/notifications/notifications';

function PollWithImagesBody({ poll, refetch }: PollWithImagesBodyProps) {
  const queryClient = useQueryClient();
  const [voted, setVoted] = useState(false);
  const ac = new AbortController();
  useEffect(() => {
    if (poll?.hasThisUserVotedAnyPollOptions) setVoted(true);
    ([...document.getElementsByClassName('pollImageZoom')]).forEach((item, _) => {
      const itemElement = item as SVGAElement;
      itemElement.onclick = (event: MouseEvent) => {
        const target = event.currentTarget as SVGAElement;
        if (!target) return;
        const imageElement = target.parentElement?.previousElementSibling?.firstElementChild as HTMLImageElement;
        if (!imageElement) return;
        queryClient.setQueryData('zoomImagePortal', { isVisible: true, imgSrc: imageElement.src });
      }
    })
  }, [poll]);
  const voteImagePoll = (e: { pollOption: PollOptionsWithImages, pollId: string }) => {
    fetch(`${API_URL}/poll/process-poll-option-with-images-vote`,
      {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "authorization": `Bearer ${window.localStorage.token}`
        },
        body: JSON.stringify({
          pollId: e.pollId,
          pollOptionIndex: e.pollOption.index,
          threadId: poll.postThreadData._id
        }),
        signal: ac.signal
      })
      .then(e => e.json())
      .then(e => {
        if (e.error) throw new Error(e.message);
        refetch();
      })
      .catch(error => notificationsSocket.emit('sendErrorMessage', error.message));
  }
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);

  return <div id={pollWithImagesBody.pollBody}>
    {
      poll.pollOptionsWithImages.map((pollOption: PollOptionsWithImages) =>
        <div key={pollOption.index}>
          <div className={pollWithImagesBody.pollImageWrapper}
            onClick={() => { voteImagePoll({ pollOption, pollId: poll._id }) }} role='button' tabIndex={0} >
            <img src={pollOption.imageUrl} alt={pollOption.imageUrl} className={pollWithImagesBody.pollImage} loading="lazy" decoding="async" />
            <div className={pollWithImagesBody.pollImageVoteLayer}
              style={
                voted ? {
                  height: `${pollOption.votes * 100 / poll.totalPollOptionsVotes}%`
                } : {}
              }
            />
            <span
              className={pollWithImagesBody.votePercentage}
              style={{ opacity: +voted }}>{`${(pollOption.votes * 100 / poll.totalPollOptionsVotes).toFixed(1).replace(/\.0$/, '')}%`}
            </span>
          </div>
          <div className={pollWithImagesBody.descriptionWrapper}>
            <span className={pollWithImagesBody.pollImageDescription}>{pollOption.description}</span>
            <FontAwesomeIcon icon={faSearch} className='pollImageZoom' />
          </div>
        </div>
      )
    }
    <div id={pollWithImagesBody.totalVotes}>TOTAL VOTES: {Number(poll.totalPollOptionsVotes).toLocaleString()}</div>
  </div >
}

export default PollWithImagesBody;




