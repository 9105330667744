/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-associated-control */

import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import postCreatorTextareaStyles from '../postCreatorTextarea/postTextarea/postCreatorTextareaStyles.module.css';
import postCreatorCardImageArea from './postCreatorCardImageArea.module.css';
import {
    acceptedImageFormats,
    acceptedVideoFormats,
    imageOrVideoMaxSize,
    imageUrlRegex,
    videoElement,
    videoUrlRegex,
    getVideoIdFromYoutubeURL,
    youtubeRegex
} from '../../../../utilities/utils';
import { PostCreatorContext } from '../../postCreator';
import { typeOfImageStateType } from './postCreatorCardImageAreaTypes';
import sendCard from './utils';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import ERROR from '../../../../utilities/errors';

export default function PostCreatorCardImageArea() {
    const navigate = useNavigate();
    const context = useContext(PostCreatorContext);
    const [isNSFW, setIsNSFW] = useState<boolean>(false);
    const [isSpoiler, setIsSpoiler] = useState<boolean>(false);
    const [typeOfImage, setTypeOfImage] = useState<typeOfImageStateType>(null);
    const [isAvailableForSending, setIsAvailableForSending] = useState(true);
    const sendCardRef = useRef(null);
    const cardImageOrVideoArea = useRef<HTMLDivElement | null>(null);
    const handleTakeOffImageOrVideo = () => {
        if (typeOfImage?.image) {
            if (cardImageOrVideoArea.current)
                cardImageOrVideoArea.current.innerHTML = '';
            setTypeOfImage(null);
        }
    }
    const handlePasteImageOrYoutubeLink = async (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        const target = event.currentTarget;
        // Get the data from the clipboard for the 2 posible types: plain text and file.
        const textData = event.clipboardData.getData('text/plain');
        const fileData = event.clipboardData.files[0];
        // if there's an image on the clipboard, then:
        if (fileData) {
            // Reject the image if it's too big
            if (fileData?.size > imageOrVideoMaxSize) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_FILE_SIZE_EXCEEDED);
            const reader = new FileReader();
            switch (fileData.type) {
                case 'video/webm':
                case 'video/mp4':
                    reader.onload = async (readerEvent) => {
                        target.innerHTML = videoElement(readerEvent.target?.result as string);
                        setTypeOfImage({ type: 'video', image: fileData });
                    };
                    reader.readAsDataURL(fileData);
                    break;
                case "image/webp":
                case "image/png":
                case "image/jpeg":
                case "image/gif":
                    reader.onload = async (readerEvent) => {
                        const htmlContent = `<img 
                          src="${readerEvent.target?.result}" 
                          title="${fileData.name}" 
                          alt="${fileData.name}" 
                          loading="lazy"
                          decoding="async" 
                          />`;
                        target.innerHTML = htmlContent;
                        setTypeOfImage({ type: 'image', image: fileData });
                    };
                    reader.readAsDataURL(fileData);
                    break;
                default:
            }
            return null;
        }
        // If there's no plain text on the clipboard then return:
        if (!textData) return false;
        const youtubeUrl = textData.match(youtubeRegex);
        const imageUrl = textData.match(imageUrlRegex)
        const videoUrl = textData.match(videoUrlRegex)
        if (youtubeUrl) {
            const youtubeVideoId = getVideoIdFromYoutubeURL(youtubeUrl[0]);
            target.innerHTML = `<iframe 
            loading="lazy"
            height=480
            src="https://www.youtube.com/embed/${youtubeVideoId}"
            frameborder="0" 
            allow="autoplay;
            encrypted-media" 
            allowfullscreen 
            style="position:relative;z-index:1;border:none;width:-webkit-fill-available">
            </iframe>`;
            setTypeOfImage({
                type: 'youtubeUrl',
                image: {
                    embed: `https://www.youtube.com/embed/${youtubeVideoId}`,
                    link: textData.match(youtubeRegex)?.[0],
                    thumbnailUrl: `https://img.youtube.com/vi/${youtubeVideoId}/maxresdefault.jpg`,
                    lowResThumbnailUrl: `https://img.youtube.com/vi/${youtubeVideoId}/hqdefault.jpg`
                }
            });
            return true;
        }
        if (imageUrl) {
            target.innerHTML = `<img src=${textData} 
            title=${textData} 
            alt=${textData}
            loading='lazy' 
            decoding='async'
           />`;
            setTypeOfImage({
                type: 'imageUrl',
                image: textData
            });
            return true;
        }
        if (videoUrl) {
            target.innerHTML = videoElement(textData);
            setTypeOfImage({
                type: 'videoUrl',
                image: textData
            }); // ESTE TODAVÍA NO ANDA, TENGO QUE TOCAR EL BACKEND!
            return true;
        }
        notificationsSocket.emit('sendErrorMessage', ERROR.IMAGE_TYPE_NOT_SUPPORTED);
        return true;
    }
    const handleUploadImageOrVideo = (event: React.ChangeEvent<HTMLInputElement>) => {
        const target = event.currentTarget;
        if (!target) return null;
        cardImageOrVideoArea.current?.focus();
        const file = target.files?.[0];
        if (!file) return null;
        if ((file.size) > imageOrVideoMaxSize) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_IMAGE_FILE_SIZE_EXCEEDED);
        const reader = new FileReader();
        reader.onload = readerEvent => {
            switch (file.type) {
                case 'video/webm':
                case 'video/mp4':
                    if (cardImageOrVideoArea.current)
                        cardImageOrVideoArea.current.innerHTML = videoElement(readerEvent.target?.result as string);
                    setTypeOfImage({
                        type: 'video',
                        image: file
                    });
                    break;
                case "image/webp":
                case "image/png":
                case "image/jpeg":
                case "image/gif":
                    if (cardImageOrVideoArea.current)
                        cardImageOrVideoArea.current.innerHTML = `<img 
                    src=${readerEvent.target?.result} 
                    title=${file.name} 
                    alt=${file.name} 
                    loading='lazy'
                    decoding='async'
                     />`;
                    setTypeOfImage({
                        type: 'image',
                        image: file
                    });
                    break;
                default:
            }
        }
        reader.readAsDataURL(file);
        return true;
    }
    const handleDropImageVideoOrYoutubeLink = (event: React.DragEvent) => {
        event.preventDefault();
        const target = event.currentTarget;
        const fileData = event.dataTransfer.files[0];
        const textData = event.dataTransfer.getData('text');
        if (fileData) {
            if (fileData.size > imageOrVideoMaxSize)
                return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_FILE_SIZE_EXCEEDED);
            const reader = new FileReader();
            switch (fileData.type) {
                case 'video/webm':
                case 'video/mp4':
                    reader.onload = async (readerEvent) => {
                        target.innerHTML = videoElement(readerEvent.target?.result as string);
                        setTypeOfImage({ type: 'video', image: fileData });
                    };
                    reader.readAsDataURL(fileData);
                    break;
                case "image/webp":
                case "image/png":
                case "image/jpeg":
                case "image/gif":
                    reader.onload = async (readerEvent) => {
                        const htmlContent = `<img 
                          src="${readerEvent.target?.result}" 
                          title="${fileData.name}" 
                          alt="${fileData.name}" 
                          loading="lazy"
                          decoding="async"
                           />`;
                        target.innerHTML = htmlContent;
                        setTypeOfImage({ type: 'image', image: fileData });
                    };
                    reader.readAsDataURL(fileData);
                    break;
                default:
            }
            return null;
        }
        if (!textData) return false;
        const youtubeUrl = textData.match(youtubeRegex);
        const imageUrl = textData.match(imageUrlRegex)
        const videoUrl = textData.match(videoUrlRegex)
        if (youtubeUrl) {
            target.innerHTML = `<iframe 
            loading="lazy"
            height=480
            src="https://www.youtube.com/embed/${textData.match(youtubeRegex)?.[1]}"
            frameborder="0" 
            allow="autoplay;
            encrypted-media" 
            allowfullscreen 
            style="position:relative;z-index:1;border:none;width:-webkit-fill-available">
            </iframe>`;
            setTypeOfImage({
                type: 'youtubeUrl',
                image: {
                    embed: `https://www.youtube.com/embed/${textData.match(youtubeRegex)?.[1]}`,
                    link: textData.match(youtubeRegex)?.[0],
                    thumbnailUrl: `https://img.youtube.com/vi/${textData.match(youtubeRegex)?.[1]}/maxresdefault.jpg`,
                    lowResThumbnailUrl: `https://img.youtube.com/vi/${textData.match(youtubeRegex)?.[1]}/hqdefault.jpg`
                }
            });
            return true;
        }
        if (imageUrl) {
            target.innerHTML = `<img src=${textData} 
            title=${textData} 
            alt=${textData}
            loading='lazy' 
            decoding='async'
           />`;
            setTypeOfImage({
                type: 'imageUrl',
                image: textData
            });
            return true;
        }
        if (videoUrl) {
            target.innerHTML = videoElement(textData);
            setTypeOfImage({
                type: 'videoUrl',
                image: textData
            }); // ESTE TODAVÍA NO ANDA, TENGO QUE TOCAR EL BACKEND!
            return true;
        }
        notificationsSocket.emit('sendErrorMessage', ERROR.IMAGE_TYPE_NOT_SUPPORTED);
        return true;
    }
    const handleIsSpoiler = () => setIsSpoiler((value: boolean) => !value);
    const handleIsNSFW = () => setIsNSFW((value: boolean) => !value);
    const handleSendCard = () => {
        if (!isAvailableForSending) return null;
        if (!context?.postTitle) return notificationsSocket.emit('sendErrorMessage', ERROR.TITLE_IS_EMPTY); // context.setNot({ ver: true, mensaje: "Title can't be empty!" });
        if (!context.postThread) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NOT_SELECTED);  // context.setNot({ ver: true, mensaje: "Select a thread!" });
        if (!typeOfImage) return notificationsSocket.emit('sendErrorMessage', ERROR.NO_IMAGE_NO_VIDEO); // context.setNot({ ver: true, mensaje: ERROR.NO_IMAGE_NO_VIDEO });
        if ((typeOfImage.image as Blob).size > imageOrVideoMaxSize) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_FILE_SIZE_EXCEEDED);
        sendCard(typeOfImage, context, setIsAvailableForSending, isSpoiler, isNSFW, navigate);
        return null;
    }
    return (
        <div id={postCreatorTextareaStyles.commentsTextareaContainerDiv}>
            <label id={postCreatorCardImageArea.cardImageAreaLabel} htmlFor="cardImageArea">
                <span className={`${postCreatorTextareaStyles.postCreatorConfirmationSpot} 
                ${typeOfImage?.image && postCreatorTextareaStyles.activeSpot}`}>●</span> IMAGE OR VIDEO (required):
            </label>
            {
                typeOfImage?.image && <FontAwesomeIcon
                    icon={faClose}
                    id={postCreatorCardImageArea.takeOffImageOrVideo}
                    onClick={handleTakeOffImageOrVideo}
                />
            }
            <div contentEditable
                id={postCreatorCardImageArea.cardImageArea}
                aria-placeholder="Paste an image or youtube video here..."
                onPaste={handlePasteImageOrYoutubeLink}
                onDrop={handleDropImageVideoOrYoutubeLink}
                ref={cardImageOrVideoArea}
            />
            <div className={postCreatorTextareaStyles.commentsTextAreaSendButton}>
                <label id={postCreatorCardImageArea.uploadImageOrVideo} >
                    <span className="fas fa-upload" role="img" />
                    <input type="file"
                        name="image"
                        accept={acceptedImageFormats + acceptedVideoFormats}
                        onChange={handleUploadImageOrVideo} />
                </label>
                <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isSpoiler && postCreatorTextareaStyles.active}`}
                    onClick={handleIsSpoiler}
                    role='button'
                    tabIndex={0}
                >
                    <span>SPOILER</span>
                </div>
                <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isNSFW && postCreatorTextareaStyles.active}`}
                    onClick={handleIsNSFW}
                    role='button'
                    tabIndex={0}>
                    <span>NSFW</span>
                </div>
                <div id={postCreatorTextareaStyles.sendPost}
                    onClick={handleSendCard}
                    ref={sendCardRef}
                    role='button'
                    tabIndex={0}
                >
                    {isAvailableForSending ? <div>➢</div> : <div id={postCreatorTextareaStyles.creatingNewPost} />}
                </div>
            </div>
        </div>
    )
}
