import React from 'react';
import PostHomeLoadingDivs from './postHomeLoadingDivs';

export default function PostsSectionHomeLoading() {
    return (
        <>
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
            <PostHomeLoadingDivs />
        </>
    )
}
