/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useState } from 'react'
import Comment from './comment/comment'
import PostCommentsReplies from './postCommentsReplies/postCommentsReplies'
import { PostCommentsRepliesType, PostCommentsType } from '../postCommentsType';

export const PostCommentsAndRepliesContext = createContext<{ comment: PostCommentsType } | null>(null);

export default function PostCommentsAndRepliesWrapper({ comment }: { comment: PostCommentsType }) {
    const [replyList, setReplyList] = useState<PostCommentsRepliesType[]>([]);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    return (
        <PostCommentsAndRepliesContext.Provider value={{ comment }}>
            <Comment comment={comment} setReplyList={setReplyList} isEditable={isEditable} setIsEditable={setIsEditable} />
            <PostCommentsReplies comment={comment} setReplyList={setReplyList} replyList={replyList} setIsEditable={setIsEditable} />
        </PostCommentsAndRepliesContext.Provider>
    )
}
