/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */

/* eslint-disable react/no-array-index-key */
import React, { useEffect, useState, useCallback } from 'react';
import { io } from 'socket.io-client';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import styles from './notifications.module.css';
import { API_URL } from '../../../utilities/utils';

const SERVER_URL = process.env.NODE_ENV === 'production' ?
    'https://gamerth-2-0-backend.onrender.com/api' :
    'https://localhost:5000/api'; // process.env.GAMERTH_API_URL || 'https://localhost:5000';

export const notificationsSocket = io(SERVER_URL as string, {
    reconnection: true,
    reconnectionAttempts: Infinity,
    reconnectionDelay: 1000,
    reconnectionDelayMax: 5000,
    timeout: 20000,
    secure: true,
    transports: ['websocket', 'polling'],
});

export default function Notifications() {
    const [notifications, setNotifications] = useState<{ notificationId: string, message: string, postUrl?: string }[]>([]);
    const navigate = useNavigate();

    const connectSocket = useCallback(() => {
        notificationsSocket.on('connect', () => {
            console.log('Successfully connected!');
        });

        notificationsSocket.on('disconnect', (reason) => {
            console.log('Disconnected:', reason);
        });

        notificationsSocket.on('reconnect_attempt', (attempt) => {
            console.log('Reconnect attempt:', attempt);
        });

        return notificationsSocket;
    }, []);

    useEffect(() => {
        const socket = connectSocket();
        const handleNotification = (data: { notificationId: string, message: string, postUrl?: string }) => {
            setNotifications((arr) => [data, ...arr]);
            setTimeout(() => requestAnimationFrame(() => {
                setNotifications((arr) => {
                    const newArr = [...arr];
                    newArr.pop();
                    return newArr;
                });
            }), 10000);
        };

        socket.on('receiveErrorMessage', handleNotification);
        socket.on('receivingMessageFromAnotherUser', handleNotification);

        return () => {
            socket.off("receiveErrorMessage", handleNotification);
            socket.off("receivingMessageFromAnotherUser", handleNotification);
            socket.disconnect();
        }
    }, [connectSocket]);

    const handleCloseNotif = (notificationId: string) => {
        setNotifications((arr) => arr.filter((item) => item.notificationId !== notificationId));
    }

    const handleMarkNotifAsSeen = async (notificationId: string, notificationUrl: string | null) => {
        if (!notificationUrl) return;
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/mark-notification-as-seen`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            navigate(notificationUrl);
        } catch (error: any) {
            notificationsSocket.emit('sendErrorMessage', error.message);
        }
    }

    return (
        <div id={styles.notificationsWrapper}>
            {
                notifications?.map((item) => (
                    <div key={item.notificationId}
                        className={styles.notificationDiv}
                        onClick={() =>
                            handleMarkNotifAsSeen(item.notificationId, item.postUrl || null)} role='button' tabIndex={0}>
                        {
                            item.postUrl ?
                                <div className={styles.notificationsWithLink}>
                                    <span>{item.message}</span>
                                </div>
                                :
                                <div className={styles.notificationsWithLink}>{item.message}</div>
                        }
                        <FontAwesomeIcon icon={faClose} onClick={() => handleCloseNotif(item.notificationId)} className={styles.closeNotif} />
                    </div>
                ))
            }
        </div>
    )
}