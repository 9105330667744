/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Link } from 'react-router-dom';
import firstCardHomeStyles from './firstCardHome.module.css';
import cardsStyle from '../cardsSectionHomeStyles.module.css';
import { FirstCardHomeProps } from './firstCardHomeTypes';

export default function FirstCardHomeTypeVideo({ card }: FirstCardHomeProps) {
  return (
    <Link to={`/thread/${card.postThreadName}/card/${card.titleId}`}>
      <video
        id={firstCardHomeStyles.firstCardVideoHome}
        className={card.isNSFW || card.isSpoiler ? cardsStyle.nsfwFilter : ''}
        src={card.videoUrl}
        preload='auto'
        title={card.title}
      />
    </Link>
  );
}
