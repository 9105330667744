/* eslint-disable import/no-cycle */
import React from 'react';
import CardVotes from './cardVotes/cardVotes';
import cardHeaderStyles from './cardHeaderStyles.module.css';
import CardInfoAndTitle from './cardInfoAndTitle/cardInfoAndTitle';
import { PostType } from '../../../../universalTypes/universalTypes';

export default function CardHeader({ card }: { card: PostType }) {
    return (
        <div className={cardHeaderStyles.cardHeader}>
            <CardVotes card={card} />
            <CardInfoAndTitle card={card} />
        </div>
    )
}
