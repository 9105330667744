/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */

import React, { memo } from 'react';
import { useQueryClient } from 'react-query';
import { API_URL } from "../../../../utilities/utils";
import BasePortal from "../basePortal";
import banUserFromThreadPortalStyles from './banUserFromThreadPortalStyles.module.css';
import banUserFromThreadImage from '../../../../assets/images/banUserFromThread3.jpg';
import maqueta from '../../../../assets/images/maqueta.png';
import { PostType } from '../../../../universalTypes/universalTypes';
import { notificationsSocket } from '../../../nav/notifications/notifications';

function BanUserFromThreadInCommentsPortal({ post, comment }: { post: PostType, comment: any }) {
    const queryClient = useQueryClient();
    const handleBanUserFromThread = (comment: any) => {
        fetch(`${API_URL}/thread/ban-user-from-thread`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify(
                    {
                        userIdToBan: comment?.commentAuthorData.id,
                        threadId: post?.postThreadData._id
                    }),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                queryClient.setQueryData("banUserFromThreadInCommentsPortal", false);
            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));
    }
    const handleCancel = () => queryClient.setQueryData("banUserFromThreadInCommentsPortal", false);

    return <BasePortal queryKey='banUserFromThreadInCommentsPortal'>
        <div id={banUserFromThreadPortalStyles.banUserFromThreadPortalWrapper}>
            <img src={banUserFromThreadImage || maqueta}
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div>
                <p>YOU ARE ABOUT TO <span>BAN </span>
                    USER <span>{comment?.commentAuthorData.nickname}</span> FROM THE THREAD <span>{post?.postThreadData.name}</span></p>
                <p> <span>This user will not be able to join and comment on this thread anymore</span>. </p>
                <p>Do you wish to continue?</p>
            </div>
            <div id={banUserFromThreadPortalStyles.cancelAndDeleteButtonsWrapper}>
                <div
                    id={banUserFromThreadPortalStyles.banUserFromThreadPortalCancelButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}
                >no, wait!</div>
                <div
                    id={banUserFromThreadPortalStyles.banUserFromThreadPortalConfirmButton}
                    role='button'
                    tabIndex={0}
                    onClick={() => handleBanUserFromThread(comment)}
                >yeah, kick this retard out!</div>
            </div>
        </div>
    </BasePortal>
}

function areEqual(prevProps: Readonly<{ post: PostType }>, nextProps: Readonly<{ post: PostType }>) {
    return prevProps.post._id === nextProps.post._id;
}

export default memo(BanUserFromThreadInCommentsPortal, areEqual);