/* eslint-disable react/require-default-props */
import React, { ReactNode } from 'react';
import mainWrapperStyles from './mainWrapperStyles.module.css';

export default function MainWrapper({ children, optionalClass }: { children: ReactNode, optionalClass?: string }) {
    return (
        <div id={mainWrapperStyles.mainWrapper} className={optionalClass}>{children}</div>
    )
}




