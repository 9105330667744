import React from 'react';
import userProfileStyles from '../userProfileStyles.module.css';

export default function UserProfileData({ children }: any) {
    return (
        <div id={userProfileStyles.userProfileData}>
            {children}
        </div>
    )
}
