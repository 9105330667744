import { API_URL } from "../../../../../../utilities/utils";
import { PostCommentsRepliesType } from "../../postCommentsType";

const fetchPostCommentsReplies = async (postId: string, commentId: string, setReplyList: any, isBeingCalledFromProfile: boolean, userProfileId?: string) => {
    console.log("userProfileId 22----->", userProfileId);
    console.log("setReplyList 22----->", setReplyList);
    const ac = new AbortController();
    const response = await fetch(
        isBeingCalledFromProfile ?
            `${API_URL}/user-profile/get-user-profile-comments-replies/${userProfileId}/${commentId}` :
            `${API_URL}/post/get-post-comments-replies/${postId}/${commentId}`,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                authorization: `Bearer ${window.localStorage.token as string}`
            },
            signal: ac.signal
        },
    );
    const parsedResponse = await response.json();
    if (!parsedResponse) throw new Error("NO REPLIES!");
    try {
        if (parsedResponse.message) throw new Error(parsedResponse.message);
    } catch (error: any) {
        console.log("error ------>", error?.message)
    }
    console.log('que trae parsedResponse?', parsedResponse);
    setReplyList(parsedResponse as PostCommentsRepliesType[]);
    // setAddReply(true);
};

export default fetchPostCommentsReplies;