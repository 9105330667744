/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { faFlag } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QueryClient } from 'react-query'
import { handleReportPost } from '../optionsUtils'
import { PostType } from '../../../../../../../universalTypes/universalTypes'

export default function Report({ context, post, queryClient }: { context: any, post: PostType, queryClient: QueryClient }) {
    return (
        <div onClick={() => handleReportPost(context, post, queryClient)} role='button' tabIndex={0}>
            <FontAwesomeIcon icon={faFlag} />
            <span>Report</span>
        </div>
    )
}
