import { OrderType, PostType } from "../../../universalTypes/universalTypes";
import { API_URL } from "../../../utilities/utils";

function getRequestURL(order: OrderType, page: number, threadId: string) {
  switch (order.order) {
    case 'fetchNew':
      return `${API_URL}/thread/get-thread-posts-poll-and-cards/infinite-scroll/${threadId}/${page}`;
    case 'fetchHot':
      return `${API_URL}/thread/get-thread-posts-poll-and-cards/infinite-scroll-hot/${threadId}/${page}`;
    case 'fetchTop':
      return `${API_URL}/thread/get-thread-posts-poll-and-cards/infinite-scroll-top/${threadId}/${page}`;
    case 'search':
      return `${API_URL}/thread/get-thread-posts-poll-and-cards/infinite-scroll-search/${threadId}/${order.value}/${page}`;
    default:
      return `${API_URL}/thread/get-thread-posts-poll-and-cards/infinite-scroll/${threadId}/${page}`;
  }
}

export default async function fetchThreadPostsInfiniteScroll(ac: AbortController, page: number, setHasMore: React.Dispatch<React.SetStateAction<boolean>>, setPostsList: React.Dispatch<React.SetStateAction<PostType[] | undefined>>, order: { order: string }, threadId: string) {
  if (page < 2) return null;
  const token = window.localStorage.token as string;
  const fetchOptions = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    signal: ac.signal,
  } as RequestInit;
  const response = await fetch(getRequestURL(order, page, threadId), fetchOptions);
  const parsedResponse = await response.json();
  if (parsedResponse.message) return console.log("------>", parsedResponse.message);
  if (parsedResponse.length < 1) return setHasMore(false);
  return setPostsList((prevPostsList) => prevPostsList && [...prevPostsList, ...parsedResponse]);
};