import { useQuery } from 'react-query';
import { API_URL } from '../../utilities/utils';
import { ThreadsType } from '../postCreator/postCreatorTypes';

async function fetchThreadToEdit(ac: AbortController, threadName: string) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/edit-thread/${threadName}`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as ThreadsType;
}



const useEditThreadQuery = (ac: AbortController, threadName: string) => useQuery(['editThread'],
    () => fetchThreadToEdit(ac, threadName),
    { refetchOnWindowFocus: false });

export default useEditThreadQuery;