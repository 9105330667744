/* eslint-disable no-underscore-dangle */
import { useQuery } from 'react-query';
import { API_URL } from '../../../../../utilities/utils';
import { UserProfileType } from '../../../userProfileTypes';

export type MemberOfListAndOrderType = {
    order: string, value?: string
  }

  export type MemberOfType = {
    _id: string,
    members: number,
    isNSFW: boolean,
    isSpoiler: boolean,
    backgroundImage: string,
    lowResThumbnail: string,
    numberOfPosts: number,
    name: string,
    description: string,
    timestamp: number,
    authorName: string
  }

async function fetchThreadsThisUserIsAMemberOf(ac: AbortController, user: UserProfileType) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/user-profile/get-threads-this-user-is-member-of/${user._id}`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as MemberOfType[];
}

const useMemberOfQuery = (ac: AbortController, user: UserProfileType) => 
    useQuery('memberOf',
    () => fetchThreadsThisUserIsAMemberOf(ac, user),
    { refetchOnWindowFocus: false });

export default useMemberOfQuery;