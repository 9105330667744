import { useQuery } from 'react-query';
import { API_URL } from '../../../utilities/utils';

const fetchUserProfilePublications = async (userId: string, ac: AbortController) => {
    const response = await fetch(
        `${API_URL}/user-profile/get-user-profile-comments/${userId}`,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                authorization: `Bearer ${window.localStorage.token as string}`
            },
            signal: ac.signal
        },
    );
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse;
};

const useUserProfilePublicationsQuery = (userId: string, ac: AbortController) =>
    useQuery('userProfilePublications',
        () => fetchUserProfilePublications(userId, ac),
        { refetchOnWindowFocus: false }
    );

export default useUserProfilePublicationsQuery;