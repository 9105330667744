/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import threadsStyles from './threadsSectionStyles.module.css';
import useThreadsQuery from './threadsSectionCustomHooks/useThreadsSectionQuery';
import Thread from './thread/thread';
import ThreadsLoading from './threadsLoading/threadsLoading';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import UniversalNav from '../sharedComponents/universalNav/universalNav';

export default function Threads() {
    const [order, setOrder] = useState({ order: 'fetchNew' });
    const ac = new AbortController();
    const { data: threadsList, remove, isError } = useThreadsQuery(ac, order);
    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
    useEffect(() => () => remove(), []);
    if (isError) return <div>errrrror</div>

    return (
        <MainWrapper>
            <div id={threadsStyles.threadsGrid}>
                <UniversalNav setOrder={setOrder} order={order} typeOfSearch='threads' />
                <section id={threadsStyles.threadsSection}>
                    {
                        threadsList ?
                            threadsList.map((thread) => <Thread thread={thread} key={thread._id} />) : <ThreadsLoading />
                    }
                </section>
            </div>
        </MainWrapper>
    )
}
