import React from 'react';
import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Root from './components/root';
import './gamerth.css';
import Home from './components/homeSection/home';
import Post from './components/post/post';
import Cards from './components/cardsSection/cardsSection';
import Card from './components/card/card';
import ThreadsSection from './components/threadsSection/threadsSection';
import UserProfile from './components/userProfile/userProfile';
import PostCreator from './components/postCreator/postCreator';
import Poll from './components/poll/poll';
import Thread from './components/thread/thread';
import ThreadCreator from './components/threadCreator/threadCreator';
import ThreadEditor from './components/threadEditor/threadEditor';
import MobileNotifications from './components/mobileNotifications/mobileNotifications';

const router = createBrowserRouter([
  {
    path: '/',
    element: (
      <Root />
    ),
    errorElement: <div style={{ color: 'red', fontSize: '10rem', padding: '50px' }}>ERROR!!!!!</div>,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/cards',
        element: <Cards />,
      },
      {
        path: '/threads',
        element: <ThreadsSection />,
      },
      {
        path: '/create-new-post/:threadName',
        element: <PostCreator />,
      },
      {
        path: '/create-new-post',
        element: <PostCreator />,
      },
      {
        path: '/create-new-thread',
        element: <ThreadCreator />,
      },
      {
        path: '/edit-thread/:threadName',
        element: <ThreadEditor />,
      },
      {
        path: '/thread/:threadName',
        element: <Thread />,
      },
      {
        path: '/thread/:threadName/post/:postId',
        element: <Post />,
      },
      {
        path: '/thread/:threadName/post/:postId/:edit',
        element: <Post />,
      },
      {
        path: '/thread/:threadName/poll/:pollId',
        element: <Poll />,
      },
      {
        path: '/thread/:threadName/card/:cardId',
        element: <Card />,
      },
      {
        path: '/profile/:userProfile',
        element: <UserProfile />,
      },
      {
        path: '/profile/:userProfile/:profileSection',
        element: <UserProfile />,
      },
      {
        path: '/notifications-mobile',
        element: <MobileNotifications />,
      },
    ],
  },
]);

createRoot(document.getElementById('root') as HTMLElement).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
