import React from 'react';
import CardLoading from './cardLoading';

export default function CardsLoading() {
    return (
        <>
            <CardLoading />
            <CardLoading />
            <CardLoading />
        </>
    )
}
