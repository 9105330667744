/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import InfiniteScroll from 'react-infinite-scroll-component';
import useUserProfileNotifications from './userProfileNotificationsCustomHooks';
import { NotificationsType } from './notificationsType';
import timeSincePosted, { API_URL } from '../../../../utilities/utils';
import notificationsStyle from './userProfileNotificationsStyles.module.css'
import fetchUserProfileNotificationsInfiniteScroll from './userProfileNotificationsInfiniteScroll';
import PostHomeSectionEndOfList from '../../../homeSection/postsSectionHome/postHomeSectionEndOfList/postHomeSectionEndOfList';
import UserProfileDontHaveAny from '../../../sharedComponents/userProfileDontHaveAny/userProfileDontHaveAny';
import { UserProfileType } from '../../userProfileTypes';
import userProfileStyles from '../../userProfileStyles.module.css';

export default function UserProfileNotifications({ userProfile }: { userProfile: UserProfileType }) {
    const ac = new AbortController();
    const { data, refetch, remove, isFetching } = useUserProfileNotifications(ac, userProfile?.isThisUserTheProfileOwner);
    const [notifications, setNotifications] = useState<NotificationsType[] | null>(null);
    const navigate = useNavigate();
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    useEffect(() => { if (data) setNotifications(() => data && [...data]) }, [data]);
    useEffect(() => {
        setPage(1);
        setHasMore(true);
    }, []);
    useEffect(() => {
        if (notifications?.length) setPage((page) => page + 1);
    }, [notifications]);
    useEffect(() => () => remove(), []);
    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
    if (isFetching) return <div id={userProfileStyles.isFetching}><div>isFetching...</div></div >
    if (!notifications?.length) return <div id={userProfileStyles.isFetching}>
        <UserProfileDontHaveAny userProfile={userProfile as UserProfileType}
            isOwnerMessage="You don't have any notifications."
            isNotOwnerMessage="this is not your profile..." />
    </div >

    const handleMarkNotifAsSeen = async (event: React.MouseEvent, notificationId: string, notificationUrl: string) => {
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/mark-notification-as-seen`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            refetch();
            navigate(notificationUrl);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const handleDeleteNotification = async (event: React.MouseEvent, notificationId: string) => {
        event.stopPropagation();
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/delete-notification`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            refetch();
        } catch (error: any) {
            console.log(error.message);
        }
    }

    return (
        <div id={notificationsStyle.notificationsSectionWrapper}>
            <InfiniteScroll
                style={{ borderRadius: '.5rem', overflow: 'initial' }}
                dataLength={notifications?.length || 0}
                next={() => fetchUserProfileNotificationsInfiniteScroll(ac, page, setHasMore, setNotifications)}
                hasMore={hasMore}
                loader={<p>Loading...</p>}
                endMessage={<PostHomeSectionEndOfList />}
            >
                {
                    notifications?.map((item, _) =>
                        <div onClick={(event) => handleMarkNotifAsSeen(event, item._id, item.notificationLink)}
                            className={notificationsStyle.notificationDiv}
                            key={item._id}>
                            <span className={notificationsStyle.notificationContent}>{item.notificationContent}</span>&nbsp;
                            <span className={notificationsStyle.timeSinceNotifications}>•&nbsp;
                                {timeSincePosted(item.timestamp)}</span>&nbsp;
                            <FontAwesomeIcon icon={faClose} onClick={(e: React.MouseEvent) => handleDeleteNotification(e, item._id)}
                                className={notificationsStyle.deleteNotification} />
                            {item.isNotificationNew && <span className={notificationsStyle.isNotificationNew}>•</span>}
                        </div>)
                }
            </InfiniteScroll>
        </div>
    )
}
