enum ProfileSections {
    WALL = 'WALL',
    PICTURES = 'PICTURES',
    FRIENDS = 'FRIENDS',
    NOTIFICATIONS = 'NOTIFICATIONS',
    BOOKMARKED = 'BOOKMARKED',
    MEMBEROF = 'MEMBEROF',
    INFORMATION = 'INFORMATION'
}

export default ProfileSections