import { useQuery } from 'react-query';
import { ThreadsListAndOrderType, ThreadsSectionType } from '../threadsSectionTypes';
import { API_URL } from '../../../utilities/utils';

function getRequestURL(order: ThreadsListAndOrderType) {
    switch (order.order) {
        case 'fetchNew':
            return `${API_URL}/threads/get-threads`;
        case 'fetchHot':
            return `${API_URL}/threads/get-threads-hot`;
        case 'fetchTop':
            return `${API_URL}/threads/get-threads-top`;
        case 'search':
            return `${API_URL}/threads/search-threads/${order.value}`;
        default:
            return `${API_URL}/threads/get-threads`;
    }
}

async function fetchThreads(ac: AbortController, order: ThreadsListAndOrderType) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(getRequestURL(order), fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as ThreadsSectionType[];
}

const useThreadsQuery = (ac: AbortController, order: ThreadsListAndOrderType) => useQuery([order.order, order.value],
    () => fetchThreads(ac, order),
    { refetchOnWindowFocus: false });

export default useThreadsQuery;