/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import { FirstCardHomeProps, IsNsfwOrSpoiler } from './firstCardHomeTypes';
import firstCardHomeStyles from './firstCardHome.module.css';

export default function FirstCardHomeTitle({ card }: FirstCardHomeProps) {
  const handleNSFWorSpoilerToggle = (event: React.MouseEvent) => {
    event.preventDefault();
    const target = event.currentTarget?.parentElement?.parentElement?.nextElementSibling?.children[1];
    const target2 = event.currentTarget?.parentElement?.parentElement?.nextElementSibling?.children[0];
    if (!target || !target2) return;
    target2.remove();
    target.classList.remove(target.classList[0]);
  }
  const isNsfwOrSpoiler = (item: IsNsfwOrSpoiler) => {
    switch (true) {
      case item.isNSFW && item.isSpoiler:
        return <>
          <span className={firstCardHomeStyles.nsfwCardTitleHome}
            onClick={handleNSFWorSpoilerToggle}
            role='button'
            tabIndex={0}>NSFW</span>&nbsp;
          <span className={firstCardHomeStyles.nsfwCardTitleHome}
            onClick={handleNSFWorSpoilerToggle}
            role='button'
            tabIndex={0}>
            SPOILER
          </span>
        </>
      case item.isNSFW:
        return <span className={firstCardHomeStyles.nsfwCardTitleHome}
          onClick={handleNSFWorSpoilerToggle}
          role='button'
          tabIndex={0}>NSFW</span>
      case item.isSpoiler:
        return <span className={firstCardHomeStyles.nsfwCardTitleHome}
          onClick={handleNSFWorSpoilerToggle}
          role='button'
          tabIndex={0}>
          SPOILER
        </span>
      default: return null;
    }
  }
  return <Link to={`/thread/${card.postThreadName}/card/${card.titleId}`} title={card.title}>
    {card.title} {isNsfwOrSpoiler(card)}
  </ Link>
    ;
}
