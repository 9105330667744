import axios from "axios";
import { ROOT_URL } from "./utils";
import { notificationsSocket } from "../components/nav/notifications/notifications";

/* eslint-disable */
export const THREAD_OR_USER = /([u|t]\/[A-z 0-9][^ \[.,\/#!$%\^&\*;:{}=\`\'\"\´~()\]\n]+)/g;
export const ROOT_URL_HOME = 'https://localhost:5000/api/home';

export function isImage(url?: string) {
  if (!url) return false;
  return /^https?:\/\/.+\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
}

export function dataURLtoFile(dataurl: string, filename: string): File {
  const arr: any[] = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export function elementInViewport(el: any) {
  const elemento = el;

  console.log(elemento.getBoundingClientRect().width);

  const width_ele = `${elemento.getBoundingClientRect().width}px`;

  let top = el.offsetTop;
  let left = el.offsetLeft;
  const width = el.offsetWidth;
  const height = el.offsetHeight;

  while (el.offsetParent) {
    el = el.offsetParent;
    top += el.offsetTop;
    left += el.offsetLeft;
  }

  if (window.pageYOffset > 268) {
    console.log(el);

    elemento.style.position = 'fixed';
    elemento.style.zIndex = '10';
    elemento.style.width = width_ele;
    elemento.style.top = '0';

    return;
  }

  elemento.style.position = '';
  elemento.style.zIndex = '';
  elemento.style.width = '';
  elemento.style.top = '';
}

export function urlCheck(url: string) {
  const regex = "((?:(http|https|Http|Https|rtsp|Rtsp):\\/\\/(?:(?:[a-zA-Z0-9\\$\\-\\_\\.\\+\\!\\*\\'\\(\\)"
    + '\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,64}(?:\\:(?:[a-zA-Z0-9\\$\\-\\_'
    + "\\.\\+\\!\\*\\'\\(\\)\\,\\;\\?\\&\\=]|(?:\\%[a-fA-F0-9]{2})){1,25})?\\@)?)?"
    + '((?:(?:[a-zA-Z0-9][a-zA-Z0-9\\-]{0,64}\\.)+' // named host
    + '(?:' // plus top level domain
    + '(?:aero|arpa|asia|a[cdefgilmnoqrstuwxz])'
    + '|(?:biz|b[abdefghijmnorstvwyz])'
    + '|(?:cat|com|coop|c[acdfghiklmnoruvxyz])'
    + '|d[ejkmoz]'
    + '|(?:edu|e[cegrstu])'
    + '|f[ijkmor]'
    + '|(?:gov|g[abdefghilmnpqrstuwy])'
    + '|h[kmnrtu]'
    + '|(?:info|int|i[delmnoqrst])'
    + '|(?:jobs|j[emop])'
    + '|k[eghimnrwyz]'
    + '|l[abcikrstuvy]'
    + '|(?:mil|mobi|museum|m[acdghklmnopqrstuvwxyz])'
    + '|(?:name|net|n[acefgilopruz])'
    + '|(?:org|om)'
    + '|(?:pro|p[aefghklmnrstwy])'
    + '|qa'
    + '|r[eouw]'
    + '|s[abcdeghijklmnortuvyz]'
    + '|(?:tel|travel|t[cdfghjklmnoprtvwz])'
    + '|u[agkmsyz]'
    + '|v[aceginu]'
    + '|w[fs]'
    + '|y[etu]'
    + '|z[amw]))'
    + '|(?:(?:25[0-5]|2[0-4]' // or ip address
    + '[0-9]|[0-1][0-9]{2}|[1-9][0-9]|[1-9])\\.(?:25[0-5]|2[0-4][0-9]'
    + '|[0-1][0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1]'
    + '[0-9]{2}|[1-9][0-9]|[1-9]|0)\\.(?:25[0-5]|2[0-4][0-9]|[0-1][0-9]{2}'
    + '|[1-9][0-9]|[0-9])))'
    + '(?:\\:\\d{1,5})?)' // plus option port number
    + '(\\/(?:(?:[a-zA-Z0-9\\;\\/\\?\\:\\@\\&\\=\\#\\~' // plus option query params
    + "\\-\\.\\+\\!\\*\\'\\(\\)\\,\\_])|(?:\\%[a-fA-F0-9]{2}))*)?"
    + '(?:\\b|$)';

  return url.match(regex);
}

export function THREAD_FROM_URL(dir: string) {
  if (dir && dir.includes('/thread/')) {
    const temp = dir.slice(3);
    if (temp.includes('/')) return temp.slice(0, temp.indexOf('/'));
    return temp;
  }
  return '';
}

export function IS_ONLINE() {
  window.onoffline = () => false;

  return true;// mmmmmmmmmmmmmmmmmmmmm no se si esto va a funcionar che
}

export function un_thread(props: any, setThread: Function, setThreadTitulo: Function, input_thread: any) {
  if (!props?.thread) return;

  fetch(
    `${ROOT_URL}/onethread/${props.thread}`,

    {

      method: 'get',

      headers: { Accept: 'application/json' },

      credentials: 'include',

    },
  )

    .then((e) => {
      if (e.status === 404) throw new Error('NO SE ENCONTRO EL THREAD!');

      return e.json();
    })

    .then((e) => {
      console.log('que trae estoooooo?', e);

      if (!e.threads) return;

      input_thread.value = `t/${e.threads.title}`;

      setThread(e.threads);

      setThreadTitulo(e.threads.title);
    })

    .catch((err) => console.log('el error fue', err));
}

export function un_thread2(con: any, input_thread: any) {
  fetch(
    `${ROOT_URL}/onethread/${input_thread.value.replace('t/', '')}`,

    {

      method: 'GET',

      mode: 'cors',

      headers: { Accept: 'application/json', Authorization: `Bearer ${window.localStorage.token}` },

    },
  )

    .then((e) => {
      if (e.status === 404) throw new Error('NO SE ENCONTRO EL THREAD!');

      return e.json();
    })

    .then((e) => {
      console.log('que trae estoooooo?', e);

      if (!e.threads) return;

      con.setThread(e.threads);

      con.setThreadTitulo(e.threads.title);

      con.setThreadMembers(e.threads.members);

      if ((e.threads.all) || (e.threads.posts && e.threads.cards && e.threads.polls)) {
        if (con.publicacionNav) {
          con.setPublicacion(true);
          con.setCards(false);
          con.setEncuesta(false);
        }

        if (con.cardsNav) {
          con.setPublicacion(false);
          con.setCards(true);
          con.setEncuesta(false);
        }

        if (con.encuestaNav) {
          con.setPublicacion(false);
          con.setCards(false);
          con.setEncuesta(true);
        }

        con.setAllowPub(true);
        con.setAllowCards(true);
        con.setAllowPolls(true);

        return;
      }

      if (e.threads.posts && e.threads.cards) {
        if (con.publicacionNav) {
          con.setPublicacion(true);
          con.setCards(false);
          con.setEncuesta(false);
        }

        if (con.cardsNav) {
          con.setPublicacion(false);
          con.setCards(true);
          con.setEncuesta(false);
        }

        con.setAllowPub(true);
        con.setAllowCards(true);
        con.setAllowPolls(false);

        return;
      }

      if (e.threads.posts && e.threads.polls) {
        if (con.publicacionNav) {
          con.setPublicacion(true);
          con.setCards(false);
          con.setEncuesta(false);
        }

        if (con.encuestaNav) {
          con.setPublicacion(false);
          con.setCards(false);
          con.setEncuesta(true);
        }

        con.setAllowPub(true);
        con.setAllowCards(false);
        con.setAllowPolls(true);

        return;
      }

      if (e.threads.posts) {
        con.setPublicacion(true);
        con.setCards(false);
        con.setEncuesta(false);

        con.setAllowPub(true);
        con.setAllowCards(false);
        con.setAllowPolls(false);
        return;
      }

      if (e.threads.cards && e.threads.polls) {
        if (con.cardsNav) {
          con.setPublicacion(false);
          con.setCards(true);
          con.setEncuesta(false);
        }

        if (con.encuestaNav) {
          con.setPublicacion(false);
          con.setCards(false);
          con.setEncuesta(true);
        }

        con.setAllowPub(false);
        con.setAllowCards(true);
        con.setAllowPolls(true);

        return;
      }

      if (e.threads.cards) {
        con.setPublicacion(false);
        con.setCards(true);
        con.setEncuesta(false);

        con.setAllowPub(false);
        con.setAllowCards(true);
        con.setAllowPolls(false);

        return;
      }

      if (e.threads.polls) {
        con.setPublicacion(false);
        con.setCards(false);
        con.setEncuesta(true);

        con.setAllowPub(false);
        con.setAllowCards(false);
        con.setAllowPolls(true);
      }
    })

    .catch((err) => console.log('el error fue', err));
}

export function scroll_into_view() {
  const ultimo: Element | any = document.getElementsByClassName('segundo-div-contenedor-comentario')[0].lastElementChild;

  const ultimo_nick = ultimo.children[0].children[1].children[0].children[0];

  const timestamp = ultimo.children[0].children[1].children[0].children[1];

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  ultimo.style.transition = 'all 2s ease-in-out';

  ultimo_nick.style.transition = 'all 2s ease-in-out';

  timestamp.style.transition = 'all 2s ease-in-out';

  ultimo.style.backgroundColor = 'red';

  ultimo.style.color = 'black';

  ultimo_nick.style.color = 'black';

  timestamp.style.color = 'black';

  requestAnimationFrame(() => {
    ultimo.style.backgroundColor = '';

    ultimo.style.color = '';

    ultimo_nick.style.color = '';

    timestamp.style.color = '';
  });
}

export function scroll_into_viewV2() {
  const ultimo: Element | any = document.getElementsByClassName('segundo-div-contenedor-comentario-alt')[0].children[0].children[0];

  const ultimo_nick = ultimo.children[0].children[1].children[0].children[0];

  const timestamp = ultimo.children[0].children[1].children[0].children[1];

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  ultimo.style.transition = 'all 2s ease-in-out';

  ultimo_nick.style.transition = 'all 2s ease-in-out';

  timestamp.style.transition = 'all 2s ease-in-out';

  ultimo.style.backgroundColor = 'red';

  ultimo.style.borderRadius = '8px';

  ultimo.style.color = 'black';

  ultimo_nick.style.color = 'black';

  timestamp.style.color = 'black';

  setTimeout(() => {
    ultimo.style.backgroundColor = '';

    ultimo.style.color = '';

    ultimo_nick.style.color = '#da1414';

    timestamp.style.color = '';
  }, 2000);
}

export function scroll_into_view_respuesta(elem: any) {
  if (!elem) return; console.log('que trae elem?', elem);

  const ultimo: Element | any = elem;

  const ultimo_nick = ultimo.children[0].children[1].children[0].children[0];

  const timestamp = ultimo.children[0].children[1].children[0].children[1];

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  ultimo.style.transition = 'all 2s ease-in-out';

  ultimo_nick.style.transition = 'all 2s ease-in-out';

  timestamp.style.transition = 'all 2s ease-in-out';

  ultimo.style.backgroundColor = 'red';

  ultimo.style.color = 'black';

  ultimo_nick.style.color = 'black';

  timestamp.style.color = 'black';

  setTimeout(() => {
    ultimo.style.backgroundColor = '';

    ultimo.style.color = '';

    ultimo_nick.style.color = '';

    timestamp.style.color = '';
  }, 2000);
}

export function scroll_into_view_respuestaV2(elem: any) {
  if (!elem) return;

  const ultimo: Element | any = elem;

  const ultimo_nick = ultimo.children[1].children[0].children[0];

  const timestamp = ultimo.children[1].children[0].children[1];

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  ultimo.style.transition = 'all 2s ease-in-out';

  ultimo_nick.style.transition = 'all 2s ease-in-out';

  timestamp.style.transition = 'all 2s ease-in-out';

  ultimo.style.backgroundColor = 'red';

  ultimo.style.color = 'black';

  ultimo_nick.style.color = 'black';

  timestamp.style.color = 'black';

  setTimeout(() => {
    ultimo.style.backgroundColor = '';

    ultimo.style.color = '';

    ultimo_nick.style.color = '#da1414';

    timestamp.style.color = '';
  }, 2000);
}

export function scroll_into_view_comentariosV2(elem: any) {
  if (!elem) return;

  console.log(elem);

  const ultimo: Element | any = elem;

  const ultimo_nick = ultimo.children[0].children[0].children[1].children[0].children[0];

  const timestamp = ultimo.children[0].children[0].children[1].children[0].children[1];

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  elem.children[0].style.transition = 'all 2s ease-in-out';

  ultimo_nick.style.transition = 'all 2s ease-in-out';

  timestamp.style.transition = 'all 2s ease-in-out';

  elem.children[0].style.backgroundColor = 'red';

  ultimo.style.color = 'black';

  ultimo_nick.style.color = 'black';

  timestamp.style.color = 'black';

  setTimeout(() => {
    elem.children[0].style.backgroundColor = '';

    elem.children[0].style.color = '#ffffffcc';

    ultimo_nick.style.color = '#da1414';

    timestamp.style.color = '';
  }, 2000);
}

export function scroll_into_view_comentarios_BoxShadow(elem: any) {
  if (!elem) return;

  console.log(elem);

  const ultimo: Element | any = elem;

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  elem.style.transition = 'all 2s ease-in-out';

  elem.style.boxShadow = '0px 0px 0px 5px red';

  setTimeout(() => {
    elem.style.boxShadow = 'rgb(0, 0, 0) 0px 1px 2px 0px, rgba(0, 0, 0, 0.15) 0px 1px 3px 1px';
  }, 2000);
}

export function scroll_into_view_comentariosV3(elem: any) {
  if (!elem) return;

  console.log(elem);

  const ultimo: Element | any = elem;

  const ultimo_nick = ultimo.children[0].children[1].children[0].children[0];

  const timestamp = ultimo.children[0].children[1].children[0].children[1];

  ultimo.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });

  elem.style.transition = 'all 2s ease-in-out';

  ultimo_nick.style.transition = 'all 2s ease-in-out';

  timestamp.style.transition = 'all 2s ease-in-out';

  elem.style.backgroundColor = 'red';

  ultimo.style.color = 'black';

  ultimo_nick.style.color = 'black';

  timestamp.style.color = 'black';

  setTimeout(() => {
    elem.style.backgroundColor = '';

    elem.style.color = '#ffffffcc';

    ultimo_nick.style.color = '#da1414';

    timestamp.style.color = '';
  }, 2000);
}



export const client = axios.create({
  baseURL: ROOT_URL
});

export function lector(): FileReader {
  return new FileReader();
}

enum Privacy {
  PUBLIC = 'Public',
  ONLY_FRIENDS = 'Only Friends',
  PRIVATE = 'Private'
}

export function pop(user: any, user_perfil: any, item_nav: any) { // pop(user, user_perfil, user_perfil.privacy.wall )
  if (!user) {
    notificationsSocket.emit('sendErrorMessage', `Only registered users can access to other users profiles!`);
    return window.location.href = '/';
  }
  if (!user_perfil) {
    notificationsSocket.emit('sendErrorMessage', `This user does not exist or his account was deleted!`);
    return window.location.href = '/';
  }
  if (user._id === user_perfil._id) return { public: true };
  // if (user.isAdmin) return { public: true }; //poner esto mas adelante para tener acceso a todos los perfiles, privados o no.
  let permisos;
  switch (item_nav) {
    case Privacy.PUBLIC:
      permisos = { public: true };
      break;
    case Privacy.ONLY_FRIENDS:
      if (user_perfil.friends_ids.includes(user._id)) permisos = { hes_my_friend: true };
      permisos = { onlyfriends: true };
      break;
    case Privacy.PRIVATE:
      permisos = { private: true };
      break;
    default:
      permisos = false;
      break;
  }

  return permisos;
}

export function permisos(user_id: any, admin_id: any, autor_id: any) {
  enum Permisos {
    ADMIN = 'ADMIN',
    AUTOR = 'AUTOR',
    VISITOR = 'VISITOR',
    ADMIN_Y_AUTOR = 'ADMIN_Y_AUTOR',
  }
  let resultado: string;
  switch (true) {
    case user_id === admin_id && user_id === autor_id:
      resultado = Permisos.ADMIN_Y_AUTOR;
      break;
    case user_id === admin_id && user_id !== autor_id:
      resultado = Permisos.ADMIN;
      break;
    case user_id !== admin_id && user_id === autor_id:
      resultado = Permisos.AUTOR;
      break;
    case user_id !== admin_id && user_id !== autor_id:
      resultado = Permisos.VISITOR;
      break;
    default:
      resultado = Permisos.VISITOR;
      break;
  }
  return resultado;
}

export function parseJwt(token: string) {
  const base64Url = token.split('.')[1];
  const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
  const jsonPayload = decodeURIComponent(window.atob(base64).split('').map((c) =>
    `%${(`00${c.charCodeAt(0).toString(16)}`).slice(-2)}`).join(''));
  return JSON.parse(jsonPayload);
}

export const countryList = [
  'Afghanistan',
  'Albania',
  'Algeria',
  'American Samoa',
  'Andorra',
  'Angola',
  'Anguilla',
  'Antarctica',
  'Argentina',
  'Armenia',
  'Aruba',
  'Australia',
  'Austria',
  'Azerbaijan',
  'Bahamas',
  'Bahrain',
  'Bangladesh',
  'Barbados',
  'Belarus',
  'Belgium',
  'Belize',
  'Benin',
  'Bermuda',
  'Bhutan',
  'Bolivia',
  'Bonaire',
  'Botswana',
  'Bouvet Island',
  'Brazil',
  'Bulgaria',
  'Burkina Faso',
  'Burundi',
  'Cabo Verde',
  'Cambodia',
  'Cameroon',
  'Canada',
  'Cayman Islands',
  'Chad',
  'Chile',
  'China',
  'Christmas Island',
  'Cocos',
  'Colombia',
  'Comoros',
  'Congo',
  'Cook Islands',
  'Costa Rica',
  'Croatia',
  'Cuba',
  'Curaçao',
  'Cyprus',
  'Czechia',
  "Côte d'Ivoire",
  'Denmark',
  'Djibouti',
  'Dominica',
  'Dominican Republic',
  'Ecuador',
  'Egypt',
  'El Salvador',
  'Equatorial Guinea',
  'Eritrea',
  'Estonia',
  'Eswatini',
  'Ethiopia',
  'Falkland Islands',
  'Faroe Islands',
  'Fiji',
  'Finland',
  'France',
  'French Guiana',
  'French Polynesia',
  'Gabon',
  'Gambia',
  'Georgia',
  'Germany',
  'Ghana',
  'Gibraltar',
  'Greece',
  'Greenland',
  'Grenada',
  'Guadeloupe',
  'Guam',
  'Guatemala',
  'Guernsey',
  'Guinea',
  'Guinea-Bissau',
  'Guyana',
  'Haiti',
  'Holy See',
  'Honduras',
  'Hong Kong',
  'Hungary',
  'Iceland',
  'India',
  'Indonesia',
  'Iran',
  'Iraq',
  'Ireland',
  'Isle of Man',
  'Israel',
  'Italy',
  'Jamaica',
  'Japan',
  'Jersey',
  'Jordan',
  'Kazakhstan',
  'Kenya',
  'Kiribati',
  'Korea',
  'Korea',
  'Kuwait',
  'Kyrgyzstan',
  'Lao',
  'Latvia',
  'Lebanon',
  'Lesotho',
  'Liberia',
  'Libya',
  'Liechtenstein',
  'Lithuania',
  'Luxembourg',
  'Macao',
  'Madagascar',
  'Malawi',
  'Malaysia',
  'Maldives',
  'Mali',
  'Malta',
  'Marshall Islands',
  'Martinique',
  'Mauritania',
  'Mauritius',
  'Mayotte',
  'Mexico',
  'Micronesia',
  'Moldova',
  'Monaco',
  'Mongolia',
  'Montenegro',
  'Montserrat',
  'Morocco',
  'Mozambique',
  'Myanmar',
  'Namibia',
  'Nauru',
  'Nepal',
  'Netherlands',
  'New Caledonia',
  'New Zealand',
  'Nicaragua',
  'Niger',
  'Nigeria',
  'Niue',
  'Norfolk Island',
  'Norway',
  'Oman',
  'Pakistan',
  'Palau',
  'Palestine, State of',
  'Panama',
  'Papua New Guinea',
  'Paraguay',
  'Peru',
  'Philippines',
  'Pitcairn',
  'Poland',
  'Portugal',
  'Puerto Rico',
  'Qatar',
  'Romania',
  'Russian Federation',
  'Rwanda',
  'Réunion',
  'Saint Barthélemy',
  'Saint Helena',
  'Saint Lucia',
  'Saint Martin',
  'Samoa',
  'San Marino',
  'Saudi Arabia',
  'Senegal',
  'Serbia',
  'Seychelles',
  'Sierra Leone',
  'Singapore',
  'Slovakia',
  'Slovenia',
  'Solomon Islands',
  'Somalia',
  'South Africa',
  'South Georgia',
  'South Sudan',
  'Spain',
  'Sri Lanka',
  'Sudan',
  'Suriname',
  'Sweden',
  'Switzerland',
  'Syria',
  'Taiwan',
  'Tajikistan',
  'Tanzania',
  'Thailand',
  'Timor-Leste',
  'Togo',
  'Tokelau',
  'Tonga',
  'Tunisia',
  'Turkey',
  'Turkmenistan',
  'Tuvalu',
  'Uganda',
  'Ukraine',
  'United Arab Emirates',
  'United Kingdom',
  'United States',
  'Uruguay',
  'Uzbekistan',
  'Vanuatu',
  'Venezuela',
  'Viet Nam',
  'Wallis and Futuna',
  'Western Sahara',
  'Yemen',
  'Zambia',
  'Zimbabwe',
  'Åland Islands',
];

export const zodiacList = ['Aries', 'Taurus', 'Gemini', 'Cancer',
  'Leo', 'Virgo', 'Libra', 'Scorpio',
  'Sagittarius', 'Capricorn', 'Aquarius', 'Pisces'];


/*
                   ACCIONES POSIBLES:
                   1- COPIAR LINK
                   2- COMPARTIR
                   3- GUARDAR
                   4- EDITAR
                   5- ELIMINAR
                   6- DENUNCIAR
                */

// SOY ADMIN Y AUTOR
// user._id === post.thread_post[0].authorId && user._id === 'post.authorId' ?

// COPIAR EL LINK, COMPARTIR, EDITAR Y ELIMINAR la publicación - denunciar Y guardar no tienen sentido ✓ works!
// <OPCIONES_POSTS_ADMIN post={post} setVisible={setVisible} isPost={true} /> :

// SOY ADMIN Y NO SOY AUTOR
// user._id === post.thread_post[0].authorId && user._id !== 'post.authorId' ?

// COPIAR EL LINK, COMPARTIR, GUARDAR Y ELIMINAR la publicación - editar y denunciar no tienen sentido ✓ works!
//  <OPCIONES_POSTS_ADMIN_2 setVisible={setVisible} post={post} user={user} isPost={true} />

// :

// NO SOY ADMIN Y SOY AUTOR de la publicacion
//  user._id !== post.thread_post[0].authorId && user._id === 'post.authorId' ?

// COPIAR EL LINK, COMPARTIR, EDITAR, ELIMINAR - guardar y denunciar no tienen sentido ✓ works!
// <OPCIONES_POSTS_AUTOR post={post} setVisible={setVisible} isPost={true} />

//   :

// NO SOY ADMIN Y NO SOY AUTOR de la publicacion
// user._id !== post.thread_post[0].authorId && user._id !== 'post.authorId' ?

// COPIAR EL LINK, COMPARTIR, GUARDAR, DENUNCIAR - editar y eliminar no tienen sentido
//  <OPCIONES_POSTS_VISITANTE post={post} setVisible={_setVisible} user={user} isPost={true} /> :

//  ''