import React, { useState } from 'react'
import PostsSectionHomeLoading from '../../homeSection/postsSectionHome/postsSectionHomeLoading/postsSectionHomeLoading'
import UniversalNav from '../../sharedComponents/universalNav/universalNav';
import threadPostsStyles from '../threadPosts/threadPosts.module.css';

export default function ThreadPostsLoading() {
    const [order, setOrder] = useState({ order: 'fetchNew' });
    return (
        <div id={threadPostsStyles.threadBody}>
            <UniversalNav setOrder={setOrder} order={order} typeOfSearch=' ' />
            <PostsSectionHomeLoading />
        </div>
    )
}
