/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import threadCreatorStyles from './threadCreatorStyles.module.css'
import { notificationsSocket } from '../nav/notifications/notifications';
import ERROR from '../../utilities/errors';


export default function ThreadCreatorAddRule({ setIsRuleAdderActive, setRules, setRuleElements, elementId }: {
    setIsRuleAdderActive: React.Dispatch<React.SetStateAction<boolean>>, setRules: React.Dispatch<React.SetStateAction<{
        id: string,
        title: string;
        description: string;
    }[]>>,
    setRuleElements: React.Dispatch<React.SetStateAction<JSX.Element[]>>,
    elementId: number
}) {
    const titleRef = useRef<HTMLInputElement>(null);
    const titleLabel = useRef<HTMLInputElement>(null);
    const descriptionRef = useRef<HTMLTextAreaElement>(null);
    const descriptionLabel = useRef<HTMLTextAreaElement>(null);
    const [isSaved, setIsSaved] = useState(false);

    function getNameText(event: React.KeyboardEvent) {
        const targetElement = event.target as HTMLInputElement;
        if (targetElement.value.length > 25) return event.preventDefault();
        const title = titleRef.current;
        if (!title) return false;
        title.value = targetElement.value;
        const currentLabel = titleLabel.current;
        if (!currentLabel) return false;
        currentLabel.innerHTML = ` ${title.value.length}`;
        return title.value;
    }

    function handleDeleteRule() {
        setRuleElements((arr) => arr.filter((item) => {
            if (item.key !== elementId.toString()) return item;
        }));
        setRules((arr) => arr.filter((item) => {
            if (item.id !== elementId.toString()) return item;
        }));
    }

    function getDescriptionText(event: React.KeyboardEvent) {
        const targetElement = event.target as HTMLTextAreaElement;
        if (targetElement.value.length > 500) return event.preventDefault();
        targetElement.style.height = `${targetElement.scrollHeight}px`;
        const description = descriptionRef.current;
        if (!description) return;
        description.value = targetElement.value;
        const currentLabel = descriptionLabel.current;
        if (!currentLabel) return false;
        currentLabel.innerHTML = ` ${description.value.length}`;
    }

    return !isSaved ? <div className={threadCreatorStyles.newRuleGrid} key={Date.now()}>
        <label htmlFor="threadRuleTitleInput"> title (required)<span ref={titleLabel}> 0</span>/25</label>
        <input className={threadCreatorStyles.newRuleTitle}
            placeholder="Write the rule's title..."
            type="text"
            autoComplete="off"
            id="threadRuleTitleInput"
            onKeyDown={getNameText}
            onKeyUp={getNameText}
            maxLength={25}
            ref={titleRef} />
        <label htmlFor="threadRuleDescriptionInput"> description (required)<span ref={descriptionLabel}> 0</span>/500</label>
        <textarea className={threadCreatorStyles.newRuleDescription}
            placeholder="Write the rule's description..."
            autoComplete="off"
            id="threadRuleDescriptionInput"
            maxLength={500}
            onKeyDown={getDescriptionText}
            onKeyUp={getDescriptionText}
            ref={descriptionRef} />
        <div className={threadCreatorStyles.cancelOrSaveGrid}>
            <div onClick={() => {
                if (!titleRef.current?.value.length) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_RULE_TITLE_EMPTY);
                if (!descriptionRef.current?.value.length) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_RULE_DESCRIPTION_EMPTY);
                const title = titleRef.current.value;
                const description = descriptionRef.current.value;
                setIsRuleAdderActive(true);
                setIsSaved(true);
                setRules((arr) => [...arr, {
                    id: elementId.toString(),
                    title,
                    description,
                }]);
                return true;
            }} role='button' tabIndex={0}>Save</div>
        </div>
    </div> : <div className={threadCreatorStyles.rules}>
        <div className={threadCreatorStyles.ruleWrapper}>
            <FontAwesomeIcon icon={faClose} className={threadCreatorStyles.deleteRule} onClick={handleDeleteRule} />
            <div>{titleRef.current?.value}</div>
            <div className={threadCreatorStyles.rulesDescription}>{descriptionRef.current?.value}</div>
        </div>
    </div>
}



