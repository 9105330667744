/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef } from 'react'
import { faBan, faUserSlash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useQueryClient } from 'react-query';
import postCommentOptionsStyles from '../postCommentOptionsStyles.module.css';
import {
    handleOnBlurPostCommentOptions,
} from './optionsUtils';
import { PostCommentsType } from '../../../../postCommentsType';
import Delete from './actions/delete';
import { CommentsSectionContext } from '../../../../../commentsSection';

export default function ThreadAdminNotCommentAuthor({ comment, setVisible, isComment, setReplyList }: { comment: PostCommentsType, setVisible: React.Dispatch<React.SetStateAction<boolean>>, isComment: boolean, setReplyList: any }) {
    const context = useContext(CommentsSectionContext);
    const queryClient = useQueryClient();
    const optionsRef = useRef<HTMLDivElement>(null);

    function handleBan() {
        context?.setCurrentComment(comment);
        queryClient.setQueryData("banUserFromThreadInCommentsPortal", true);
    }

    function handleBlockUser() {
        context?.setCurrentComment(comment);
        queryClient.setQueryData("blockUserInCommentsPortal", true);
    }

    return (
        <div className={postCommentOptionsStyles.postCommentOptionsContainer}
            onBlur={(event) => handleOnBlurPostCommentOptions(event, optionsRef, setVisible)} ref={optionsRef}>
            <Delete comment={comment} isComment={isComment} setReplyList={setReplyList} />
            <div onClick={handleBan} role='button' tabIndex={0}>
                <FontAwesomeIcon icon={faBan} />
                <span>Ban</span>
            </div>
            <div onClick={handleBlockUser} role='button' tabIndex={0}>
                <FontAwesomeIcon icon={faUserSlash} />
                <span>Block</span>
            </div>
        </div>
    )
}
