/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import ThreadsSection from '../threadsSectionHome/threadsSection';
import cardsSectionHomeStyles from './cardsSectionHomeStyles.module.css';
import FirstCardHome from './firstCardHome/firstCardHome';
import SecondAndThirdCardsHome from './twoSmallCardsHome/twoSmallCardsHome';
import { CardTypeHome } from './cardsSectionHomeTypes';
import useCardsHomeQuery from './cardsSectionHomeCustomHooks/useCardsHomeQuery';
import CardSectionHomeLoading from './cardsSectionHomeLoading/cardSectionHomeLoading';
import CardSectionHomeError from './cardsSectionHomeError/cardSectionHomeError';

export default function CardsSectionHome() {
  const ac = new AbortController();
  const { data: threeCards, isLoading, isError, remove } = useCardsHomeQuery(ac);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
  useEffect(() => () => remove(), []);
  if (isLoading) return <CardSectionHomeLoading />
  if (isError) return <CardSectionHomeError />

  return (
    <section>
      <div id={cardsSectionHomeStyles.cardsSectionHomeContainer}>
        <div id={cardsSectionHomeStyles.firstCardHome}>
          <header>
            <FontAwesomeIcon icon={faClock} />
            <span> RECENT CARDS </span>
          </header>
          {
            threeCards?.map(
              (card: CardTypeHome, index: number) => (index === 0 ? (
                <FirstCardHome card={card} key={card._id} />
              )
                : (
                  <SecondAndThirdCardsHome card={card} index={index} key={card._id} />
                ))
            )
          }
        </div>
      </div>
      <ThreadsSection />
    </section>
  );
}
