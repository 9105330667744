/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import NotThreadAdminAndPostAuthor from './options/notThreadAdminAndPostAuthor';
import NotThreadAdminNotPostAuthor from './options/notThreadAdminNotPostAuthor';
import ThreadAdminAndPostAuthor from "./options/threadAdminAndPostAuthor";
import ThreadAdminNotPostAuthor from "./options/ThreadAdminNotPostAuthor";
import { PostType } from '../../../../../universalTypes/universalTypes';

export default function PostHomeOptionsPermits(
    post: PostType,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
) {
    const { postThreadData } = post;
    switch (true) {
        case postThreadData.isThisUserTheAdminAndPostAuthor:
            return <ThreadAdminAndPostAuthor post={post} setVisible={setVisible} />
        case postThreadData.isThisUserTheAdmin:
            return <ThreadAdminNotPostAuthor post={post} setVisible={setVisible} />
        case postThreadData.isThisUserThePostAuthor:
            return <NotThreadAdminAndPostAuthor post={post} setVisible={setVisible} />
        default: /* VISITOR */
            return <NotThreadAdminNotPostAuthor post={post} setVisible={setVisible} />
    }
}