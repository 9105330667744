/* eslint-disable  */
import React from 'react';
import cardsStyle from './twoSmallCardsHome.module.css';

export default function SecondCardHomeTitleNSFW({ isNSFW }: { isNSFW: boolean }) {
  const handleTakeOffNsfw = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const classList = event.currentTarget.parentElement?.children[1].classList;
    classList?.remove(classList[0]);
    event.currentTarget.remove();
  }
  return (
    <div onClick={handleTakeOffNsfw} className={cardsStyle.twoSmallCardsHomeSignNSFW}>
      <span>
        <span className='fas fa-exclamation-triangle' />
        {isNSFW ? '「 N S F W ! 」' : '「 S P O I L E R ! 」'}
        <span className='fas fa-exclamation-triangle' />
      </span>
    </div>
  );
}