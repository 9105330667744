/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react'
import memberOfStyles from './userProfileMemberOfSectionStyles.module.css';
import { UserProfileType } from '../../userProfileTypes';
import useMemberOfQuery from './memberOfCustomHooks/memberOfQuery';
import Thread from '../../../threadsSection/thread/thread';
import ThreadsLoading from '../../../threadsSection/threadsLoading/threadsLoading';
import threadsStyles from '../../../threadsSection/threadsSectionStyles.module.css';
import UserProfileDontHaveAny from '../../../sharedComponents/userProfileDontHaveAny/userProfileDontHaveAny';
import userProfileStyles from '../../userProfileStyles.module.css';

export default function UserProfileMemberOfSection({ userProfile }: { userProfile: UserProfileType }) {
    const ac = new AbortController();
    const { data: memberOfList, remove, isError, isFetching } = useMemberOfQuery(ac, userProfile);
    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
    useEffect(() => () => remove(), []);
    if (isError) return <div>errrrror</div>
    if (isFetching) return <div id={userProfileStyles.isFetching}
        className={memberOfStyles.isFetchingSmartPhoneMemberOf} ><ThreadsLoading /></div >
    if (!memberOfList?.length) return <div id={userProfileStyles.isFetching}>
        <UserProfileDontHaveAny userProfile={userProfile as UserProfileType}
            isOwnerMessage="You are not member of any thread..."
            isNotOwnerMessage="this user is not a member of any thread..." />
    </div>


    return (
        <div id={memberOfStyles.threadsGrid} style={{ minHeight: '623.99px' }}>
            <section id={threadsStyles.threadsSection}>
                {
                    memberOfList.map((thread) => <Thread thread={thread} key={thread._id} />)
                }
            </section>
        </div>
    )
}
