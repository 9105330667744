/* eslint-disable import/no-cycle */
import React, { useContext, useState } from 'react';
import postCreatorTypeSelectedStyles from './postCreatorTypeSelectedStyles.module.css';
import PostCreatorTitleAndThread from './postCreatorTitleAndThread/postCreatorTitleAndThread';
import PostCreatorCardImageArea from './postCreatorCardImageArea/postCreatorCardImageArea';
import PostCreatorTextArea from './postCreatorTextarea/postTextarea/postCreatorTextarea';
import { PostCreatorContext } from '../postCreator';
import { PostTypes } from '../postCreatorTypes';
import PostCreatorPollWithImagesArea from './postCreatorPollWithImagesArea/postCreatorPollWithImagesArea';
import PollTypeSelector from './pollTypeSelector/pollTypeSelector';
import PostCreatorPollWithoutImages from './postCreatorPollWithoutImagesArea/postCreatorPollWithoutImagesArea';

export default function PostCreatorTypeSelected() {
    const [pollhasPollImages, setPollhasPollImages] = useState<boolean | null>(null);
    const context = useContext(PostCreatorContext);
    const pollTypeSelector = () => {
        switch (true) {
            case pollhasPollImages === true:
                return <PostCreatorPollWithImagesArea setPollhasPollImages={setPollhasPollImages} />
            case pollhasPollImages === false:
                return <PostCreatorPollWithoutImages setPollhasPollImages={setPollhasPollImages} />
            default:
                return <PollTypeSelector setPollhasPollImages={setPollhasPollImages} />
        }
    }
    const postTypeSelected = (postType?: string) => {
        switch (postType) {
            case PostTypes.POSTS:
                return <PostCreatorTextArea />
            case PostTypes.CARDS:
                return <PostCreatorCardImageArea />
            case PostTypes.POLLS:
                return pollTypeSelector();
            default: return null
        }
    }
    return (
        <div id={postCreatorTypeSelectedStyles.postCreatorTypeSelected}>
            <div id={postCreatorTypeSelectedStyles.postCreatorTypeSelectedContentGrid}>
                <PostCreatorTitleAndThread />
                {postTypeSelected(context?.postType)}
            </div>
        </div>
    )
}
