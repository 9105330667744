/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import threadCreatorStyles from './threadCreatorStyles.module.css'
import ThreadCreatorAddRule from './threadCreatorAddRule';
import { API_URL } from '../../utilities/utils';
import { notificationsSocket } from '../nav/notifications/notifications';
import ERROR from '../../utilities/errors';

export default function ThreadCreator() {
    const navigate = useNavigate();
    const [typeOfPost, setTypeOfPost] = useState<string[]>([]);
    const [isRuleAdderActive, setIsRuleAdderActive] = useState(true);
    const [ruleElements, setRuleElements] = useState<JSX.Element[]>([]);
    const [isNSFW, setIsNSFW] = useState(false);
    const [description, setDescription] = useState<string>();
    const [name, setName] = useState<string>();
    const [rules, setRules] = useState<{ id: string, title: string, description: string }[]>([]);
    const textareaRef = useRef(null);

    const handleAddRule = () => {
        const dateNow = Date.now();
        setRuleElements((arr) => [...arr,
        <ThreadCreatorAddRule
            key={dateNow}
            setIsRuleAdderActive={setIsRuleAdderActive}
            setRules={setRules}
            setRuleElements={setRuleElements}
            elementId={dateNow}
        />
        ]);
        setIsRuleAdderActive(false);
    };

    function textAreaAdjust(event: React.KeyboardEvent) {
        const targetElement = event.target as HTMLTextAreaElement;
        if (targetElement.value.length > 500) return event.preventDefault();
        targetElement.style.height = `${targetElement.scrollHeight}px`;
        return setDescription(targetElement.value);
    }

    function getNameText(event: React.KeyboardEvent) {
        if (event.key === ' ') return event.preventDefault();
        const targetElement = event.target as HTMLInputElement;
        if (targetElement.value.length > 25) return event.preventDefault();
        return setName((targetElement as HTMLInputElement).value);
    }

    function addPostType(type: string) {
        setTypeOfPost((arr) => {
            if (arr.includes(type)) {
                return arr.filter((item) => item !== type)
            }
            return [...arr, type]
        });
    }

    const createThread = async () => {
        if (!name) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NEEDS_A_NAME);
        if (name.length > 25) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_THREAD_NAME_LENGTH_EXCEEDED);
        if (!description) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NEEDS_A_DESCRIPTION);
        if (description.length > 500) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_THREAD_DESCRIPTION_LENGHT_EXCEEDED);
        if (!rules.length) return notificationsSocket.emit('sendErrorMessage', ERROR.ZERO_THREAD_RULES);
        if (!typeOfPost.length) return notificationsSocket.emit('sendErrorMessage', ERROR.ZERO_TYPE_OF_POSTS);
        const ac = new AbortController();
        await fetch(`${API_URL}/create-new-thread`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${window.localStorage.token}`
            },
            body: JSON.stringify({
                name,
                description,
                rules: JSON.stringify(rules),
                typeOfPost: JSON.stringify(typeOfPost),
                isNSFW
            }),
            signal: ac.signal,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error) throw new Error(response.message);
                navigate(`/thread/${response.name}`)
            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));
        return true;
    }

    return (
        <MainWrapper>
            <div id={threadCreatorStyles.threadCreatorGrid}>
                <div id={threadCreatorStyles.threadCreatorContainer}>
                    <div id={threadCreatorStyles.threadCreatorTitle}>CREATE NEW THREAD</div>
                    <div className={threadCreatorStyles.threadCreatorNameGrid}>
                        <label htmlFor="threadNameInput">
                            <span className={`${threadCreatorStyles.confirmationDot} 
                            ${name && threadCreatorStyles.activeSpot} `}>●</span> NAME (required)
                            <span> {name?.length || 0}</span>/25
                        </label>
                        <input className={threadCreatorStyles.threadCreatorInputs}
                            placeholder="Write the thread's name..."
                            type="text"
                            autoComplete="off"
                            id="threadNameInput"
                            onKeyDown={getNameText}
                            onKeyUp={getNameText}
                            maxLength={25}
                        />
                        <div className={threadCreatorStyles.itemHelp}>Crafting a community name requires a succinct and descriptive name within a 25-letter limit with
                            no spaces between words and camelCase recommended, ie: &quot;rpgsFromTheGoldenEra&quot;.<br /><br />
                            Choose words that resonate with the community&apos;s goals and values to create a compelling first impression.</div>
                    </div>
                    <div className={threadCreatorStyles.threadCreatorNameGrid}>
                        <label htmlFor="threadNameInput">
                            <span className={`${threadCreatorStyles.confirmationDot} 
                            ${description && threadCreatorStyles.activeSpot} `}>●</span> DESCRIPTION (required)
                            <span> {description?.length || 0}</span>/500</label>
                        <textarea className={threadCreatorStyles.threadCreatorInputs}
                            placeholder="Write the thread's description..."
                            autoComplete="off"
                            id="threadNameInput"
                            onKeyDown={textAreaAdjust}
                            onKeyUp={textAreaAdjust}
                            maxLength={500}
                            ref={textareaRef}
                        />
                        <div className={threadCreatorStyles.itemHelp}>Craft a clear and concise community description within a 500-character limit.
                            Prioritize specificity to accurately represent the community&apos;s identity, objectives, and audience.
                            Avoid ambiguity to attract the right members effectively.</div>
                    </div>
                    <div className={threadCreatorStyles.threadCreatorNameGrid}>
                        <label htmlFor="threadNameInput"><span className={`${threadCreatorStyles.confirmationDot} 
                            ${rules.length && threadCreatorStyles.activeSpot} `}>●</span> RULES (required)</label>
                        {
                            ruleElements.map((item, index) => <div key={item.props.elementId} className={threadCreatorStyles.rulesIndexes}>
                                <div>{index + 1}.&nbsp;</div><div>{item}</div>
                            </div>)
                        }

                        {
                            isRuleAdderActive && <div id={threadCreatorStyles.addRule}
                                onClick={handleAddRule}
                                role='button'
                                tabIndex={0} >
                                <span className='fas fa-plus' />
                                <span>Add Rule</span>
                            </div>
                        }
                    </div>
                    <div id={threadCreatorStyles.typeOfPostsWrapper}>
                        <span className={`${threadCreatorStyles.confirmationDot} 
                            ${typeOfPost.length && threadCreatorStyles.activeSpot} `}>●</span>
                        <span> What type of post should be allowed? (required)</span>
                        <div id={threadCreatorStyles.typeOfPosts}>
                            <span onClick={() => addPostType('POSTS')} className={typeOfPost.includes('POSTS') ? threadCreatorStyles.activeType : ''}>POSTS</span>
                            <span onClick={() => addPostType('POLLS')} className={typeOfPost.includes('POLLS') ? threadCreatorStyles.activeType : ''}>POLLS</span>
                            <span onClick={() => addPostType('CARDS')} className={typeOfPost.includes('CARDS') ? threadCreatorStyles.activeType : ''}>CARDS</span>
                        </div>
                    </div>
                    <div id={threadCreatorStyles.isThisThreadSafe}>
                        <span>Is this thread NSFW?</span>
                        <span onClick={() => setIsNSFW(true)} className={isNSFW ? threadCreatorStyles.nsfwActive : ''}>YES</span>
                        <span onClick={() => setIsNSFW(false)} className={!isNSFW ? threadCreatorStyles.nsfwActive : ''}>NO</span>
                    </div>
                    <div id={threadCreatorStyles.createThreadGrid}>
                        <div onClick={createThread}>create!</div>
                    </div>
                </div>
                <div id={threadCreatorStyles.threadCreatorRules}><div>When establishing a community,
                    it&apos;s crucial to ensure a safe and respectful environment for all members.<br /><br />
                    Therefore, it&apos;s imperative for users to abide by the following basic rules:</div>
                    <div><u>Personal Information and Harassment</u>: No disclosing personal info or engaging in harassment.</div>
                    <div><u>Piracy</u>: No sharing copyrighted material to avoid legal issues.</div>
                    <div><u>Spam</u>: No promoting products or links, except from legitimate YouTube channels.</div>
                    <div><u>Duplicate Content</u>: Reposting for undue attention is prohibited.</div>
                    <div><u>Illegal Pornography</u>: Posting such content will lead to legal action.</div>
                    <div><u>NSFW and Spoiler Flags</u>: Must flag as NSFW and/or Spoiler content if it requires so.</div>
                    Adherence to these rules fosters a positive, safe, and inclusive community environment. Violations may lead to disciplinary action. Let&apos;s build a welcoming space where everyone can share freely.</div>
            </div >
        </MainWrapper >
    )
}
