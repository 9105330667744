/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { memo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { API_URL } from "../../../../utilities/utils";
import BasePortal from "../basePortal";
import reportCommentPortalStyles from './reportCommentStyles.module.css';
import imgTrashbin from '../../../../assets/images/trashbin10.png';
import maqueta from '../../../../assets/images/maqueta.png';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import ERROR from '../../../../utilities/errors';

function ReportComment({ comment, isThisBeingCalledFromUserProfile }: { comment: any, isThisBeingCalledFromUserProfile?: boolean }) {
    const [reportReason, setReportReason] = useState({ reasonNumber: 0, reason: '' });
    const queryClient = useQueryClient();
    const handleReportComment = () => {
        if (!reportReason.reason) return notificationsSocket.emit('sendErrorMessage', ERROR.REPORT_HAS_NO_REASON);
        fetch(isThisBeingCalledFromUserProfile ? `${API_URL}/user-profile/process-report-comment` : `${API_URL}/post/process-report-comment`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify(
                    {
                        reason: reportReason.reason,
                        commentId: comment._id,
                    }
                ),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                queryClient.setQueryData("reportComment", false);
                setReportReason({ reasonNumber: 0, reason: '' });
                notificationsSocket.emit('sendErrorMessage', ERROR.REPORT_SENT);
                return true;
            })
            .catch((error) => {
                notificationsSocket.emit('sendErrorMessage', error.message);
                queryClient.setQueryData("reportComment", false);
                setReportReason({ reasonNumber: 0, reason: '' });
                return false;
            });
        return false;
    }

    const handleCancel = () => {
        queryClient.setQueryData("reportComment", false);
        setReportReason({ reasonNumber: 0, reason: '' });
    };

    const handleClickReport = (event: React.MouseEvent<HTMLLIElement>, reasonNumber: number) => {
        setReportReason({ reasonNumber, reason: event.currentTarget.innerText });
    }

    return <BasePortal queryKey="reportComment">
        <div id={reportCommentPortalStyles.reportCommentPortalWrapper}>
            <img
                src={imgTrashbin || maqueta}
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div>
                <p>YOU ARE ABOUT TO <span>REPORT</span> THIS COMMENT, BECAUSE:</p>
                <p>
                    <ul>
                        <li onClick={(event) => handleClickReport(event, 1)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 1 ? reportCommentPortalStyles.reportSelected : ''}>
                            Contains gore o inapropiate images or links
                        </li>
                        <li onClick={(event) => handleClickReport(event, 2)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 2 ? reportCommentPortalStyles.reportSelected : ''}>
                            Contains personal info or harassment
                        </li>
                        <li onClick={(event) => handleClickReport(event, 3)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 3 ? reportCommentPortalStyles.reportSelected : ''}>
                            Contains piracy
                        </li>
                        <li onClick={(event) => handleClickReport(event, 4)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 4 ? reportCommentPortalStyles.reportSelected : ''}>
                            Contains spam
                        </li>
                        <li onClick={(event) => handleClickReport(event, 5)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 5 ? reportCommentPortalStyles.reportSelected : ''}>
                            Contains spoilers.
                        </li>
                    </ul>
                </p>
            </div>
            <div id={reportCommentPortalStyles.cancelAndReportButtons}>
                <div
                    id={reportCommentPortalStyles.cancelCommentPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}
                >no, wait!</div>
                <div
                    id={reportCommentPortalStyles.reportCommentPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleReportComment}
                >yeah, report this shit!</div>
            </div>
        </div>
    </BasePortal>
}

function areEqual(prevProps: Readonly<{ comment: any; }>, nextProps: Readonly<{ comment: any; }>) {
    return prevProps.comment._id === nextProps.comment._id;
}

export default memo(ReportComment, areEqual);