import React from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import maqueta from '../../../../images/maqueta.png';
import postSectionHomeLoadingStyles from './postSectionHomeLoadingStyles.module.css';

export default function PostHomeLoading() {
    return (
        <div className={postSectionHomeLoadingStyles.postSectionHomeLoadingWrapper}>
            <div>
                <img className={postSectionHomeLoadingStyles.postSectionHomeLoadingImage}
                    src={maqueta}
                    alt={maqueta}
                    loading="lazy"
                    decoding="async"
                />
            </div>
            <div className={postSectionHomeLoadingStyles.postSectionHomeLoadingTitle}>
                <div className={postSectionHomeLoadingStyles.postSectionHomeLoadingText} />
                <div className={postSectionHomeLoadingStyles.postSectionHomeLoadingOptions}>
                    <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>
                <div className={postSectionHomeLoadingStyles.postSectionHomeLoadingData} />
            </div>
        </div>
    )
}
