/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import NotThreadAdminAndCardAuthor from './options/notThreadAdminAndCardAuthor';
import NotThreadAdminNotCardAuthor from './options/notThreadAdminNotCardAuthor';
import ThreadAdminAndCardAuthor from "./options/threadAdminAndCardAuthor";
import ThreadAdminNotCardAuthor from "./options/ThreadAdminNotCardAuthor";
import { PostType } from '../../../../universalTypes/universalTypes';

export default function cardOptionsPermits(
    card: PostType,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    isItBeingCalledFromThread:boolean
) {

    switch (true) {
        case card.postThreadData.isThisUserTheAdminAndPostAuthor:
            return <ThreadAdminAndCardAuthor card={card} setVisible={setVisible} isItBeingCalledFromThread={isItBeingCalledFromThread} />
        case card.postThreadData.isThisUserTheAdmin:
            return <ThreadAdminNotCardAuthor card={card} setVisible={setVisible} isItBeingCalledFromThread={isItBeingCalledFromThread} />
        case card.postThreadData?.isThisUserThePostAuthor:
            return <NotThreadAdminAndCardAuthor card={card} setVisible={setVisible} />
        default: /* VISITOR */
            return <NotThreadAdminNotCardAuthor card={card} setVisible={setVisible} />
    }
}