/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBookmark } from '@fortawesome/free-solid-svg-icons';
import { API_URL } from '../../../../../../utilities/utils';
import savePostStyle from './savePostStyles.module.css';
import { PostType, UseQueryRefetch } from '../../../../../../universalTypes/universalTypes';
import { notificationsSocket } from '../../../../../nav/notifications/notifications';

export default function SavePost({ post, refetch }: { post: PostType, refetch: UseQueryRefetch }) {

    const handleSavePost = () => {
        fetch(
            `${API_URL}/post/process-bookmark-post`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Content-Type": "application/json",
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    postId: post._id,
                    threadId: post.postThreadData._id
                })
            })
            .then(e => e.json())
            .then(e => {
                if (e.error) throw new Error(e.message);
                refetch();
            })
            .catch(error => notificationsSocket.emit('sendErrorMessage', error.message));
    }

    return (
        <div onClick={handleSavePost}
            role='button'
            tabIndex={0}
            id={savePostStyle.handleSavePost}>
            {
                post.hasThisUserBookmarkedThisPost ?
                    <>
                        <span id={savePostStyle.savedPostTooltip}>Unsave Post</span>
                        <FontAwesomeIcon icon={faBookmark} id={savePostStyle.savedPost} className={savePostStyle.savedPost} />
                    </>
                    :
                    <>
                        <span id={savePostStyle.savePostTooltip}>Save Post</span>
                        <FontAwesomeIcon icon={faBookmark} id={savePostStyle.notSavedPost} />
                    </>
            }
        </div>
    )
}
