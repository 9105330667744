/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useCallback, useContext, useState } from 'react';

import { faBookmark, faReply, faShare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import commentVotesStyles from '../../commentVotesStyles.module.css'
import PostCommentOptions from '../postCommentsReplies/postHomeOptions/postCommentOptions';
import { PostCommentsRepliesType, PostCommentsType } from '../../postCommentsType';
import { PostType } from '../../../../../../universalTypes/universalTypes';
import { API_URL } from '../../../../../../utilities/utils';
import postVotesPathDString from '../../../../postHeader/postVotes/postVotesPathDString';
import { CommentsSectionContext } from '../../../commentsSection';
import { notificationsSocket } from '../../../../../nav/notifications/notifications';
import useUserQuery from '../../../../../nav/user/useUsers';
import { PostCommentsContext } from '../../postComments';

export default function CommentVotes({ post, reply, setVisible, isComment, comment, setIsEditable, setReplyList }: {
    post: PostType,
    reply: PostCommentsRepliesType | null,
    setVisible: React.Dispatch<React.SetStateAction<{
        ok: boolean;
        commentAuthorNickname: string | null;
    }>>,
    isComment: boolean,
    comment: PostCommentsType | null,
    setIsEditable: any,
    setReplyList: any
}) {
    const { data: user } = useUserQuery();
    const context = useContext(PostCommentsContext);
    const commentsSectionContext = useContext(CommentsSectionContext);
    const [voted, setHasVoted] = useState({
        hasThisUserVoted: isComment ? comment?.hasThisUserVoted : reply?.hasThisUserVoted,
        hasThisUserVotedPositive: isComment ? comment?.hasThisUserVotedPositive : reply?.hasThisUserVotedPositive,
        totalVotes: isComment ? comment?.totalVotes : reply?.totalVotes
    });
    const handleVote = useCallback((vote: boolean) => {
        fetch(context?.isBeingCalledFromProfile ? `${API_URL}/user-profile/process-comment-votes` : `${API_URL}/post/process-comment-votes`,
            {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    postId: context?.isBeingCalledFromProfile ? null : post._id,
                    commentId: isComment ? comment?._id : reply?._id,
                    isVotePositive: vote,
                    threadId: context?.isBeingCalledFromProfile ? null : commentsSectionContext?.post.postThreadData._id
                })
            }
        )
            .then(e => e.json())
            .then(e => {
                if (e.error) throw new Error(e.message);
                if (e.ok) {
                    if (user?._id !== (isComment ? comment?.commentAuthorData.id : reply?.commentAuthorData.id)) {
                        console.log("comment?.commentAuthorData.id", comment?.commentAuthorData.id);
                        console.log("reply?.commentAuthorData.id", reply?.commentAuthorData.id);
                        notificationsSocket.emit('sendMessageToSpecificUser', {
                            userId: isComment ? comment?.commentAuthorData.id : reply?.commentAuthorData.id,
                            postUrl: `${window.location.pathname.substring(1)}#${comment?._id}`,
                            message: `${user?.nickname} ${vote ? 'upvoted' : 'downvoted'} your ${isComment ? 'comment' : 'reply'}!`
                        });
                    }
                }
                setHasVoted({ hasThisUserVoted: e.ok, hasThisUserVotedPositive: e.isVotePositive, totalVotes: e.totalVotes })
            })
            .catch(err => notificationsSocket.emit('sendErrorMessage', err.message));
    }, [comment?._id]);

    const votesOutcome = () => {
        if (voted.totalVotes! > 0) return commentVotesStyles.positiveVotesOutcome;
        if (voted.totalVotes! < 0) return commentVotesStyles.negativeVotesOutcome;
        return null;
    }

    const hasVotedPositive = () => {
        if (voted.hasThisUserVoted && voted.hasThisUserVotedPositive) return commentVotesStyles.commentUpVoted;
        return '';
    }

    const hasVotedNegative = () => {
        if (voted.hasThisUserVoted && !voted.hasThisUserVotedPositive) return commentVotesStyles.commentDownVoted;
        return '';
    }

    const [bookmarked, setHasBookmarked] = useState({
        hasThisUserBookmarkedThisComment: isComment ?
            comment?.hasThisUserBookmarkedThisComment : reply?.hasThisUserBookmarkedThisComment,
    });

    const handleBookmarkCard = () => {
        fetch(context?.isBeingCalledFromProfile ? `${API_URL}/user-profile/process-save-post` : `${API_URL}/post/process-bookmark-comment`,
            {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    commentId: isComment ? comment?._id : reply?._id,
                    threadId: commentsSectionContext?.post.postThreadData._id
                }),
            })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                setHasBookmarked({
                    hasThisUserBookmarkedThisComment: response.ok
                })
            })
            .catch(e => notificationsSocket.emit('sendErrorMessage', e.message));
    }

    return (
        <div className={commentVotesStyles.commentVotesAndOptionsContainer}>
            <div className={commentVotesStyles.commentVotesContainer}>
                <svg version="1.1" width="18" height="18" viewBox="0 0 24 24" onClick={() => handleVote(true)}>
                    <path className={hasVotedPositive()}
                        fill='transparent'
                        stroke='#5d666b'
                        d={postVotesPathDString} />
                </svg>
                <div className={`${commentVotesStyles.numberOfVotes} ${votesOutcome()}`}>{voted.totalVotes}</div>
                <svg version="1.1" width="18" height="18" viewBox="0 0 24 24" onClick={() => handleVote(false)} >
                    <path className={hasVotedNegative()}
                        fill='transparent'
                        stroke='#5d666b'
                        d={postVotesPathDString} />
                </svg>
            </div>
            <div className={commentVotesStyles.commentOptions}>
                <div onClick={() => setVisible((visible) => {
                    if (visible.ok) {
                        return { ok: false, commentAuthorNickname: null }
                    }
                    return {
                        ok: true, commentAuthorNickname: isComment ?
                            `${comment?.commentAuthorData.nickname}&nbsp;`
                            : `${reply?.commentAuthorData.nickname}&nbsp;`
                    }
                })
                }
                    tabIndex={0}
                    role='button' >
                    <FontAwesomeIcon icon={faReply} /> Reply
                </div >
                <div onClick={handleBookmarkCard} tabIndex={0} role='button'>
                    <FontAwesomeIcon
                        icon={faBookmark}
                        className={bookmarked.hasThisUserBookmarkedThisComment ? commentVotesStyles.commentOptionSaved : ''}
                    /> <span> {bookmarked.hasThisUserBookmarkedThisComment ? 'Saved' : 'Save'}</span>
                </div>
                <div>
                    <FontAwesomeIcon icon={faShare} /> Share
                </div>
                <PostCommentOptions
                    isComment={isComment}
                    reply={reply}
                    comment={comment}
                    setIsEditable={setIsEditable}
                    setReplyList={setReplyList} />
            </div >
        </div >
    )
}
