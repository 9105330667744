/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react';
import { Link } from 'react-router-dom';
import fivePostsInCardsSection from './fivePostsInCardsSection.module.css';
import maqueta from '../../../assets/images/maqueta.png';

export default function FivePostsInCardsSectionLoading() {
    return (
        <>
            <div className={fivePostsInCardsSection.postGrid} key={0}>
                <div>
                   <img src={maqueta} alt={maqueta} />
                </div>
                <div className={fivePostsInCardsSection.loadingPostTitle} />
                <div className={fivePostsInCardsSection.loadingPostInfo} />
            </div>
            <div className={fivePostsInCardsSection.postGrid} key={1}>
                <div>
                   <img src={maqueta} alt={maqueta} />
                </div>
                <div className={fivePostsInCardsSection.loadingPostTitle} />
                <div className={fivePostsInCardsSection.loadingPostInfo} />
            </div>
            <div className={fivePostsInCardsSection.postGrid} key={2}>
                <div>
                   <img src={maqueta} alt={maqueta} />
                </div>
                <div className={fivePostsInCardsSection.loadingPostTitle} />
                <div className={fivePostsInCardsSection.loadingPostInfo} />
            </div>
            <div className={fivePostsInCardsSection.postGrid} key={3}>
                <div>
                   <img src={maqueta} alt={maqueta} />
                </div>
                <div className={fivePostsInCardsSection.loadingPostTitle} />
                <div className={fivePostsInCardsSection.loadingPostInfo} />
            </div>
            <div className={fivePostsInCardsSection.postGrid} key={4}>
                <div>
                   <img src={maqueta} alt={maqueta} />
                </div>
                <div className={fivePostsInCardsSection.loadingPostTitle} />
                <div className={fivePostsInCardsSection.loadingPostInfo} />
            </div>
        </>
    )
}
