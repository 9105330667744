/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLink } from '@fortawesome/free-solid-svg-icons'
import { handleCopyLinkPost } from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsUtils';
import { PostType } from '../../../../../universalTypes/universalTypes';
import linkStyles from '../share/shareStyles.module.css';

export default function CopyLink({ post, setVisible }: { post: PostType, setVisible: React.Dispatch<React.SetStateAction<boolean>> | null }) {
    return <div
        onClick={() => handleCopyLinkPost(post, setVisible)}
        role='button'
        tabIndex={0}>
        <FontAwesomeIcon icon={faLink} /><span className={linkStyles.optionText} >Link</span>
    </div>
}
