/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useRef, useState } from 'react'
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor as TinyMCEEditor } from "tinymce";
import editPostStyles from './editPostCommentStyles.module.css';
import { API_URL, whichContextGlobal } from '../../../../../../../utilities/utils';
import { PostCommentsAndRepliesContext } from '../../postCommentsAndRepliesWrapper';
import { CommentsSectionContext } from '../../../../commentsSection';
import { ReplyContext } from '../../postCommentsReplies/comentReply';
import fetchPostCommentsReplies from '../../postCommentsReplies/postCommentsRepliesUtils';
import EditorTinyMCE from '../../../../../tinymceEditor/tinymceEditor';
import Emojis from '../../../../../tinymceEditor/emojis/emojis';
import { notificationsSocket } from '../../../../../../nav/notifications/notifications';
import sharedCSS from '../../../../../../sharedCSS/sharedCSS.module.css';
import ERROR from '../../../../../../../utilities/errors';
import LoadingSpinner from '../../../../../loadingSpinner/loadingSpinner';
import { CommentsSectionContext as UserProfileCommentsSectionContext } from '../../../../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';
import { PostCommentsContext } from '../../../postComments';
import { PostCommentsRepliesType, PostCommentsType } from '../../../postCommentsType';


export default function EditPostComment({ isComment, comment, setReplyList, setIsEditable }: { isComment: boolean, comment: PostCommentsType | null, setReplyList: any, setIsEditable: any }) {
    const context = useContext(PostCommentsContext);
    // const context = useContext(PostCommentsAndRepliesContext);
    const usperProfilePubWrapperContext = useContext(UserProfileCommentsSectionContext);
    const replyContext = useContext(ReplyContext);
    const [isAvailableForSending, setIsAvailableForSending] = useState(true);
    const [areEmojisVisible, setAreEmojisVisible] = useState(false);
    const [commentContent, setCommentContent] = useState<Document | null>(null);
    const emojisButtonRef = useRef<SVGSVGElement | null>(null);
    const sendPostRef = useRef(null);
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const updatePostComment = () => {
        if (!isAvailableForSending) return null;
        if (!commentContent) return notificationsSocket.emit('sendErrorMessage', ERROR.POST_BODY_IS_EMPTY);
        setIsAvailableForSending(false);
        const editorContent = commentContent.activeElement?.innerHTML; // postBody?.documentElement.outerHTML.replace(/<style[\s\S]*?<\/style>/gi, '');
        fetch(usperProfilePubWrapperContext?.userProfile ?
            `${API_URL}/user-profile/edit-user-profile-comment/${usperProfilePubWrapperContext.userProfile._id}` :
            `${API_URL}/post/edit-post-comment`,
            {
                method: 'POST',
                mode: 'cors',
                headers: { "Authorization": `Bearer ${window.localStorage.token}`, "Content-Type": 'application/json' },
                body: JSON.stringify({
                    commentId: isComment ? comment?._id : replyContext?.reply._id,
                    commentContent: editorContent,
                    threadId: context?.post?.postThreadData?._id
                })
            })
            .then(response => response.json())
            .then(response => {
                console.log("response?? que es???", response);
                console.log("isComment?? que es???", isComment);
                console.log("context?.setCommentsList?? que es???", context?.setCommentsList);
                console.log("context?.setCommentsList?? que es???", context);
                if (response.error) throw new Error(response.message);
                setIsEditable(false);
                if (isComment) {
                    if (usperProfilePubWrapperContext?.userProfile) {
                        usperProfilePubWrapperContext?.setCommentsList((arr: any) => {
                            console.log("!!!!!!!!!!", arr);
                            const newArr = [...arr];
                            return newArr.map((item) => {
                                if (item._id === response._id) return response;
                                return item;
                            })
                        });
                        return;
                    }
                    context?.setCommentsList((arr: any) => {
                        console.log("!!!!!!!!!!", arr);
                        const newArr = [...arr];
                        return newArr.map((item) => {
                            if (item._id === response._id) return response;
                            return item;
                        })
                    });
                    return;
                }
                replyContext?.setIsReplyEditable(false);
                fetchPostCommentsReplies(context?.post?._id as string,
                    comment?._id as string,
                    setReplyList,
                    context?.isBeingCalledFromProfile as boolean,
                    usperProfilePubWrapperContext?.userProfile._id);
            })
            .catch(error => {
                notificationsSocket.emit('sendErrorMessage', error.message);
                setIsAvailableForSending(true);
            })
        return null;
    }

    return <div id={editPostStyles.editPostBody}>
        <EditorTinyMCE
            setPostBody={setCommentContent}
            post={{ postBody: isComment ? comment?.commentContent : replyContext?.reply.commentContent }}
            editorRef={editorRef}
            setAreEmojisVisible={setAreEmojisVisible} />
        <div id={editPostStyles.wrapperEditButton}>
            <div id={editPostStyles.updateButtonWrapper}
                onClick={updatePostComment}
                ref={sendPostRef}
                role='button'
                tabIndex={0}
            >
                {
                    isAvailableForSending ?
                        <div>Edit {isComment ? 'Comment' : 'Reply'}</div>
                        :
                        <LoadingSpinner />
                }
            </div>
        </div>
        {
            areEmojisVisible as boolean &&
            <Emojis emojisButtonRef={emojisButtonRef.current as SVGSVGElement}
                setAreEmojisVisible={setAreEmojisVisible}
                editorRef={editorRef.current} />
        }
    </div>
} 