/* eslint-disable no-nested-ternary */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { createContext, useEffect, useMemo, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import cardsStyles from './cardsSectionStyles.module.css';
import useCardsQuery from './cardsCustomHooks/useCardsQuery';
import Card from './card/card';
import CardsLoading from './cardsLoading/cardsLoading';
import { PostType, PostPollCardOrThreadContextType } from '../../universalTypes/universalTypes';
import fetchCardsInfiniteScroll from './cardsUtils';
import PostHomeSectionEndOfList from '../homeSection/postsSectionHome/postHomeSectionEndOfList/postHomeSectionEndOfList';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import FivePostsInCardsSection from './fivePostsInCardsSection/fivePostsInCardsSection';
import DeletePost from '../sharedComponents/reactPortals/deletePost/deletePost';
import BlockUserPortal from '../sharedComponents/reactPortals/blockUserPortal/blockUserPortal';
import BanUserFromThreadPortal from '../sharedComponents/reactPortals/banUserFromThreadPortal/banUserFromThreadPortal';
import ReportPost from '../sharedComponents/reactPortals/reportPost/reportPost';
import UniversalNav from '../sharedComponents/universalNav/universalNav';

export const CardsContext = createContext<PostPollCardOrThreadContextType>(null);

export default function Cards() {
  const [postsList, setPostsList] = useState<PostType[]>();
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [page, setPage] = useState(1);
  const [currentPost, setCurrentPost] = useState<PostType | null>(null);
  const [order, setOrder] = useState({ order: 'fetchNew' });
  const ac = new AbortController();
  const { data, refetch, remove } = useCardsQuery(ac, order);
  useEffect(() => setPostsList(() => data && [...data]), [data]);
  useEffect(() => {
    setPage(1);
    setHasMore(true);
  }, [order.order]);
  useEffect(() => {
    if (postsList?.length) setPage((page) => page + 1);
  }, [postsList]);
  const contextValue = useMemo(() => ({ setCurrentPost, currentPost, refetch }), []);
  useEffect(() => () => remove(), []);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);

  return (
    <MainWrapper >
      <div id={cardsStyles.cardsGrid}>
        <section id={cardsStyles.cardsSection}>
          <UniversalNav setOrder={setOrder} order={order} typeOfSearch='cards' />
          <CardsContext.Provider value={contextValue}>
            <InfiniteScroll
              style={{ borderRadius: '.5rem', overflow: 'initial' }}
              dataLength={postsList?.length || 0}
              next={() => fetchCardsInfiniteScroll(ac, page, setHasMore, setPostsList, order)}
              hasMore={hasMore}
              loader={<p>Loading...</p>}
              endMessage={<PostHomeSectionEndOfList />}
            >
              {
                postsList ?
                  postsList.map((card) => <Card card={card} key={card._id} />) :
                  <CardsLoading />
              }
              {
                postsList && currentPost && (
                  <>
                    <DeletePost
                      post={currentPost}
                      typeOfPost='CARD'
                      setPostsList={setPostsList}
                      calledFrom='cardsSection' />
                    <BlockUserPortal currentPost={currentPost} refetch={refetch} />
                    <BanUserFromThreadPortal currentPost={currentPost} />
                    <ReportPost post={currentPost} />
                  </>
                )
              }
            </InfiniteScroll>
          </CardsContext.Provider>
        </section>
        <FivePostsInCardsSection />
      </div>
    </MainWrapper >
  );
}
