/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShare } from '@fortawesome/free-solid-svg-icons';
import sharePostStyles from './sharePostStyles.module.css';

export default function SharePost() {
    const sharePost = async () => {
        // setNot({ ver: true, mensaje: 'Only registered users can share publications.' });
    };
    return (
        <div onClick={sharePost} role='button' tabIndex={0} id={sharePostStyles.sharePost}>
            <span id={sharePostStyles.sharePostTooltip}>Share Post</span>
            <FontAwesomeIcon icon={faShare} />
        </div>
    )
}
