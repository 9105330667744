/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import { QueryClient } from "react-query";
import { useContext } from "react";
import { PostType, PostPollCardOrThreadContextType } from "../../../../../../universalTypes/universalTypes";

export function handleDeletePost(context: PostPollCardOrThreadContextType, post: PostType, queryClient: QueryClient) {
  const currentPost = post as PostType
  if (!context) return;
  context.setCurrentPost(currentPost);
  queryClient.setQueryData("deletePost", true);
}

export function handleCopyLinkPost(post: PostType, setVisible: React.Dispatch<React.SetStateAction<boolean>> | null) {
  if (post.isPost) {
    navigator.clipboard.writeText(`${window.location.origin}/thread/${post.postThreadData?.name}/post/${post.titleId}`);
    setVisible?.(false);
    return;
  }
  if (post.isCard) {
    navigator.clipboard.writeText(`${window.location.origin}/thread/${post.postThreadData?.name}/card/${post.titleId}`);
    setVisible?.(false);
    return;
  }
  navigator.clipboard.writeText(`${window.location.origin}/thread/${post.postThreadData?.name}/poll/${post.titleId}`);
  setVisible?.(false);
}

export function handleOnBlurPostHomeOptions(event: React.FocusEvent<HTMLDivElement>, optionsRef: React.RefObject<HTMLDivElement>, setVisible: React.Dispatch<React.SetStateAction<boolean>>) {
  const { relatedTarget: target } = event;
  const orc = optionsRef.current;
  if (target !== orc && !orc?.contains(target)) setVisible(false);
}

export function handleBlockUser(context: PostPollCardOrThreadContextType, post: PostType, queryClient: QueryClient) {
  if (!context) return;
  const currentPost = post as PostType
  context.setCurrentPost(currentPost);
  queryClient.setQueryData("blockUserPortal", true);
}

export function handleBanUserFromThread(context: PostPollCardOrThreadContextType, post: PostType, queryClient: QueryClient) {
  if (!context) return;
  const currentPost = post as PostType
  context.setCurrentPost(currentPost);
  queryClient.setQueryData("banUserFromThreadPortal", true);
}

export const handleReportPost = (context: PostPollCardOrThreadContextType, post: PostType, queryClient: QueryClient) => {
  if (!context) return;
  const currentPost = post as PostType
  context.setCurrentPost(currentPost);
  queryClient.setQueryData("reportPost", true);
}





