/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useNavigate } from 'react-router-dom';
import postCommentsStyles from './postCommentsStyles.module.css';
import PostCommentsAndRepliesWrapper from './postCommentsAndReplies/postCommentsAndRepliesWrapper';
import { PostCommentsType } from './postCommentsType';
import fetchPostCommentsInfiniteScroll from './postCommentsUtil';
import { CommentsSectionContext } from '../commentsSection';
import { CommentsSectionContext as UserProfileCommentsSectionContext } from '../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';
import CommentsSectionContextType from '../commentsSectionTypes';
import { whichContextGlobal } from '../../../../utilities/utils';

export const PostCommentsContext = createContext<any>(null);

export default function PostComments({ userProfile, isBeingCalledFromProfile }: any) {
    const commentsSectionContext = whichContextGlobal(CommentsSectionContext, UserProfileCommentsSectionContext, null) as CommentsSectionContextType;
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [order, setOrder] = useState({ order: 'fetchNew' });
    const [page, setPage] = useState(1);
    const [loadButton, setLoadButton] = useState(false);
    const navigate = useNavigate();
    useEffect(() => {
        if (window.location.hash) {
            setLoadButton(true);
            document.getElementById(window.location.hash.substring(1))?.scrollIntoView(false);
        }
    }, [commentsSectionContext?.commentsList]);
    useEffect(() => {
        if (loadButton) {
            setHasMore(false);
        }
    }, [loadButton]);
    useEffect(() => {
        if (commentsSectionContext?.commentsList?.length) setPage((page) => page + 1);
    }, [commentsSectionContext?.commentsList]);
    useEffect(() => {
        setPage(1);
        setHasMore(true);
    }, [order.order]);

    const handleLoadAllComments = () => {
        setLoadButton(false);
        navigate(window.location.pathname);
        window.location.reload();
    };

    const ac = new AbortController();
    return (
        <div id={isBeingCalledFromProfile ? postCommentsStyles.userProfileCommentsWrapper : postCommentsStyles.commentsWrapper}>
            <div id={postCommentsStyles.totalComments}>Comments: {commentsSectionContext?.totalPostComments}</div>
            <PostCommentsContext.Provider value={{
                setPage,
                userProfile,
                post: commentsSectionContext?.post,
                queryClient: commentsSectionContext?.queryClient,
                refetch: commentsSectionContext?.refetch,
                remove: commentsSectionContext?.remove,
                setCommentsList: commentsSectionContext?.setCommentsList,
                commentsList: commentsSectionContext?.commentsList,
                setCurrentComment: commentsSectionContext?.setCurrentComment,
                totalPostComments: commentsSectionContext?.data?.totalPostComments || 0,
                currentComment: commentsSectionContext?.currentComment,
                isBeingCalledFromProfile
            }}>
                <InfiniteScroll
                    style={{ borderRadius: '.5rem', overflow: 'initial', display: 'grid', rowGap: '1rem', paddingTop: '1rem' }}
                    dataLength={commentsSectionContext?.commentsList.length || 0}
                    next={() => fetchPostCommentsInfiniteScroll(ac,
                        isBeingCalledFromProfile ? userProfile?._id : commentsSectionContext?.post._id as string,
                        page,
                        setHasMore,
                        commentsSectionContext?.setCommentsList as React.Dispatch<React.SetStateAction<PostCommentsType[]>>,
                        order)}
                    hasMore={hasMore}
                    loader={null}
                >
                    {
                        commentsSectionContext?.commentsList.map((comment) =>
                            <PostCommentsAndRepliesWrapper comment={comment} key={comment._id} />)
                    }
                </InfiniteScroll>
                {
                    loadButton && <div
                        id={postCommentsStyles.loadAllComments}
                        onClick={handleLoadAllComments}
                        tabIndex={0}
                        role='button'>Load all comments
                    </div>
                }
            </PostCommentsContext.Provider>
        </div>
    )
}
