/* eslint-disable no-underscore-dangle */
import React from 'react';
import { GoogleOAuthProvider, GoogleLogin, CredentialResponse } from '@react-oauth/google';
import { API_URL, GOOGLE_CLIENT_ID } from '../../../../utilities/utils';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import './loginPortalStylesNoModule.css';

export default function LoginPortalGoogle() {
    return (
        <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID} >
            <GoogleLogin
                onSuccess={(credentialResponse: CredentialResponse) => {
                    fetch(
                        `${API_URL}/user/userLogin`,
                        {
                            method: 'POST',
                            mode: 'cors',
                            headers: {
                                authorization: `Bearer ${credentialResponse.credential as string}`,
                            },
                        },
                    )
                        .then((response: Response) => response.json())
                        .then((response) => {
                            if (response.error) throw new Error(response.message);
                            console.log("RESPONSE ------>", response);
                            notificationsSocket.emit("loginUser", { userId: response.userId });
                            window.localStorage.token = response.user;
                            window.location.reload();
                        })
                        .catch((err) => console.log(err));
                }}
                onError={() => {
                    console.log('Login Failed >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>');
                }}
            />
        </GoogleOAuthProvider>
    )
}
