/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import NotThreadAdminAndCommentAuthor from './options/notThreadAdminAndCommentAuthor';
import NotThreadAdminNotCommentAuthor from './options/notThreadAdminNotCommentAuthor';
import ThreadAdminAndCommentAuthor from "./options/threadAdminAndCommentAuthor";
import ThreadAdminNotCommentAuthor from "./options/ThreadAdminNotCommentAuthor";
import { PostCommentsType } from '../../../postCommentsType';

export default function PostCommentOptionsPermits(
    comment: PostCommentsType,
    setVisible: React.Dispatch<React.SetStateAction<boolean>>,
    setIsEditable: React.Dispatch<React.SetStateAction<boolean>>,
    isComment: boolean,
    setReplyList: any
) {
    console.log("comment --->", comment);
    const {
        commentThreadData,
        isThisUserTheProfileOwnerAndCommentAuthor,
        isThisUserTheProfileOwner,
        isThisUserTheCommentAuthor } = comment as any; // MODIFICAR EL BACKEND, PORQUE NO TRAE commentThreadData
    console.log("----->", commentThreadData);
    switch (true) {
        case commentThreadData?.isThisUserTheAdminAndCommentAuthor || isThisUserTheProfileOwnerAndCommentAuthor:
            return <ThreadAdminAndCommentAuthor comment={comment} setVisible={setVisible} setIsEditable={setIsEditable} isComment={isComment} setReplyList={setReplyList} />
        case commentThreadData?.isThisUserTheAdmin || isThisUserTheProfileOwner:
            return <ThreadAdminNotCommentAuthor comment={comment} setVisible={setVisible} isComment={isComment} setReplyList={setReplyList} />
        case commentThreadData?.isThisUserTheCommentAuthor || isThisUserTheCommentAuthor:
            return <NotThreadAdminAndCommentAuthor comment={comment} setVisible={setVisible} setIsEditable={setIsEditable} isComment={isComment} setReplyList={setReplyList} />
        default: /* VISITOR */
            return <NotThreadAdminNotCommentAuthor comment={comment} setVisible={setVisible} isComment={isComment} />
    }
}

/* const {
        isThisUserTheProfileOwnerAndCommentAuthor,
        isThisUserTheProfileOwner,
        isThisUserTheCommentAuthor
    } = comment;
    switch (true) {
        case isThisUserTheProfileOwnerAndCommentAuthor:
            return <ThreadAdminAndCommentAuthor comment={comment} setVisible={setVisible} setIsEditable={setIsEditable} isComment={isComment} />
        case isThisUserTheProfileOwner:
            return <ThreadAdminNotCommentAuthor comment={comment} setVisible={setVisible} isComment={isComment} />
        case isThisUserTheCommentAuthor:
            return <NotThreadAdminAndCommentAuthor comment={comment} setVisible={setVisible} setIsEditable={setIsEditable} isComment={isComment} />
        default: 
        return <NotThreadAdminNotCommentAuthor comment={comment} setVisible={setVisible} isComment={isComment} />
    } */