import React from 'react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import threadsSectionLoadingStyles from './threadsSectionLoadingStyles.module.css'

export default function ThreadsSectionLoading() {
    return (
        <div id={threadsSectionLoadingStyles.threadsSectionLoadingWrapper}>
            <div><FontAwesomeIcon icon={faClock} /> recent threads</div>
            <div />
            <div />
            <div />
        </div>
    )
}
