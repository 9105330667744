import { useQuery } from 'react-query';
import { API_URL } from '../../../utilities/utils';
import { PostType } from '../../../universalTypes/universalTypes';

const fetchPost = async (threadName: string, postId: string, ac: AbortController, typeOfPost: string) => {
    const response = await fetch(
        `${API_URL}/thread/${threadName}/${typeOfPost}/${postId}`,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                authorization: `Bearer ${window.localStorage.token as string}`
            },
            signal: ac.signal
        },
    );
    const parsedResponse = await response.json();
    if (!parsedResponse) throw new Error("NO POST!");
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as PostType;
};

const usePostQuery = (threadName: string, postId: string, ac: AbortController, typeOfPost: string) =>
    useQuery('post',
        () => fetchPost(threadName, postId, ac, typeOfPost),
        { refetchOnWindowFocus: false }
    );

export default usePostQuery;