import { useState } from 'react'
import { ThreadsType } from '../postCreatorTypes';


export default function UsePostAtttributes() {
    const [postType, setPostType] = useState('POSTS');
    const [postTitle, setPostTitle] = useState('');
    const [postThread, setPostThread] = useState<ThreadsType | null>(null);
    const [threadList, setThreadList] = useState<ThreadsType[] | null>(null);
    const [isNSFW, setIsNSFW] = useState(false);
    const [isSpoiler, setIsSpoiler] = useState(false);
    const [postBody, setPostBody] = useState<Document | null>(null);

    return {
        postType,
        setPostType,
        postTitle,
        setPostTitle,
        postThread,
        setPostThread,
        threadList,
        setThreadList,
        isNSFW,
        setIsNSFW,
        isSpoiler,
        setIsSpoiler,
        postBody,
        setPostBody
    }
}
