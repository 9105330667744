/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import React, { memo, useMemo } from 'react';
import postRulesStyles from './postCreatorRulesStyles.module.css';

function PostCreatorRules() {

    const rulesTitle = useMemo(() => [
        'Personal information, harassment',
        'Piracy',
        'Spam',
        'Duplicate content',
        'Ilegal pornography',
        'NSFW and Spoiler flags'
    ], []);

    const rulesText = useMemo(() => [
        `In order to preserve the well-being of the members of the community,
        any type of disclosure of personal information and / or harassment,
        doxxing, cyberbullying, etc. is not allowed.`,
        `In order to avoid legal problems both for the site 
         and for the users themselves, publishing links to anything
         that contains copyright is not allowed.`,
        `The publication of links to promote own or third-party products
         (forums, blogs, sales sites, referals etc.) is not allowed,
          the only exception being the publication of links
           from YouTube channels.`,
        `In order for no user to earn points with a
         post by another user and for the very quality 
         of the site's posts, this is not allowed.`,
        `Offenders who post such abominable things, 
        will be reported to justice and taken to court.`,
        `Users who do not properly flag posts that are 
        NSFW or contain spoilers will be banned.`
    ], []);

    const handleDropdownRule = (event: any) => {
        const target = event.currentTarget.children[1];
        if (target.style.maxHeight === '') {
            target.style.maxHeight = '500px';
            return;
        }
        target.style.maxHeight = '';
    }

    return (
        <div id={postRulesStyles.postRulesFirstContainer}>
            <div id={postRulesStyles.postRulesContainer}>
                <div>GENERAL RULES</div>
                {
                    rulesTitle.map((item, index) =>
                        <div className={postRulesStyles.rules}
                            key={index}
                            role='button'
                            tabIndex={0}
                            onClick={handleDropdownRule}>
                            <div>{index + 1}. {item}</div>
                            <div>
                                {rulesText[index]}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default memo(PostCreatorRules);
