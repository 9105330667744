/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import postCommentsStyles from '../../postCommentsStyles.module.css';
import EditPostComment from './editPostComment/editPostComment';
import CommentVotesAndReplies from './commentVotesAndReplies';
import timeSincePosted from '../../../../../../utilities/utils';
import { PostCommentsContext } from '../../postComments';
import { PostCommentsType } from '../../postCommentsType';

export default function Comment({ comment, setReplyList, isEditable, setIsEditable }: { comment: PostCommentsType, setReplyList: any, isEditable: any, setIsEditable: any }) {
    const context = useContext(PostCommentsContext);
    const navigate = useNavigate();
    const handleVisitProfile = () => navigate(`/profile/${comment?.commentAuthorData.nickname}` as string);

    return (
        <div className={postCommentsStyles.comment} id={comment?._id}>
            <img className={postCommentsStyles.commentsProfileImage}
                src={comment?.commentAuthorData.lowResThumbnail}
                alt={comment?.commentAuthorData.lowResThumbnail}
                loading="lazy"
                decoding="async"
                onClick={handleVisitProfile}
            />
            <div className={postCommentsStyles.commentAuthorNickname}>
                <span onClick={handleVisitProfile} role='button' tabIndex={0}>{comment?.commentAuthorData.nickname}</span> <span> • {` ${timeSincePosted(comment?.timestamp as number)}`}</span>
            </div>
            {
                !isEditable ?
                    <div ref={(element) => {
                        const tempElement = element;
                        if (!tempElement) return;
                        const comentContent = comment?.commentContent;
                        if (!comentContent) return;
                        tempElement.innerHTML = comentContent;
                    }} className={postCommentsStyles.commentContent} />
                    :
                    <EditPostComment
                        isComment
                        comment={comment}
                        setReplyList={setReplyList}
                        setIsEditable={setIsEditable} />
            }
            <CommentVotesAndReplies
                reply={null}
                isComment
                comment={comment}
                setReplyList={setReplyList}
                setIsEditable={setIsEditable}
            />
        </div>
    )
}
