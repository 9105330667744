/* eslint-disable  */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import cardBodyStyles from './cardBodyStyles.module.css';

export default function CardBodyNSFW({ isNSFW }: { isNSFW: boolean }) {
    const handleTakeOffNsfw = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        event.preventDefault();
        const classList = event.currentTarget.parentElement?.children[1].classList;
        classList?.remove(classList[0]);
        event.currentTarget.remove();
    }
    const isNSFWorSpoiler = () => {
        if (isNSFW) return '「 N S F W ! 」';
        return '「 S P O I L E R ! 」';
    }
    return (
        <div onClick={handleTakeOffNsfw} className={cardBodyStyles.nsfwDiv}>
            <span>
                <FontAwesomeIcon icon={faExclamationTriangle} />
                {isNSFWorSpoiler()}
                <FontAwesomeIcon icon={faExclamationTriangle} />
            </span>
        </div>
    );
}