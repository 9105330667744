/* eslint-disable no-plusplus */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import postCreatorPollAreaStyles from './postCreatorPollWithImagesStyles.module.css';
import postCreatorTextareaStyles from '../postCreatorTextarea/postTextarea/postCreatorTextareaStyles.module.css';
import PostCreatorPollImage from './postCreatorPollImage';
import { PostCreatorPollAreaWithImagesProps, pollImagesType } from './postCreatorPollWithImagesTypes';
import { API_URL } from '../../../../utilities/utils';
import { PostCreatorContext } from '../../postCreator';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import ERROR from '../../../../utilities/errors';

export default function PostCreatorPollAreaWithImages({ setPollhasPollImages }: PostCreatorPollAreaWithImagesProps) {
    const context = useContext(PostCreatorContext);
    const [pollData, setPollData] = useState<pollImagesType[]>([]);
    const navigate = useNavigate();
    const [isImageAdderActive, setIsImageAdderActive] = useState(true);
    const [pollImageElements, setPollImageElements] = useState<JSX.Element[]>([]);
    const sendPollRef = useRef(null);
    const [isAvailableForSending, setIsAvailableForSending] = useState(true);
    const [isNSFW, setIsNSFW] = useState(false);
    const [isSpoiler, setIsSpoiler] = useState(false);
    const handleIsSpoiler = () => setIsSpoiler((value) => !value);
    const handleIsNSFW = () => setIsNSFW((value) => !value);
    const handleAddPollImage = () => {
        setPollImageElements((arr) => [...arr,
        <PostCreatorPollImage
            key={Date.now()}
            pollData={pollData}
            setPollData={setPollData}
            setIsImageAdderActive={setIsImageAdderActive}
            setPollImageElements={setPollImageElements}
        />
        ]);
        setIsImageAdderActive(false);
    };
    const handleGoBack = () => setPollhasPollImages(null);
    const sendPoll = () => {
        if (!isAvailableForSending) return false;
        if (!context?.postTitle) return notificationsSocket.emit('sendErrorMessage', ERROR.TITLE_IS_EMPTY);
        if (!context?.postThread) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NOT_SELECTED);
        setIsAvailableForSending(false);
        fetch(`${API_URL}/pollCreator/create-new-poll`,
            {
                mode: 'cors',
                method: 'post',
                headers: {
                    "Authorization": `Bearer ${window.localStorage.token}`,
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    title: context.postTitle,
                    threadId: context.postThread._id,
                    spoiler: isSpoiler.toString(),
                    nsfw: isNSFW.toString(),
                    sticky: 'false',
                    pollOptionsWithImages: pollData,
                    pollHasImages: true
                })
            })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                navigate(`/thread/${response.postThreadName}/poll/${response.titleId}`);
            })
            .catch(e => {
                notificationsSocket.emit('sendErrorMessage', e.message);
                setIsAvailableForSending(true);
            })
        return null;
    }

    return (
        <div id={postCreatorTextareaStyles.commentsTextareaContainerDiv}>
            <label id={postCreatorPollAreaStyles.cardImageAreaLabel} htmlFor="cardImageArea">
                <span className={`${postCreatorTextareaStyles.postCreatorConfirmationSpot}
                 ${pollData.length > 1 && postCreatorTextareaStyles.activeSpot} `}>●</span> POLL IMAGES (required):
            </label>
            <div id={postCreatorPollAreaStyles.pollImagesWrapper}>
                {pollImageElements}
                {
                    isImageAdderActive &&
                    <div id={postCreatorPollAreaStyles.pollImageAdder}
                        onClick={handleAddPollImage}
                        role='button'
                        tabIndex={0} >
                        <span className='fas fa-plus' />
                        <span>add an Image</span>
                    </div>
                }
            </div>
            <div className={postCreatorTextareaStyles.commentsTextAreaSendButton}>
                <div onClick={handleGoBack}
                    role='button'
                    tabIndex={0}
                    id={postCreatorTextareaStyles.goBackToPollTypeSelector}>
                    <span>&#x21E6;</span>&nbsp;
                    <span>back</span>
                </div>
                <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isSpoiler && postCreatorTextareaStyles.active}`}
                    onClick={handleIsSpoiler}
                    role='button'
                    tabIndex={0}
                >
                    <span>SPOILER</span>
                </div>
                <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isNSFW && postCreatorTextareaStyles.active}`}
                    onClick={handleIsNSFW}
                    role='button'
                    tabIndex={0}>
                    <span>NSFW</span>
                </div>
                <div id={postCreatorTextareaStyles.sendPost}
                    onClick={sendPoll}
                    ref={sendPollRef}
                    role='button'
                    tabIndex={0}
                >
                    {isAvailableForSending ? <div>➢</div> : <div id={postCreatorTextareaStyles.creatingNewPost} />}
                </div>
            </div>
        </div >
    )
}
