/* eslint-disable no-underscore-dangle */
import React from 'react';
import twoSmallCardsHomeStyles from './twoSmallCardsHome.module.css';
import { SecondAndThirdCardsHomeProps } from './twoSmallCardsHomeTypes';
import TwoSmallCardsHomeSwitchImageTypeOrVideo from './twoSmallCardsHomeSwitchImageTypeOrVideo';

export default function SecondAndThirdCardsHome({ card, index }: SecondAndThirdCardsHomeProps) {
  const setCorrectPadding = (indexParam: number) => {
    if (indexParam > 0 && indexParam % 2 !== 0 ) return twoSmallCardsHomeStyles.paddingRight;
    return twoSmallCardsHomeStyles.paddingLeft;
  };

  return (
    <div id={twoSmallCardsHomeStyles.twoSmallCardsHome} className={setCorrectPadding(index as number)} key={card._id}>
      <div>
        <TwoSmallCardsHomeSwitchImageTypeOrVideo card={card} />
      </div>
    </div>
  );
}
