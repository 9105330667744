/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from 'react-router-dom';
import userProfileStyles from '../userProfileStyles.module.css';

export default function UserProfileFriends({ userProfile }: any) {
    console.log(userProfile);
    return (
        <div className={userProfileStyles.userProfilePictures}>
            <div>Friends</div>
            {
                userProfile.friends?.map((item: any) => <Link to={`/profile/${item.nickname}`} key={item._id}>
                    <img src={item.lowResThumbnail}
                        alt='imageAlt'
                        loading="lazy"
                        decoding="async"
                        title={item.nickname} />
                    <div className={userProfileStyles.friendsNickname}>{item.nickname}</div>
                </Link>)
            }
        </div>
    )
}
