/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { useQueryClient } from 'react-query';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import userProfileInformationStyles from './userProfileInformation.module.css'
import { UserProfileType } from '../../userProfileTypes';
import ChangeUserNickname from '../../../sharedComponents/reactPortals/changeUserNickname/changeUserNickname';

export default function UserProfileInformation({ userProfile }: { userProfile: UserProfileType }) {
    const queryClient = useQueryClient();
    const handleClick = () => queryClient.setQueryData("changeUserNickname", true);
    return (
        <>
            <div id={userProfileInformationStyles.userProfileInformationGrid}>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.nickname}</div>
                    {
                        userProfile?.isThisUserTheProfileOwner &&
                        !userProfile?.hasThisUserChangedHisNickname &&
                        <FontAwesomeIcon icon={faEdit}
                            className={userProfileInformationStyles.editItem}
                            onClick={handleClick} tabIndex={0} role='button' />
                    }
                    <div>Nickname</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.rank}</div>
                    <div>Rank</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.sex}</div>
                    <div>Sex</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.birthday}</div>
                    <div>Birthday</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.country}</div>
                    <div>Nationality</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.city}</div>
                    <div>Living In</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.maritalStatus}</div>
                    <div>Civil Status</div>
                </div>
                <div className={userProfileInformationStyles.infoItems}>
                    <div>{userProfile?.info.zodiac}</div>
                    <div>Zodiac Sign</div>
                </div>
            </div>
            <ChangeUserNickname queryKey="changeUserNickname" userProfileId={userProfile?._id} />
        </>
    )
}
