/* eslint-disable import/no-cycle */
import { API_URL } from "../../../../utilities/utils";
import { FriendType } from "./userProfileFriendsSectionTypes";

export default async function fetchUserProfileFriendsInfiniteScroll(ac: AbortController,
    page: number, setHasMore: React.Dispatch<React.SetStateAction<boolean>>,
    setFriends: React.Dispatch<React.SetStateAction<FriendType[] | null>>, toggleFriendsAndRequests: boolean) {
    if (page < 2) return null;
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        signal: ac.signal,
    } as RequestInit;
    const response = toggleFriendsAndRequests ?
        await fetch(`${API_URL}/user-profile/infinite-scroll/get-this-user-friends/${page}`, fetchOptions) :
        await fetch(`${API_URL}/user-profile/infinite-scroll/get-this-user-friend-requests/${page}`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) return console.log("------>", parsedResponse.message);
    if (parsedResponse.length < 1) return setHasMore(false);
    return setFriends((prevPostsList) => prevPostsList && [...prevPostsList, ...parsedResponse]);
};
