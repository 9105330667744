/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { faComment, faBookmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardOptionsStyles from './cardOptionsStyles.module.css';
import { API_URL } from '../../../../utilities/utils';
import { PostType } from '../../../../universalTypes/universalTypes';
import Share from './share/share';
import CopyLink from './link/link';
import { notificationsSocket } from '../../../nav/notifications/notifications';

export default function CardOptions({ card }: { card: PostType }) {
    const [bookmarked, setHasBookmarked] = useState({
        hasThisUserBookmarkedThisPost: card.hasThisUserBookmarkedThisPost,
    });

    const handleBookmarkCard = () => {
        fetch(`${API_URL}/post/process-bookmark-post`,
            {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    postId: card._id,
                    threadId: card.postThreadData._id
                }),
            })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                setHasBookmarked({
                    hasThisUserBookmarkedThisPost: response.ok
                })
            })
            .catch(e => notificationsSocket.emit('sendErrorMessage', e.message));
    }

    return (
        <div className={cardOptionsStyles.cardOptionsDiv}>
            <div>
                <FontAwesomeIcon icon={faComment} /> <span className={cardOptionsStyles.optionText}> {card.totalComments} Comments</span>
                <span className={cardOptionsStyles.totalCommentsMobile}> {card.totalComments}</span>
            </div>
            <Share />
            <div onClick={handleBookmarkCard}
                role='button'
                tabIndex={0}>
                <FontAwesomeIcon
                    icon={faBookmark}
                    className={bookmarked.hasThisUserBookmarkedThisPost ? cardOptionsStyles.cardOptionSaved : ''}
                /> <span className={cardOptionsStyles.optionText} > {bookmarked.hasThisUserBookmarkedThisPost ? 'Bookmarked' : 'Bookmark'}</span>
            </div>
            <CopyLink post={card} setVisible={null} />
        </div>
    )
}
