/* eslint-disable no-underscore-dangle */
import { useQuery } from 'react-query';
import { API_URL } from '../../utilities/utils';
import {PostType, OrderType } from '../../universalTypes/universalTypes';
import { ThreadsType } from '../postCreator/postCreatorTypes';

function getRequestURL(order: OrderType, thread: ThreadsType) {
    switch (order.order) {
        case 'fetchNew':
            return `${API_URL}/thread/get-thread-posts-poll-and-cards/${thread._id}`;
        case 'fetchHot':
            return `${API_URL}/thread/get-thread-posts-poll-and-cards-hot/${thread._id}`;
        case 'fetchTop':
            return `${API_URL}/thread/get-thread-posts-poll-and-cards-top/${thread._id}`;
        case 'search':
            return `${API_URL}/thread/get-thread-posts-poll-and-cards-search/${order.value}`;
        default:
            return `${API_URL}/thread/get-thread-posts-poll-and-cards`;
    }
}

async function fetchThread(ac: AbortController, thread: ThreadsType, order: OrderType) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(getRequestURL(order, thread), fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as PostType[];
}

const useThreadPostsPollsAndCards = (ac: AbortController, thread: ThreadsType, order: OrderType) => useQuery([order],
    () => fetchThread(ac, thread, order),
    { refetchOnWindowFocus: false });

export default useThreadPostsPollsAndCards;