/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useEffect, useState } from 'react';
import postCreatorTypeStyles from './postCreatorTypeStyles.module.css';
import { PostCreatorContext } from '../postCreator';
import { PostTypes } from '../postCreatorTypes';

export default function PostCreatorType() {
    const [postType, setPostType] = useState(['POSTS', 'POLLS', 'CARDS']);
    const context = useContext(PostCreatorContext);

    const handlePostType = (event: React.MouseEvent) => {
        const targetElement = event.target as HTMLDivElement;
        switch (targetElement.id) {
            case 'POSTS':
                if (!postType.includes(targetElement.id)) return;
                context?.setPostType(PostTypes.POSTS);
                break;
            case 'CARDS':
                if (!postType.includes(targetElement.id)) return;
                context?.setPostType(PostTypes.CARDS);
                break;
            case 'POLLS':
                if (!postType.includes(targetElement.id)) return;
                context?.setPostType(PostTypes.POLLS);
                break;
            default:
        }
    }
    const isTypeActive = (type: string) => {
        if (!postType.includes(type)) {
            return postCreatorTypeStyles.forbiddenOption;
        }
        if (context?.postType === type) {
            return postCreatorTypeStyles.postTypeActive;
        }
        return '';
    };

    useEffect(() => {
        if (context?.postThread?.typeOfPost?.length)
            setPostType(context?.postThread?.typeOfPost);
    }, [context?.postThread]);

    return (
        <div id={postCreatorTypeStyles.postCreatorTypeGrid}>
            <div onClick={handlePostType}
                role='button'
                tabIndex={0}
                className={isTypeActive(PostTypes.POSTS)}
                id='POSTS'>POST</div>
            <div onClick={handlePostType}
                role='button'
                tabIndex={0}
                className={isTypeActive(PostTypes.CARDS)}
                id='CARDS'>CARD</div>
            <div onClick={handlePostType}
                role='button'
                tabIndex={0}
                className={isTypeActive(PostTypes.POLLS)}
                id='POLLS'>POLL</div>
        </div>
    )
}
