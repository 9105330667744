/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useRef } from 'react';
import postCommentOptionsStyle from '../postCommentOptionsStyles.module.css';
import { PostCommentsType } from '../../../../postCommentsType';
import Delete from './actions/delete';
import { handleOnBlurPostCommentOptions } from './optionsUtils';
import Edit from './actions/edit';

export default function NotThreadAdminAndCommentAuthor({ comment, setVisible, setIsEditable, isComment, setReplyList }: { comment: PostCommentsType, setVisible: React.Dispatch<React.SetStateAction<boolean>>, setIsEditable: React.Dispatch<React.SetStateAction<boolean>>, isComment: boolean, setReplyList: any }) {
    const optionsRef = useRef<HTMLDivElement>(null);

    return (
        <div className={postCommentOptionsStyle.postCommentOptionsContainer}
            onBlur={(event) => handleOnBlurPostCommentOptions(event, optionsRef, setVisible)}
            ref={optionsRef}>
            <Edit setVisible={setVisible}
                setIsEditable={setIsEditable}
                isComment={isComment}
            />
            <Delete comment={comment} isComment={isComment} setReplyList={setReplyList} />
        </div>
    )
}
