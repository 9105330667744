/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cardsStyle from './firstCardHome.module.css';
import FirstCardHomeInfo from './firstCardHomeInfo';
import FirstCardHomeTitle from './firstCardHomeTitle';
import { FirstCardHomeProps } from './firstCardHomeTypes';
import FirstCardHomeSwitchImageTypeOrVideo from './firstCardHomeSwitchImageTypeOrVideo';

export default function FirstCardHome({ card }: FirstCardHomeProps) {
  return (
    <React.Fragment key={card._id}>
      <div id={cardsStyle.cardInfoHome}>
        <FirstCardHomeTitle card={card} />
        <FirstCardHomeInfo card={card} />
      </div>
      <FirstCardHomeSwitchImageTypeOrVideo card={card} />
    </React.Fragment>
  );
}
