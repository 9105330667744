/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import userProfileStyles from '../userProfileStyles.module.css';
import postCommentsStyles from '../postComments/postCommentsStyles.module.css';
import UserProfileLoadingPublicationTemplate from './userProfileLoadingPublicationTemplate';


export default function UserProfileLoadingTemplate() {
    return (
        <div id={userProfileStyles.userProfilePublicationsWrapper}>
            <div id={postCommentsStyles.commentsWrapper}>
                <div id={postCommentsStyles.totalComments}>Publications</div>
                <div style={{
                    height: 'auto',
                    overflow: 'initial',
                    borderRadius: '0.5rem',
                    display: 'grid',
                    rowGap: '1.5rem'
                }}>
                    <UserProfileLoadingPublicationTemplate />
                    <UserProfileLoadingPublicationTemplate />
                    <UserProfileLoadingPublicationTemplate />
                    <UserProfileLoadingPublicationTemplate />
                    <UserProfileLoadingPublicationTemplate />
                    <UserProfileLoadingPublicationTemplate />
                </div>
            </div >
        </div>
    )
}
