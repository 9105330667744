/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { API_URL } from '../../../../utilities/utils'
import { UserProfileType } from '../../userProfileTypes'
import userProfileStyles from '../../userProfileStyles.module.css';
import UserProfileDontHaveAny from '../../../sharedComponents/userProfileDontHaveAny/userProfileDontHaveAny';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import useUserProfileFriends from './userProfileFriendsSectionCustomHooks';
import PostHomeSectionEndOfList from '../../../homeSection/postsSectionHome/postHomeSectionEndOfList/postHomeSectionEndOfList';
import fetchUserProfileFriendsInfiniteScroll from './userProfileFriendsSectionUtils';
import { FriendType } from './userProfileFriendsSectionTypes';



export default function UserProfileFriendsSection({ userProfile }: { userProfile: UserProfileType }) {
    const [toggleFriendsAndRequests, setToggleFriendsAndRequests] = useState(true);
    const [friends, setFriends] = useState<FriendType[] | null>([]);
    const [friendRequests, setFriendsRequests] = useState<FriendType[] | null>([]);
    const ac = new AbortController();
    const { data, refetch, remove, isFetching } = useUserProfileFriends(ac, userProfile, toggleFriendsAndRequests);
    console.log('data --->', data);
    useEffect(() => {
        if (toggleFriendsAndRequests)
            if (data) setFriends(() => (data as any).friends && [...(data as any).friends])
    }, [data]);
    useEffect(() => {
        if (!toggleFriendsAndRequests)
            if (data) setFriendsRequests(() => (data as any).friendRequests && [...(data as any).friendRequests])
    }, [data]);
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    useEffect(() => {
        setPage(1);
        setHasMore(true);
        refetch();
    }, [toggleFriendsAndRequests]);


    const handleAcceptOrDecline = (userToAccept: FriendType, isAcceptingFriendRequest: boolean) => {
        const token = window.localStorage.token as string;
        fetch(`${API_URL}/user-profile/accept-friend-request`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                userIdToAccept: userToAccept._id,
                isAcceptingFriendRequest
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.message) throw new Error(response.message);
                if (isAcceptingFriendRequest) {
                    setFriends((arr: any) => {
                        const newArr = [...arr];
                        newArr.unshift({ _id: userToAccept._id, thumbnail: userToAccept.thumbnail, nickname: userToAccept.nickname });
                        return newArr;
                    });
                    setFriendsRequests((arr: any) => {
                        const newArr = arr?.filter((item: any) => item._id !== userToAccept._id)
                        return newArr;
                    })
                    if (response.thisUserId !== userToAccept._id) {
                        notificationsSocket.emit('sendMessageToSpecificUser', {
                            notificationId: response.friendRequestNotificationId,
                            userId: userToAccept._id,
                            postUrl: `profile/${response.thisUserData.nickname}`,
                            message: `${response.thisUserData.nickname} accepted your friend request!`
                        });
                    }
                    return;
                }
                setFriendsRequests((arr: any) => {
                    const newArr = arr?.filter((item: any) => item._id !== userToAccept._id)
                    return newArr;
                })
            })
    }

    const handleToggle = (trueOrFalse: boolean) => setToggleFriendsAndRequests(trueOrFalse)

    if (isFetching) return <div id={userProfileStyles.isFetching} style={{
        gridTemplateRows: 'auto 100%'
    }}><div id={userProfileStyles.friendsAndRequestsWrapper}>
            <div
                className={toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                onClick={() => handleToggle(true)}
                tabIndex={0}
                role='button'
            >Friends</div>
            <div
                className={!toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                onClick={() => handleToggle(false)}
                tabIndex={0}
                role='button'>Friend Requests</div>
        </div><div>isFetching...</div></div >
    if (toggleFriendsAndRequests && !friends?.length) return <div id={userProfileStyles.isFetching} style={{
        gridTemplateRows: 'auto 100%'
    }}>
        <div id={userProfileStyles.friendsAndRequestsWrapper}>
            <div
                className={toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                onClick={() => handleToggle(true)}
                tabIndex={0}
                role='button'
            >Friends</div>
            <div
                className={!toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                onClick={() => handleToggle(false)}
                tabIndex={0}
                role='button'>Friend Requests</div>
        </div>
        <UserProfileDontHaveAny userProfile={userProfile as UserProfileType}
            isOwnerMessage="You don't have any friends to show..."
            isNotOwnerMessage="This user don't have any friends to show..." />
    </div>
    if (!toggleFriendsAndRequests && !friendRequests?.length) return <div id={userProfileStyles.isFetching} style={{
        gridTemplateRows: 'auto 100%'
    }}>
        <div id={userProfileStyles.friendsAndRequestsWrapper}>
            <div
                className={toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                onClick={() => handleToggle(true)}
                tabIndex={0}
                role='button'
            >Friends</div>
            <div
                className={!toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                onClick={() => handleToggle(false)}
                tabIndex={0}
                role='button'>Friend Requests</div>
        </div>
        <UserProfileDontHaveAny userProfile={userProfile as UserProfileType}
            isOwnerMessage="You don't have any friend requests to show..."
            isNotOwnerMessage="This is not your profile..." />
    </div>

    return (
        <div id={userProfileStyles.friendsWrapper}>
            <div id={userProfileStyles.friendsAndRequestsWrapper}>
                <div
                    className={toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                    onClick={() => handleToggle(true)}
                    tabIndex={0}
                    role='button'
                >Friends</div>
                <div
                    className={!toggleFriendsAndRequests ? userProfileStyles.isActive : ''}
                    onClick={() => handleToggle(false)}
                    tabIndex={0}
                    role='button'>Friend Requests</div>
            </div>
            {
                toggleFriendsAndRequests ?
                    < div className={userProfileStyles.friendsDivider}>
                        <div className={userProfileStyles.friendsTitle}>Friends</div>
                        <div className={userProfileStyles.friendsSectionWrapper}>
                            <InfiniteScroll
                                style={{ borderRadius: '.5rem', overflow: 'initial' }}
                                dataLength={friends?.length || 0}
                                next={() => fetchUserProfileFriendsInfiniteScroll(ac, page, setHasMore, setFriends, toggleFriendsAndRequests)}
                                hasMore={hasMore}
                                loader={<p>Loading...</p>}
                                endMessage={<PostHomeSectionEndOfList />}
                            >
                                {
                                    friends?.map((item) => <Link to={`/profile/${item.nickname}`} key={item._id}>
                                        <img src={item.thumbnail}
                                            alt='imageAlt'
                                            loading="lazy"
                                            decoding="async"
                                            title={item.nickname} />
                                        <div className={userProfileStyles.friendsNickname}>{item.nickname}</div>
                                    </Link>)
                                }
                            </InfiniteScroll>
                        </div>
                    </div>
                    :
                    userProfile?.isThisUserTheProfileOwner && !!friendRequests?.length &&
                    <div className={userProfileStyles.friendsDivider}>
                        <div className={userProfileStyles.friendsTitle}>Friend Requests</div>
                        <div className={userProfileStyles.friendsSectionWrapper}>
                            <InfiniteScroll
                                style={{ borderRadius: '.5rem', overflow: 'initial' }}
                                dataLength={friends?.length || 0}
                                next={() => fetchUserProfileFriendsInfiniteScroll(ac, page, setHasMore, setFriends, toggleFriendsAndRequests)}
                                hasMore={hasMore}
                                loader={<p>Loading...</p>}
                                endMessage={<PostHomeSectionEndOfList />}
                            >
                                {
                                    friendRequests?.map((item) => <div key={item._id}>
                                        <Link to={`/profile/${item.nickname}`} >
                                            <img src={item.thumbnail}
                                                alt='imageAlt'
                                                loading="lazy"
                                                decoding="async"
                                                title={item.nickname} />
                                            <div className={userProfileStyles.friendsNickname}>{item.nickname}</div>
                                        </Link>
                                        <div className={userProfileStyles.accetOrDecline}>
                                            <div onClick={() => handleAcceptOrDecline(item, true)} tabIndex={0} role='button'>accept</div>
                                            <div onClick={() => handleAcceptOrDecline(item, false)} tabIndex={0} role='button'> decline</div>
                                        </div>
                                    </div>
                                    )
                                }
                            </InfiniteScroll>
                        </div>
                        <hr id={userProfileStyles.separator} />
                    </div>
            }
        </div>
    );

}
