/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faBell,
    faBookmark,
    faDiceD20,
    faHome,
    faImage,
    faInfoCircle,
    faUserFriends
} from '@fortawesome/free-solid-svg-icons';
import userProfileStyles from '../userProfileStyles.module.css';
import ProfileSections from '../userProfileUtils';

export default function UserProfileNav({ setProfileSection, profileSection }: any) {
    const handleClickNav = (section: string) => setProfileSection(section);

    return (
        <div id={userProfileStyles.userProfileNav}>
            <div
                onClick={() => handleClickNav(ProfileSections.WALL)}
                tabIndex={0} role='button'
                className={profileSection === ProfileSections.WALL ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Wall</span>
                <FontAwesomeIcon icon={faHome} className={userProfileStyles.navMobile} />
            </div>
            <div
                onClick={() => handleClickNav(ProfileSections.WALL)}
                tabIndex={0} role='button'
                className={profileSection === ProfileSections.PICTURES ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Pictures</span>
                <FontAwesomeIcon icon={faImage} className={userProfileStyles.navMobile} />
            </div>
            <div onClick={() => handleClickNav(ProfileSections.FRIENDS)}
                tabIndex={0}
                role='button'
                className={profileSection === ProfileSections.FRIENDS ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Friends</span>
                <FontAwesomeIcon icon={faUserFriends} className={userProfileStyles.navMobile} />
            </div>
            <div
                onClick={() => handleClickNav(ProfileSections.NOTIFICATIONS)}
                tabIndex={0}
                role='button'
                className={profileSection === ProfileSections.NOTIFICATIONS ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Notifications</span>
                <FontAwesomeIcon icon={faBell} className={userProfileStyles.navMobile} />
            </div>
            <div
                onClick={() => handleClickNav(ProfileSections.BOOKMARKED)}
                tabIndex={0}
                role='button'
                className={profileSection === ProfileSections.BOOKMARKED ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Bookmarked</span>
                <FontAwesomeIcon icon={faBookmark} className={userProfileStyles.navMobile} />
            </div>
            <div
                onClick={() => handleClickNav(ProfileSections.MEMBEROF)}
                tabIndex={0}
                role='button'
                className={profileSection === ProfileSections.MEMBEROF ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Member Of</span>
                <FontAwesomeIcon icon={faDiceD20} className={userProfileStyles.navMobile} />
            </div>
            <div
                onClick={() => handleClickNav(ProfileSections.INFORMATION)}
                tabIndex={0}
                role='button'
                className={profileSection === ProfileSections.INFORMATION ? userProfileStyles.userProfileNavSelected : ''}>
                <span className={userProfileStyles.navDesktop} >Information</span>
                <FontAwesomeIcon icon={faInfoCircle} className={userProfileStyles.navMobile} />
            </div>
        </div>
    )
}
