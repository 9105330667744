import { ActionType, StateType } from "./postCreatorPollWithImagesTypes";

export function reducer(state: StateType, action: ActionType) {
    switch (action.type) {
        case 'SET_IS_SAVED':
            return { ...state, isSaved: action.payload };
        case 'SET_POLL_IMAGE_FILE':
            return { ...state, pollImageFile: action.payload };
        case 'SET_IS_BUTTON_AVAILABLE':
            return { ...state, isButtonAvailable: action.payload };
        case 'SET_IS_IMAGE_UPLOADED':
            return { ...state, isImageUploaded: action.payload };
        default:
            return state;
    }
}

export const initialState: StateType = {
    isSaved: false,
    pollImageFile: null,
    isButtonAvailable: true,
    isImageUploaded: false,
};