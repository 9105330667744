/* eslint-disable react/require-default-props */
/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import React from 'react';

import PostFooterOptionsAdminAndAuthor from "../postFooterOptionsComponents/postFooterOptionsAdminAndAuthor"
import PostFooterOptionsAdminThread from '../postFooterOptionsComponents/postFooterOptionsAdminThread';
import PostFooterOptionsPostAuthor from '../postFooterOptionsComponents/postFooterOptionsPostAuthor';
import PostFooterOptionsVisitor from '../postFooterOptionsComponents/postFooterOptionsVisitor';
import { PostType, UseQueryRefetch } from '../../../../universalTypes/universalTypes';

export default function PostFooterOptions({ post, refetch, setIsEditable = null }: { post: PostType, refetch: UseQueryRefetch, setIsEditable?: React.Dispatch<React.SetStateAction<boolean>> | null }) {

    switch (true) {
        case post.postThreadData.isThisUserTheAdminAndPostAuthor:
            return <PostFooterOptionsAdminAndAuthor post={post} refetch={refetch} setIsEditable={setIsEditable} />
        case post.postThreadData.isThisUserTheAdmin:
            return <PostFooterOptionsAdminThread post={post} refetch={refetch} />
        case post.postThreadData.isThisUserThePostAuthor:
            return <PostFooterOptionsPostAuthor post={post} refetch={refetch} setIsEditable={setIsEditable} />
        default: /* VISITOR */
            return <PostFooterOptionsVisitor post={post} refetch={refetch} />
    }
}
