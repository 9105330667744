import React from 'react';
import FirstCardHomeTypeVideo from "./firstCardHomeTypeVideo";
import CardsTypeYoutubeOrImage from "./firstCardHomeTypeYoutubeOrImage";
import { FirstCardHomeProps } from "./firstCardHomeTypes";

export default function FirstCardHomeSwitchImageTypeOrVideo(props: FirstCardHomeProps) {
  const { card } = props;
  switch (true) {
    case !!card.youtube || !!card.imageUrl:
      return <CardsTypeYoutubeOrImage card={card} />;
    default:
      return (
        <FirstCardHomeTypeVideo card={card} />
      );
  }
};