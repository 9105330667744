/* eslint-disable  */
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import firstCardHomeStyles from './firstCardHome.module.css';

export default function FirstCardHomeTitleNSFW({ isNSFW }: { isNSFW: boolean }) {
  const handleTakeOffNsfw = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    const classList = event.currentTarget.parentElement?.children[1].classList;
    classList?.remove(classList[0]);
    event.currentTarget.remove();
  }
  return (
    <div onClick={handleTakeOffNsfw} id={firstCardHomeStyles.nsfwDivHome}>
      <span>
        <FontAwesomeIcon icon={faExclamationTriangle} />
        {isNSFW ? '「 N S F W ! 」' : '「 S P O I L E R ! 」'}
        <FontAwesomeIcon icon={faExclamationTriangle} />
      </span>
    </div>
  );
}
