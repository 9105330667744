/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import threadPostsStyles from './threadPosts.module.css';
import useThreadPostsPollsAndCards from '../useThreadPostsPollsAndCards';
import Post from '../../homeSection/postsSectionHome/postHome/postHome';
import Card from '../../cardsSection/card/card';
import PostsSectionHomeLoading from '../../homeSection/postsSectionHome/postsSectionHomeLoading/postsSectionHomeLoading';

import { PostPollCardOrThreadContextType, PostType } from '../../../universalTypes/universalTypes';
import PostHomeSectionEndOfList from '../../homeSection/postsSectionHome/postHomeSectionEndOfList/postHomeSectionEndOfList';
import fetchThreadPostsInfiniteScroll from './threadPostUtils';
import DeletePost from '../../sharedComponents/reactPortals/deletePost/deletePost';
import BlockUserPortal from '../../sharedComponents/reactPortals/blockUserPortal/blockUserPortal';
import BanUserFromThreadPortal from '../../sharedComponents/reactPortals/banUserFromThreadPortal/banUserFromThreadPortal';
import ReportPost from '../../sharedComponents/reactPortals/reportPost/reportPost';
import { ThreadsType } from '../../postCreator/postCreatorTypes';
import UniversalNav from '../../sharedComponents/universalNav/universalNav';

export const ThreadContext = createContext<PostPollCardOrThreadContextType>(null);

export default function ThreadPosts({ thread }: { thread: ThreadsType }) {
    const [postsList, setPostsList] = useState<PostType[]>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [order, setOrder] = useState({ order: 'fetchNew' });
    const [page, setPage] = useState(1);
    const [currentPost, setCurrentPost] = useState<PostType | null>(null);
    const ac = new AbortController();
    const { data, refetch, remove } = useThreadPostsPollsAndCards(ac, thread, order);
    useEffect(() => setPostsList(() => data && [...data]), [data]);
    useEffect(() => {
        setPage(1);
        setHasMore(true);
    }, [order.order]);
    useEffect(() => {
        if (postsList?.length) setPage((page) => page + 1);
    }, [postsList]);
    const typeOfPost = () => {
        if (currentPost?.isPost) return 'POST';
        if (currentPost?.isPoll) return 'POLL';
        return 'CARD';
    }
    useEffect(() => () => remove(), []);
    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
    useEffect(() => console.log("hasMore???--->", hasMore), [hasMore]);
    useEffect(() => console.log("page???--->", page), [page]);
    // const contexto = useMemo(() => ({ setCurrentPost, currentPost, refetch }), [])

    return (
        <div id={threadPostsStyles.threadBody}>
            <UniversalNav setOrder={setOrder} order={order} typeOfSearch='posts' />
            <ThreadContext.Provider value={{ setCurrentPost, currentPost, refetch }}>
                <InfiniteScroll
                    style={{ borderRadius: '.5rem', overflow: 'initial' }}
                    dataLength={postsList?.length || 0}
                    next={() => fetchThreadPostsInfiniteScroll(ac, page, setHasMore, setPostsList, order, thread._id)}
                    hasMore={hasMore}
                    loader={<p>Loading...</p>}
                    endMessage={<PostHomeSectionEndOfList />}
                >
                    {
                        postsList ?
                            postsList.map((item) => (
                                !item.isCard ? <Post post={item} key={item._id} isItBeingCalledFromThread /> :
                                    <Card card={item} key={item._id} isItBeingCalledFromThread />
                            ))
                            : <PostsSectionHomeLoading />
                    }
                    {
                        currentPost && (
                            <>
                                <DeletePost
                                    post={currentPost}
                                    typeOfPost={typeOfPost()}
                                    setPostsList={setPostsList} calledFrom='thread' />
                                <BlockUserPortal currentPost={currentPost} refetch={refetch} />
                                <BanUserFromThreadPortal currentPost={currentPost} />
                                <ReportPost post={currentPost} />
                            </>
                        )
                    }
                </InfiniteScroll>
            </ThreadContext.Provider>
        </div>
    )
}
