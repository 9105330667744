/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import cardBodyStyles from './cardBodyStyles.module.css'
import CardBodyNSFW from './cardBodyNSFW';
import { PostType } from '../../../../universalTypes/universalTypes';

export default function CardVideo({ card }: { card: PostType }) {
    return (
        <div className={cardBodyStyles.cardBodyImageOrVideo} >
            {(card.isNSFW || card.isSpoiler) && <CardBodyNSFW isNSFW={card.isNSFW} />}
            <video
                src={card.videoUrl}
                preload='auto'
                controls
                className={`${card.isNSFW || card.isSpoiler ? cardBodyStyles.nsfwFilter : ''}`} />
        </div>
    );
}
