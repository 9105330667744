/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import React, { useMemo } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { faDiceD20, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import threadInfoStyles from './threadInfo.module.css';
import {
    postInfoIsAutorOrMember,
    postInfoIsNSFW,
    threadDateOfCreation
} from './threadInfoUtils';
import { ThreadsType } from '../../postCreator/postCreatorTypes';
import maqueta from '../../../assets/images/maqueta.png';

export default function ThreadInfo({ postThread, setPostThread, isThisBeingUsedFromAThread }: { postThread: ThreadsType, setPostThread: React.Dispatch<React.SetStateAction<ThreadsType | null>>, isThisBeingUsedFromAThread: boolean }) {
    const navigate = useNavigate();
    const isMember = useMemo(() => postInfoIsAutorOrMember(postThread, setPostThread), [postThread]);
    const handleUnfoldRuleDescription = (ev: React.MouseEvent<HTMLDivElement>) => {
        const ele = ev.currentTarget.nextElementSibling as HTMLDivElement;
        if (!ele) return;
        if (ele.style.maxHeight === '500px') {
            ele.style.maxHeight = '0';
            return;
        };
        ele.style.maxHeight = '500px';
    }
    const typeOfPosts = (arr: string[]) => arr?.map((item) => <span key={item}> {item} </span>)
    const handleCreatePost = () => navigate(`/create-new-post/${postThread.name}`);
    const handleEditThread = () => {
        navigate(`/edit-thread/${postThread?.name}`);
    }

    return (
        <div id={threadInfoStyles.threadInfoFirstContainer}>
            <div id={threadInfoStyles.threadInfoContainer}>
                <div>
                    <Link to={`/thread/${postThread.name}`}>
                        <img src={postThread.lowResThumbnail || maqueta}
                            alt='imageAlt'
                            loading="lazy"
                            decoding="async"
                            id={threadInfoStyles.threadInfoLowResThumbnail}
                        />
                    </Link>
                    <div>
                        <Link id={threadInfoStyles.threadInfoName}
                            to={`/thread/${postThread.name}`}><FontAwesomeIcon icon={faDiceD20}
                                id={threadInfoStyles.threadInfoNameDice} /> <div id={threadInfoStyles.threadInfoNameDiv}
                                    title={postThread.name}>
                                {postThread.name}
                            </div>
                        </Link>
                        <div />
                        <div id={threadInfoStyles.memberAndEditWrapper}>
                            {isMember}
                            {postThread.isThisUserTheAdmin &&
                                < div id={threadInfoStyles.editThread} onClick={handleEditThread} >
                                    <FontAwesomeIcon icon={faPencil}
                                        id={threadInfoStyles.editThreadPencil}
                                    /> Edit
                                </div>
                            }
                        </div>
                    </div>
                </div>
                <div id={threadInfoStyles.threadInfoDescription}>
                    <span>{postThread.description}</span>
                </div>
                <div id={threadInfoStyles.membersAndPosts}>
                    <div>{postThread.howManyMembers}</div> <div>{postThread.numberOfPosts}</div>
                    <span>Members</span> <span>Posts</span>
                </div>
                <div id={threadInfoStyles.typeOfContentAndDateOfCreation}>
                    <div>{postInfoIsNSFW(postThread, threadInfoStyles)}</div>
                    <div>{threadDateOfCreation(postThread)}</div>
                    <span>Type Of Content</span>
                    <span>Date Of Creation</span>
                </div>
                <div id={threadInfoStyles.typeOfPostsAllowed}>
                    <div>{typeOfPosts(postThread.typeOfPost)}</div>
                    <span>Type Of Posts Allowed</span>
                </div>
                <Link to={`/profile/${postThread.threadAdminData.nickname}`} id={threadInfoStyles.threadAdminGrid}>
                    <div>
                        <img src={postThread.threadAdminData?.lowResThumbnail}
                            alt={postThread.threadAdminData?.lowResThumbnail}
                            id={threadInfoStyles.threadAdminImage}
                            loading="lazy"
                            decoding="async"
                        />
                        <div>
                            <span id={threadInfoStyles.threadInfoAdmin}>{postThread.threadAdminData.nickname}</span>
                            <span>{postThread.threadAdminData.info.rank}</span>
                        </div>
                    </div>
                    <span><FontAwesomeIcon icon={faDiceD20} id={threadInfoStyles.threadInfoAdminDice} /> {postThread.name} Administrator</span>
                </Link>
                {
                    isThisBeingUsedFromAThread && <div id={threadInfoStyles.threadInfoCreatePost}
                        onClick={handleCreatePost}
                        role='button'
                        tabIndex={0}>
                        <div>Create Post!</div>
                    </div>
                }
                <div id={threadInfoStyles.threadInfoRulesWrapper}>
                    <div><FontAwesomeIcon icon={faDiceD20} id={threadInfoStyles.threadInfoRulesDice} /> {postThread.name} rules:</div>
                    {
                        postThread.rules.map((item, index) => (
                            <div key={index}>
                                <div className={threadInfoStyles.threadInfoRuleTitle}
                                    onClick={handleUnfoldRuleDescription}
                                    role='button'
                                    tabIndex={0}>
                                    <div>{index + 1}.</div>
                                    <div>{item.title}</div>
                                </div>
                                <div className={threadInfoStyles.threadInfoRuleDescription}>
                                    <div>{item.description}</div>
                                </div>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div >
    )
}



