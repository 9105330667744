import React from 'react';
import userProfileDontHaveAnyStyles from './userProfileDontHaveAnyStyles.module.css';
import { UserProfileType } from '../../userProfile/userProfileTypes';

export default function UserProfileDontHaveAny({ userProfile, isOwnerMessage, isNotOwnerMessage }: { userProfile: UserProfileType, isOwnerMessage: string, isNotOwnerMessage: string }) {
    return (
        <div id={userProfileDontHaveAnyStyles.dontHaveAny}>
            {userProfile?.isThisUserTheProfileOwner ? isOwnerMessage : isNotOwnerMessage}
        </div>
    )
}
