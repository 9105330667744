/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardOptionsThreeDotsRightStyles from './cardOptionsThreeDotsRightStyles.module.css';
import cardOptionsPermits from './cardOptionsThreeDotsRightUtils';
import { PostType } from '../../../../universalTypes/universalTypes';

export default function CardOptionsThreeDotsRight({ card, isItBeingCalledFromThread = false }: { card: PostType, isItBeingCalledFromThread: boolean }) {
    const [visible, setVisible] = useState<boolean>(false);
    const optionsRef = useRef<HTMLDivElement>(null);
    const iconRef = useRef<SVGSVGElement>(null);

    const handleCardOptions = () => setVisible(visible => !visible);

    const handleOnBlurCardOptions = (event: React.FocusEvent<HTMLDivElement>) => {
        const { relatedTarget: tgt } = event;
        const orc = optionsRef.current;
        console.log("relatedTarget", tgt);
        console.log("orc", orc);
        console.log("orc?.parentElement?.nextElementSibling -->", orc?.nextElementSibling);

        if (tgt !== orc && !orc?.contains(tgt) && tgt !== orc?.nextElementSibling && !orc?.nextElementSibling?.contains(tgt))
            setVisible(false);
    }

    return (
        <>
            <div
                className={cardOptionsThreeDotsRightStyles.cardOptions}
                onClick={handleCardOptions}
                role='button'
                tabIndex={0}
                ref={optionsRef}
                onBlur={handleOnBlurCardOptions}>
                <FontAwesomeIcon icon={faEllipsisVertical} ref={iconRef} />
            </div>
            {visible && cardOptionsPermits(card, setVisible, isItBeingCalledFromThread)}
        </>
    );
}

