/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { createContext, useMemo } from 'react';
import postCreatorStyles from './postCreatorStyles.module.css';
import PostCreatorType from './postCreatorType/postCreatorType';
import PostCreatorTypeSelected from './postCreatorTypeSelected/postCreatorTypeSelected';
import { PostCreatorContextType } from './postCreatorTypes';
import UsePostAtttributes from './postCreatorCustomHooks/usePostAtttributes';
import ThreadInfo from '../sharedComponents/threadInfo/threadInfo';
import PostCreatorRules from './postCreatorRules/postCreatorRules';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';

export const PostCreatorContext = createContext<PostCreatorContextType>(null);

export default function PostCreator() {
    const {
        postType,
        setPostType,
        postTitle,
        setPostTitle,
        postThread,
        setPostThread
    } = UsePostAtttributes();

    const contextValue = useMemo(() => ({
        postType,
        setPostType,
        postTitle,
        setPostTitle,
        postThread,
        setPostThread
    }), [postType, postTitle, postThread]);

    return (
        <MainWrapper>
            <div id={postCreatorStyles.postCreatorGrid}>
                <PostCreatorContext.Provider value={contextValue}>
                    <div id={postCreatorStyles.postCreatorType}>
                        <PostCreatorType />
                        <PostCreatorTypeSelected />
                    </div>
                    {
                        postThread ?
                            <ThreadInfo
                                postThread={postThread}
                                setPostThread={setPostThread}
                                isThisBeingUsedFromAThread={false} /> :
                            <PostCreatorRules />
                    }
                </PostCreatorContext.Provider>
            </div >
        </MainWrapper >
    )
}
