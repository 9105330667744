import React, { Dispatch, SetStateAction } from "react";
import "./tinymceEditorStyles.css";
import { useQueryClient } from "react-query";
import { Editor } from "@tinymce/tinymce-react";
import {
  contentStyle,
  setupTinymceEditor,
  tinyMCEEditorPlugins,
} from "../../../utilities/utils";

export default function EditorTinyMCE({ setPostBody, post, editorRef, setAreEmojisVisible }: { setPostBody: Dispatch<SetStateAction<Document | null>>, post: any, editorRef: any, setAreEmojisVisible: any }) {
  const queryClient = useQueryClient();
  function insertContents(inst: any) {
    console.log("---->", post)
    inst.setContent(post.postBody);
  }

  return <Editor
    initialValue=""
    init={{
      setup: (editor) => setupTinymceEditor(editor, queryClient, setAreEmojisVisible, setPostBody, editorRef),
      menubar: false,
      plugins: tinyMCEEditorPlugins,
      toolbar: false,
      content_style: contentStyle(),
      autoresize_bottom_margin: 0,
      min_height: 66,
      height: 66,
      statusbar: false,
      media_types: "image video",
      file_picker_types: "file image media",
      block_unsupported_drop: false,
      image_title: true,
      automatic_uploads: true,
      object_resizing: false,
      image_dimensions: false,
      image_advtab: false,
      toolbar_sticky: true,
      default_link_target: "_blank",
      placeholder: "Create your post...",
      init_instance_callback: post && insertContents,
      sandbox_iframes: false,
      license_key: 'gpl',
      extended_valid_elements: 'iframe[src|frameborder|style|scrolling|class|width|height|name|align|sandbox|allowfullscreen]',
    }}
  />
}
