/* eslint-disable  */
import axios from 'axios';
import { Editor as TinyMCEEditor, EditorEvent } from 'tinymce';
import { CommandsAndOthers, TinymceExecCommandType } from '../universalTypes/universalTypes';
import { QueryClient } from 'react-query';
import { notificationsSocket } from '../components/nav/notifications/notifications';
import ERROR from './errors';
import { useContext } from 'react';

export const APP_URL = window.location.origin;
export const API_URL = process.env.NODE_ENV === 'production' ? 'https://gamerth-2-0-backend.onrender.com/api' : ((window.location.host === 'localhost:3001' || window.location.host === 'localhost:3000') ? 'https://localhost:5000/api' : 'http://192.168.1.36:5000/api');;// process.env.GAMERTH_API_URL || ((window.location.host === 'localhost:3001' || window.location.host === 'localhost:3000') ? 'https://localhost:5000/api' : 'http://192.168.1.36:5000/api');
export const ROOT_URL = ((window.location.host === 'localhost:3001' || window.location.host === 'localhost:3000') ? 'https://localhost:5000/src' : 'http://192.168.1.36:5000/src');
export const GOOGLE_CLIENT_ID = "579446697931-n3km066pn1fb1jc7gcdrp5ah5noigv9u.apps.googleusercontent.com"// "1096796727470-4ushm4fstje6am564rp505orthiknbmf.apps.googleusercontent.com";
export const TINYMCE_APIKEY = "yiq83u51nwnvsaajpda2o39ubu5uqbghyfkxjk4lu67lqk6a";
export const gamerthLogoURL = "https://w3s.link/ipfs/bafkreig2o67clyhcsplrmigomzehmvl2fod7brtv3tdr3p5ezkasoutfta";
// export const youtubeRegex = /http(?:s?):\/\/(?:www\.)?youtu(?:be\.com\/watch\?v=|\.be\/)([\w\-\_]*)(&(amp;)?‌​[\w\?‌​=]*)?/g;
// export const youtubeRegex = /(youtu.*be.*)\/(watch\?v=|embed\/|v|shorts|)(.*?((?=[&#?])|$))/
export const youtubeRegex = /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=|shorts\/))([^#&?]{11})/;

export const imageUrlRegex = /(https?:\/\/.*\.(?:png|jpg|svg|bmp|gif|webp))/ig;
export const videoUrlRegex = /(https?:\/\/.*\.(?:webm|mp4))/ig;
export const facebookVideosRegex = /^https?:\/\/www\.facebook\.com.*\/(video(s)?|watch|story)(\.php?|\/).+$/gm;
export const xTwitter = /(x.*com.*)\/(status\/)(\d+)(?:\/video\/\d+)?(?=[&#?]|$)/gm;
export const isLink = /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/ig;
export const isTagged = /@([a-zA-Z0-9_]+)/ig;
export const getVideoIdFromYoutubeURL = (url: string) => {
  var match = url.match(youtubeRegex);
  return (match && match[1]) ? match[1] : false;
}

export const getIdFromExTwitterURL = (url: string) => {
  console.log("url --->", url);
  const regExp = /(x.*com.*)\/(status\/)(\d+)/gm;
  const match = url.match(regExp);
  if (!match) return false;
  return match[0].split('/')[3];;
}

export const getUserFromExTwitterURL = (url: string) => {
  const regex = /\/([^\/]+)\/status(?:\/\d+(?:\/video\/\d+)?)?/gm;
  const match = url.match(regex);
  if (!match) return null;
  return match[0].split('/')[1]; // Accede al primer elemento del array y extrae el usuario
}

export const getVideoIdFromFacebookVideoURL = (url: string) => {
  const regex = /\/videos\/(\d+)\//;
  const match = url.match(regex);
  if (match && match[1]) {
    return match[1];
  }
  const regex2 = /\/videos\/(\d+)/;
  const match2 = url.match(regex2);
  if (match2 && match2[1]) {
    return match2[1];
  }
  return null;
}


// https://www.facebook.com/plugins/video.php?&href=https://www.facebook.com/manganimetvoficial/videos/243128427311506


export const imageOrVideoMaxSize = 26214400; // 25 Megabytes
export const tinyMCEEditorPlugins = [
  "-advlist",
  "autolink",
  "lists",
  "image",
  "charmap",
  "preview",
  "anchor",
  "searchreplace",
  "visualblocks",
  "code",
  "fullscreen",
  "insertdatetime",
  "media",
  "table",
  "code",
  "help",
  "autoresize",
  /* "file_picker",
  "video",
  "clipboard", */
];

/* export const tinyMCEEditorToolbar = `bold italic underline strikethrough subscript superscript  
    bullist numlist 
    alignleft aligncenter alignright alignjustify 
    blockquote spoiler  
    uploadImage uploadVideo 
    removeformat`; */
export const tinyMCEEditorToolbar = `
    bold 
    italic 
    underline 
    numlist 
    alignleft 
    aligncenter   
    blockquote 
    spoiler  
    uploadImage 
    uploadVideo 
    removeformat`;
// export const tinyMCEEditorToolbar = "bold italic strikethrough superscript | spoiler blockquote uploadImage uploadVideo bullist numlist removeformat | emoticons";
export const videoElement = (videoSource?: string) => `<video 
                        src = ${videoSource}
                        controls="true"
                        style="width: 100%;
                        max-height: 500px;
                        height:500px;
                        z-index: 1;
                        position: relative;
                        background-color:black"
                    />`;
export const acceptedImageFormats = "image/webp, image/png, image/jpeg, image/gif, image/bmp, image/svg+xml";
export const acceptedVideoFormats = "video/webm, video/mp4";

const getIdToUnblurImage = (fileName?: string, lastModified?: number) => `${fileName}${lastModified?.toString()}`.replaceAll(' ', '');

export function tinymceExecCommand(
  editor: TinyMCEEditor,
  typeOfInsertion: string,
  wildcard: CommandsAndOthers
) {
  switch (typeOfInsertion) {
    case 'spoiler':
      editor?.execCommand('hiliteColor', true, 'black');
      editor?.execCommand('foreColor', true, 'black');
      return true;
    case 'emoticons':
      editor?.execCommand('mceInsertContent', true, wildcard?.textData);
      return true;
    case 'imageBlurredPreview':
      editor?.execCommand(
        "mceInsertContent",
        false,
        `<div class="postImageOrVideoWrapper">
            <img 
                src=${wildcard?.reader?.result} 
                title=${wildcard?.file?.name} 
                id= ${getIdToUnblurImage(wildcard?.file?.name, wildcard?.file?.lastModified)}
                alt=${wildcard?.file?.name} 
                style="filter:blur(10px);
                width:100%;
                border:none;
                border-radius:.5rem;
                transition: all .15s ease-in-out;
                vertical-align: baseline;
                max-height: 500px;
                object-fit: contain;
                background-color: #0f0f0f"
                loading='lazy' 
                decoding='async'
                class='postImage' />
                <br/>
            </div>`
      );
      return true;
    case 'videoFetchedFromServer':
      editor?.execCommand(
        "mceInsertContent",
        false,
        `<div class="postImageOrVideoWrapper">
              <video controls="controls"
              src=${wildcard?.reader?.result} 
              title=${wildcard?.file?.name} 
              id= ${getIdToUnblurImage(wildcard?.file?.name, wildcard?.file?.lastModified)}
              alt=${wildcard?.file?.name} 
              style="width: 100%;
              max-height: 500px;
              height:500px;
              z-index: 1;
              filter:blur(10px);
              position: relative;"></video>
              <br /><br />
          </div>`
      );
      return true;
    case 'escapeKey':
      editor?.editorManager.activeEditor?.execCommand('removeFormat');
      editor?.editorManager.activeEditor?.execCommand('Outdent');
      return true;
    case 'isYouTubeURL':
      editor?.execCommand(
        'mceInsertContent',
        false,
        `<iframe 
               loading="lazy" 
               height=480
               src="https://www.youtube.com/embed/${getVideoIdFromYoutubeURL(wildcard?.isYouTubeURL?.[0] as string)}"
               frameborder="0" 
               allow="autoplay;
               encrypted-media" 
               allowfullscreen 
               style="position:relative;z-index:1;border:none;border-radius:.5rem;width: -webkit-fill-available">
          </iframe>
          <br/>`,
      );
      return true;
    case 'isExTwitterURL':
      // const editorDocument = editor.editorManager.activeEditor?.contentDocument;
      // const createDiv = document.createElement('p');
      // Crea el video de Twitter
      console.log(wildcard?.isExTwitterURL?.[0]);
      // Crear el bloquequote
      const blockquote = document.createElement('blockquote');
      blockquote.className = 'twitter-tweet';
      // blockquote.setAttribute('data-media-max-width', '560');

      // Crear el párrafo
      const p = document.createElement('p');
      p.lang = 'es';
      p.dir = 'ltr';
      p.innerHTML = '‼️<a href="https://twitter.com/hashtag/ATENCI%C3%93N?src=hash&amp;ref_src=twsrc%5Etfw">#ATENTION</a>| THIS WILL PASTE A TWITTER VIDEO <a href="https://t.co/8KbkJrIQ7i">NOW!</a>';

      // Crear el enlace
      const a = document.createElement('a');
      a.href = `https://twitter.com/${getUserFromExTwitterURL(wildcard?.isExTwitterURL?.[0] as string)}/status/${getIdFromExTwitterURL(wildcard?.isExTwitterURL?.[0] as string)}`;
      const date = new Date();
      a.innerHTML = date.getDate() + '/' + date.getMonth() + '/' + date.getFullYear();

      // Agregar los elementos al bloquequote
      blockquote.appendChild(p);
      blockquote.appendChild(a);

      editor?.execCommand(
        'mceInsertContent',
        false,
        blockquote.outerHTML
      );

      setTimeout(() => {
        editor.editorManager.activeEditor?.dispatch('click');
        editor?.execCommand(
          'mceInsertContent',
          false,
          `<p style="text-align:center">
              <img src="https://w3s.link/ipfs/bafkreifsayoo2mw3wpitu2s5ip273e2zwpeobk3cvinvvoavvv2e3poflq" 
                  data-src="https://w3s.link/ipfs/bafkreifsayoo2mw3wpitu2s5ip273e2zwpeobk3cvinvvoavvv2e3poflq"
                  style="height:50px;width:50px;border-radius: 50%;"/>
          </p>`);
        editor?.execCommand(
          'mceInsertContent',
          false,
          '<p><br/></p>'
        );
      }, 1000);

      return true;
    case 'isFacebookVideoURL':
      const videoId = getVideoIdFromFacebookVideoURL(wildcard?.isFacebookVideoURL?.[0] as string);
      console.log("videoId", videoId);
      console.log("wildcard?.isFacebookVideoURL", wildcard?.isFacebookVideoURL?.[0]);
      if (!videoId) return false;
      const source = `https://www.facebook.com/plugins/video.php?href=${wildcard?.isFacebookVideoURL?.[0]}`;
      editor?.execCommand(
        'mceInsertContent',
        false,
        `<div style="position: relative;height:1000px">
            <iframe
               height=480
               allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share;"
                src=${source}
                allowfullscreen
                style="width: -webkit-fill-available"
               >
          </iframe>
          </div>
          `,
      );
      const iframeElement = editor.dom.select(`[src="${source}"]`)[0];
      editor?.dom.setAttrib(iframeElement, 'sandbox', 'allow-scripts allow-same-origin');
      editor?.dom.setAttrib(iframeElement, 'controls', 'true');
      editor?.dom.setAttrib(iframeElement, 'src', source);
      editor?.dom.setAttrib(iframeElement, 'allowfullscreen', true);
      editor?.dom.setAttrib(iframeElement.parentElement?.parentElement as HTMLElement, 'style', "height:fit-content");

      editor?.execCommand(
        'mceInsertContent',
        false,
        `<p style="text-align:center">
            <img src="https://w3s.link/ipfs/bafkreifkopv4ns52nj7o5tukeswzolbxxazjkxzyk6d667bf4abe3qr7sy" 
                data-src="https://w3s.link/ipfs/bafkreifkopv4ns52nj7o5tukeswzolbxxazjkxzyk6d667bf4abe3qr7sy"
                style="height:50px;width:50px;border-radius: 50%;"/>
        </p>`);
      editor?.execCommand(
        'mceInsertContent',
        false,
        '<p><br/></p>'
      );
      return true;

    case 'isImageURL':
      editor?.execCommand('mceInsertContent', false, imageURLify(wildcard?.textData as string));
      return true;
    case 'isVideoURL':
      editor?.execCommand('mceInsertContent', false, videoURLify(wildcard?.textData as string));
      return true;
    case 'isLink':
      editor?.execCommand('mceInsertContent', false, urlify(wildcard?.textData as string));
      return true;
    default:
      editor?.execCommand('mceInsertContent', false, wildcard?.textData);
      return true;
  }
}
export function processTextDataWithRegex(
  event: EditorEvent<DragEvent | ClipboardEvent | InputEvent>,
  editor: TinyMCEEditor,
  tinymceExecCommand: TinymceExecCommandType
) {
  let textData;
  if ('clipboardData' in event) textData = event.clipboardData?.getData('text/plain');
  else textData = event.dataTransfer?.getData('text/plain');
  if (!textData) return true;
  const isExTwitterURL = textData?.match(xTwitter);
  if (isExTwitterURL) return tinymceExecCommand(editor, 'isExTwitterURL', { isExTwitterURL });
  const isFacebookVideoURL = textData?.match(facebookVideosRegex);
  if (isFacebookVideoURL) return tinymceExecCommand(editor, 'isFacebookVideoURL', { isFacebookVideoURL });
  const isYouTubeURL = textData?.match(youtubeRegex);
  if (isYouTubeURL) return tinymceExecCommand(editor, 'isYouTubeURL', { isYouTubeURL });
  const imageURL = textData.match(imageUrlRegex);
  if (imageURL) return tinymceExecCommand(editor, 'isImageURL', { textData })
  const videoURL = textData.match(videoUrlRegex);
  if (videoURL) return tinymceExecCommand(editor, 'isVideoURL', { textData })
  const link = textData.match(isLink);
  if (link) return tinymceExecCommand(editor, 'isLink', { textData });
  return tinymceExecCommand(editor, '', { textData });
}
export function listenToFormatChange(editor: TinyMCEEditor) {
  const spButton = document.getElementsByTagName('button')[4];
  editor.formatter.formatChanged('forecolor', (isNew: boolean) => {
    if (isNew) return spButton.classList.add('tox-tbtn--enabled');
    return spButton.classList.remove('tox-tbtn--enabled');
  }, true);
}
export function urlify(text: string) {
  return text.replace(isLink, (url: string) => `<a href="${url}">${url}</a>`)
}

export function tagToLink(text: string) {
  return text.replace(isTagged, (url: string) => `<a href="${url}">${url}</a>`)
}

export function imageURLify(text: string) {
  return text.replace(imageUrlRegex, (url: string) => `<img src="${url}" 
  data-src="${url}"
  alt='imageAlt'
  loading="lazy"
  decoding="async"
  style='width:100%;
  border-radius:.5rem;
  max-height: 500px;
  object-fit: contain;
  background-color: #0f0f0f'
  class="postImage"/>`);
}
export function videoURLify(text: string) {
  return text.replace(videoUrlRegex, (url: string) => videoElement(url));
}
export function readSaveAddImageAndVideo(
  reader: FileReader,
  file: File,
  editor: TinyMCEEditor,
  tinymceExecCommand: TinymceExecCommandType
) {
  reader.readAsDataURL(file);
  const tinyMCEIframe = editor.editorManager.activeEditor?.contentDocument;
  reader.onload = (ev) => {
    const formData = new FormData();
    console.log("file", file);
    switch (file.type) {
      case "video/webm":
      case "video/mp4":
        tinymceExecCommand(editor, 'videoFetchedFromServer', { reader: ev.target, file });
        editor.setEditableRoot(false);
        formData.append("video", file as Blob);
        fetch(`${API_URL}/postCreator/process-upload-video-in-create-new-post`, {
          method: "POST",
          mode: "cors",
          headers: {
            authorization: `Bearer ${window.localStorage.token}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) throw new Error(response.message);
            const videoElementId = getIdToUnblurImage(file.name, file.lastModified);
            const VideoElement = (tinyMCEIframe?.querySelector(`[data-mce-p-id="${videoElementId}"]`) as HTMLVideoElement).children[0] as HTMLVideoElement;
            if (!VideoElement) return false;
            VideoElement.src = response.video;
            VideoElement.style.filter = "";
            if (!VideoElement.parentElement) return false;
            VideoElement.parentElement.style.filter = "";
            VideoElement.parentElement?.parentElement?.classList.remove('postImageOrVideoWrapper');
            editor.setEditableRoot(true);
          }).catch((err) => {
            notificationsSocket.emit('sendErrorMessage', err.message);
            editor.setEditableRoot(true);
          });
        break;
      case "image/webp":
      case "image/png":
      case "image/jpeg":
      case "image/gif": {
        tinymceExecCommand(editor, 'imageBlurredPreview', { reader: ev.target, file });
        editor.setEditableRoot(false);    // get('mytextarea').setMode('readonly');
        formData.append("image", file as Blob);
        fetch(`${API_URL}/postCreator/process-upload-image-in-create-new-post`, {
          method: "POST",
          mode: "cors",
          headers: {
            authorization: `Bearer ${window.localStorage.token}`,
          },
          body: formData,
        })
          .then((response) => response.json())
          .then((response) => {
            if (response.error) throw new Error(response.message);
            const imageElementId = getIdToUnblurImage(file.name, file.lastModified);
            const imageElement = tinyMCEIframe?.getElementById(imageElementId) as HTMLImageElement;
            if (!imageElement) return false;
            imageElement.src = response.image;
            imageElement.dataset.src = response.lowResImage;
            imageElement.style.filter = "";
            imageElement.parentElement?.classList.remove('postImageOrVideoWrapper');
            editor.setEditableRoot(true);
            return true;
          })
          .catch((err) => {
            notificationsSocket.emit('sendErrorMessage', err.message);
            editor.setEditableRoot(true);
          });
        break;
      }
      default: return false;
    }
    return false;
  };
  return false;
}
export function contentStyle() {
  return `
  #tinymce { 
    overflow: hidden;
    font-family:Segoe UI;
    font-size:16px; 
    line-height: 1.33; 
    background-color:rgb(41, 42, 45)!important;
    color:#ffffffcc;
    margin:0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.25);
    min-height: 65px!important
  } 
  #tinymce * {
     -webkit-user-drag: none;
     outline: none!important;
     border: none!important
   }
  .mce-preview-object.mce-object-iframe {
     width: -webkit-fill-available; 
     margin:0;
     border:none;
     border-radius:.5rem!important
  } 
  .mce-preview-object,
  .mce-preview-object video {
     width: 100%;
     border: none !important;
     background-color: #0f0f0f;
     border-radius: .5rem;
     margin: 0!important;
  }
  p{ 
      margin:0 
  }
  img{
     width: 100%;
     border-radius: .5rem!important;
  }
  a{
     text-decoration: none;
     color: #c51818;
  }
  #noteView > div { 
      height: 100% !important; 
  }
      
   .mce-content-body[data-mce-placeholder]:not(.mce-visualblocks)::before {
      color:#5d666b;
  }
      
  .postImageOrVideoWrapper {
    position: relative;
    display: grid;
    align-items: center;
    justify-content: center;
  }

  .postImageOrVideoWrapper::before {
    content: 'Loading...';
    width: 100%;
    position: absolute;
    text-align: center;
    color: #c51818;
    z-index: 3;
    font-weight: bold;
    text-shadow: 1px 1px black;
    font-size: 1.5rem;
  }

  blockquote{
    position:relative;
  }

  blockquote::before {
    content: '“';
    position: absolute;
    font-size: 4rem;
    font-weight: bold;
    top: -32px;
    left: -18px;
}

  .postImageOrVideoWrapper::after {
    content: "";
    position: absolute;
    top: 50.28%;
    left: 61.05%;
    width: 10px;
    height: 10px;
    border: 5px solid #c51818;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    transform: translate(-50%, -50%);
    z-index:2
  }
    @keyframes spin {
      0% {
        transform: translate(-50%, -50%) rotate(0deg);
      }
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }

    iframe {
      overflow:hidden;
      border-radius: 0.5rem;
      width: -webkit-fill-available;
     }

     .twitter-tweet.twitter-tweet-rendered {
        margin: 0 auto;
      }
}
  `;
}

export function initEditor(editorRef: any, editor: TinyMCEEditor) {
  if (editorRef && editorRef.current !== editor) editorRef.current = editor;
}
export function setupTinymceEditor(editor: TinyMCEEditor, queryClient: QueryClient, setAreEmojisVisible: any, setPostBody: any, editorRef: any) {
  setAreEmojisVisible(false);
  queryClient.setQueryData('editor', editor);

  editor._eventDispatcher?.on("init", () => {
    setPostBody(() => {
      if (!editor.getDoc()) return null;
      return editor.getDoc();
    });
    initEditor(editorRef as TinyMCEEditor, editor);
    const script = document.createElement('script');
    script.src = "https://platform.twitter.com/widgets.js";
    editor.editorManager.activeEditor?.contentDocument.body.appendChild(script);
  });

  editor.on('SetContent', function () {
    const scriptTwo = document.createElement('script')
    scriptTwo.innerText = 'if(window.twttr) window.twttr.widgets.load();';
    editor.editorManager.activeEditor?.contentDocument.body.appendChild(scriptTwo);
  });

  editor.on('Change', function () {
    const scriptTwo = document.createElement('script')
    scriptTwo.innerText = 'if(window.twttr) window.twttr.widgets.load();';
    editor.editorManager.activeEditor?.contentDocument.body.appendChild(scriptTwo);
  });


  // const scriptTwo = document.createElement('script')
  // scriptTwo.innerText = 'window.twttr.widgets.load();';
  // editor.editorManager.activeEditor?.contentDocument.body.appendChild(scriptTwo);

  editor.ui.registry.addButton("spoiler", {
    tooltip: "covers spoiler text",
    type: "button",
    icon: "notice",
    onAction: () => {
      tinymceExecCommand(editor, 'spoiler', null);
      listenToFormatChange(editor);
    },
    onSetup: () => {
      listenToFormatChange(editor);
      return () => '';
    }
  });

  editor.ui.registry.addButton("uploadImage", {
    tooltip: "upload image from your device",
    type: "button",
    icon: "image",
    onAction: () => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", acceptedImageFormats);
      input.addEventListener("change", (e) => {
        const file = (e.currentTarget as HTMLInputElement)?.files?.[0];
        if (!file) return;
        if (file.size > imageOrVideoMaxSize) throw new Error(ERROR.MAX_IMAGE_FILE_SIZE_EXCEEDED);
        const reader = new FileReader();
        reader.onload = () => {
          const formData = new FormData();
          formData.append("image", file);
          tinymceExecCommand(editor, 'imageBlurredPreview', { reader, file });
          editor.setEditableRoot(false);
          fetch(`${API_URL}/postCreator/process-upload-image-in-create-new-post`, {
            method: "POST",
            mode: "cors",
            headers: {
              authorization: `Bearer ${window.localStorage.token as string}`,
            },
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.error) throw new Error(response.message as string);
              const imageElementId = getIdToUnblurImage(file?.name, file?.lastModified);
              const imageElement = editor?.dom.get(imageElementId) as HTMLImageElement;
              if (!imageElement) return false;
              imageElement.src = response.image;
              imageElement.dataset.src = response.lowResImage;
              imageElement.style.filter = "";
              imageElement.parentElement?.classList.remove('postImageOrVideoWrapper');
              editor.setEditableRoot(true);
              return true;
            })
            .catch((err) => {
              notificationsSocket.emit('sendErrorMessage', err.message);
              editor.setEditableRoot(true);
            })
        };
        reader.readAsDataURL(file as Blob);
      });
      input.click();
    },
  });

  editor.ui.registry.addButton("uploadVideo", {
    tooltip: "upload video from your device",
    type: "button",
    icon: "embed",
    onAction: () => {
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", acceptedVideoFormats);
      input.addEventListener("change", (e) => {
        const file = (e.target as HTMLInputElement)?.files?.[0];
        if (!file) return;
        if (file.size > imageOrVideoMaxSize) throw new Error(ERROR.MAX_VIDEO_FILE_SIZE_EXCEEDED);
        const reader = new FileReader();
        reader.onload = () => {
          const formData = new FormData();
          formData.append(
            "video", file as File
          );
          tinymceExecCommand(editor, 'videoFetchedFromServer', { reader, file });
          editor.setEditableRoot(false);
          fetch(`${API_URL}/postCreator/process-upload-video-in-create-new-post`, {
            method: "POST",
            mode: "cors",
            headers: {
              authorization: `Bearer ${window.localStorage.token}`,
            },
            body: formData,
          })
            .then((response) => response.json())
            .then((response) => {
              if (response.error) throw new Error(response.message);
              const videoElementId = getIdToUnblurImage(file?.name, file?.lastModified);
              const VideoElement = (editor?.dom.doc.querySelector(`[data-mce-p-id="${videoElementId}"]`) as HTMLVideoElement).children[0] as HTMLVideoElement;
              if (!VideoElement) return false;
              VideoElement.src = response.video;
              VideoElement.style.filter = "";
              if (!VideoElement.parentElement) return false;
              VideoElement.parentElement.style.filter = "";
              VideoElement.parentElement?.parentElement?.classList.remove('postImageOrVideoWrapper');
              editor.setEditableRoot(true);
              return true;
            })
            .catch((err) => {
              notificationsSocket.emit('sendErrorMessage', err.message);
              editor.setEditableRoot(true);
            });
        };
        reader.readAsDataURL(file as Blob);
      });
      input.click();
    },
  });

  editor._eventDispatcher?.on("keyup", () => setPostBody(() => {
    if (!editor.getContent()) return null;
    return editor.getDoc();
  }));

  editor._eventDispatcher?.on("change", () => setPostBody(() => {
    if (!editor.getContent()) return null;
    return editor.getDoc();
  }));

  editor._eventDispatcher?.on("keyDown", (event: KeyboardEvent) => {
    if (event.key === "Escape") {
      setAreEmojisVisible(false);
      tinymceExecCommand(editor, 'escapeKey', null);
    }
  });



  editor._eventDispatcher?.on("drop",
    (event: EditorEvent<DragEvent>): boolean => {
      event.preventDefault();
      const reader = new FileReader();
      const file = event.dataTransfer?.files[0] as File;
      if (!file) return processTextDataWithRegex(event, editor, tinymceExecCommand);
      if (file.size > imageOrVideoMaxSize) {
        notificationsSocket.emit('sendErrorMessage', ERROR.MAX_FILE_SIZE_EXCEEDED);
        return false;
      }
      return readSaveAddImageAndVideo(reader, file, editor, tinymceExecCommand);
    }
  );

  editor._eventDispatcher?.on("paste",
    (event: EditorEvent<ClipboardEvent>): boolean => {
      event.preventDefault();
      const reader = new FileReader();
      const file = event.clipboardData?.files[0] as File;
      if (!file) return processTextDataWithRegex(event, editor, tinymceExecCommand);
      if (file.size > imageOrVideoMaxSize) {
        notificationsSocket.emit('sendErrorMessage', ERROR.MAX_FILE_SIZE_EXCEEDED);
        return false;
      }
      return readSaveAddImageAndVideo(reader, file, editor, tinymceExecCommand);
    }
  );

  editor._eventDispatcher?.on("mousedown", () => {
    setAreEmojisVisible(false);
  });

  if (!window.matchMedia("(max-width: 1279px)").matches) {

    editor._eventDispatcher?.on("contextMenu", (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();
      setAreEmojisVisible(true);
      return false;
    });

    editor.ui.registry.addContextToolbar('textselection', {
      predicate: (node) => !editor.selection.isCollapsed(),
      items: tinyMCEEditorToolbar,
      position: 'selection',
      scope: 'node'
    });
  }


  /* editor.ui.registry.addContextToolbar('textselection', {
    predicate: (node) => !editor.selection.isCollapsed(),
    items: tinyMCEEditorToolbar,
    position: 'selection',
    scope: 'node'
  }); */

  /* editor._eventDispatcher?.on("keyup",
    (event: EditorEvent<KeyboardEvent>): boolean => {
      event.preventDefault();
      console.log('empieza');
      // Obtener la posición del caret al hacer clic en el editor
      var bookmark = editor.selection.getBookmark();
      console.log('bookmark', bookmark);
      // Restaurar la selección usando el bookmark
      editor.selection.moveToBookmark(bookmark);

      // Obtener el objeto Range que representa la selección
      var range = editor.selection.getRng();

      // Clonar el rango actual
      var rangeClone = range.cloneRange();

      // Extender el rango clonado hasta el principio del contenido del editor
      rangeClone.setStart(editor.getBody(), 0);

      // Establecer el rango clonado hasta la posición actual del caret
      rangeClone.setEnd(range.startContainer, range.startOffset);

      // Obtener el texto desde la posición del caret hacia atrás hasta el primer espacio
      var textoDetrasCaret = rangeClone.toString().split(' ').pop();


      // editor?.execCommand('mceInsertContent', false, tagToLink(textoDetrasCaret as string));
      console.log("textoDetrasCaret -->", textoDetrasCaret);


      if (textoDetrasCaret?.match(isTagged) ) {
        const newText = textoDetrasCaret.replace(isTagged, (url: string) => `<a href="${url}">${url}</a>`);
        const textContent = editor.getContent();


        console.log("editor.getContent -->", textContent);
        console.log("newText -->", newText);

        const existe = textContent.match(textoDetrasCaret);
        console.log("existe -->", existe?.[0]);

        const nuevoContenido = textContent.replace(existe?.[0] as string, (url: string) => "<a href='\/u\/"+ url +"'>"+url+"</a>");
        console.log("nuevoContenido-->", nuevoContenido);

        if(event.key === ' '){

          //add an empty span with a unique id
          var endId = editor.dom.uniqueId();
          editor.dom.add(editor.getBody(), 'span', {'id': endId}, '');
          
          //select that span
          var newNode = editor.dom.select('span#' + endId);
          editor.selection.select(newNode[0]);
          return true;
        }
        editor.setContent(nuevoContenido);
        editor.selection.select(editor.getBody(), true); // editor is the editor instance
        editor.selection.collapse(false);
        return true;

      }


     
      return true;
    }
  ); */
}

//----------------------------------------------------------------------------

export default function timeSincePosted(timeStamp: number): string | undefined {
  if (!timeStamp) return;
  const formatter = new Intl.RelativeTimeFormat('en-us', { style: 'long' });
  let result;
  const DIVISIONS = [
    { amount: 60, name: 'seconds' },
    { amount: 60, name: 'minutes' },
    { amount: 24, name: 'hours' },
    { amount: 7, name: 'days' },
    { amount: 4.34524, name: 'weeks' },
    { amount: 12, name: 'months' },
    { amount: Number.POSITIVE_INFINITY, name: 'years' }
  ]
  let duration = (timeStamp - Date.now()) / 1000
  for (let i = 0; i <= DIVISIONS.length; i++) {
    const division = DIVISIONS[i];
    if (Math.abs(duration) < division?.amount) {
      result = formatter.format(Math.round(duration), division.name as any);
      return result;
    }
    duration /= division?.amount
  }
}
export function blockInspector() {
  document.onkeydown = (e) => {
    if (e.key === "F12") {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'I') {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'C') {
      e.preventDefault();
    }
    if (e.ctrlKey && e.shiftKey && e.key === 'J') {
      e.preventDefault();
    }
    if (e.ctrlKey && e.key === 'U') {
      e.preventDefault();
    }
  };
  document.addEventListener('contextmenu', (e) => {
    e.preventDefault();
  });
}
export function dataURLtoFileV2(dataurl: string, filename: string): File {
  const arr: any[] = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  const nombre = filename + Date.now() + mime.slice(mime.indexOf('/')).replace('/', '.');

  return new File([u8arr], nombre, { type: mime });
}
export function zoomInImages(queryClient: QueryClient) {
  ([...document.getElementsByClassName('postImage')] as HTMLImageElement[]).forEach((item) => {
    const itemElement = item;
    itemElement.onclick = () => queryClient.setQueryData('zoomImagePortal', { isVisible: true, imgSrc: item.src });
  });
}

export const whichContextGlobal = (firstContext: any, secondContext: any, thirdContext: any) => {
  if ((firstContext as any)._currentValue) return useContext(firstContext);
  if ((secondContext as any)._currentValue) return useContext(secondContext);
  if ((thirdContext as any)._currentValue) return useContext(thirdContext);
  return null;
};

