/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { createPortal } from 'react-dom';
import { useQuery, useQueryClient } from 'react-query';
import { CSSTransition } from 'react-transition-group';
import zoomImagePortalStyles from './zoomImagePortalStyles.module.css';

export default function ZoomImagePortal({ queryKey }: { queryKey: string }) {
    const queryClient = useQueryClient();
    const { data } = useQuery<{ isVisible: boolean, imgSrc: string }>(queryKey);
    const handleZoomOutImage = () => queryClient.setQueryData(queryKey, { visible: false, imgSrc: '' });
    return createPortal(
        <CSSTransition classNames='alerta' in={data?.isVisible as boolean} timeout={300} unmountOnExit>
            <div id={zoomImagePortalStyles.postHomeImagePortalContainer} onClick={handleZoomOutImage} tabIndex={0} role='button'>
                <img src={data?.imgSrc} alt={data?.imgSrc} loading='lazy' decoding='async' />
            </div >
        </CSSTransition>,
        document.getElementById('portal-root') as HTMLElement);
}
