/* eslint-disable jsx-a11y/no-noninteractive-tabindex */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouseChimney,
  faSquare,
  faDiceD20,
  faGlobeAmericas,
  faUsers,
} from '@fortawesome/free-solid-svg-icons';
import { Link, useLocation } from 'react-router-dom';
import sidebar from './sidebarStyles.module.css';
import imgPlaceHolder from '../../images/maqueta.png';
import { UserType } from '../nav/user/userType';
import useUserQuery from '../nav/user/useUsers';

export default function Sidebar() {
  const { data } = useUserQuery();
  const homeRef = useRef<HTMLAnchorElement>(null);
  const cardsRef = useRef<HTMLAnchorElement>(null);
  const threadsRef = useRef<HTMLAnchorElement>(null);
  const userRef = useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const [user, setUser] = useState<UserType | null>(null)
  useEffect(() => {
    if (data) setUser(data);
  }, [data]);
  console.log(location.pathname, user?.nickname);

  function isThisMyProfile() {
    if (!location.pathname.includes('/@')) return '';
    const nickFromPath = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
    if (nickFromPath !== user?.nickname) return '';
    return sidebar.highlightItemSidebar;
  }

  return (
    <div id={sidebar.sidebar}>
      <Link to="/"
        id={sidebar.home}
        tabIndex={0}
        ref={homeRef}
        className={location.pathname === '/' ? sidebar.highlightItemSidebar : ''}
      >
        <div>
          <FontAwesomeIcon icon={faHouseChimney} />
          <span>HOME</span>
        </div>
      </Link>
      <Link to="/cards"
        id={sidebar.cards}
        tabIndex={0}
        ref={cardsRef}
        className={location.pathname === '/cards' ? sidebar.highlightItemSidebar : ''}
      >
        <div>
          <FontAwesomeIcon icon={faSquare} />
          <span>CARDS</span>
        </div>
      </Link>
      <Link
        to="/threads"
        id={sidebar.threads}
        tabIndex={0}
        ref={threadsRef}
        className={location.pathname === '/threads' ? sidebar.highlightItemSidebar : ''}
      >
        <div>
          <FontAwesomeIcon icon={faDiceD20} />
          <span>THREADS</span>
        </div>
      </Link>
      <div
        id={sidebar.allSidebar}
        tabIndex={0}
        className={location.pathname === '/all' ? sidebar.highlightItemSidebar : ''}
      >
        <div>
          <FontAwesomeIcon icon={faGlobeAmericas} />
          <span>ALL</span>
        </div>
      </div>
      <Link
        to={user ? `/profile/${user.nickname}` : '/'}
        id={sidebar.userSidebar}
        tabIndex={0}
        ref={userRef}
        className={isThisMyProfile()}
      >
        <div>
          <img id={sidebar.userImageSidebar}
            src={user?.lowResThumbnail || imgPlaceHolder}
            loading="lazy"
            decoding="async"
            alt="user"
          />
          <span>{user?.nickname}</span>
        </div>
      </Link>
      <div id={sidebar.socialSidebar} tabIndex={0}>
        <div>
          <FontAwesomeIcon icon={faUsers} />
          <span>SOCIAL</span>
        </div>
      </div>
    </div >
  );
}
