/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */

import React, { useEffect, useReducer, useRef, useState } from 'react';
import postCreatorPollAreaStyles from './postCreatorPollWithImagesStyles.module.css';
import {
    API_URL,
    acceptedImageFormats,
    dataURLtoFileV2,
    imageOrVideoMaxSize,
    imageUrlRegex,
} from '../../../../utilities/utils';
import { PostCreatorPollImageAreaProps, pollImagesType } from './postCreatorPollWithImagesTypes';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import { initialState, reducer } from './postCreatorPollWithImagesUtils';
import sharedCSS from '../../../sharedCSS/sharedCSS.module.css';
import ERROR from '../../../../utilities/errors';
import LoadingSpinner from '../../../sharedComponents/loadingSpinner/loadingSpinner';

export default function PostCreatorPollImage({ pollData, setPollData, setIsImageAdderActive, setPollImageElements }: PostCreatorPollImageAreaProps) {
    const [state, dispatch] = useReducer(reducer, initialState);
    const descriptionRef = useRef<HTMLInputElement>(null);
    const pollImagesContainerRef = useRef<HTMLDivElement>(null);
    const isSavedRef = useRef<boolean | null>(null);
    const contentEditableRef = useRef<HTMLDivElement>(null);

    const handleTakeOffPollImage = (event: MouseEvent) => {
        dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: false });
        const target = event?.currentTarget as HTMLSpanElement;
        if (!target) return;
        if (!isSavedRef.current) {
            const editableDiv = contentEditableRef.current;
            if (!editableDiv) return;
            editableDiv.innerHTML = '';
            return;
        }
        const element = pollImagesContainerRef?.current; // Reemplaza "miElemento" con el ID de tu elemento
        const parent = pollImagesContainerRef?.current?.parentElement;
        const children = Array.from(parent?.children as Iterable<Element>);
        const indexOfChildren = children.indexOf(element as Element);
        setPollData((arr) => {
            const newArr = [...arr];
            newArr.splice(indexOfChildren, 1);
            return newArr;
        });
        setPollImageElements((arr) => {
            const newArr = [...arr];
            newArr.splice((indexOfChildren), 1);
            return newArr;
        });
        if (!isSavedRef.current) setIsImageAdderActive(false);

    }

    const handleDropImage = (event: React.DragEvent) => {
        event.preventDefault();
        if (state.isSaved) return notificationsSocket.emit('sendErrorMessage', ERROR.YOU_CANT_REPLACE_SAVED_PICTURE);
        const target = event.currentTarget;
        const fileData = event.dataTransfer.files[0];
        const textData = event.dataTransfer.getData('text');
        if (fileData) {
            if (fileData.size > imageOrVideoMaxSize)
                return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_FILE_SIZE_EXCEEDED);
            const reader = new FileReader();
            switch (fileData.type) {
                case "image/webp":
                case "image/png":
                case "image/jpeg":
                case "image/gif":
                    reader.onload = async (readerEvent) => {
                        const img = document.createElement('img');
                        img.src = readerEvent.target?.result as string;
                        img.title = fileData.name;
                        img.alt = fileData.name;
                        img.loading = 'lazy';
                        img.decoding = 'async';
                        const span = document.createElement('span');
                        span.classList.add('fas');
                        span.classList.add('fa-times');
                        span.classList.add(postCreatorPollAreaStyles.takeOffPollPicture);
                        span.dataset.index = pollData.length.toString();
                        span.onclick = handleTakeOffPollImage;
                        target.innerHTML = '';
                        target.appendChild(img);
                        target.appendChild(span);
                        dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: true });
                        dispatch({ type: 'SET_POLL_IMAGE_FILE', payload: fileData });
                    };
                    reader.readAsDataURL(fileData);
                    break;
                default:
            }
            return null;
        }
        if (!textData) return false;
        const imageUrl = textData.match(imageUrlRegex)
        if (imageUrl) {
            const img = document.createElement('img');
            img.src = textData;
            img.title = textData;
            img.alt = textData;
            img.loading = 'lazy';
            img.decoding = 'async';
            const span = document.createElement('span');
            span.classList.add('fas');
            span.classList.add('fa-times');
            span.classList.add(postCreatorPollAreaStyles.takeOffPollPicture);
            span.dataset.index = pollData.length.toString();
            span.onclick = handleTakeOffPollImage;
            target.appendChild(img);
            target.appendChild(span);
            dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: true });
            dispatch({ type: 'SET_POLL_IMAGE_FILE', payload: textData });
            return true;
        }
        notificationsSocket.emit('sendErrorMessage', ERROR.IMAGE_TYPE_NOT_SUPPORTED);
        return true;
    }

    const handlePasteImage = async (event: React.ClipboardEvent<HTMLDivElement>) => {
        event.preventDefault();
        if (state.isSaved) return notificationsSocket.emit('sendErrorMessage', ERROR.YOU_CANT_REPLACE_SAVED_PICTURE);
        const target = event.currentTarget;
        if (target.firstElementChild) return false;
        // Get the data from the clipboard for the 2 posible types: plain text and file.
        const textData = event.clipboardData.getData('text/plain');
        const fileData = event.clipboardData.files[0];
        // if there's an image on the clipboard, then:
        if (fileData) {
            // Reject the image if it's too big
            if (fileData?.size > imageOrVideoMaxSize) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_IMAGE_FILE_SIZE_EXCEEDED);
            const reader = new FileReader();
            switch (fileData.type) {
                case "image/webp":
                case "image/png":
                case "image/jpeg":
                case "image/gif":
                    reader.onload = async (readerEvent) => {
                        const img = document.createElement('img');
                        img.src = readerEvent.target?.result as string;
                        img.title = fileData.name;
                        img.alt = fileData.name;
                        img.loading = 'lazy';
                        img.decoding = 'async';
                        const span = document.createElement('span');
                        span.classList.add('fas');
                        span.classList.add('fa-times');
                        span.classList.add(postCreatorPollAreaStyles.takeOffPollPicture);
                        span.dataset.index = pollData.length.toString();
                        span.onclick = handleTakeOffPollImage;
                        target.innerHTML = '';
                        target.appendChild(img);
                        target.appendChild(span);
                        dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: true });
                        dispatch({ type: 'SET_POLL_IMAGE_FILE', payload: fileData });
                    };
                    reader.readAsDataURL(fileData);
                    break;
                default:
            }
            return null;
        }
        // If there's no plain text on the clipboard then return:
        if (!textData) return false;
        const imageUrl = textData.match(imageUrlRegex)
        if (imageUrl) {
            const img = document.createElement('img');
            img.src = textData;
            img.title = textData;
            img.alt = textData;
            img.loading = 'lazy';
            img.decoding = 'async';
            const span = document.createElement('span');
            span.classList.add('fas');
            span.classList.add('fa-times');
            span.classList.add(postCreatorPollAreaStyles.takeOffPollPicture);
            span.dataset.index = pollData.length.toString();
            span.onclick = handleTakeOffPollImage;
            target.appendChild(img);
            target.appendChild(span);
            dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: true });
            dispatch({ type: 'SET_POLL_IMAGE_FILE', payload: textData });
            return true;
        }
        notificationsSocket.emit('sendErrorMessage', ERROR.IMAGE_TYPE_NOT_SUPPORTED);
        return true;
    }

    const handleLoadPollImage = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (state.isSaved) return notificationsSocket.emit('sendErrorMessage', ERROR.YOU_CANT_REPLACE_SAVED_PICTURE);
        const target = event.currentTarget;
        if (!target?.files?.length) return false;
        const reader = new FileReader();
        reader.onload = (readerEvent) => {
            const img = document.createElement('img');
            img.src = readerEvent.target?.result as string;
            img.title = target.value;
            img.alt = target.value;
            img.loading = 'lazy';
            img.decoding = 'async';
            const span = document.createElement('span');
            span.classList.add('fas');
            span.classList.add('fa-times');
            span.classList.add(postCreatorPollAreaStyles.takeOffPollPicture);
            span.dataset.index = pollData.length.toString();
            span.onclick = handleTakeOffPollImage;
            if (!contentEditableRef.current) return;
            contentEditableRef.current.innerHTML = '';
            contentEditableRef.current?.appendChild(img);
            contentEditableRef.current?.appendChild(span);
            dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: true });
            dispatch({ type: 'SET_POLL_IMAGE_FILE', payload: dataURLtoFileV2(readerEvent.target?.result as string, Date.now().toString()) });
        }
        reader.readAsDataURL(target.files[0]);
        return true;
    }

    const handleSavePollImage = () => {
        if (!state.isButtonAvailable) return null;
        if (!contentEditableRef.current?.children.length) return notificationsSocket.emit('sendErrorMessage', ERROR.NO_IMAGE);
        if (typeof state.pollImageFile === 'string') {
            setPollData((pollArr) => [...pollArr, {
                imageUrl: state.pollImageFile,
                description: descriptionRef?.current?.value,
                index: pollArr.length
            }] as pollImagesType[]);
            // hacer que aparezca el + para agregar otra image al poll
            setIsImageAdderActive(true);
            dispatch({ type: 'SET_IS_SAVED', payload: true });
            return null;
        }
        const reader = new FileReader();
        const fd = new FormData();
        fd.append('image', state.pollImageFile as Blob);
        reader.onload = () => {
            // ev.target?.result;
            if (!state.pollImageFile) return notificationsSocket.emit('sendErrorMessage', ERROR.NO_IMAGE);
            if (!descriptionRef.current?.value) return notificationsSocket.emit('sendErrorMessage', ERROR.NO_DESCRIPTION);
            dispatch({ type: 'SET_IS_BUTTON_AVAILABLE', payload: false });
            fetch(`${API_URL}/postCreator/process-upload-image-in-create-new-post`,
                {
                    method: 'POST',
                    mode: 'cors',
                    headers: { 'authorization': `Bearer ${window.localStorage.token}` },
                    body: fd

                })
                .then(e => e.json())
                .then(e => {
                    if (e.error) throw new Error(e.message);
                    // Guardar el link que viene del backend en un state o
                    // algo que se va a mandar para guadar el poll
                    setPollData((pollArr) => [...pollArr, {
                        imageUrl: e.image,
                        description: descriptionRef?.current?.value,
                        index: pollArr.length
                    }] as pollImagesType[]);
                    // hacer que aparezca el + para agregar otra image al poll
                    dispatch({ type: 'SET_IS_IMAGE_UPLOADED', payload: false });
                    setIsImageAdderActive(true);
                    dispatch({ type: 'SET_IS_SAVED', payload: true });
                    dispatch({ type: 'SET_IS_BUTTON_AVAILABLE', payload: true });
                })
                .catch(err => {
                    notificationsSocket.emit('sendErrorMessage', err.message);
                    dispatch({ type: 'SET_IS_BUTTON_AVAILABLE', payload: true });
                })
            return null;
        }
        reader.readAsDataURL(state.pollImageFile as Blob);
        return null;
    }

    const handleCancel = () => {
        setPollImageElements((arr) => {
            const tempArr = [...arr];
            tempArr.pop();
            return tempArr;
        });
        setIsImageAdderActive(true);
    }

    const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (!event.ctrlKey) return event.preventDefault();
        return true;
    }

    useEffect(() => {
        isSavedRef.current = state.isSaved;
    }, [state.isSaved]);

    return (
        <div className={postCreatorPollAreaStyles.pollImagesContainer} ref={pollImagesContainerRef}>
            <div contentEditable
                aria-placeholder="Paste an image here..."
                className={postCreatorPollAreaStyles.pollImageWrapper}
                onPaste={handlePasteImage}
                onDrop={handleDropImage}
                onKeyDown={handleKeyDown}
                ref={contentEditableRef} />
            {
                !state.isSaved ? <>
                    {
                        !state.isImageUploaded && <label htmlFor="pollImage" className={postCreatorPollAreaStyles.pollImageUploadButton}>
                            <span className='fas fa-upload' /> or upload it </label>
                    }
                    <input type="file"
                        id="pollImage"
                        style={{ display: 'none' }}
                        accept={acceptedImageFormats}
                        onChange={handleLoadPollImage}
                    />
                    <input type='text' ref={descriptionRef}
                        placeholder='Enter image description...'
                        className={`${postCreatorPollAreaStyles.pollImageText} ${state.isImageUploaded ? postCreatorPollAreaStyles.inputMarginTop : ''}`} />
                    {
                        state.isImageUploaded && <div className={postCreatorPollAreaStyles.pollImagesAcceptCancel}>
                            <span onClick={handleCancel}
                                role='button'
                                tabIndex={0}> cancel </span>
                            {
                                state.isButtonAvailable ? <span onClick={handleSavePollImage}
                                    role='button'
                                    tabIndex={0}> save </span> :
                                    <LoadingSpinner />
                            }

                        </div>
                    }
                </> : <div className={postCreatorPollAreaStyles.pollImageDescription}>{descriptionRef.current?.value}</div>
            }
        </div >
    )
}
