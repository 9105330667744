/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import hamburguerMenuMobileStyles from './hamburguerMenuMobileStyles.module.css';
import MenuMobile from '../../menuMobile/menuMobile';
import { UserType } from '../user/userType';
import HamburgerMenuMobileLogin from './hamburgerMenuMobileLogin';

export default function HamburguerMenuMobile({ user }: { user: UserType }) {
    const [visible, setVisible] = useState(false);
    const menuRef = useRef<HTMLDivElement>(null);
    const handleMenuOff = (e: MouseEvent) => {
        const { target } = e;
        if (menuRef.current && !menuRef.current.contains(target as Node)) {
            setVisible(false);
        }
    };
    useEffect(() => {
        document.body.addEventListener('click', handleMenuOff, true);
        return () => {
            document.body.removeEventListener('click', handleMenuOff, true);
        };
    }, []);
    return user ? <div id={hamburguerMenuMobileStyles.menuHamburguerWrapper}
        onClick={() => setVisible(e => !e)}
        tabIndex={0}
        role='button'
        ref={menuRef}>
        <FontAwesomeIcon icon={faBars} id={hamburguerMenuMobileStyles.menuHamburguer} />
        <MenuMobile visible={visible} user={user} />
    </div > : <HamburgerMenuMobileLogin />
}
