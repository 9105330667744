/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useRef, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import threadEditorStyles from './threadEditorStyles.module.css'
import ThreadEditorAddRule from './threadEditorAddRule';
import { API_URL } from '../../utilities/utils';
import { notificationsSocket } from '../nav/notifications/notifications';
import useEditThreadQuery from './useThreadQuery';
import ERROR from '../../utilities/errors';

export default function ThreadEditor() {
    const params = useParams();
    const ac = new AbortController();
    const { data: thread } = useEditThreadQuery(ac, params.threadName as string);
    const [typeOfPost, setTypeOfPost] = useState<string[]>([]);
    const [isRuleAdderActive, setIsRuleAdderActive] = useState(true);
    const [ruleElements, setRuleElements] = useState<JSX.Element[]>([]);
    const [isNSFW, setIsNSFW] = useState(false);
    const [description, setDescription] = useState<string>();
    const [name, setName] = useState<string>();
    const [rules, setRules] = useState<{ id: string, title: string, description: string }[]>([]);
    const navigate = useNavigate();
    const textareaRef = useRef(null);



    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);

    const handleAddRule = () => {
        const dateNow = Date.now();
        setRuleElements((arr) => [...arr,
        <ThreadEditorAddRule
            setIsRuleAdderActive={setIsRuleAdderActive}
            setRules={setRules} key={dateNow}
            setRuleElements={setRuleElements}
            elementId={dateNow}
        />
        ]);
        setIsRuleAdderActive(false);
    };

    function textAreaAdjust(event: React.KeyboardEvent) {
        const targetElement = event.target as HTMLTextAreaElement;
        if (targetElement.value.length > 500) return event.preventDefault();
        targetElement.style.height = `${targetElement.scrollHeight}px`;
        return setDescription(targetElement.value);
    }

    function addPostType(type: string) {
        setTypeOfPost((arr) => {
            if (arr.includes(type)) {
                return arr.filter((item) => item !== type)
            }
            return [...arr, type]
        });
    }

    function handleDeleteRule(ruleId: string) {
        setRuleElements((arr) => arr.filter((item) => {
            if (item.key !== ruleId.toString()) return item;
        }));
        setRules((arr) => arr.filter((item, ind) => {
            if (item.id !== ruleId.toString()) return item;
        }));
    }

    const editThread = async () => {
        if (!name) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NEEDS_A_NAME);
        if (name.length > 25) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_THREAD_NAME_LENGTH_EXCEEDED);
        if (!description) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NEEDS_A_DESCRIPTION);
        if (description.length > 500) return notificationsSocket.emit('sendErrorMessage', ERROR.MAX_THREAD_DESCRIPTION_LENGHT_EXCEEDED);
        if (!rules.length) return notificationsSocket.emit('sendErrorMessage', ERROR.ZERO_THREAD_RULES);
        if (!typeOfPost.length) return notificationsSocket.emit('sendErrorMessage', ERROR.ZERO_TYPE_OF_POSTS);
        const ac = new AbortController();
        await fetch(`${API_URL}/edit-thread`, {
            method: 'POST',
            mode: 'cors',
            headers: {
                'content-type': 'application/json',
                Authorization: `Bearer ${window.localStorage.token}`
            },
            body: JSON.stringify({
                name,
                description,
                rules: JSON.stringify(rules),
                typeOfPost: JSON.stringify(typeOfPost),
                isNSFW
            }),
            signal: ac.signal,
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.error) throw new Error(response.message);
                navigate(`/thread/${name}`);
            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));
        return true;
    }

    useEffect(() => {
        if (thread) {
            setName(thread.name);
            setDescription(thread.description);
            const target = textareaRef.current as HTMLTextAreaElement | null;
            if (target) target.value = thread.description;
            setRules(thread.rules);
            setTypeOfPost(thread.typeOfPost);
            setRuleElements(() => thread?.rules.map((rule) => <div className={threadEditorStyles.rules} key={rule.id}>
                <div className={threadEditorStyles.ruleWrapper}>
                    <FontAwesomeIcon icon={faClose} className={threadEditorStyles.deleteRule} onClick={() => handleDeleteRule(rule.id)} />
                    <div>{rule.title}</div>
                    <div className={threadEditorStyles.rulesDescription}>{rule.description}</div>
                </div>
            </div>));
        }
    }, [thread]);

    return (
        <MainWrapper>
            <div id={threadEditorStyles.threadEditorGrid}>
                <div id={threadEditorStyles.threadEditorContainer}>
                    <div id={threadEditorStyles.threadEditorTitle}>THREAD EDITOR </div>
                    <div className={threadEditorStyles.threadEditorNameGrid}>
                        <div style={{ minWidth: "220px", fontSize: '1.5rem', fontWeight: 'bold', justifySelf: "center", textAlign: "center" }}>
                            Thread【 <FontAwesomeIcon icon={faDiceD20} /> {thread?.name} 】Editor
                        </div>
                    </div>
                    <div className={threadEditorStyles.threadEditorNameGrid}>
                        <label htmlFor="threadNameInput">
                            <span className={`${threadEditorStyles.confirmationDot} 
                            ${description && threadEditorStyles.activeSpot} `}>●</span> DESCRIPTION (required)
                            <span> {description?.length || 0}</span>/500</label>
                        <textarea className={threadEditorStyles.threadEditorInputs}
                            placeholder="Write the thread's description..."
                            autoComplete="off"
                            id="threadNameInput"
                            onKeyDown={textAreaAdjust}
                            onKeyUp={textAreaAdjust}
                            maxLength={500}
                            ref={textareaRef}
                        />
                        <div className={threadEditorStyles.itemHelp}>Craft a clear and concise community description within a 500-character limit.
                            Prioritize specificity to accurately represent the community&apos;s identity, objectives, and audience.
                            Avoid ambiguity to attract the right members effectively.</div>
                    </div>
                    <div className={threadEditorStyles.threadEditorNameGrid}>
                        <label htmlFor="threadNameInput"><span className={`${threadEditorStyles.confirmationDot} 
                            ${rules.length && threadEditorStyles.activeSpot} `}>●</span> RULES (required)</label>
                        {
                            ruleElements.map((item, index) => <div key={item.props.elementId} className={threadEditorStyles.rulesIndexes}>
                                <div>{index + 1}.&nbsp;</div><div>{item}</div>
                            </div>)
                        }
                        {
                            isRuleAdderActive && <div id={threadEditorStyles.addRule}
                                onClick={handleAddRule}
                                role='button'
                                tabIndex={0} >
                                <span className='fas fa-plus' />
                                <span>Add Rule</span>
                            </div>
                        }
                    </div>
                    <div id={threadEditorStyles.typeOfPostsWrapper}>
                        <span className={`${threadEditorStyles.confirmationDot} 
                            ${typeOfPost.length && threadEditorStyles.activeSpot} `}>●</span>
                        <span> What type of post should be allowed? (required)</span>
                        <div id={threadEditorStyles.typeOfPosts}>
                            <span onClick={() => addPostType('POSTS')} className={typeOfPost.includes('POSTS') ? threadEditorStyles.activeType : ''}>POSTS</span>
                            <span onClick={() => addPostType('POLLS')} className={typeOfPost.includes('POLLS') ? threadEditorStyles.activeType : ''}>POLLS</span>
                            <span onClick={() => addPostType('CARDS')} className={typeOfPost.includes('CARDS') ? threadEditorStyles.activeType : ''}>CARDS</span>
                        </div>
                    </div>
                    <div id={threadEditorStyles.isThisThreadSafe}>
                        <span>Is this thread NSFW?</span>
                        <span onClick={() => setIsNSFW(true)} className={isNSFW ? threadEditorStyles.nsfwActive : ''}>YES</span>
                        <span onClick={() => setIsNSFW(false)} className={!isNSFW ? threadEditorStyles.nsfwActive : ''}>NO</span>
                    </div>
                    <div id={threadEditorStyles.editThreadGrid}>
                        <div onClick={editThread}>SAVE EDITS!</div>
                    </div>
                </div>
                <div id={threadEditorStyles.threadEditorRules}><div>When establishing a community,
                    it&apos;s crucial to ensure a safe and respectful environment for all members.<br /><br />
                    Therefore, it&apos;s imperative for users to abide by the following basic rules:</div>
                    <div><u>Personal Information and Harassment</u>: No disclosing personal info or engaging in harassment.</div>
                    <div><u>Piracy</u>: No sharing copyrighted material to avoid legal issues.</div>
                    <div><u>Spam</u>: No promoting products or links, except from legitimate YouTube channels.</div>
                    <div><u>Duplicate Content</u>: Reposting for undue attention is prohibited.</div>
                    <div><u>Illegal Pornography</u>: Posting such content will lead to legal action.</div>
                    <div><u>NSFW and Spoiler Flags</u>: Must flag as NSFW and/or Spoiler content if it requires so.</div>
                    Adherence to these rules fosters a positive, safe, and inclusive community environment. Violations may lead to disciplinary action. Let&apos;s build a welcoming space where everyone can share freely.</div>
            </div >
        </MainWrapper >
    )
}
