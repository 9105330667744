/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { faCamera, faDiceD20, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from 'react-query';
import threadStyles from './threadStyles.module.css'
import useThreadQuery from './useThreadQuery';
import ThreadPosts from './threadPosts/threadPosts';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import ZoomImagePortal from '../sharedComponents/reactPortals/zoomImagePortal/zoomImagePortal';
import ThreadInfo from '../sharedComponents/threadInfo/threadInfo';
import { ThreadsType } from '../postCreator/postCreatorTypes';
import ThreadPostsLoading from './threadPostsLoading/threadPostsLoading';
import maqueta from '../../assets/images/maqueta.png';
import { API_URL, acceptedImageFormats } from '../../utilities/utils';
import ThreadInfoLoading from '../sharedComponents/threadInfo/threadInfoLoadingTemplate/threadInfoLoading';
import { notificationsSocket } from '../nav/notifications/notifications';
import sharedCSS from '../sharedCSS/sharedCSS.module.css';
import LoadingSpinner from '../sharedComponents/loadingSpinner/loadingSpinner';
import postStyles from '../post/postStyles.module.css'

export default function Thread() {
    const queryClient = useQueryClient();
    const [visible, setVisible] = useState(true);
    const [visible2, setVisible2] = useState(false);
    const [isSaved, setIsSaved] = useState<{ ok: boolean, file: File | null }>({ ok: true, file: null });
    const [isSavedBackground, setIsSavedBackground] = useState<{ ok: boolean, file: File | null }>({ ok: true, file: null });
    const [savingThumbnail, setSavingThumbnail] = useState(false);
    const [savingBackgroundImage, setSavingBackgroundImage] = useState(false);
    const ac = new AbortController();
    const threadThumbnailRef = useRef<HTMLImageElement>(null);
    const threadBackgroundRef = useRef<HTMLDivElement>(null);
    const { threadName } = useParams();
    const { data: thread, remove } = useThreadQuery(ac, threadName as string);
    const [postThread, setPostThread] = useState<ThreadsType | null>(null);
    useEffect(() => {
        if (thread)
            setPostThread(thread);
    }, [thread]);
    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
    useEffect(() => () => remove(), []);

    const handleChangeThumbnail = () => {
        const inputDiv = document.createElement('input');
        inputDiv.setAttribute("type", "file");
        inputDiv.setAttribute("accept", acceptedImageFormats);
        inputDiv.click();
        inputDiv.onchange = (event) => {
            const target = event.currentTarget as HTMLInputElement;
            if (!target?.files?.length) return;
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const thumbnail = threadThumbnailRef.current;
                if (!thumbnail) return;
                thumbnail.src = readerEvent.target?.result as string;
            }
            reader.readAsDataURL(target.files[0]);
            setIsSaved({ ok: false, file: target.files[0] });
        }
    }

    const handleCancel = () => {
        if (savingThumbnail) return;
        setIsSaved({ ok: true, file: null });
        const thumbnail = threadThumbnailRef.current;
        if (!thumbnail) return;
        thumbnail.src = thumbnail.dataset.source as string;
    };

    const handleSave = () => {
        const thumbnail = threadThumbnailRef.current;
        if (!thumbnail) return;
        setSavingThumbnail(true);
        const fd = new FormData();
        fd.append('thumbnail', isSaved.file as Blob);
        fd.append('threadId', thread?._id as string);
        fetch(`${API_URL}/thread/update-thread-thumbnail`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Authorization": `Bearer ${window.localStorage.token}`
            },
            body: fd
        })
            .then(e => e.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                thumbnail.dataset.source = thumbnail.src as string;
                setSavingThumbnail(false);
                setIsSaved({ ok: true, file: null });
            })
            .catch(error => {
                if (error) notificationsSocket.emit('sendErrorMessage', error.message);
                thumbnail.src = thumbnail.dataset.source as string;
                setSavingThumbnail(false);
                setIsSaved({ ok: true, file: null });
            });
    }

    const handleZoomThumbnail = () => queryClient.setQueryData('zoomImagePortal',
        { isVisible: true, imgSrc: threadThumbnailRef.current?.src });

    const handleZoomBackgroundImage = () => queryClient.setQueryData('zoomImagePortal',
        {
            isVisible: true,
            imgSrc: threadBackgroundRef.current?.style.backgroundImage.replace('url("', '').replace('")', '')
        });

    const handleUpdateBackground = () => {
        const inputDiv = document.createElement('input');
        inputDiv.setAttribute("type", "file");
        inputDiv.setAttribute("accept", acceptedImageFormats);
        inputDiv.click();
        inputDiv.onchange = (event) => {
            const target = event.currentTarget as HTMLInputElement;
            if (!target?.files?.length) return;
            const reader = new FileReader();
            reader.onload = (readerEvent) => {
                const backgroundImage = threadBackgroundRef.current;
                if (!backgroundImage) return;
                backgroundImage.style.backgroundImage = `url(${readerEvent.target?.result as string})`;
            }
            reader.readAsDataURL(target.files[0]);
            setIsSavedBackground({ ok: false, file: target.files[0] });
        }
    }

    const handleUpdateBackgroundCancel = () => {
        if (savingBackgroundImage) return;
        setIsSavedBackground({ ok: true, file: null });
        const background = threadBackgroundRef.current;
        if (!background) return;
        background.style.backgroundImage = `url(${background.dataset.source as string})`;
    }

    const handleBackgroundSave = () => {
        const background = threadBackgroundRef.current;
        if (!background) return;
        setSavingBackgroundImage(true);
        const fd = new FormData();
        fd.append('background', isSavedBackground.file as Blob);
        fd.append('threadId', thread?._id as string);
        fetch(`${API_URL}/thread/update-thread-background`, {
            method: "POST",
            mode: 'cors',
            headers: {
                "Authorization": `Bearer ${window.localStorage.token}`
            },
            body: fd
        })
            .then(e => e.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                background.dataset.source = background.style.backgroundImage as string;
                setIsSavedBackground({ ok: true, file: null });
                setSavingBackgroundImage(false);
            })
            .catch(error => {
                if (error) notificationsSocket.emit('sendErrorMessage', error.message);
                background.style.backgroundImage = `url('${background.dataset.source as string}')`;
                setIsSavedBackground({ ok: true, file: null });
                setSavingBackgroundImage(false);
            });
    }

    const isNsfwOrSpoiler = (thread: ThreadsType) => {
        switch (true) {
            case thread.isNSFW && thread.isSpoiler:
                return <>
                    <span className={threadStyles.postHomeNsfwSign} >
                        {thread.isNSFW && 'NSFW'}
                    </span>&nbsp;
                    <span className={threadStyles.postHomeNsfwSign} >
                        {thread.isSpoiler && 'SPOILER'}
                    </span>
                </>
            case thread.isNSFW:
                return <span className={threadStyles.postHomeNsfwSign} >
                    {thread.isNSFW && 'NSFW'}
                </span>
            case thread.isSpoiler:
                return <span className={threadStyles.postHomeNsfwSign} >
                    {thread.isSpoiler && 'SPOILER'}
                </span>
            default: return null;
        }
    }

    return (
        <MainWrapper>
            <div id={threadStyles.threadWrapper}>
                <div id={threadStyles.header}
                    ref={threadBackgroundRef}
                    data-source={thread?.backgroundImage}
                    style={thread ? { backgroundImage: `url('${thread?.backgroundImage}')` } :
                        { backgroundImage: `url(${maqueta})` }}>
                    <img id={threadStyles.threadThumbnail}
                        ref={threadThumbnailRef}
                        src={thread?.thumbnail || maqueta}
                        data-source={thread?.thumbnail}
                        alt={thread?.lowResThumbnail}
                        loading="lazy"
                        decoding="async"
                        onClick={handleZoomThumbnail}
                    />
                    {
                        thread?.isThisUserTheAdmin && <div id={threadStyles.changeImageWrapper}
                            onClick={handleChangeThumbnail}
                            role='button'
                            tabIndex={0}>
                            <FontAwesomeIcon icon={faCamera} />
                        </div>
                    }
                    {
                        !isSaved.ok && <div id={sharedCSS.thumbnailSaveDivWrapper}>
                            <span onClick={handleCancel} role='button' tabIndex={0}>cancel</span>
                            {
                                !savingThumbnail ? <span onClick={handleSave} role='button' tabIndex={0}>save</span> :
                                    <LoadingSpinner />
                            }
                        </div>
                    }
                    {
                        thread?.isThisUserTheAdmin && <div id={threadStyles.updateBackgroundPicture}
                            onClick={handleUpdateBackground}
                            role='button'
                            tabIndex={0}>
                            <div>
                                <FontAwesomeIcon icon={faCamera} />
                                <span> update picture</span>
                            </div>
                        </div>
                    }
                    {
                        !isSavedBackground.ok && <div id={sharedCSS.backgroundSaveDivWrapper}>
                            <span onClick={handleUpdateBackgroundCancel} role='button' tabIndex={0}>cancel</span>
                            {
                                !savingBackgroundImage ? <span onClick={handleBackgroundSave} role='button' tabIndex={0}>save</span> :
                                    <LoadingSpinner />
                            }
                        </div>
                    }
                    <div id={threadStyles.zoomInBackgroundImage} onClick={handleZoomBackgroundImage} tabIndex={0} role='button'>
                        <FontAwesomeIcon icon={faMagnifyingGlass} />
                    </div>
                    <div id={threadStyles.threadTitle}>
                        <FontAwesomeIcon
                            icon={faDiceD20}
                            id={threadStyles.threadTitleDice}
                        /> {thread?.name} {thread && isNsfwOrSpoiler(thread)}</div>
                </div>
                {
                    postThread &&
                    <div id={postStyles.threadInfoInSmartphones} onClick={() => {
                        setVisible(false);
                        setVisible2(true);
                    }}
                        role='button'
                        tabIndex={0}>
                        {
                            visible && <div id={postStyles.touchForThreadInfo}>Touch for Thread Info</div>
                        }
                        {
                            visible2 && <ThreadInfo
                                postThread={postThread}
                                setPostThread={setPostThread}
                                isThisBeingUsedFromAThread={false} />
                        }
                    </div>
                }
                {
                    thread ?
                        <ThreadPosts thread={thread} /> :
                        <ThreadPostsLoading />
                }
                {
                    !postThread ? <div id={postStyles.threadInfoInSmartphones2}>
                        <ThreadInfoLoading />
                    </div> : <div id={postStyles.threadInfoInSmartphones2}>
                        <ThreadInfo
                            postThread={postThread}
                            setPostThread={setPostThread}
                            isThisBeingUsedFromAThread />
                    </div>
                }
                <ZoomImagePortal queryKey="zoomImagePortal" />
            </div>
        </MainWrapper >
    )
}

