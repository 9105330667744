import React from 'react';
import { faClock, faPoo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardSectionHomeErrorStyles from './cardSectionHomeErrorStyles.module.css';
import ThreadsSectionError from '../../threadsSectionHome/threadsSectionError/threadsSectionError';

export default function CardSectionHomeError() {
    return (
        <section>
            <div id={cardSectionHomeErrorStyles.cardsSectionHomeErrorContainer}>
                <div id={cardSectionHomeErrorStyles.firstCardHomeError}>
                    <header>
                        <FontAwesomeIcon icon={faClock} />
                        <span> RECENT CARDS </span>
                    </header>
                </div>
                <div>SOMETHING WENT VERY WRONG... <FontAwesomeIcon icon={faPoo} /></div>
            </div>
            <ThreadsSectionError />
        </section>
    )
}
