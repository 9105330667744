/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState } from 'react';
import hamburguerMenuMobileStyles from './hamburguerMenuMobileStyles.module.css';
import LoginPortalPatchFIx from '../login/loginPortalPatchFIx';

export default function HamburgerMenuMobileLogin() {
    const [visible, setVisible] = useState(false);
    const handleLogin = () => {
        setVisible(true);
    };
    return <>
        <div onClick={handleLogin} id={hamburguerMenuMobileStyles.loginButtonMobile}>Login</div>
        <LoginPortalPatchFIx setVisible={setVisible} visible={visible} />
    </>

}
