/* eslint-disable no-underscore-dangle */
import React, { memo, useEffect, useRef } from 'react';
import postBodyStyles from './postBody.module.css';
import { PostBodyProps } from './postBodyTypes';
import './postBodyStyles.css';
import { PostType } from '../../../universalTypes/universalTypes';

function PostBody({ post }: PostBodyProps) {
  const postTextRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (postTextRef.current) postTextRef.current.innerHTML = post?.postBody;
  }, [post]);
  return <div id={postBodyStyles.postBody} ref={postTextRef} />;
}

function areEqual(prevProps: Readonly<{ post: PostType; }>, nextProps: Readonly<{ post: PostType; }>) {
  return prevProps.post._id === nextProps.post._id;
}

export default memo(PostBody, areEqual);
