enum ERROR {
    MAX_FILE_SIZE_EXCEEDED = "This file is too big, the MAX size allowed is of 25MB!",
    MAX_IMAGE_FILE_SIZE_EXCEEDED = "The image file is too big! the MAX size allowed is of 25MB!",
    MAX_VIDEO_FILE_SIZE_EXCEEDED = "The video file is too big! the file MAX size allowed is of 25MB!",
    THREAD_NEEDS_A_NAME = "The thread needs a name!",
    MAX_THREAD_NAME_LENGTH_EXCEEDED = "The thread's name can't be longer than 25 characters!",
    THREAD_NEEDS_A_DESCRIPTION = "The thread needs a description!",
    MAX_THREAD_DESCRIPTION_LENGHT_EXCEEDED = "The thread's description can't be longer than 500 characters!",
    ZERO_THREAD_RULES = "The thread needs at least 1 rule!",
    ZERO_TYPE_OF_POSTS = "The thread needs at least 1 type of post enabled!",
    THREAD_RULE_TITLE_EMPTY = "The rule's title can't be empty",
    THREAD_RULE_DESCRIPTION_EMPTY = "The rule's description can't be empty",
    YOU_CANT_REPLACE_SAVED_PICTURE = "You can't replace a picture that was saved!",
    POST_BODY_IS_EMPTY = "The post's body can't be empty!",
    COMMENT_BODY_IS_EMPTY = "The comment's body can't be empty!",
    IMAGE_TYPE_NOT_SUPPORTED = "Image type not supported!",
    TITLE_IS_EMPTY = "The title can't be empty!",
    THREAD_NOT_SELECTED = "You must select a thread!",
    NO_IMAGE_NO_VIDEO = "An image or video are required to create a card!",
    NO_IMAGE = "You need to upload an image!",
    NO_DESCRIPTION = "You need to add a description!",
    POST_TITLE_LENGTH_EXCEEDED = "Title cant have more than 120 chars!",
    REPORT_HAS_NO_REASON = "You must indicate a reason for the report!",
    REPORT_SENT = "The report was sent!"
}
export default ERROR;