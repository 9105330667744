/* eslint-disable no-underscore-dangle */
import { useQuery } from 'react-query';
import { API_URL } from '../../../utilities/utils';
import { parseJwt } from '../../../utilities/unusedUtils';
import { UserType } from './userType';
import { notificationsSocket } from '../notifications/notifications';

const fetchUser = async () => {
  const response = await fetch(`${API_URL}/user/getUserDataForLogin`, {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${window.localStorage.token as string}`
    },
  });
  const parsedResponse = await response.json();
  if (parsedResponse.message) throw new Error(parsedResponse.message);
  const user = parseJwt(parsedResponse.user) as UserType;
  notificationsSocket.emit("loginUser", { userId: user._id });
  return user;
};

const useUserQuery = () => useQuery('user', fetchUser, { refetchOnWindowFocus: false });

export default useUserQuery;
