/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react'
import { faBell, faClose } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom';
import useNotifications from './notificationsBellCustomHooks';
import { NotificationsType } from './notificationsType';
import notificationsBellStyle from './notificationsBellStyles.module.css';
import timeSincePosted, { API_URL } from '../../../utilities/utils';
import { notificationsSocket } from '../notifications/notifications';
import { UserType } from '../user/userType';

export default function NotificationsBell({ user }: { user: UserType }) {
    const ac = new AbortController();
    const { data, refetch } = useNotifications(ac);
    const [visible, setVisible] = useState(false);
    const [notifications, setNotifications] = useState<NotificationsType>();
    const bellWrapperRef = useRef<HTMLDivElement>(null);
    const notificationRef = useRef<HTMLDivElement>(null);
    const bellDiv = useRef<HTMLDivElement>(null);
    const navigate = useNavigate();
    useEffect(() => {
        if (data) setNotifications(data);
    }, [data]);
    useEffect(() => {
        notificationsSocket.on('receivingMessageFromAnotherUser', () => {
            refetch();
        });
        return () => {
            notificationsSocket.off("receivingMessageFromAnotherUser");
        }
    }, [notificationsSocket]);
    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);

    const handleOnClick = () => {
        setVisible(e => !e);
    }

    const handleOnClickMobileBell = () => {
        navigate('/notifications-mobile');
    }

    const handleEscape = (e: KeyboardEvent) => {
        if (e.key === "Escape") {
            setVisible(false);
        };
    };

    const handleMenuOff = (e: MouseEvent) => {
        const { target } = e;
        if (notificationRef.current && !notificationRef.current.contains(target as Node) && target !== bellDiv.current &&
            !bellDiv.current?.contains(target as Node)) {
            setVisible(false);
        }
    };

    useEffect(() => {
        document.body.addEventListener('keydown', handleEscape);
        document.body.addEventListener('click', handleMenuOff, true);
        return () => {
            document.body.removeEventListener('keydown', handleEscape);
            document.body.removeEventListener('click', handleMenuOff, true);
        };
    }, []);

    const handleMarkNotifAsSeen = async (event: React.MouseEvent, notificationId: string, notificationUrl: string) => {
        setVisible(false);
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/mark-notification-as-seen`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            refetch();
            navigate(notificationUrl);
        } catch (error: any) {
            console.log(error.message);
        }
    }

    const handleDeleteNotification = async (event: React.MouseEvent, notificationId: string) => {
        event.stopPropagation();
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                notificationId
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/notifications/delete-notification`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            refetch();
        } catch (error: any) {
            notificationsSocket.emit('sendErrorMessage', error.message);
        }
    }

    const handleNavigateToAllNotifications = () => {
        setVisible(false);
        navigate(`/profile/${user?.nickname}/notifications`);
    }

    return (
        <>
            <div id={notificationsBellStyle.bell} className={visible ? notificationsBellStyle.bellActive : ''} ref={bellWrapperRef}>
                <div onClick={handleOnClick} ref={bellDiv}>
                    {notifications?.hasNewNotifications && <div id={notificationsBellStyle.hasNewNotifications} />}
                    <FontAwesomeIcon icon={faBell} />
                </div>
                {
                    visible && <div id={notificationsBellStyle.notificationsContainer} ref={notificationRef}>
                        {
                            notifications?.notifications?.map((item, _) =>
                                <div onClick={(event) => handleMarkNotifAsSeen(event, item._id, item.notificationLink)}
                                    className={notificationsBellStyle.notificationDiv}
                                    key={item._id}>
                                    <span>{item.notificationContent}</span>&nbsp;
                                    <span className={notificationsBellStyle.timeSinceNotifications}>•&nbsp;
                                        {timeSincePosted(item.timestamp)}</span>&nbsp;
                                    <FontAwesomeIcon icon={faClose} onClick={(e: React.MouseEvent) => handleDeleteNotification(e, item._id)}
                                        className={notificationsBellStyle.deleteNotification} />
                                    {item.isNotificationNew && <span className={notificationsBellStyle.isNotificationNew}>•</span>}
                                </div>)
                        }
                        <div id={notificationsBellStyle.showMeAllNotif} onClick={handleNavigateToAllNotifications} >Show me all notifications</div>
                    </div>
                }
            </div>
            <div id={notificationsBellStyle.mobileBell} className={visible ? notificationsBellStyle.bellActive : ''} ref={bellWrapperRef}>
                <div onClick={handleOnClickMobileBell} ref={bellDiv}>
                    {notifications?.hasNewNotifications && <div id={notificationsBellStyle.hasNewNotifications} />}
                    <FontAwesomeIcon icon={faBell} />
                </div>
            </div>
        </>
    )
}
