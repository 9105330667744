/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { useQueryClient } from 'react-query';
import postOptionsStyle from '../postHomeOptionsStyles.module.css';
import { PostsSectionHome } from '../../../postsSectionHome';
import {
    handleOnBlurPostHomeOptions
} from './optionsUtils';
import { PostType } from '../../../../../../universalTypes/universalTypes';
import { ThreadContext } from '../../../../../thread/threadPosts/threadPosts';
import Edit from './optionsActions/edit';
import CopyLink from './optionsActions/link';
import Delete from './optionsActions/delete';
import Share from './optionsActions/share';
import { UserProfileBookmarksContext } from '../../../../../userProfile/userProfileSections/userProfileBookmarks/userProfileBookmarks';
import Sticky from './optionsActions/sticky';
import { whichContextGlobal } from '../../../../../../utilities/utils';

export default function ThreadAdminAndPostAuthor({ post, setVisible }: { post: PostType, setVisible: React.Dispatch<React.SetStateAction<boolean>> }) {
    const context = whichContextGlobal(PostsSectionHome, ThreadContext, UserProfileBookmarksContext);
    if (!context) return null;
    const queryClient = useQueryClient();
    const optionsRef = useRef<HTMLDivElement>(null);

    return (
        <div className={postOptionsStyle.postsHomeOptionsContainer}
            onBlur={(event) => handleOnBlurPostHomeOptions(event, optionsRef, setVisible)}
            ref={optionsRef}>
            <Edit post={post} />
            <CopyLink post={post} setVisible={setVisible} />
            <Share />
            <Sticky post={post} context={context} setVisible={setVisible} />
            <Delete
                post={post}
                context={context}
                queryClient={queryClient}
            />
        </div>
    )
}
