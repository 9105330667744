import { API_URL } from "../../../../utilities/utils";
import { NotificationsType } from "./notificationsType";

export default async function fetchUserProfileNotificationsInfiniteScroll(ac: AbortController, page: number, setHasMore: React.Dispatch<React.SetStateAction<boolean>>, setNotifications: React.Dispatch<React.SetStateAction<NotificationsType[] | null>>) {
  if (page < 2) return null;
  const token = window.localStorage.token as string;
  const fetchOptions = {
    method: 'GET',
    mode: 'cors',
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${token}`
    },
    signal: ac.signal,
  } as RequestInit;
  const response = await fetch(`${API_URL}/user-profile/infinite-scroll/get-this-user-notifications/${page}`, fetchOptions);
  const parsedResponse = await response.json();
  if (parsedResponse.message) return console.log("------>", parsedResponse.message);
  if (parsedResponse.length < 1) return setHasMore(false);
  return setNotifications((prevPostsList) => prevPostsList && [...prevPostsList, ...parsedResponse]);
};

