/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import postStyles from '../post/postStyles.module.css';
import CardBody from './cardBody/cardBody';
import PostLoadingTemplate from '../sharedComponents/postFooter/postFooterOptionsComponents/postLoadingTemplate/postLoadingTemplate';
import usePostQuery from '../post/postCustomHooks/usePost';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import ReportPost from '../sharedComponents/reactPortals/reportPost/reportPost';
import ZoomImagePortal from '../sharedComponents/reactPortals/zoomImagePortal/zoomImagePortal';
import DeletePost from '../sharedComponents/reactPortals/deletePost/deletePost';
import ThreadInfo from '../sharedComponents/threadInfo/threadInfo';
import PostVotes from '../sharedComponents/postHeader/postVotes/postVotes';
import PostAutorAndThread from '../sharedComponents/postHeader/postAuthorAndThread/postAuthorAndThread';
import PostTitle from '../sharedComponents/postHeader/postTitle/postTitle';
import PostFooterOptions from '../sharedComponents/postFooter/postFooterUtils/postFooterUtils';
import CommentsSection from '../sharedComponents/commentsSection/commentsSection';
import ThreadInfoLoading from '../sharedComponents/threadInfo/threadInfoLoadingTemplate/threadInfoLoading';

function ErrorStuff({ error }: { error: unknown }) {
  return <div>Error is: {(error as Error).message}</div>
}

export default function Card() {
  const { threadName = '', cardId = '' } = useParams();
  const ac = new AbortController();
  const [visible, setVisible] = useState(true);
  const [visible2, setVisible2] = useState(false);
  const { data: card, isError, error, refetch, isLoading, remove } = usePostQuery(threadName, cardId, ac, 'card');
  const [postThread, setPostThread] = useState<any>();
  useEffect(() => {
    setPostThread(card?.postThreadData);
  }, [card]);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
  if (isError) return <ErrorStuff error={error} />;
  useEffect(() => () => remove(), []);

  return (
    <>
      <MainWrapper /* optionalClass={postStyles.mobileClassForMainWrapperInPosts} */>
        <div id={postStyles.postWrapper}>
          <div id={postStyles.postGrid}>
            <div id={postStyles.postContainerDiv}>
              <div>
                {
                  !isLoading && card ? (
                    <>
                      <PostVotes post={card} refetch={refetch} />
                      <PostAutorAndThread post={card} />
                      <PostTitle post={card} />
                      <CardBody card={card} />
                      <PostFooterOptions post={card} refetch={refetch} />
                    </>
                  ) : <PostLoadingTemplate />
                }
              </div>
            </div>
            {
              postThread &&
              <div id={postStyles.threadInfoInSmartphones} onClick={() => {
                setVisible(false);
                setVisible2(true);
              }}
                role='button'
                tabIndex={0}>
                {
                  visible && <div id={postStyles.touchForThreadInfo}>Touch for Thread Info</div>
                }
                {
                  visible2 && <ThreadInfo
                    postThread={postThread}
                    setPostThread={setPostThread}
                    isThisBeingUsedFromAThread={false} />
                }
              </div>
            }
            {card && <CommentsSection post={card} />}
          </div>
          {
            !postThread ? <div id={postStyles.threadInfoInSmartphones2}>
              <ThreadInfoLoading />
            </div> :
              <div id={postStyles.threadInfoInSmartphones2}>
                <ThreadInfo
                  postThread={postThread}
                  setPostThread={setPostThread}
                  isThisBeingUsedFromAThread={false}
                />
              </div>
          }
        </div>
      </MainWrapper >
      {
        !isLoading && card && (
          <>
            <DeletePost post={card} typeOfPost='CARD' setPostsList={null} calledFrom='card' />
            <ReportPost post={card} />
            <ZoomImagePortal queryKey="zoomImagePortal" />
          </>
        )
      }
    </>
  );
}