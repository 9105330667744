/* eslint-disable react/require-default-props */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Editor as TinyMCEEditor } from "tinymce";
import commentsTextareaStyles from './commentsTextareaStyles.module.css';
import { API_URL, whichContextGlobal } from '../../../../../utilities/utils';
import { CommentsSectionContext } from '../../commentsSection';
import EditorTinyMCE from '../../../tinymceEditor/tinymceEditor';
import Emojis from '../../../tinymceEditor/emojis/emojis';
import { notificationsSocket } from '../../../../nav/notifications/notifications';
import useUserQuery from '../../../../nav/user/useUsers';
import ERROR from '../../../../../utilities/errors';
import LoadingSpinner from '../../../loadingSpinner/loadingSpinner';
import { CommentsSectionContext as UserProfileCommentsSectionContext } from '../../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';


export default function CommentsTextArea({ isBeingCalledFromProfile }: { isBeingCalledFromProfile?: boolean }) {
  const context = whichContextGlobal(CommentsSectionContext, UserProfileCommentsSectionContext, null) as any;
  const [isAvailableForSending, setIsAvailableForSending] = useState(true);
  const [postBody, setPostBody] = useState<Document | null>(null);
  const [areEmojisVisible, setAreEmojisVisible] = useState(false);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const emojisButtonRef = useRef<SVGSVGElement | null>(null);
  const { data: user } = useUserQuery();
  const location = useLocation();

  const sendComment = () => {
    if (!isAvailableForSending) return null;
    if (!editorRef.current?.getContent({ format: "text" })) return notificationsSocket.emit('sendErrorMessage', ERROR.COMMENT_BODY_IS_EMPTY);
    setIsAvailableForSending(false);
    const editorContent = postBody?.activeElement?.innerHTML; // postBody?.documentElement.outerHTML.replace(/<style[\s\S]*?<\/style>/gi, '');
    if (isBeingCalledFromProfile) {
      console.log("location --->", location);
      fetch(`${API_URL}/user-profile/process-profile-comment`,
        {
          method: 'POST',
          mode: 'cors',
          headers: { "Authorization": `Bearer ${window.localStorage.token}`, "Content-Type": 'application/json' },
          body: JSON.stringify({
            commentContent: editorContent,
            userProfileId: context.userProfileId,
          })
        })
        .then(response => response.json())
        .then(response => {
          if (response.error) throw new Error(response.message);
          editorRef.current?.setContent('');
          setIsAvailableForSending(true);
          context?.setCommentsList((arr: any) => [response, ...arr]);
        })
        .catch(error => {
          notificationsSocket.emit('sendErrorMessage', error.message);
          setIsAvailableForSending(true);
        })

      return true;
    }

    fetch(`${API_URL}/post/process-post-comment`,
      {
        method: 'POST',
        mode: 'cors',
        headers: { "Authorization": `Bearer ${window.localStorage.token}`, "Content-Type": 'application/json' },
        body: JSON.stringify({
          commentPostId: context?.post?._id,
          commentThreadId: context?.post.postThreadData._id,
          commentContent: editorContent,
          threadId: context?.post.postThreadData._id,
          postAuthorId: context?.post.postAuthorData.id,
          postAuthorNickname: context?.post.postAuthorData.nickname,
          postThreadName: context?.post.postThreadData.name,
          postTitleId: context?.post.titleId,
          isPost: context?.post.isPost,
          isPoll: context?.post.isPoll,
          isCard: context?.post.isCard,
        })
      })
      .then(response => response.json())
      .then(response => {
        if (response.error) throw new Error(response.message);
        editorRef.current?.setContent('');
        setIsAvailableForSending(true);
        context?.setCommentsList((arr: any) => [response.comment, ...arr]);
        if (context?.post.postAuthorData.id !== response.comment.commentAuthorData.id) {
          const postType = context?.post.isPost ? '/post/' : context?.post.isPoll ? '/poll/' : '/card/';
          notificationsSocket.emit('sendMessageToSpecificUser', {
            notificationId: response.commentedPostNotificationId,
            userId: context?.post.postAuthorData.id,
            postUrl: `/thread/${context?.post.postThreadData.name}${postType}${context?.post.titleId}#${response.comment._id}`,
            message: `${response.comment.commentAuthorData.nickname} commented your post!`
          });
        }

        if (response.taggedUsersToNotificate.length) {
          response.taggedUsersToNotificate.forEach((item: any) => {
            if (user?._id !== item._id) {
              const newNotification = {
                notificationId: item.notificationId,
                userId: item._id,
                postUrl: `/thread/${context?.post.postThreadData.name}/post/${context?.post.titleId}#${response.comment._id}`,
                message: `${user?.nickname} has tagged you!`
              };
              notificationsSocket.emit('sendMessageToSpecificUser', newNotification);
            }
          });
        }

        // enviar notificación a todos los usuarios taggeados, dios mío que paja hacer esto... guardo y sigo más tarde...
      })
      .catch(error => {
        notificationsSocket.emit('sendErrorMessage', error.message);
        setIsAvailableForSending(true);
      })
    return true
  }

  return (
    <div id={commentsTextareaStyles.commentsTextareaContainerDiv} className={isBeingCalledFromProfile ? commentsTextareaStyles.adaptedForUserProfile : ''}>
      <div>
        <div> comment as <Link to={`/profile/${user?.nickname}`}> {user?.nickname || 'Guest'} </Link>
        </div>
        <EditorTinyMCE
          setPostBody={setPostBody}
          post={null}
          editorRef={editorRef}
          setAreEmojisVisible={setAreEmojisVisible}
        />
        <div className={commentsTextareaStyles.commentsTextAreaSendButton}
          onClick={sendComment}
          role='button'
          tabIndex={0}>
          <div id={commentsTextareaStyles.postCommentButton}>
            {
              isAvailableForSending ?
                <span>Comment</span> :
                <LoadingSpinner />
            }
          </div>
        </div>
        {
          areEmojisVisible as boolean &&
          <Emojis emojisButtonRef={emojisButtonRef.current as SVGSVGElement}
            setAreEmojisVisible={setAreEmojisVisible}
            editorRef={editorRef.current} />
        }
      </div>
    </div >
  );
}


