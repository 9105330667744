/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClose, faDiceD20, faDoorOpen, faGear, faNewspaper } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import hamburgerMenuStyles from './menuMobileStyle.module.css';
import { UserType } from '../nav/user/userType';

export default function MenuMobile({ visible, user }: { visible: boolean, user: UserType }) {

  const handleLogOut = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
    /* const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      // Notificar al servidor (opcional)
      fetch('/user/logout-and-invalidate-token', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.error) throw new Error(response.message);
          if (response.ok) {
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            console.log('Logged out successfully');
          }
        })
        .catch(err => console.log(err.message)); */
  }

  return (
    <div className={`${hamburgerMenuStyles.menuWrapper} ${visible ? hamburgerMenuStyles.menuOn : ''} `}>
      <Link to={`/profile/${user?.nickname}`} className={hamburgerMenuStyles.menuItems}>
        <img src={user?.lowResThumbnail} alt={user?.nickname} className={hamburgerMenuStyles.menuItemsIcon} /><span>My Profile</span>
      </Link>
      <Link to="/create-new-post/" className={hamburgerMenuStyles.menuItems}>
        <FontAwesomeIcon icon={faNewspaper} />Create New Post
      </Link>
      <Link to="/create-new-thread/" className={hamburgerMenuStyles.menuItems}>
        <FontAwesomeIcon icon={faDiceD20} />Create New Thread
      </Link>
      <Link to="/settings/" className={hamburgerMenuStyles.menuItems}>
        <FontAwesomeIcon icon={faGear} />Settings
      </Link>
      <div className={hamburgerMenuStyles.menuItems} onClick={handleLogOut} tabIndex={0} role='button'>
        <FontAwesomeIcon icon={faDoorOpen} /> Log Out
      </div>
      <div className={hamburgerMenuStyles.menuItemsImageWrapper}>
        <img src='https://w3s.link/ipfs/bafybeibuqux2tztxlhfs5duz3kxx3rts2akclqbfqeinyhvlokmj34xdke' alt='gamerth-girl' />
      </div>
      <div id={hamburgerMenuStyles.closeX}><FontAwesomeIcon icon={faClose} /></div>
    </div >
  )
}
