import React from 'react';
import userProfileStyles from '../userProfileStyles.module.css';

export default function UserProfileGrid({ children }: any) {
    return (
        <div id={userProfileStyles.userProfileGrid}>
            <div>
                {children}
            </div>
        </div>
    )
}
