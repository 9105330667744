import { useQuery } from 'react-query';
import { API_URL } from '../../utilities/utils';
import { parseJwt } from '../../utilities/unusedUtils';
import { UserProfileType } from './userProfileTypes';


const fetchUserProfile = async (userProfile: string) => {
    const response = await fetch(`${API_URL}/user-profile/get-user-profile-data/${userProfile}`, {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${window.localStorage.token as string}`
        },
    });
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parseJwt(parsedResponse.user) as UserProfileType;
};

const useUserProfileQuery = (userProfile: string) => useQuery('userProfile', () => fetchUserProfile(userProfile), { refetchOnWindowFocus: false });

export default useUserProfileQuery;