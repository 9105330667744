/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { useQueryClient } from 'react-query';
import maqueta from '../../../../assets/images/maqueta.png';
import cardBodyStyles from '../cardBody.module.css';
import cardBodySwitchImageVideoOrYoutubeStyles from './cardBodySwitchImageVideoOrYoutubeStyles.module.css'
import { PostType } from '../../../../universalTypes/universalTypes';

export default function CardBodySwitchImageVideoOrYoutube({ card }: { card: PostType }) {
    const queryClient = useQueryClient();
    switch (true) {
        case card.youtube != null:
            return <iframe
                height='480'
                id={cardBodySwitchImageVideoOrYoutubeStyles.cardBodyIframeYoutube}
                src={card.youtube?.embed}
                loading="lazy"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={card.title}
                className={`${card.isNSFW || card.isSpoiler ? cardBodyStyles.nsfwFilter : ''}`
                } />;
        case card.imageUrl != null:
            return <img
                src={card.imageUrl || maqueta}
                alt={card.imageUrl}
                id={cardBodySwitchImageVideoOrYoutubeStyles.cardBodyImage}
                loading="lazy"
                decoding="async"
                onClick={() => queryClient.setQueryData('zoomImagePortal', { isVisible: true, imgSrc: card.imageUrl })} />;
        default:
            return <video
                controls
                src={card.videoUrl}
                id={cardBodySwitchImageVideoOrYoutubeStyles.cardBodyVideo}
            />;
    }
}// SEGUIR ACÁ