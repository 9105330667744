/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef, useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import postOptionsStyle from './postHomeOptionsStyles.module.css';
import postHomeOptionsPermits from './postHomeOptionsUtils';
import { PostType } from '../../../../../universalTypes/universalTypes';

export default function PostHomeOptions({ post }: { post: PostType }) {
  const [visible, setVisible] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<SVGSVGElement>(null);
  const handlePostHomeOptions = () => setVisible(visible => !visible);
  const handleOnBlurPostHomeOptions = (event: React.FocusEvent<HTMLDivElement>) => {
    const { relatedTarget: tgt } = event;
    const orc = optionsRef.current;
    if (tgt !== orc && !orc?.contains(tgt) && !orc?.nextElementSibling?.contains(tgt))
      setVisible(false);
  }

  return (
    <>
      <div
        className={postOptionsStyle.postsHomeOptions}
        onClick={handlePostHomeOptions}
        role='button'
        tabIndex={0}
        ref={optionsRef}
        onBlur={handleOnBlurPostHomeOptions}>
        <FontAwesomeIcon icon={faEllipsisVertical} ref={iconRef} />
      </div>
      {visible && postHomeOptionsPermits(post, setVisible)}
    </>
  );
}
