/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import cardInfoAndTitleStyles from './cardInfoAndTitleStyles.module.css';
import { PostType } from '../../../../../universalTypes/universalTypes';
import timeSincePosted from '../../../../../utilities/utils';

export default function CardInfoAndTitle({ card }: { card: PostType }) {
    const handleNSFWorSpoilerToggle = (event: React.MouseEvent) => {
        event.preventDefault();
        const target = event.currentTarget?.parentElement?.parentElement?.parentElement?.nextElementSibling?.children[1];
        const target2 = event.currentTarget?.parentElement?.parentElement?.parentElement?.nextElementSibling?.children[0];
        if (!target || !target2) return;
        target2.remove();
        target.classList.remove(target.classList[0]);
    }
    const isNsfwOrSpoiler = (card: PostType) => {
        switch (true) {
            case card.isNSFW && card.isSpoiler:
                return <>
                    <span className={cardInfoAndTitleStyles.nsfwOrSpoilerSignInCardTitle}
                        onClick={handleNSFWorSpoilerToggle}
                        role='button'
                        tabIndex={0}
                    >NSFW</span>&nbsp;
                    <span className={cardInfoAndTitleStyles.nsfwOrSpoilerSignInCardTitle}
                        onClick={handleNSFWorSpoilerToggle}
                        role='button'
                        tabIndex={0}>SPOILER</span>
                </>
            case card.isNSFW:
                return <span className={cardInfoAndTitleStyles.nsfwOrSpoilerSignInCardTitle}
                    onClick={handleNSFWorSpoilerToggle}
                    role='button'
                    tabIndex={0}
                >NSFW</span>
            case card.isSpoiler:
                return <span className={cardInfoAndTitleStyles.nsfwOrSpoilerSignInCardTitle}
                    onClick={handleNSFWorSpoilerToggle}
                    role='button'
                    tabIndex={0}
                >SPOILER</span>
            default: return null;
        }
    }
    return (
        <div className={cardInfoAndTitleStyles.cardInfoAndTitleDiv}>
            <div>
                Posted on<Link to={`/thread/${card.postThreadData?.name}`}>
                    <FontAwesomeIcon icon={faDiceD20} className={cardInfoAndTitleStyles.cardInfoThreadDice} /> {card.postThreadData?.name}</Link>by
                <Link to={`/profile/${card.postAuthorData?.nickname}`}> {card.postAuthorData?.nickname}</Link> •
                {` ${timeSincePosted(card.timestamp)}`}
            </div>
            <Link to={`/thread/${card.postThreadData?.name}/card/${card.titleId}`}>{card.title}  {isNsfwOrSpoiler(card)}</Link>
        </div>
    )
}
