
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-plusplus */
import React from 'react';
import maqueta from '../../../../assets/images/maqueta.png';
import postInfoStyles from './postInfo.module.css';

export default function ThreadInfoLoading() {
    return (
        <div id={postInfoStyles.threadInfoFirstContainer}>
            <div id={postInfoStyles.threadInfoContainer}>
                <div>
                    <img src={maqueta}
                        alt='imageAlt'
                        loading="lazy"
                        decoding="async"
                    />
                </div>
                <div >
                    <span id={postInfoStyles.threadInfoDescriptionLoading} />
                </div>
                <div id={postInfoStyles.threadInfoPostAndMembersWrapper}>
                    <div className={postInfoStyles.postAndMembersNumbersLoading} /> <div className={postInfoStyles.postAndMembersNumbersLoading} />
                    <span className={postInfoStyles.postAndMembersLoading} /> <span className={postInfoStyles.postAndMembersLoading} />
                </div>
                <div id={postInfoStyles.tocAndDocLoadingWrapper}>
                    <div className={postInfoStyles.tocAndDocLoading} />
                    <div className={postInfoStyles.tocAndDocLoading} />
                    <span className={postInfoStyles.tocAndDocTitleLoading} />
                    <span className={postInfoStyles.tocAndDocTitleLoading} />
                </div >

                <div id={postInfoStyles.threadInfoAllowedTypeOfPosts}>
                    <div />
                    <div />
                    <div />
                    <div />
                </div>

                <div id={postInfoStyles.threadInfoAdminDataWrapper}>
                    <div>
                        <div id={postInfoStyles.userImageLoading} />
                        <div>
                            <span id={postInfoStyles.userNameLoading} />
                            <span id={postInfoStyles.userRankLoading} />
                        </div>
                    </div>
                    <span id={postInfoStyles.adminTitleLoading} />
                </div>
                <div id={postInfoStyles.threadInfoRulesWrapper}>
                    <div id={postInfoStyles.rulesTitleLoading} />
                    <div className={postInfoStyles.rulesLoading} />
                    <div className={postInfoStyles.rulesLoading} />
                    <div className={postInfoStyles.rulesLoading} />
                    <div className={postInfoStyles.rulesLoading} />
                    <div className={postInfoStyles.rulesLoading} />
                </div>
            </div >
        </div >
    )
}


