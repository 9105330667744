import { useQuery } from 'react-query';
import { API_URL } from '../../../../utilities/utils';
import { ThreadsTypeHome } from '../threadsSectionHomeTypes';

async function fetchThread(ac: AbortController) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/home/get-three-threads`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as ThreadsTypeHome[];
}

const useThreadQuery = (ac: AbortController) => useQuery('thread',
    () => fetchThread(ac),
    { refetchOnWindowFocus: false });

export default useThreadQuery;