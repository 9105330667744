import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSearch,
  faComments,
  faPencilAlt,
} from '@fortawesome/free-solid-svg-icons';
import navStyle from './navStyle.module.css';
// import logo from './logoNuevo3.png';
import User from './user/user';
import Notifications from './notifications/notifications';
import NotificationsBell from './notificationsBell/notificationsBell';
import useUserQuery from './user/useUsers';
import { UserType } from './user/userType';
import { gamerthLogoURL } from '../../utilities/utils';
import HamburguerMenuMobile from './hamburguerMenuMobile/hamburguerMenuMobile';

export default function Nav() {
  const newPostRef = useRef<HTMLAnchorElement>(null);
  const location = useLocation();
  const { data: user } = useUserQuery();
  return (
    <div id={navStyle.nav}>
      <Link to="/" id={navStyle.logo}>
        <img
          loading="lazy"
          decoding="async"
          src={gamerthLogoURL}
          alt="GAMERTH"
          title="Just dive yourself into what you love!"
        />
      </Link>
      <div className={navStyle.searchBarNav}>
        <input type="search" placeholder='Search posts, polls and cards by title and #tags...' />
      </div>
      <div id={navStyle.loupe}>
        <FontAwesomeIcon icon={faSearch} />
      </div>
      {user && <NotificationsBell user={user as UserType} />}
      <HamburguerMenuMobile user={user as UserType} />
      <div id={navStyle.chat}>
        <FontAwesomeIcon icon={faComments} />
      </div>
      <Link to="/create-new-post"
        id={navStyle.post}
        ref={newPostRef}
        className={location.pathname === '/create-new-post' ? navStyle.highlightItemNav : ''}>
        <FontAwesomeIcon icon={faPencilAlt} />
      </Link>
      <User user={user as UserType} />
      <Notifications />
    </div>
  );
}
