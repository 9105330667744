/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFlag } from '@fortawesome/free-solid-svg-icons';
import { useQueryClient } from 'react-query';
import reportPostStyles from './reportPostStyles.module.css';

export default function ReportPost() {
    const queryClient = useQueryClient();
    const handleReportPost = () => {
        queryClient.setQueryData("reportPost", true);
    }
    return (
        <div onClick={handleReportPost} role='button' tabIndex={0} id={reportPostStyles.reportPost}>
            <span id={reportPostStyles.reportPostTooltip}>Report</span>
            <FontAwesomeIcon icon={faFlag} />
        </div>
    )
}
