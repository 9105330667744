/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { Dispatch, SetStateAction, useRef, useState } from 'react';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import postCommentStyle from './postCommentOptionsStyles.module.css';
import postCommentOptionsPermits from './postCommentOptionsUtils';
import { PostCommentsType } from '../../../postCommentsType';
import { PostCommentsAndRepliesContext } from '../../postCommentsAndRepliesWrapper';
import { CommentsSectionContext } from '../../../../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';
import { whichContextGlobal } from '../../../../../../../utilities/utils';
import { PostCommentsContext } from '../../../postComments';

export default function PostCommentOptions({ isComment, reply, comment, setIsEditable, setReplyList }: { isComment: boolean, reply: any, comment: any, setIsEditable: any, setReplyList: any }) {
  const context = whichContextGlobal(PostCommentsContext, null, null) as any;
  const [visible, setVisible] = useState<boolean>(false);
  const optionsRef = useRef<HTMLDivElement>(null);
  const iconRef = useRef<SVGSVGElement>(null);
  const handlePostCommentOptions = () => setVisible(visible => !visible);
  const handleOnBlurPostCommentOptions = (event: React.FocusEvent<HTMLDivElement>) => {
    const { relatedTarget: tgt } = event;
    const orc = optionsRef.current;
    if (tgt !== orc && !orc?.contains(tgt) && !orc?.nextElementSibling?.contains(tgt))
      setVisible(false);
  }

  return (
    <>
      <div
        className={postCommentStyle.postCommentOptions}
        onClick={handlePostCommentOptions}
        role='button'
        tabIndex={0}
        ref={optionsRef}
        onBlur={handleOnBlurPostCommentOptions}>
        <FontAwesomeIcon icon={faEllipsisVertical} ref={iconRef} />
      </div>
      {
        visible && postCommentOptionsPermits(
          isComment ? comment as PostCommentsType : reply,
          setVisible,
          setIsEditable as Dispatch<SetStateAction<boolean>>,
          isComment,
          setReplyList
        )
      }
    </>
  );
}
