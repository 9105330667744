/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copyLinkStyles from './copyLinkStyles.module.css';

export default function CopyLinkPost() {
    const handleCopyLinkPost = async () => navigator.clipboard.writeText(window.location.href);
    return (
        <div onClick={handleCopyLinkPost} role='button' tabIndex={0} id={copyLinkStyles.copyLink}>
            <span id={copyLinkStyles.copyLinkToolTip}>Copy Link</span>
            <FontAwesomeIcon icon={faLink} />
        </div>
    )
}
