import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock, faPoo } from '@fortawesome/free-solid-svg-icons';
import threadsSectionErrorStyles from './threadsSectionErrorStyles.module.css'

export default function ThreadsSectionError() {
    return (
        <div id={threadsSectionErrorStyles.threadsSectionErrorWrapper}>
            <div> <FontAwesomeIcon icon={faClock} /> recent threads</div>
            <div>SOMETHING WENT VERY WRONG... <FontAwesomeIcon icon={faPoo} /></div>
        </div>
    )
}
