export const postImageType = (image?: HTMLImageElement, youtubeImage?: HTMLIFrameElement | null) => {
  if (image) return image.src;
  if (youtubeImage) {
    const youtubeImageID = youtubeImage?.src?.slice(youtubeImage.src.lastIndexOf('/') + 1);
    const youtubeImageURL = `https://img.youtube.com/vi/${youtubeImageID}/maxresdefault.jpg`;
    return youtubeImageURL;
  }
  return '';
}

export const postThumbnailType = (image?: HTMLImageElement, youtubeImage?: HTMLIFrameElement | null) => {
  console.log(image?.dataset.src)
  if (image) return image.dataset.src as string;
  if (youtubeImage) {
    const youtubeImageID = youtubeImage?.src?.slice(youtubeImage.src.lastIndexOf('/') + 1);
    const youtubeImageLowRes = `https://img.youtube.com/vi/${youtubeImageID}/default.jpg`;
    return youtubeImageLowRes;
  }
  return '';
}