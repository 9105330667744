/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react'
import { Link } from 'react-router-dom';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ThreadsSectionType } from '../threadsSectionTypes';
import threadStyles from './threadStyles.module.css'

export default function Thread({ thread }: { thread: ThreadsSectionType }) {
    function threadDateOfCreation(thread: ThreadsSectionType) {
        const monthNames = [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December"
        ];
        return `${monthNames[new Date(thread?.timestamp).getMonth()]} 
        ${new Date(thread?.timestamp).getDate()}th, 
        ${new Date(thread?.timestamp).getFullYear()}`;
    }

    function typeOfContent(thread: ThreadsSectionType) {
        if (thread.isNSFW) return <div className={threadStyles.nsfwOrSpoilerWrapper}><div className={threadStyles.nsfwOrSpoilerDiv}>NSFW</div></div>
        if (thread.isSpoiler) return <div className={threadStyles.nsfwOrSpoilerWrapper}><div className={threadStyles.nsfwOrSpoilerDiv}>SPOILER</div></div>
        return <div>Safe for Work</div>
    }
    
    const isNsfwOrSpoiler = (thread: ThreadsSectionType) => {
        switch (true) {
            case thread.isNSFW && thread.isSpoiler:
                return <>
                    <span className={threadStyles.postHomeNsfwSign} >
                        {thread.isNSFW && 'NSFW'}
                    </span>&nbsp;
                    <span className={threadStyles.postHomeNsfwSign} >
                        {thread.isSpoiler && 'SPOILER'}
                    </span>
                </>
            case thread.isNSFW:
                return <span className={threadStyles.postHomeNsfwSign} >
                    {thread.isNSFW && 'NSFW'}
                </span>
            case thread.isSpoiler:
                return <span className={threadStyles.postHomeNsfwSign} >
                    {thread.isSpoiler && 'SPOILER'}
                </span>
            default: return null;
        }
    }

    return (
        <Link to={`/thread/${thread.name}`} className={threadStyles.linkToThread} >
            <img
                loading="lazy"
                decoding="async"
                src={thread.lowResThumbnail}
                alt={thread.name}
                className={`${threadStyles.threadThumbnail}
                ${(thread.isNSFW || thread.isSpoiler) &&
                    threadStyles.postHomeImageNsfwFilter}`}
            />
            <img
                className={`${threadStyles.threadBackground}
                ${(thread.isNSFW || thread.isSpoiler) &&
                    threadStyles.postHomeImageNsfwFilter}`}
                loading="lazy"
                decoding="async"
                src={thread.backgroundImage}
                alt={thread.name}
            />
            <figcaption className={threadStyles.threadFirstFigCaption}>
                <span className={threadStyles.threadName}>
                    <FontAwesomeIcon icon={faDiceD20} />&nbsp;
                    {thread.name} {isNsfwOrSpoiler(thread)}
                </span>
            </figcaption>
            <figcaption className={threadStyles.threadSecondFigCaption}>
                <div className={threadStyles.firstCaptionChild}>
                    <strong className={threadStyles.threadName}>About <FontAwesomeIcon icon={faDiceD20}
                        className={threadStyles.threadDescription} /> {thread.name}</strong>
                </div>
                <div className={threadStyles.threadDescription}>{thread.description}</div>
                <div className={threadStyles.threadInfoContainer}>
                    <div>
                        <div>{thread.members}</div>
                        <div>Members</div>
                    </div>
                    <div>
                        <div>{thread.numberOfPosts}</div>
                        <div>Posts</div>
                    </div>
                    <div>
                        {typeOfContent(thread)}
                        <div>Type of Content</div>
                    </div>
                    <div>
                        <div>{thread.authorName}</div>
                        <div>Administrator</div>
                    </div>
                    <div>
                        <div>{threadDateOfCreation(thread)}</div>
                        <div>Date Of Creation</div>
                    </div>
                </div>
            </figcaption>
        </Link>
    )
}
