/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import pollWithoutImagesBody from './pollWithoutImagesBody.module.css';
import { PollWithImagesBodyProps } from './pollWithoutImagesBodyTypes';
import './pollWithoutImagesBodyStyles.css';
import { API_URL } from '../../../utilities/utils';
import { PollOptionsWithOutImages } from '../../../universalTypes/universalTypes';
import { notificationsSocket } from '../../nav/notifications/notifications';

function PollWithoutImagesBody({ poll, refetch }: PollWithImagesBodyProps) {
  const queryClient = useQueryClient();
  const [voted, setVoted] = useState(false);
  const ac = new AbortController();
  useEffect(() => {
    if (poll?.hasThisUserVotedAnyPollOptionsWithoutImages) setVoted(true);
    ([...document.getElementsByClassName('pollImageZoom')]).forEach((item, _) => {
      const itemElement = item as SVGAElement;
      itemElement.onclick = (event: MouseEvent) => {
        const target = event.currentTarget as SVGAElement;
        if (!target) return;
        const imageElement = target.parentElement?.previousElementSibling?.firstElementChild as HTMLImageElement;
        if (!imageElement) return;
        queryClient.setQueryData('zoomImagePortal', { isVisible: true, imgSrc: imageElement.src });
      }
    })
  }, [poll]);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
  const voteOptionPoll = (e: { pollOption: PollOptionsWithOutImages, pollId: string }) => {
    fetch(`${API_URL}/poll/process-poll-option-without-images-vote`,
      {
        mode: 'cors',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          "authorization": `Bearer ${window.localStorage.token}`
        },
        body: JSON.stringify({
          pollId: e.pollId,
          pollOptionIndex: e.pollOption.index,
          threadId: poll.postThreadData._id
        }),
        signal: ac.signal
      })
      .then(e => e.json())
      .then(e => {
        if (e.error) throw new Error(e.message);
        refetch();
      })
      .catch(error => notificationsSocket.emit('sendErrorMessage', error.message));
  }

  return <div id={pollWithoutImagesBody.pollBody}>
    {
      poll.pollOptionsWithoutImages.map((pollOption) =>
        <div key={pollOption.index} className={pollWithoutImagesBody.pollOptionTextWrapper}>
          <div className={pollWithoutImagesBody.pollOptionTextWrapper}
            onClick={() => { voteOptionPoll({ pollOption, pollId: poll._id }) }}
            role='button'
            tabIndex={0} >
            <div className={pollWithoutImagesBody.descriptionWrapper}>
              <span>{pollOption.description}</span>
              <div className={pollWithoutImagesBody.pollVoteLayer}
                style={
                  voted ? {
                    width: `${pollOption.votes * 100 / poll.totalPollOptionsWithoutImagesVotes}%`
                  } : {}
                }
              />
              <span
                className={pollWithoutImagesBody.votePercentage}
                style={{ opacity: +voted }}>{`${(pollOption.votes * 100 / poll.totalPollOptionsWithoutImagesVotes).toFixed(1).replace(/\.0$/, '')}%`}
              </span>
            </div>
          </div>
        </div>
      )
    }
    <div id={pollWithoutImagesBody.totalVotes}>TOTAL VOTES: {Number(poll.totalPollOptionsWithoutImagesVotes).toLocaleString()}</div>
  </div >
}

export default PollWithoutImagesBody;




