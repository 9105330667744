/* eslint-disable react/jsx-closing-tag-location */
import React, { ReactNode, useRef } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import basePortalStyles from './basePortalStyles.module.css';
// import gamerthLogo from "../../nav/logoNuevo3.png";
import './reactPortalTransitions.css';
import { gamerthLogoURL } from '../../../utilities/utils';

export default function BasePortal({ children, queryKey }: { children: ReactNode, queryKey: string }) {
    const queryClient = useQueryClient();
    const { data: isBasePortalVisible } = useQuery(queryKey);
    const divRef = useRef<HTMLDivElement>(null);
    const onCloseloginPortal = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> &
    { target: HTMLDivElement }) => {
        if (divRef?.current?.contains(ev.target)) return;
        queryClient.setQueryData(queryKey, false);
    };
    return createPortal(
        <CSSTransition classNames='alerta' in={isBasePortalVisible as boolean} timeout={300} unmountOnExit>
            <div id={basePortalStyles.basePortalContainerDiv} onClick={onCloseloginPortal} role='none'>
                <div ref={divRef}>
                    <div id={basePortalStyles.basePortalDivImageContainer}>
                        <img
                            loading="lazy"
                            decoding="async"
                            src={gamerthLogoURL}
                            alt="GAMERTH"
                            title="Just dive yourself into what you love!"
                        />
                    </div>
                    {children}
                </div>
            </div>
        </CSSTransition>, document.getElementById('portal-root') as HTMLElement);
}
