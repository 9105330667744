/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react';
import { faEllipsisVertical, faPoo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import maqueta from '../../../../images/maqueta.png';
import postSectionHomeErrorStyles from './postSectionHomeErrorStyles.module.css';

export default function PostHomeErrorDivs() {
    return (
        <div>
            <div className={postSectionHomeErrorStyles.postSectionHomeErrorWrapper}>
                <div>
                    <img className={postSectionHomeErrorStyles.postSectionHomeErrorImage}
                        src={maqueta}
                        alt={maqueta}
                        loading="lazy"
                        decoding="async"
                    />
                </div>
                <div className={postSectionHomeErrorStyles.postSectionHomeErrorTitle}>
                    <div className={postSectionHomeErrorStyles.postSectionHomeErrorText}>SOMETHING WENT VERY WRONG... <FontAwesomeIcon icon={faPoo} /></div>
                    <div className={postSectionHomeErrorStyles.postSectionHomeErrorOptions}>
                        <FontAwesomeIcon icon={faEllipsisVertical} />
                    </div>
                </div>
            </div>
        </div>
    )
}
