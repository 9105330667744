/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react';
import cardBodyStyles from './cardBody.module.css';
import CardBodySwitchImageVideoOrYoutube from './CardBodySwitchImageVideoOrYoutube/cardBodySwitchImageVideoOrYoutube';
import { PostType } from '../../../universalTypes/universalTypes';

function CardBody({ card }: { card: PostType }) {
  return <div id={cardBodyStyles.cardBody} >
    <CardBodySwitchImageVideoOrYoutube card={card} />
  </div>;
}

function areEqual(prevProps: Readonly<{ card: PostType; }>, nextProps: Readonly<{ card: PostType; }>) {
  return prevProps.card._id === nextProps.card._id;
}

export default memo(CardBody, areEqual);
