/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useContext, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Editor as TinyMCEEditor } from "tinymce";
import postCreatorTextareaStyles from './postCreatorTextareaStyles.module.css';
import { PostCreatorContext } from '../../../postCreator';
import { API_URL, youtubeRegex } from '../../../../../utilities/utils';
import { postImageType, postThumbnailType } from './postCreatorTextareaUtils';
import EditorTinyMCE from '../../../../sharedComponents/tinymceEditor/tinymceEditor';
import Emojis from '../../../../sharedComponents/tinymceEditor/emojis/emojis';
import { notificationsSocket } from '../../../../nav/notifications/notifications';
import ERROR from '../../../../../utilities/errors';

export default function CommentsTextArea() {
  const emojisButtonRef = useRef<SVGSVGElement | null>(null);
  const editorRef = useRef<TinyMCEEditor | null>(null);
  const [isAvailableForSending, setIsAvailableForSending] = useState(true);
  const [isNSFW, setIsNSFW] = useState(false);
  const [isSpoiler, setIsSpoiler] = useState(false);
  const navigate = useNavigate();
  const context = useContext(PostCreatorContext);
  const [postBody, setPostBody] = useState<Document | null>(null);
  const sendPostRef = useRef(null);
  const handleIsSpoiler = () => setIsSpoiler((value) => !value);
  const handleIsNSFW = () => setIsNSFW((value) => !value);
  const [areEmojisVisible, setAreEmojisVisible] = useState(false);
  // const socket = useSocket();

  const handleSendPost = () => {
    if (!isAvailableForSending) return null;
    if (!context) return notificationsSocket.emit('sendErrorMessage', 'Something went VERY wrong...');
    if (!context.postTitle) notificationsSocket.emit('sendErrorMessage', ERROR.TITLE_IS_EMPTY);
    if (!postBody || postBody.activeElement?.innerHTML === '<p><br data-mce-bogus="1"></p>') return notificationsSocket.emit('sendErrorMessage', ERROR.POST_BODY_IS_EMPTY);
    if (!context.postThread) return notificationsSocket.emit('sendErrorMessage', ERROR.THREAD_NOT_SELECTED);
    setIsAvailableForSending(false);
    const image = postBody?.getElementsByTagName("img")[0];
    const youtubeImage: HTMLIFrameElement | null = postBody?.getElementsByTagName("iframe")[0].src.match(youtubeRegex) ? postBody?.getElementsByTagName("iframe")[0] : null;
    console.log("image -->", image);
    console.log("youtubeImage -->", youtubeImage);
    const editorContent = postBody?.activeElement?.innerHTML; // postBody?.documentElement.outerHTML.replace(/<style[\s\S]*?<\/style>/gi, '');
    // console.log("editorContent --->", editorContent);
    // console.dir(postBody.activeElement?.innerHTML);
    if (!editorContent) return notificationsSocket.emit('sendErrorMessage', ERROR.POST_BODY_IS_EMPTY);

    fetch(`${API_URL}/postCreator/create-new-post`,
      {
        method: 'POST',
        mode: 'cors',
        headers: { "Authorization": `Bearer ${window.localStorage.token}`, "Content-Type": 'application/json' },
        body: JSON.stringify({
          title: context?.postTitle,
          threadId: context?.postThread._id,
          postBody: editorContent,
          spoiler: isSpoiler.toString(),
          nsfw: isNSFW.toString(),
          sticky: 'false',
          thumbnail: postImageType(image, youtubeImage),
          lowResThumbnail: postThumbnailType(image, youtubeImage),
        })
      })
      .then(response => response.json())
      .then(response => {
        if (response.error) throw new Error(response.message);
        navigate(`/thread/${response.postThreadName}/post/${response.titleId}`);
      })
      .catch(error => {
        notificationsSocket.emit('sendErrorMessage', error.message);
        setIsAvailableForSending(true);
      })
    return null
  }
  return (
    <div id={postCreatorTextareaStyles.commentsTextareaContainerDiv}>
      <label htmlFor='postTitleInput' id={postCreatorTextareaStyles.bodyLabel}>
        <span className={`${postCreatorTextareaStyles.postCreatorConfirmationSpot} 
                        ${postBody && postCreatorTextareaStyles.activeSpot} `}
        >●</span>  BODY (required):
      </label>
      <div id={postCreatorTextareaStyles.editorWrapper}>
        <EditorTinyMCE
          setPostBody={setPostBody}
          post={null}
          editorRef={editorRef}
          setAreEmojisVisible={setAreEmojisVisible} />
        <div className={postCreatorTextareaStyles.commentsTextAreaSendButton}>
          <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isSpoiler && postCreatorTextareaStyles.active}`}
            onClick={handleIsSpoiler}
            role='button'
            tabIndex={0}
          >
            <span>SPOILER</span>
          </div>
          <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isNSFW && postCreatorTextareaStyles.active}`}
            onClick={handleIsNSFW}
            role='button'
            tabIndex={0}>
            <span>NSFW</span>
          </div>
          <div id={postCreatorTextareaStyles.sendPost}
            onClick={handleSendPost}
            ref={sendPostRef}
            role='button'
            tabIndex={0}
          >
            {isAvailableForSending ? <div>➢</div> : <div id={postCreatorTextareaStyles.creatingNewPost} />}
          </div>
        </div>
        {
          areEmojisVisible as boolean &&
          <Emojis emojisButtonRef={emojisButtonRef.current as SVGSVGElement}
            setAreEmojisVisible={setAreEmojisVisible}
            editorRef={editorRef.current}
          />
        }
      </div>
    </div>
  );
}


