/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import postFooterStyles from './postFooterOptionsComponentsStyles.module.css';
import CopyLinkPost from './commonOptions/copyLink/copyLinkPost';
import DeletePost from './commonOptions/deletePost/deletePost';
import SavePost from './commonOptions/savePost/savePost';
import SharePost from './commonOptions/sharePost/sharePost';
import { PostType, UseQueryRefetch } from '../../../../universalTypes/universalTypes';

export default function PostFooterOptionsAdminThread({ post, refetch }: { post: PostType, refetch: UseQueryRefetch }) {
    return (
        <div id={postFooterStyles.postOptionsContainer}>
            <CopyLinkPost />
            <SharePost />
            <SavePost post={post} refetch={refetch} />
            <DeletePost />
        </div>
    );
}