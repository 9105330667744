/* eslint-disable react/jsx-no-useless-fragment */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React, { useContext, useEffect } from 'react';
import { QueryClient } from 'react-query';
import Reply from './comentReply';
import fetchPostCommentsReplies from './postCommentsRepliesUtils';
import { PostCommentsContext } from "../../postComments";
import { zoomInImages } from '../../../../../../utilities/utils';
import { PostCommentsType } from '../../postCommentsType';


export default function PostCommentsReplies({ comment, setReplyList, replyList, setIsEditable }: { comment: PostCommentsType, setReplyList: any, replyList: any, setIsEditable: any }) {

    const context = useContext(PostCommentsContext);

    useEffect(() => {
        console.log("comment?._id  !!!!!!!! ------>", comment?._id);
        console.log("setReplyList  !!!!!!!! ------>", setReplyList);
        console.log("replyList  !!!!!!!! ------>", replyList);
        fetchPostCommentsReplies(
            context?.post?._id as string,
            comment?._id as string,
            setReplyList,
            context?.isBeingCalledFromProfile as boolean,
            context?.userProfile?._id);
    }, [comment?._id]);
    useEffect(() => {
        zoomInImages(context?.queryClient as QueryClient);
    });

    useEffect(() => {
        console.log("replyList -->", replyList)
        if (window.location.hash) {
            document.getElementById(window.location.hash.substring(1))?.scrollIntoView(false);
        }
    }, [replyList]);

    return replyList?.map((reply: any) => <Reply reply={reply} key={reply._id} setReplyList={setReplyList} comment={comment} setIsEditable={setIsEditable} />)


}
