/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import { useQueryClient } from 'react-query';
import { API_URL } from "../../../../utilities/utils";
import BasePortal from "../basePortal";
import reportPostPortalStyles from './reportPostStyles.module.css';
import imgTrashbin from '../../../../assets/images/trashbin10.png';
import maqueta from '../../../../assets/images/maqueta.png';
import { PostType } from '../../../../universalTypes/universalTypes';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import ERROR from '../../../../utilities/errors';
import { UserProfileType } from '../../../userProfile/userProfileTypes';

function ReportPost({ post, userProfile, isThisBeingCalledFromUserProfile }: { post?: PostType, userProfile?: UserProfileType, isThisBeingCalledFromUserProfile?: boolean }) {
    const [reportReason, setReportReason] = useState({ reasonNumber: 0, reason: '' });
    const queryClient = useQueryClient();
    const handleReportPost = () => {
        if (!reportReason.reason) return notificationsSocket.emit('sendErrorMessage', ERROR.REPORT_HAS_NO_REASON);
        fetch(isThisBeingCalledFromUserProfile ?
            `${API_URL}/user-profile/process-report-user-profile` :
            `${API_URL}/post/process-report-post`,
            {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify(
                    {
                        reason: reportReason.reason,
                        postId: isThisBeingCalledFromUserProfile ? null : post?._id,
                        threadId: isThisBeingCalledFromUserProfile ? null : post?.postThreadData._id,
                        userProfileId: isThisBeingCalledFromUserProfile ? userProfile?._id : null
                    }
                ),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                queryClient.setQueryData("reportPost", false);
                setReportReason({ reasonNumber: 0, reason: '' });
                notificationsSocket.emit('sendErrorMessage', ERROR.REPORT_SENT);
                return true;
            })
            .catch((error) => {
                notificationsSocket.emit('sendErrorMessage', error.message);
                queryClient.setQueryData("reportPost", false);
                setReportReason({ reasonNumber: 0, reason: '' });
                return false;
            });
        return false;
    }

    const handleCancel = () => {
        queryClient.setQueryData("reportPost", false);
        setReportReason({ reasonNumber: 0, reason: '' });
    };

    const handleClickReport = (event: React.MouseEvent<HTMLLIElement>, reasonNumber: number) => {
        setReportReason({ reasonNumber, reason: event.currentTarget.innerText });
    }

    const targetToReport = isThisBeingCalledFromUserProfile ? userProfile?.nickname : 'THIS POST';

    return <BasePortal queryKey="reportPost">
        <div id={reportPostPortalStyles.reportPostPortalWrapper}>
            <img
                src={imgTrashbin || maqueta}
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div>
                <p>YOU ARE ABOUT TO <span>REPORT</span> {targetToReport}, BECAUSE:</p>
                <p>
                    <ul>
                        <li onClick={(event) => handleClickReport(event, 1)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 1 ? reportPostPortalStyles.reportSelected : ''}>
                            Contains gore o inapropiate images or links
                        </li>
                        <li onClick={(event) => handleClickReport(event, 2)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 2 ? reportPostPortalStyles.reportSelected : ''}>
                            Contains personal info or harassment
                        </li>
                        <li onClick={(event) => handleClickReport(event, 3)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 3 ? reportPostPortalStyles.reportSelected : ''}>
                            Contains piracy
                        </li>
                        <li onClick={(event) => handleClickReport(event, 4)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 4 ? reportPostPortalStyles.reportSelected : ''}>
                            Contains spam
                        </li>
                        <li onClick={(event) => handleClickReport(event, 5)}
                            tabIndex={0}
                            role='button'
                            className={reportReason.reasonNumber === 5 ? reportPostPortalStyles.reportSelected : ''}>
                            Contains spoilers.
                        </li>
                    </ul>
                </p>
            </div>
            <div id={reportPostPortalStyles.cancelAndReportButtons}>
                <div
                    id={reportPostPortalStyles.cancelPostPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}
                >no, wait!</div>
                <div
                    id={reportPostPortalStyles.reportPostPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleReportPost}
                >yeah, report this shit!</div>
            </div>
        </div>
    </BasePortal>
}

export default ReportPost;