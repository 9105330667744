/* eslint-disable no-underscore-dangle */
import React, { useCallback, useState } from 'react';
import { API_URL } from '../../../../../utilities/utils';
import cardVotesStyles from './cardVotesStyles.module.css';
import { PostType } from '../../../../../universalTypes/universalTypes';
import postVotesPathDString from '../../../../sharedComponents/postHeader/postVotes/postVotesPathDString';
import { notificationsSocket } from '../../../../nav/notifications/notifications';

export default function CardVotes({ card }: { card: PostType }) {
    const [voted, setHasVoted] = useState({
        hasThisUserVoted: card.hasThisUserVoted,
        hasThisUserVotedPositive: card.hasThisUserVotedPositive,
        totalVotes: card.totalVotes
    });
    const handleVote = useCallback((vote: boolean) => {
        fetch(`${API_URL}/card/process-card-vote`,
            {
                mode: 'cors',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    cardId: card._id,
                    isVotePositive: vote,
                    threadId: card.postThreadData._id
                })
            }
        )
            .then(e => e.json())
            .then(e => {
                if (e.error) throw new Error(e.message);
                if (e.ok) {
                    notificationsSocket.emit('sendMessageToSpecificUser', {
                        userId: card.postAuthorData.id,
                        postUrl: `/thread/${card.postThreadData.name}/card/${card.titleId}`,
                        message: `${card.postAuthorData.nickname} ${vote ? 'upvoted' : 'downvoted'} your card!`
                    });
                }

                setHasVoted({ hasThisUserVoted: e.ok, hasThisUserVotedPositive: e.isVotePositive, totalVotes: e.totalVotes })
            })
            .catch(err => {
                notificationsSocket.emit('sendErrorMessage', err.message);
            })
    }, [card._id]);

    const votesOutcome = () => {
        if (voted.totalVotes > 0) return cardVotesStyles.positiveVotesOutcome;
        if (voted.totalVotes < 0) return cardVotesStyles.negativeVotesOutcome;
        return null;
    }

    const hasVotedPositive = () => {
        if (voted.hasThisUserVoted && voted.hasThisUserVotedPositive) return cardVotesStyles.cardUpVoted;
        return '';
    }

    const hasVotedNegative = () => {
        if (voted.hasThisUserVoted && !voted.hasThisUserVotedPositive) return cardVotesStyles.cardDownVoted;
        return '';
    }

    return (
        <div id={cardVotesStyles.cardVotesContainer}>
            <svg version="1.1" width="20" height="20" viewBox="0 0 24 24" onClick={() => handleVote(true)}>
                <path className={hasVotedPositive()}
                    fill='transparent'
                    stroke='#5d666b'
                    d={postVotesPathDString} />
            </svg>
            <div className={`${cardVotesStyles.numberOfVotes} ${votesOutcome()}`}>{voted.totalVotes}</div>
            <svg version="1.1" width="20" height="20" viewBox="0 0 24 24" onClick={() => handleVote(false)} >
                <path className={hasVotedNegative()}
                    fill='transparent'
                    stroke='#5d666b'
                    d={postVotesPathDString} />
            </svg>
        </div>
    )
}
