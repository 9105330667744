import { faShare } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React from 'react';
import shareStyles from './shareStyles.module.css';

export default function Share() {
    return (
        <div>
            <FontAwesomeIcon icon={faShare} /> <span className={shareStyles.optionText} > Share</span>
        </div>
    )
}
