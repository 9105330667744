/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState } from 'react';
import loginStyle from './loginStyleNav.module.css';
import LoginPortalPatchFIx from './loginPortalPatchFIx';

export default function Login() {
  const [visible, setVisible] = useState(false);
  const handleLogin = () => {
    setVisible(true);
  };
  return <>
    <div onClick={handleLogin} id={loginStyle.loginDiv}>Login</div>
    <LoginPortalPatchFIx setVisible={setVisible} visible={visible} />
  </>
}
