import { Dispatch, SetStateAction } from "react";

export type ThreadsType = {
    _id: string,
    members: number,
    isNSFW: boolean,
    isSpoiler: boolean,
    backgroundImage: string,
    lowResThumbnail: string,
    thumbnail: string,
    name: string,
    description: string,
    timestamp: number,
    isThisUserTheAdmin: boolean,
    isThisUserAMember: boolean,
    howManyMembers: number,
    isThisUserTheAdminAndPostAuthor: boolean,
    isThisUserThePostAuthor: boolean,
    rules: [
        {
            id: string,
            title: string,
            description: string
        }
    ]
    numberOfPosts: number,
    threadAdminData: {
        _id: string,
        nickname: string,
        thumbnail: string,
        lowResThumbnail: string,
        info: {
            rank: string
        },
    },
    typeOfPost: string[]
}

export type PostCreatorContextType = {
    postType: string,
    setPostType: Dispatch<SetStateAction<string>>,
    postTitle: string,
    setPostTitle: Dispatch<SetStateAction<string>>,
    postThread: ThreadsType | null,
    setPostThread: Dispatch<SetStateAction<ThreadsType | null>>
} | null;

export enum PostTypes {
    POSTS = 'POSTS',
    CARDS = 'CARDS',
    POLLS = 'POLLS'
}