import React from 'react'
import CardBodyImage from './cardBodyImage';
import CardBodyYoutube from './cardBodyYoutube';
import CardBodyVideo from './cardBodyVideo';

export default function CardBodySwitchImageVideoOrYoutube(props: any) {
    const { card } = props;
    switch (true) {
        case !!card.imageUrl:
            return <CardBodyImage card={card} />;
        case !!card.youtube:
            return <CardBodyYoutube card={card} />;
        default:
            return <CardBodyVideo card={card} />;
    }
}