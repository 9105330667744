/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import postCommentOptionsStyle from '../postCommentOptionsStyles.module.css';
import { handleOnBlurPostCommentOptions } from './optionsUtils';
import { PostCommentsType } from '../../../../postCommentsType';
import Delete from './actions/delete';
import { CommentsSectionContext } from '../../../../../commentsSection';
import Edit from './actions/edit';
import { whichContextGlobal } from '../../../../../../../../utilities/utils';
import { CommentsSectionContext as UserProfileCommentsSectionContext } from '../../../../../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';

export default function ThreadAdminAndCommentAuthor({ comment, setVisible, setIsEditable, isComment, setReplyList }: { comment: PostCommentsType, setVisible: React.Dispatch<React.SetStateAction<boolean>>, setIsEditable: React.Dispatch<React.SetStateAction<boolean>>, isComment: boolean, setReplyList: any }) {
    const context = whichContextGlobal(CommentsSectionContext, UserProfileCommentsSectionContext, null);
    if (!context) return null;
    const optionsRef = useRef<HTMLDivElement>(null);
    return (
        <div className={postCommentOptionsStyle.postCommentOptionsContainer}
            onBlur={(event) => handleOnBlurPostCommentOptions(event, optionsRef, setVisible)}
            ref={optionsRef}>
            <Edit setVisible={setVisible}
                setIsEditable={setIsEditable}
                isComment={isComment}
            />
            <Delete comment={comment} isComment={isComment} setReplyList={setReplyList} />
        </div>
    )
}
