/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { PostType } from '../../../../../../../universalTypes/universalTypes';
import { API_URL } from '../../../../../../../utilities/utils';
import { notificationsSocket } from '../../../../../../nav/notifications/notifications';

export default function Sticky({ post, context, setVisible }: { post: PostType, context: any, setVisible: React.Dispatch<React.SetStateAction<boolean>> }) {

    const handleSticky = async (sticky: boolean, post: PostType) => {
        const token = window.localStorage.token as string;
        const fetchOptions = {
            method: 'POST',
            mode: 'cors',
            headers: {
                'Content-Type': 'application/json',
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify({
                sticky: !sticky,
                postId: post._id
            })
        } as RequestInit;

        const response = await fetch(`${API_URL}/post/process-post-sticky-or-unsticky`, fetchOptions);
        const parsedResponse = await response.json();
        try {
            if (parsedResponse.message) throw new Error(parsedResponse.message);
            setVisible(false);
            context?.refetch();
        } catch (error: any) {
            notificationsSocket.emit('sendErrorMessage', error.message);
            setVisible(false);
        }

    }
    return (
        <div onClick={() => handleSticky(post.isSticky, post)} role='button' tabIndex={0}>
            <FontAwesomeIcon icon={faThumbTack} />
            <span>{post.isSticky ? 'Un-Sticky' : 'Sticky'}</span>
        </div>
    )
}
