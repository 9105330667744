import React from 'react';
import postVotesStyles from '../../sharedComponents/postHeader/postVotes/postVotesStyles.module.css';
import pollLoadingTemplate from './pollLoadingTemplateStyles.module.css';
import postVotesPathDString from '../../sharedComponents/postHeader/postVotes/postVotesPathDString';

export default function PollLoadingTemplate() {
    return (
        <div id={pollLoadingTemplate.pollLoadingTemplateContainer}>
            <div id={postVotesStyles.postVotesContainer}>
                <svg version="1.1" width="22" height="22" viewBox="0 0 24 24" >
                    <path
                        fill='transparent'
                        stroke='#5d666b'
                        d={postVotesPathDString} />
                </svg>
                <div className={`${postVotesStyles.numberOfVotes}`}>0</div>
                <svg version="1.1" width="22" height="22" viewBox="0 0 24 24"  >
                    <path
                        fill='transparent'
                        stroke='#5d666b'
                        d={postVotesPathDString} />
                </svg>
            </div>
            <div id={pollLoadingTemplate.pollLoadingTemplateAutorAndThread} />
            <div id={pollLoadingTemplate.pollLoadingTemplateTitle} />
            <div id={pollLoadingTemplate.pollLoadingTemplateBody}>
                <div />
                <div />
                <div />
                <div />
                <div />
                <div />
            </div>
            <div id={pollLoadingTemplate.totalVotes} />
        </div>
    );
}
