/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { QueryClient } from 'react-query'
import { handleDeletePost } from '../optionsUtils'
import { PostType } from '../../../../../../../universalTypes/universalTypes'

export default function Delete({ post, context, queryClient }: { post: PostType, context: any, queryClient: QueryClient }) {
    return (
        <div onClick={() => handleDeletePost(context, post, queryClient)} role='button' tabIndex={0}>
            <FontAwesomeIcon icon={faTrash} />
            <span>Delete</span>
        </div>
    )
}
