import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisVertical } from '@fortawesome/free-solid-svg-icons';
import cardLoadingStyles from './cardLoadingStyles.module.css';
import postVotesPathDString from '../../sharedComponents/postHeader/postVotes/postVotesPathDString';
import cardVotesStyles from '../card/cardHeader/cardVotes/cardVotesStyles.module.css'
import cardHeaderStyles from '../card/cardHeader/cardHeaderStyles.module.css';

export default function CardLoading() {
    return (
        <div className={cardLoadingStyles.cardLoadingWrapper}>
            <div className={cardHeaderStyles.cardHeader}>
                <div id={cardVotesStyles.cardVotesContainer}>
                    <svg version="1.1" width="20" height="20" viewBox="0 0 24 24">
                        <path
                            fill='transparent'
                            stroke='#5d666b'
                            d={postVotesPathDString} />
                    </svg>
                    <div className={cardVotesStyles.numberOfVotes}>0</div>
                    <svg version="1.1" width="20" height="20" viewBox="0 0 24 24">
                        <path
                            fill='transparent'
                            stroke='#5d666b'
                            d={postVotesPathDString} />
                    </svg>
                </div>
                <div className={cardLoadingStyles.cardLoadingInfoAndTitleDiv}>
                    <div />
                    <div />
                </div>
            </div>
            <div />
            <div style={{ maxHeight: '32.54px' }} />
            <div className={cardLoadingStyles.cardLoadingOptions}>
                <FontAwesomeIcon icon={faEllipsisVertical} />
            </div>
        </div >
    )
}
