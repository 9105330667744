/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import { faEdit } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import editPostStyles from './editPostStyles.module.css';

export default function EditPost({ setIsEditable }: { setIsEditable: React.Dispatch<React.SetStateAction<boolean>> | null }) {
    const editPost = async () => {
        setIsEditable?.(true);
    };
    return (
        <div onClick={editPost} role='button' tabIndex={0} id={editPostStyles.editPost}>
            <span id={editPostStyles.editPostTooltip}>Edit Post</span>
            <FontAwesomeIcon icon={faEdit} />
        </div>
    )
}
