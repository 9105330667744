import { useQuery } from 'react-query';
import { API_URL } from '../../../../utilities/utils';
import { CardTypeHome } from '../cardsSectionHomeTypes';

async function fetchCardsHome(ac: AbortController) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/home/get-three-cards`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as CardTypeHome[];
}

const useCardsHomeQuery = (ac: AbortController) => useQuery('CardsHome',
    () => fetchCardsHome(ac),
    { refetchOnWindowFocus: false });

export default useCardsHomeQuery;