/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useRef } from 'react';
import { faFan, faFire, faTrophy } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import postSectionHomeNavStyles from './postSectionHomeNavStyles.module.css';
import { PostsNavProps } from './postsSectionHomeNavTypes';
import { OrderType } from '../../../universalTypes/universalTypes';

export default function UniversalNav({ setOrder, order, typeOfSearch }: PostsNavProps) {
  const handleFetch = (order: OrderType) => setOrder(order);
  const searchRef = useRef<HTMLInputElement>(null);
  const handleOnChange = (event: any) => {
    const target = searchRef.current;
    if (!target) return;
    if (event.key === 'Backspace' && target.value.length < 1) target.blur();
  }

  return (
    <div id={postSectionHomeNavStyles.postsNavbarHome}>
      <button
        className={`${postSectionHomeNavStyles.postsNavbarHomeButtons} 
        ${order.order === 'fetchNew' && postSectionHomeNavStyles.postsNavbarHomeButtonsHighlite}`}
        type="button"
        onClick={() => handleFetch({ order: 'fetchNew' })}>
        <FontAwesomeIcon icon={faFan} />
        <span> NEW </span>
      </button>
      <button
        className={`${postSectionHomeNavStyles.postsNavbarHomeButtons} 
        ${order.order === 'fetchHot' && postSectionHomeNavStyles.postsNavbarHomeButtonsHighlite}`}
        type="button"
        onClick={() => handleFetch({ order: 'fetchHot' })}>
        <FontAwesomeIcon icon={faFire} />
        <span> HOT! </span>
      </button>
      <button
        className={`${postSectionHomeNavStyles.postsNavbarHomeButtons} 
        ${order.order === 'fetchTop' && postSectionHomeNavStyles.postsNavbarHomeButtonsHighlite}`}
        type="button"
        onClick={() => handleFetch({ order: 'fetchTop' })}>
        <FontAwesomeIcon icon={faTrophy} />
        <span> TOP </span>
      </button>
      <div id={postSectionHomeNavStyles.searchPostsNavbarHome}
        onKeyDown={(event: React.KeyboardEvent) => event.key === 'Enter' &&
          (searchRef.current?.value.length as number) > 0 &&
          handleFetch({ order: 'search', value: searchRef.current?.value })} role='search'>
        <input type="search" placeholder={`Search ${typeOfSearch} by title...`} ref={searchRef} onKeyDown={handleOnChange} />
      </div>
    </div>
  );
}
