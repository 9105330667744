/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react';
import { useQueryClient } from 'react-query';
import { API_URL } from "../../../../utilities/utils";
import BasePortal from "../basePortal";
import blockUserPortalStyles from './blockUserPortalStyles.module.css';
import imgTrashbin from '../../../../assets/images/trashbin10.png';
import maqueta from '../../../../assets/images/maqueta.png';
import { PostType, UseQueryRefetch } from '../../../../universalTypes/universalTypes';
import { notificationsSocket } from '../../../nav/notifications/notifications';

function BlockUserPortal({ currentPost, refetch }: { currentPost: PostType, refetch: UseQueryRefetch }) {
    const queryClient = useQueryClient();
    const userToBlockId = currentPost?.postAuthorData?.id;
    const handleBlockUser = () => {
        fetch(`${API_URL}/user/process-block-user`,
            {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({ userToBlockId }),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                refetch();
                queryClient.setQueryData("blockUserPortal", false);
            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));;
    }
    const handleCancel = () => queryClient.setQueryData("blockUserPortal", false);

    return <BasePortal queryKey='blockUserPortal'>
        <div id={blockUserPortalStyles.blockUserPortalWrapper}>
            <img
                src={imgTrashbin || maqueta}
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div>
                <p>YOU ARE ABOUT TO <span>BLOCK</span> THIS USER </p>
                <p> <span>You will not be able to see any posts, cards, polls, threads or comments from this user</span> </p>
                <p>Do you wish to continue?</p>
            </div>
            <div id={blockUserPortalStyles.cancelAndDeleteButtonsWrapper}>
                <div
                    id={blockUserPortalStyles.blockUserPortalCancelButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}>no, wait!</div>
                <div
                    id={blockUserPortalStyles.blockUserPortalConfirmButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleBlockUser}>yeah, to hell with this a-hole!</div>
            </div>
        </div>
    </BasePortal>
}

function areEqual(prevProps: Readonly<{ currentPost: PostType }>, nextProps: Readonly<{ currentPost: PostType }>) {
    return prevProps.currentPost._id === nextProps.currentPost._id;
}

export default memo(BlockUserPortal, areEqual);