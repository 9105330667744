/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef } from 'react';
import { faFlag, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from 'react-query';
import postOptionsStyle from '../postCommentOptionsStyles.module.css';
import { handleOnBlurPostCommentOptions, handleReportComment, handleReportPost } from './optionsUtils';
import { PostCommentsType } from '../../../../postCommentsType';
import { CommentsSectionContext } from '../../../../../commentsSection';
import { CommentsSectionContext as UserProfileCommentsSectionContext } from '../../../../../../../userProfile/userProfilePublicationsWrapper/userProfilePublicationsWrapper';
import { whichContextGlobal } from '../../../../../../../../utilities/utils';


export default function NotThreadAdminNotPostAuthor({ comment, setVisible, isComment }:
    {
        comment: PostCommentsType, setVisible: React.Dispatch<React.SetStateAction<boolean>>, isComment: boolean
    }
) {
    const context = whichContextGlobal(CommentsSectionContext, UserProfileCommentsSectionContext, null) as any;
    const queryClient = useQueryClient();
    if (!context) return null;
    const optionsRef = useRef<HTMLDivElement>(null);

    function handleBlockUser() {
        context?.setCurrentComment(comment);
        queryClient.setQueryData("blockUserInCommentsPortal", true);
    }
    return (
        <div className={postOptionsStyle.postCommentOptionsContainer}
            ref={optionsRef}
            onBlur={(event) => handleOnBlurPostCommentOptions(event, optionsRef, setVisible)}>
            <div onClick={() => handleReportComment(context as any, comment, queryClient)} role='button' tabIndex={0}>
                <FontAwesomeIcon icon={faFlag} />
                <span>Report</span>
            </div>
            <div onClick={handleBlockUser} role='button' tabIndex={0}>
                <FontAwesomeIcon icon={faUserSlash} />
                <span>Block</span>
            </div>
        </div>
    )
}