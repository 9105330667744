/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useContext } from 'react'
import { ReplyContext } from '../../../comentReply';

export default function Edit({ setVisible, setIsEditable, isComment }: { setVisible: React.Dispatch<React.SetStateAction<boolean>>, setIsEditable: React.Dispatch<React.SetStateAction<boolean>>, isComment: boolean }) {
    const contextReplies = useContext(ReplyContext);
    const handleOnClickEdit = () => {
        setVisible(false);
        if (isComment) setIsEditable(true);
        else contextReplies?.setIsReplyEditable(true);
    }
    return (
        <div onClick={handleOnClickEdit} role='button' tabIndex={0}>
            <FontAwesomeIcon icon={faEdit} />
            <span>Edit</span>
        </div>
    )
}
