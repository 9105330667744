import React from 'react';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardSectionHomeLoadingStyles from './cardSectionHomeLoadingStyles.module.css';
import ThreadsSectionLoading from '../../threadsSectionHome/threadsSectionLoading/threadsSectionLoading';

export default function CardSectionHomeLoading() {
    return (
        <section id={cardSectionHomeLoadingStyles.cardsSectionHomeLoadingSection}>
            <div id={cardSectionHomeLoadingStyles.cardsSectionHomeLoadingContainer}>
                <div id={cardSectionHomeLoadingStyles.firstCardHomeLoading}>
                    <header>
                        <FontAwesomeIcon icon={faClock} />
                        <span> RECENT CARDS </span>
                    </header>
                    <div id={cardSectionHomeLoadingStyles.cardInfoHomeLoading} >
                        <div />
                        <div />
                    </div>
                    <div id={cardSectionHomeLoadingStyles.cardInfoHomeLoadingImage} />
                    <div id={cardSectionHomeLoadingStyles.smallCardImagesWrapper} >
                        <div id={cardSectionHomeLoadingStyles.cardInfoHomeLoadingImage1} />
                        <div id={cardSectionHomeLoadingStyles.cardInfoHomeLoadingImage2} />
                        <div id={cardSectionHomeLoadingStyles.cardInfoHomeLoadingImage3} />
                        <div id={cardSectionHomeLoadingStyles.cardInfoHomeLoadingImage4} />
                    </div>
                </div>
            </div>
            <ThreadsSectionLoading />
        </section>
    )
}
