/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react'
import { useQueryClient } from 'react-query';
import deletePostStyles from './deletePostStyles.module.css';

export default function DeletePost() {
    const queryClient = useQueryClient();
    const handleDeletePost = () => queryClient.setQueryData("deletePost", true);

    return (
        <div onClick={handleDeletePost} role='button' tabIndex={0} id={deletePostStyles.deletePost}>
            <span id={deletePostStyles.deletePostTooltip}>Delete Post</span>
            <i className="fas fa-trash-alt" />
        </div>
    )
}
