/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react';
import { useQueryClient } from 'react-query';
import cardOptionsThreeDotsRightStyles from '../cardOptionsThreeDotsRightStyles.module.css';
import { CardsContext } from '../../../cardsSection';
import { PostType } from '../../../../../universalTypes/universalTypes';
import { ThreadContext } from '../../../../thread/threadPosts/threadPosts';
import Report from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsActions/report';
import Block from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsActions/block';
import { UserProfileBookmarksContext } from '../../../../userProfile/userProfileSections/userProfileBookmarks/userProfileBookmarks';
import { whichContextGlobal } from '../../../../../utilities/utils';

export default function NotThreadAdminNotCardAuthor(
    { card, setVisible }: { card: PostType, setVisible: React.Dispatch<React.SetStateAction<boolean>> }
) {
    const context = whichContextGlobal(CardsContext, ThreadContext, UserProfileBookmarksContext);
    const queryClient = useQueryClient();
    if (!context) return null;
    const optionsRef = useRef<HTMLDivElement>(null);

    const handleOnBlurCardHomeOptions = (event: React.FocusEvent<HTMLDivElement>) => {
        const { relatedTarget: target } = event;
        const orc = optionsRef.current;
        if (target !== orc && !orc?.contains(target)) setVisible(false);
    }

    return (
        <div className={cardOptionsThreeDotsRightStyles.cardOptionsContainer} ref={optionsRef} onBlur={handleOnBlurCardHomeOptions}>
            <Report post={card} context={context} queryClient={queryClient} />
            <Block post={card} context={context} queryClient={queryClient} />
        </div>
    )
}