import React from 'react';
import postTitleStyles from './postTitleStyles.module.css';
import PostReadThisForMe from '../postReadThisForMe/postReadThisForMe';
import { PostTitleProps } from './postTitleTypes';

export default function PostTitle({ post }: PostTitleProps) {

  const isNsfwOrSpoiler = (post: { isNSFW: boolean, isSpoiler: boolean }) => {
    if (post.isNSFW)
      return <span className={postTitleStyles.nsfwOrSpoilerSignInPostTitle}>NSFW</span>
    if (post.isSpoiler) return <span className={postTitleStyles.nsfwOrSpoilerSignInPostTitle}>SPOILER</span>
    return null;
  }

  return (
    <>
      <div id={postTitleStyles.titlePost}>{post?.title} {isNsfwOrSpoiler(post)}</div>
      <PostReadThisForMe post={post} />
    </>
  );
}
