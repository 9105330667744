/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { ChangeEvent, useContext, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import postCreatorTitleAndThread from './postCreatorTitleAndThreadStyles.module.css'
import { PostCreatorContext } from '../../postCreator';
import { API_URL } from '../../../../utilities/utils';
import { ThreadsType } from '../../postCreatorTypes';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import ERROR from '../../../../utilities/errors';

export default function PostCreatorTitleAndThread() {
    const { threadName } = useParams();
    const [threadList, setThreadList] = useState<ThreadsType[] | null>(null);
    const [titleLength, setTitleLength] = useState(0);
    const context = useContext(PostCreatorContext);
    const threadRef = useRef<HTMLInputElement>(null);
    const titleRef = useRef<HTMLInputElement>(null);
    if (!context) return null;

    const handleInputTitle = (event: ChangeEvent<HTMLInputElement>) => {
        const title = event.currentTarget.value;
        if (title.length > 120) return notificationsSocket.emit('sendErrorMessage', ERROR.POST_TITLE_LENGTH_EXCEEDED);
        context?.setPostTitle(title);
        return setTitleLength(title.length);
    }

    const handleInputThread = (event: ChangeEvent<HTMLInputElement>) => {
        const threadName = event.currentTarget.value;
        if (!threadName.length) context?.setPostThread(null);
        fetch(`${API_URL}/postCreator/threadList/${threadName}`,
            {
                method: 'GET',
                mode: 'cors',
                headers: {
                    "Accept": "application/json",
                    "Authorization": `Bearer ${window.localStorage.token}`
                },
            })
            .then(response => response.json())
            .then(parsedResponse => setThreadList(parsedResponse))
            .catch(err => {
                setThreadList(null);
                console.log(err);
            })
    }

    const handleSelectThread = (thread: ThreadsType) => {
        context.setPostThread(thread);
        if (!thread.typeOfPost.includes(context.postType))
            context.setPostType(thread.typeOfPost[0]);
        thread.typeOfPost.includes(context.postType)
        setThreadList(null);
        const targetElement = threadRef.current;
        if (!targetElement) return;
        targetElement.value = thread.name;
    }

    useEffect(() => {
        if (threadName) {
            fetch(`${API_URL}/postCreator/oneThread/${threadName}`,
                {
                    method: 'GET',
                    mode: 'cors',
                    headers: {
                        "Accept": "application/json",
                        "Authorization": `Bearer ${window.localStorage.token}`
                    },
                })
                .then(response => response.json())
                .then(parsedResponse => handleSelectThread(parsedResponse))
                .catch(err => {
                    console.log(err);
                })
        }
    }, [threadName]);

    return (
        <div id={postCreatorTitleAndThread.postCreatorTitleAndThreadGrid}>
            <div className={postCreatorTitleAndThread.postCreatorTitleAndThreadLabels}>
                <label htmlFor='postTitleInput' >
                    <span
                        className={`
                        ${postCreatorTitleAndThread.postCreatorConfirmationSpot} 
                        ${context.postTitle && postCreatorTitleAndThread.activeSpot
                            } `}
                    >●</span> TITLE (required)<span> {titleLength}</span>/120
                </label>
                <input
                    className={postCreatorTitleAndThread.postCreatorTitleAndThreadInputs}
                    placeholder="Write the post's title..."
                    type='text'
                    onChange={handleInputTitle}
                    value={context?.postTitle}
                    ref={titleRef}
                    autoComplete='off'
                    id="postTitleInput" />
            </div>
            <div className={postCreatorTitleAndThread.postCreatorTitleAndThreadLabels}>
                <label>
                    <span
                        className={`
                        ${postCreatorTitleAndThread.postCreatorConfirmationSpot} 
                        ${context.postThread && postCreatorTitleAndThread.activeSpot
                            } `}
                    >●</span> THREAD (required):</label>
                <input
                    className={postCreatorTitleAndThread.postCreatorTitleAndThreadInputs}
                    placeholder="Write the thread's name..."
                    type='text'
                    ref={threadRef}
                    onChange={handleInputThread}
                />
                {
                    threadList?.length ? <div id={postCreatorTitleAndThread.threadListContainer}>
                        {
                            threadList?.map((item) =>
                                <div onClick={() => handleSelectThread(item)}
                                    className={postCreatorTitleAndThread.threadWrapper}
                                    key={item._id}
                                    role='button'
                                    tabIndex={0}
                                >
                                    <img src={item.lowResThumbnail}
                                        loading="lazy" decoding="async"
                                        alt={item.name}
                                        className={postCreatorTitleAndThread.threadThumbnail}
                                    />
                                    <div className={postCreatorTitleAndThread.threadAndMembersGrid}>
                                        <div>
                                            <FontAwesomeIcon icon={faDiceD20} />
                                            <div className={postCreatorTitleAndThread.threadListName}> {item.name}</div>
                                        </div>
                                        <div className={postCreatorTitleAndThread.threadHowManyMembers}>{item.howManyMembers} <span>members</span></div>
                                    </div>
                                </div>)
                        }
                    </div> : null
                }
            </div>
        </div >
    )
}
