/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useRef, MouseEvent } from 'react'
import { Link } from 'react-router-dom';
import { useQueryClient } from 'react-query';
import { faPoll, faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PostsData from '../postsSectionHomeData';
import postStyle from './postHome.module.css';
import PostHomeOptions from './postHomeOptions/postHomeOptions';
import { PostType } from '../../../../universalTypes/universalTypes';

export default function Post({ post, isItBeingCalledFromThread = false }: { post: PostType, isItBeingCalledFromThread?: boolean }) {
    const queryClient = useQueryClient();
    const postImage = useRef<HTMLImageElement>(null);
    const handleZoomImage = () => queryClient.setQueryData('zoomImagePortal', { isVisible: true, imgSrc: post.thumbnail });
    const handleNSFWorSpoilerToggle = (event: MouseEvent) => {
        event.preventDefault();
        postImage.current?.classList.remove(postImage.current?.classList[1]);
    }

    const isNsfwOrSpoiler = (post: PostType) => {
        switch (true) {
            case post.isNSFW && post.isSpoiler:
                return <>
                    <span className={postStyle.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                        {post.isNSFW && 'NSFW'}
                    </span>&nbsp;
                    <span className={postStyle.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                        {post.isSpoiler && 'SPOILER'}
                    </span>
                </>
            case post.isNSFW:
                return <span className={postStyle.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                    {post.isNSFW && 'NSFW'}
                </span>
            case post.isSpoiler:
                return <span className={postStyle.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                    {post.isSpoiler && 'SPOILER'}
                </span>
            default: return null;
        }
    }

    return (
        <div className={postStyle.postHomeContainer} key={post._id} id={post._id}>
            {post.isSticky && isItBeingCalledFromThread && <FontAwesomeIcon icon={faThumbTack} className={postStyle.thumbTackForSticky} />}
            <div className={postStyle.postHomeDiv}>
                <div className={postStyle.postHomeLinkImage} onClick={handleZoomImage} tabIndex={0} role='button'>
                    <img className={`${postStyle.postHomeImage} 
                         ${(post.isNSFW || post.isSpoiler) &&
                        postStyle.postHomeImageNsfwFilter}`}
                        src={post.lowResThumbnail || post.thumbnail}
                        alt={post.title}
                        loading="lazy"
                        decoding="async"
                        ref={postImage}
                    />
                </div>
                <div className={postStyle.postHomeText}>
                    <div>
                        <Link className={postStyle.postHomeLink}
                            to={`/thread/${post.postThreadData?.name}${post.isPost ? '/post/' : '/poll/'}${post.titleId}`}
                        >
                            {post.title} {!post.isPost && <FontAwesomeIcon icon={faPoll} title='This is a poll' />} {isNsfwOrSpoiler(post)}
                        </Link>
                    </div>
                    <PostHomeOptions post={post} />
                    <PostsData post={post} />
                </div>
            </div>
        </div>
    )
}


