/* eslint-disable react/require-default-props */
/* eslint-disable no-underscore-dangle */
/* eslint-disable import/no-cycle */
import React from 'react';
import { faThumbTack } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import cardStyles from './cardStyles.module.css'
import CardBodySwitchImageVideoOrYoutube from './cardBody/cardBodySwitchImageVideoOrYoutube';
import CardHeader from './cardHeader/cardHeader';
import CardOptions from './cardOptions/cardOptions';
import CardOptionsThreeDotsRight from './cardOptionsThreeDotsRight/cardOptionsThreeDotsRight';
import { PostType } from '../../../universalTypes/universalTypes';

export default function Card({ card, isItBeingCalledFromThread = false }: { card: PostType, isItBeingCalledFromThread?: boolean }) {
    return (
        <div className={cardStyles.cardWrapper} >
            {card.isSticky && isItBeingCalledFromThread && <FontAwesomeIcon icon={faThumbTack} className={cardStyles.thumbTackForSticky} />}
            <CardHeader card={card} />
            <CardBodySwitchImageVideoOrYoutube card={card} />
            <CardOptions card={card} />
            <CardOptionsThreeDotsRight card={card} isItBeingCalledFromThread />
        </div>
    )
}


