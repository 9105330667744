/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Link } from "react-router-dom";
import cardBodyStyles from './cardBodyStyles.module.css'
import CardBodyNSFW from './cardBodyNSFW';
import { PostType } from '../../../../universalTypes/universalTypes';

export default function CardBodyImage({ card }: { card: PostType }) {
    return (
        <Link to={`/thread/${card.postThreadData?.name}/card/${card.titleId}`}
            className={cardBodyStyles.cardBodyImageOrVideo}>
            {(card.isNSFW || card.isSpoiler) && <CardBodyNSFW isNSFW={card.isNSFW} />}
            <img src={card.imageUrl}
                alt={card.imageUrl}
                loading='lazy'
                decoding='async'
                className={`${card.isNSFW || card.isSpoiler ? cardBodyStyles.nsfwFilter : ''} `}
            />
        </Link>
    );
}