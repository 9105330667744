/* eslint-disable jsx-a11y/click-events-have-key-events */
import React from 'react';
import pollTypeSelectorStyles from './pollTypeSelectorStyles.module.css';

export default function PollTypeSelector({ setPollhasPollImages }: any) {
    const handleSelectPollWithImages = () => setPollhasPollImages(true);
    const handleSelectPollWithoutImages = () => setPollhasPollImages(false);
    return (
        <div id={pollTypeSelectorStyles.pollTypeSelectorWrapper}>
            <div onClick={handleSelectPollWithImages} tabIndex={0} role='button'>poll with images</div>
            <div onClick={handleSelectPollWithoutImages} tabIndex={0} role='button'>poll without images</div>
        </div>
    )
}
