/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useRef } from 'react'
import { useQueryClient } from 'react-query';
import cardOptionsThreeDotsRightStyles from '../cardOptionsThreeDotsRightStyles.module.css';
import { CardsContext } from '../../../cardsSection';
import { PostType } from '../../../../../universalTypes/universalTypes';
import Ban from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsActions/ban';
import Delete from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsActions/delete';
import Block from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsActions/block';
import { ThreadContext } from '../../../../thread/threadPosts/threadPosts';
import { UserProfileBookmarksContext } from '../../../../userProfile/userProfileSections/userProfileBookmarks/userProfileBookmarks';
import Sticky from '../../../../homeSection/postsSectionHome/postHome/postHomeOptions/options/optionsActions/sticky';
import { whichContextGlobal } from '../../../../../utilities/utils';

export default function ThreadAdminNotCardAuthor(
    { card, setVisible, isItBeingCalledFromThread }:
        {
            card: PostType,
            setVisible: React.Dispatch<React.SetStateAction<boolean>>,
            isItBeingCalledFromThread: boolean
        }
) {

    const context = whichContextGlobal(CardsContext, ThreadContext, UserProfileBookmarksContext);
    if (!context) return null;
    const queryClient = useQueryClient();
    const optionsRef = useRef<HTMLDivElement>(null);

    const handleOnBlurCardHomeOptions = (event: React.FocusEvent<HTMLDivElement>) => {
        const { relatedTarget: target } = event;
        const orc = optionsRef.current;
        if (target !== orc && !orc?.contains(target)) setVisible(false);
    }

    return (
        <div className={cardOptionsThreeDotsRightStyles.cardOptionsContainer} onBlur={handleOnBlurCardHomeOptions} ref={optionsRef}>
            {isItBeingCalledFromThread && <Sticky post={card} context={context} setVisible={setVisible} />}
            <Delete post={card} context={context} queryClient={queryClient} />
            {isItBeingCalledFromThread && <Ban post={card} context={context} queryClient={queryClient} />}
            <Block post={card} context={context} queryClient={queryClient} />
            {isItBeingCalledFromThread && <Sticky post={card} context={context} setVisible={setVisible} />}
        </div>
    )
}
