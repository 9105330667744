import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import postAuthorAndThreadStyles from './postAuthorAndThreadStyles.module.css';
import { PostAuthorAndThreadProps } from './postAuthorAndThreadTypes';
import timeSincePosted from '../../../../utilities/utils';

export default function PostAutorAndThread({ post }: PostAuthorAndThreadProps) {
  return (
    <div id={postAuthorAndThreadStyles.postInfo}>
      Posted on
      <Link to={`/thread/${post.postThreadData?.name}`}>
        <span>
          <FontAwesomeIcon icon={faDiceD20} id={postAuthorAndThreadStyles.postInfoThreadNameDice} /> {post.postThreadData?.name}
        </span>
      </Link>
      by
      <Link to={`/profile/${post.postAuthorData?.nickname}`}>
        {` ${post.postAuthorData?.nickname} `}
      </Link>
      •
      {` ${timeSincePosted(post.timestamp)}`}
    </div>
  );
}
