/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { faClock, faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useThreadQuery from './threadSectionHomeCustomHooks/useThreadQuery';
import threadsStyle from './threadsSection.module.css';
import ThreadsSectionLoading from './threadsSectionLoading/threadsSectionLoading';
import ThreadsSectionError from './threadsSectionError/threadsSectionError';

export default function ThreadsSection() {
  const ac = new AbortController();
  const { data: threeThreads, isError, isLoading, remove } = useThreadQuery(ac);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
  useEffect(() => () => remove(), []);
  if (isError) return <ThreadsSectionError />
  if (isLoading) return <ThreadsSectionLoading />


  return (
    <div id={threadsStyle.threadsSection}>
      <div><FontAwesomeIcon icon={faClock} /> recent threads</div>
      {
        threeThreads?.map((thread) => (
          <Link to={`/thread/${thread.name}`} key={thread._id}>
            <img
              loading="lazy"
              decoding="async"
              src={thread.lowResThumbnail}
              alt={thread.name}
            />
            <img
              loading="lazy"
              decoding="async"
              src={thread.backgroundImage}
              alt={thread.name}
            />
            <figcaption>
              <span>
                <FontAwesomeIcon icon={faDiceD20} />&nbsp;
                {thread.name}
              </span>
            </figcaption>
            <figcaption>
              <div>
                About <strong><FontAwesomeIcon icon={faDiceD20} />&nbsp;
                  {thread.name}</strong>
              </div>
              <div>{thread.description}</div>
            </figcaption>
          </Link>
        ))
      }
    </div>
  );
}
