/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useRef, useState } from 'react'
import { faSmile } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Editor as TinyMCEEditor } from "tinymce";
import editPostStyles from './editPostStyles.module.css';
import { API_URL } from '../../../utilities/utils';
import postCreatorTextareaStyles from "../../postCreator/postCreatorTypeSelected/postCreatorTextarea/postTextarea/postCreatorTextareaStyles.module.css";
import { postImageType, postThumbnailType } from '../../postCreator/postCreatorTypeSelected/postCreatorTextarea/postTextarea/postCreatorTextareaUtils';
import { PostType } from '../../../universalTypes/universalTypes';
import EditorTinyMCE from '../../sharedComponents/tinymceEditor/tinymceEditor';
import Emojis from '../../sharedComponents/tinymceEditor/emojis/emojis';
import { notificationsSocket } from '../../nav/notifications/notifications';
import sharedCSS from '../../sharedCSS/sharedCSS.module.css';
import ERROR from '../../../utilities/errors';
import LoadingSpinner from '../../sharedComponents/loadingSpinner/loadingSpinner';

export default function EditPost({ post, refetch, setIsEditable, remove }: { post: PostType, refetch: any, setIsEditable: React.Dispatch<React.SetStateAction<boolean>>, remove: any }) {
    const [isAvailableForSending, setIsAvailableForSending] = useState(true);
    const [isNSFW, setIsNSFW] = useState(post.isNSFW);
    const [isSpoiler, setIsSpoiler] = useState(post.isSpoiler);
    const [areEmojisVisible, setAreEmojisVisible] = useState(false);
    const [postBody, setPostBody] = useState<Document | null>(null);
    const emojisButtonRef = useRef<SVGSVGElement | null>(null);
    const sendPostRef = useRef(null);
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const handleIsSpoiler = () => setIsSpoiler((value) => !value);
    const handleIsNSFW = () => setIsNSFW((value) => !value);
    const updatePost = () => {
        if (!isAvailableForSending) return null;
        if (!postBody) return notificationsSocket.emit('sendErrorMessage', ERROR.POST_BODY_IS_EMPTY);
        setIsAvailableForSending(false);
        const image = postBody?.getElementsByTagName("img")[0];
        const youtubeImage: HTMLIFrameElement | undefined | string = postBody?.getElementsByTagName("iframe")[0];
        const editorContent = postBody.activeElement?.innerHTML; // postBody?.documentElement.outerHTML.replace(/<style[\s\S]*?<\/style>/gi, '');
        fetch(`${API_URL}/post/edit-post`,
            {
                method: 'POST',
                mode: 'cors',
                headers: { "Authorization": `Bearer ${window.localStorage.token}`, "Content-Type": 'application/json' },
                body: JSON.stringify({
                    postId: post._id,
                    threadId: post.postThreadData._id,
                    postBody: editorContent,
                    spoiler: isSpoiler.toString(),
                    nsfw: isNSFW.toString(),
                    thumbnail: postImageType(image, youtubeImage),
                    lowResThumbnail: postThumbnailType(image, youtubeImage)
                })
            })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                setIsEditable(false);
                remove();
                refetch();
            })
            .catch(error => {
                notificationsSocket.emit('sendErrorMessage', error.message);
                setIsAvailableForSending(true);
            })
        return null
    }

    return <div id={editPostStyles.editPostBody}>
        <EditorTinyMCE
            setPostBody={setPostBody}
            post={post}
            editorRef={editorRef}
            setAreEmojisVisible={setAreEmojisVisible} />
        <div id={editPostStyles.wrapperEditButton}>
            <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isSpoiler && postCreatorTextareaStyles.active}`}
                onClick={handleIsSpoiler}
                role='button'
                tabIndex={0}
            >
                <span>SPOILER</span>
            </div>
            <div className={`${postCreatorTextareaStyles.spoilerNSFW} ${isNSFW && postCreatorTextareaStyles.active}`}
                onClick={handleIsNSFW}
                role='button'
                tabIndex={0}>
                <span>NSFW</span>
            </div>
            <div id={editPostStyles.updateButtonWrapper}
                onClick={updatePost}
                ref={sendPostRef}
                role='button'
                tabIndex={0}
            >
                {
                    isAvailableForSending ?
                        <div>SAVE EDITS!</div> :
                        <LoadingSpinner />
                }
            </div>
        </div>
        {
            areEmojisVisible as boolean &&
            <Emojis emojisButtonRef={emojisButtonRef.current as SVGSVGElement}
                setAreEmojisVisible={setAreEmojisVisible}
                editorRef={editorRef.current} />
        }
    </div>
} 