/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useContext, useRef, useState } from 'react';
import { Editor as TinyMCEEditor } from "tinymce";
import { faReply } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import CommentVotes from './commentVotes';
import postCommentsStyles from '../../postCommentsStyles.module.css';
import { PostCommentsRepliesType, PostCommentsType } from '../../postCommentsType';
import fetchPostCommentsReplies from '../postCommentsReplies/postCommentsRepliesUtils';
import { API_URL } from '../../../../../../utilities/utils';
import { PostType } from '../../../../../../universalTypes/universalTypes';
import EditorTinyMCE from '../../../../tinymceEditor/tinymceEditor';
import Emojis from '../../../../tinymceEditor/emojis/emojis';
import { notificationsSocket } from '../../../../../nav/notifications/notifications';
import ERROR from '../../../../../../utilities/errors';
import { PostCommentsContext } from '../../postComments';

export default function CommentVotesAndReplies({ reply, isComment, comment, setReplyList, setIsEditable }: { reply: PostCommentsRepliesType | null, isComment: boolean, comment: PostCommentsType | null, setReplyList: any, setIsEditable: any }) {

    const context = useContext(PostCommentsContext);
    // const otherContext = useContext(PostCommentsAndRepliesContext);
    const [areEmojisVisible, setAreEmojisVisible] = useState(false);
    const [isAvailableForSending, setIsAvailableForSending] = useState(true);
    const [replyContent, setReplyContent] = useState<Document | null>(null);
    const editorRef = useRef<TinyMCEEditor | null>(null);
    const emojisButtonRef = useRef<SVGSVGElement | null>(null);
    const [visible, setVisible] = useState<{ ok: boolean, commentAuthorNickname: string | null }>({ ok: false, commentAuthorNickname: null });
    const sendReply = () => {
        if (!isAvailableForSending) return null;
        if (!editorRef.current?.getContent({ format: "text" })) return notificationsSocket.emit('sendErrorMessage', ERROR.POST_BODY_IS_EMPTY); // setNot({ ver: true, mensaje: "" });
        setIsAvailableForSending(false);
        const editorContent = replyContent?.activeElement?.innerHTML; // replyContent?.documentElement.outerHTML.replace(/<style[\s\S]*?<\/style>/gi, '');
        fetch(context.isBeingCalledFromProfile ? `${API_URL}/user-profile/process-profile-reply` : `${API_URL}/post/process-post-reply`,
            {
                method: 'POST',
                mode: 'cors',
                headers: { "Authorization": `Bearer ${window.localStorage.token}`, "Content-Type": 'application/json' },
                body: JSON.stringify({
                    replyCommentId: comment?._id,
                    commentPostId: context.isBeingCalledFromProfile ? null : context?.post?._id,
                    commentThreadId: context.isBeingCalledFromProfile ? null : context?.post.postThreadData._id,
                    commentContent: editorContent,
                    threadId: context.isBeingCalledFromProfile ? null : context?.post.postThreadData._id,
                    commentUserProfileId: context.isBeingCalledFromProfile ? context.userProfile._id : null
                })
            })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                editorRef.current?.setContent('');
                setIsAvailableForSending(true);
                fetchPostCommentsReplies(
                    context?.post?._id as string,
                    comment?._id as string,
                    setReplyList,
                    context.isBeingCalledFromProfile as boolean,
                    context.userProfile?._id);
                setVisible({ ok: false, commentAuthorNickname: null });
                if (comment?.commentAuthorData.id !== response.authorId) {
                    notificationsSocket.emit('sendMessageToSpecificUser', {
                        userId: comment?.commentAuthorData.id,
                        postUrl: `thread/${context?.post.postThreadData.name}/post/${context?.post.titleId}#${response._id}`,
                        message: `${response.authorReplyNickname} replied your comment!`
                    });
                }
            })
            .catch(error => {
                console.log("error --->", error);
                notificationsSocket.emit('sendErrorMessage', error.message);
                setIsAvailableForSending(true);
            })
        return null
    }

    return (
        <div className={postCommentsStyles.commentVotesAndRepliesWrapper}>
            <CommentVotes
                post={context?.post as PostType}
                reply={reply}
                setVisible={setVisible}
                isComment={isComment}
                comment={comment}
                setIsEditable={setIsEditable}
                setReplyList={setReplyList} />
            {
                visible.ok && <div className={postCommentsStyles.replyTinyMCEEditorWrapper}>
                    <EditorTinyMCE setPostBody={setReplyContent}
                        post={{ postBody: visible.commentAuthorNickname }}
                        editorRef={editorRef}
                        setAreEmojisVisible={setAreEmojisVisible}
                    />
                    <div className={postCommentsStyles.replySendButton}
                        onClick={sendReply}
                        role='button'
                        tabIndex={0}>
                        <FontAwesomeIcon icon={faReply} />
                    </div>
                    {
                        areEmojisVisible as boolean &&
                        <Emojis emojisButtonRef={emojisButtonRef.current as SVGSVGElement}
                            setAreEmojisVisible={setAreEmojisVisible}
                            editorRef={editorRef.current}
                        />
                    }
                </div>
            }
        </div>
    )
}
