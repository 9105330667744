/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/jsx-no-undef */
import React, { ChangeEvent, useState } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL } from '../../../../utilities/utils';
import BasePortal from '../basePortal';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import changeUserNicknameStyles from './changeUserNickname.module.css';
import imgTrashbin from '../../../../assets/images/trashbin10.png';

export default function ChangeUserNickname({ queryKey, userProfileId }: { queryKey: string, userProfileId: string }) {
    const [nickname, setNickname] = useState<string>();
    const [newNicknameError, setNewNicknameError] = useState<string | null>(null);
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    function handleChangeNickname() {
        if (!nickname) return setNewNicknameError("**Nickname can't be less than 3 letters!**");
        if (nickname.match(/[^a-zA-Z0-9_\s]/)) return setNewNicknameError('**Nickname can contain only letters, numbers or _ (underscores)!**');
        if (nickname.length > 25) return setNewNicknameError("**Nickname can't be more than 25 letters!**");
        fetch(`${API_URL}/user-profile/change-nickname-only-one-time`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    userProfileId,
                    nickname
                }),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                queryClient.setQueryData(queryKey, false);
                navigate(`/profile/@${nickname}`);
                return true;
            })
            .catch((error) => {
                setNewNicknameError(error.message);
                notificationsSocket.emit('sendErrorMessage', error.message);
                return false;
            });
        return false;
    }

    const handleCancel = () => queryClient.setQueryData(queryKey, false);

    const handleInputNewNickname = (e: ChangeEvent<HTMLInputElement>) => {
        const target = e.target as HTMLInputElement;
        if (target.value.match(/[^a-zA-Z0-9_\s]/)) return setNewNicknameError('**Nickname can contain only letters, numbers or _ (underscores)!**');
        if (target.value.length > 25) return setNewNicknameError("**Nickname can't be more than 25 letters!**");
        setNewNicknameError(null);
        setNickname(target.value);
        return true;
    }

    return <BasePortal queryKey={queryKey}>
        <div id={changeUserNicknameStyles.changeNicknameWrapper}>
            <img
                src='https://w3s.link/ipfs/bafkreibz7ojb5edhz7ik6435ybfz6utk2iymdbbh4lrqf3ct5wrbpdp3ie'
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div id={changeUserNicknameStyles.newNicknameTextWrapper}>
                <input type='text' id={changeUserNicknameStyles.newNickname} className={newNicknameError ? changeUserNicknameStyles.fixInputMargin : ''}
                    placeholder='Enter your new nickname...'
                    autoComplete='off'
                    onChange={(e) => handleInputNewNickname(e)}
                />
                {newNicknameError && <div id={changeUserNicknameStyles.alreadyTakenError}>{newNicknameError}</div>}
                <p>YOU ARE ABOUT TO <span id={changeUserNicknameStyles.changeNickname}>CHANGE</span> YOUR NICKNAME</p>
                <p>However, you are allowed to do this just one time!</p>
                <p>Do you wish to continue?</p>
            </div>
            <div id={changeUserNicknameStyles.cancelAndDeleteButtons}>
                <div
                    id={changeUserNicknameStyles.cancelPostPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}
                >no, wait!</div>
                <div
                    id={changeUserNicknameStyles.deletePostPortalButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleChangeNickname}
                >yeah, change it!</div>
            </div>
        </div>
    </BasePortal>
}
