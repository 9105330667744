import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import { FirstCardHomeProps } from './firstCardHomeTypes';
import timeSincePosted from '../../../../utilities/utils';
import firstCardHomeStyles from './firstCardHome.module.css';

export default function FirstCardHomeInfo({ card }: FirstCardHomeProps) {
  return (
    <span>
      On
      <Link to={`/thread/${card.postThreadName}`}>
        <span>
          <FontAwesomeIcon icon={faDiceD20}
            className={firstCardHomeStyles.firstCardHomeThreadNameDice} />&nbsp;
          {card.postThreadName}
        </span>
      </Link>
      By
      <a href={`/profile/${card.postAuthorNickname}`}>
        {` ${card.postAuthorNickname} `}
      </a>
      •
      {` ${timeSincePosted(card.timestamp) as string}`}
    </span>
  );
}
