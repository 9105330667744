import React, { useRef } from 'react'
import { createPortal } from 'react-dom';
import { CSSTransition } from 'react-transition-group';
import LoginPortalGoogle from '../../sharedComponents/reactPortals/loginPortal/loginPortalGoogle'
import loginPortalStyles from './loginPortalStyles.module.css';
// import imgTrashbin from '../../../assets/images/trashbin10.png';
import maqueta from '../../../assets/images/maqueta.png';
// import gamerthLogo from "../logoNuevo3.png"
import basePortalStyles from '../../sharedComponents/reactPortals/basePortalStyles.module.css';
import '../../sharedComponents/reactPortals/reactPortalTransitions.css';
import { gamerthLogoURL } from '../../../utilities/utils';

export default function LoginPortalPatchFIx({ setVisible, visible }: any) {
  const divRef = useRef<HTMLDivElement>(null);
  const onCloseloginPortal = (ev: React.MouseEvent<HTMLDivElement, MouseEvent> &
  { target: HTMLDivElement }) => {
    if (divRef?.current?.contains(ev.target)) return;
    setVisible(false);
  };

  return createPortal(
    <CSSTransition classNames='alerta' in={visible} timeout={300} unmountOnExit>
      <div id={basePortalStyles.basePortalContainerDiv} onClick={onCloseloginPortal} role='none'>
        <div ref={divRef}>
          <div id={basePortalStyles.basePortalDivImageContainer}>
            <img
              loading="lazy"
              decoding="async"
              src={gamerthLogoURL}
              alt="GAMERTH"
              title="Just dive yourself into what you love!"
            />
          </div>
          <div id={loginPortalStyles.loginPortalWrapper}>
            <img
              src={"https://w3s.link/ipfs/bafkreibijtdyuzguzvapqw343b3cpf5tfhba4tda5lmvo6ainldjublr7q" || maqueta}
              alt='imagenTrashbin'
              loading='lazy'
              decoding='async'
            />
            <div id={loginPortalStyles.loginPortalBodyWrapper}>
              <div>WELCOME TO <img src={gamerthLogoURL} alt="Gamerth" loading='lazy' decoding='async' /></div>
              <LoginPortalGoogle />
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>, document.getElementById('portal-root') as HTMLElement);
}
