import React from 'react';
import { Link } from 'react-router-dom';
import cardsStyle from '../cardsSectionHomeStyles.module.css';
import FirstCardHomeTitleNSFW from './firstCardHomeTitleNSFW';
import { FirstCardHomeProps } from './firstCardHomeTypes';

export default function CardsTypeYoutubeOrImage({ card }: FirstCardHomeProps) {
  return (
    <Link to={`/thread/${card.postThreadName}/card/${card.titleId}`}>
      {(card.isNSFW || card.isSpoiler) && <FirstCardHomeTitleNSFW isNSFW={card.isNSFW} />}
      <img
        className={card.isNSFW || card.isSpoiler ? cardsStyle.nsfwFilter : ''}
        src={card.youtube ? card.youtube.thumbnailUrl : card.imageUrl}
        alt={card.title}
        loading='lazy'
        decoding='async'
        title={card.title}
      />
    </Link>
  );
}
