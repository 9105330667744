/* eslint-disable react/require-default-props */
/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react'
import { useQueryClient } from 'react-query';
import CommentsTextArea from './postCommentTextarea/commentsArea/commentsTextarea';
import { PostType } from '../../../universalTypes/universalTypes';
import { PostCommentsType } from './postComments/postCommentsType';
import usePostCommentsQuery from './postComments/usePostComments';
import { zoomInImages } from '../../../utilities/utils';
import PostComments from './postComments/postComments';
import CommentsSectionContextType from './commentsSectionTypes';
import BanUserFromThreadInCommentsPortal from '../reactPortals/banUserFromThreadPortal/banUserFromThreadInCommentsPortal';
import BlockUserInCommentsPortal from '../reactPortals/blockUserPortal/blockUserInCommentsPortal';
import ReportComment from '../reactPortals/reportComment/reportComment';

export const CommentsSectionContext = createContext<CommentsSectionContextType>(null);

export default function CommentsSection({ post }: { post: PostType }) {
    const { data, refetch, remove } = usePostCommentsQuery(post._id, !!window.location.hash);
    const [commentsList, setCommentsList] = useState<PostCommentsType[]>([]);
    const [currentComment, setCurrentComment] = useState(null);
    const queryClient = useQueryClient();


    useEffect(() => {
        zoomInImages(queryClient);
    });
    useEffect(() => {
        if (data?.comments?.length)
            setCommentsList(data?.comments);
    }, [data]);
    return (
        <CommentsSectionContext.Provider value={{
            post,
            queryClient,
            refetch,
            remove,
            setCommentsList,
            commentsList,
            setCurrentComment,
            totalPostComments: data?.totalPostComments || 0,
            currentComment,
            isBeingCalledFromProfile: false,
            data
        }}>
            <CommentsTextArea />
            <PostComments />
            {
                currentComment &&
                <>
                    <BanUserFromThreadInCommentsPortal post={post} comment={currentComment} />
                    <BlockUserInCommentsPortal comment={currentComment} refetch={refetch} />
                    <ReportComment comment={currentComment} />
                </>
            }
        </CommentsSectionContext.Provider>
    )
}
