/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useState } from 'react';
import { UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import UserProfileFriends from './userProfileFriends/userProfileFriends';
import UserProfileBackgroundImageAndThumbnail from './userProfileBackgroundImageAndThumbnail/userProfileBackgroundImageAndThumbnail';
import UserProfileNav from './userProfileNav/userProfileNav';
import UserProfilePictures from './userProfilePictures/userProfilePictures';
import UserProfileGrid from './userProfileGrid/userProfileGrid';
import UserProfileData from './userProfileData/userProfileData';
import UserProfilePublicationsWrapper from './userProfilePublicationsWrapper/userProfilePublicationsWrapper';
import UserProfileContent from './userProfileContent/userProfileContent';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import useUserProfileQuery from './userProfileCustomHook';
import { UserProfileType } from './userProfileTypes';
import UserProfileLoadingTemplate from './userProfileLoadingTemplate/userProfileLoadingTemplate';
import ProfileSections from './userProfileUtils';
import UserProfileFriendsSection from './userProfileSections/userProfileFriendsSection/userProfileFriendsSection';
import UserProfileNotifications from './userProfileSections/userProfileNotifications/userProfileNotifications';
import UserProfileInformation from './userProfileSections/userProfileInformation/userProfileInformation';
import UserProfileMemberOfSection from './userProfileSections/userProfileMemberOfSection/userProfileMemberOfSection';
import ZoomImagePortal from '../sharedComponents/reactPortals/zoomImagePortal/zoomImagePortal';
import UserProfileBookmarks from './userProfileSections/userProfileBookmarks/userProfileBookmarks';
import userProfileStyles from './userProfileStyles.module.css';
import ReportPost from '../sharedComponents/reactPortals/reportPost/reportPost';


export default function UserProfile() {
    const params = useParams();
    const [profileSection, setProfileSection] = useState<string>(ProfileSections.WALL);
    const { data: userProfile, refetch, remove }: UseQueryResult<UserProfileType, unknown> = useUserProfileQuery(params.userProfile as string);
    useEffect(() => {
        remove();
        refetch();
        setProfileSection(ProfileSections.WALL);
    }, [params.userProfile]);

    useEffect(() => {
        if (!params.profileSection) return;
        remove();
        refetch();
        setProfileSection(params.profileSection?.toUpperCase() as string);
    }, [params.profileSection]);

    function selectProfileSection() {
        switch (profileSection) {
            case ProfileSections.WALL:
                return <UserProfileContent>
                    <UserProfileData>
                        {
                            userProfile ? <div>
                                <div style={{ fontSize: '1.2rem', fontWeight: 'bold', color: 'var(--gamerthWhite)' }}>About me</div>
                                <div>{userProfile?.info.aboutMe}</div>
                            </div> : <div>Loading...</div>
                        }
                        {
                            userProfile ? <UserProfilePictures userProfile={userProfile} /> : <div>Loading...</div>
                        }
                        {
                            userProfile ? <UserProfileFriends userProfile={userProfile} /> : <div>Loading...</div>
                        }
                    </UserProfileData>
                    {userProfile ? <UserProfilePublicationsWrapper userProfile={userProfile} /> : <UserProfileLoadingTemplate />}
                </UserProfileContent>;
            case ProfileSections.FRIENDS:
                return <UserProfileFriendsSection userProfile={userProfile as UserProfileType} />
            case ProfileSections.NOTIFICATIONS:
                return <UserProfileNotifications userProfile={userProfile as UserProfileType} />
            case ProfileSections.BOOKMARKED:
                return <UserProfileBookmarks userProfile={userProfile as UserProfileType} />
            case ProfileSections.MEMBEROF:
                return <UserProfileMemberOfSection userProfile={userProfile as UserProfileType} />
            case ProfileSections.INFORMATION:
                return <UserProfileInformation userProfile={userProfile as UserProfileType} />
            default: return null
        }
        return null;
    }
    return (
        <MainWrapper>
            <UserProfileGrid>
                <UserProfileBackgroundImageAndThumbnail userProfile={userProfile as UserProfileType} refetch={refetch} />
                <div id={userProfileStyles.userProfileNicknameSmartPhone}>{userProfile?.nickname}</div>
                <UserProfileNav setProfileSection={setProfileSection} profileSection={profileSection} />
                {selectProfileSection()}
            </UserProfileGrid>
            <ZoomImagePortal queryKey="zoomImagePortal" />
            {
                userProfile && <ReportPost userProfile={userProfile} isThisBeingCalledFromUserProfile />
            }
        </MainWrapper >
    )
}
