import React from 'react';
import CardsSection from './cardsSectionHome/cardsSectionHome';
import homeStyle from './homeStyles.module.css';
import PostsSection from './postsSectionHome/postsSectionHome';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';

export default function Posts() {
  return (
    <MainWrapper>
      <div id={homeStyle.homeGrid}>
        <PostsSection />
        <CardsSection />
      </div>
    </MainWrapper>
  );
}
