/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
import React, { useEffect, useRef, useState } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import postCreatorPollAreaStyles from './postCreatorPollWithoutImagesStyles.module.css';
import { PostCreatorPollImageAreaProps, pollImagesType } from './postCreatorPollWithoutImagesTypes'
import { notificationsSocket } from '../../../nav/notifications/notifications';
import ERROR from '../../../../utilities/errors';

export default function PostCreatorPollText({
    pollData,
    setPollData,
    setIsImageAdderActive,
    setPollOptionElements
}: PostCreatorPollImageAreaProps) {
    const descriptionRef = useRef<HTMLInputElement>(null);
    const pollTextContainerRef = useRef<HTMLDivElement>(null);
    const [isSaved, setIsSaved] = useState(false);
    const isSavedRef = useRef<boolean | null>(null);

    const handleTakeOffPollOption = () => {
        const element = pollTextContainerRef?.current; // Reemplaza "miElemento" con el ID de tu elemento
        const parent = pollTextContainerRef?.current?.parentElement;
        const children = Array.from(parent?.children as Iterable<Element>);
        const indexOfChildren = children.indexOf(element as Element);
        setPollData((arr) => {
            const newArr = [...arr];
            newArr.splice(indexOfChildren, 1);
            return newArr;
        });
        setPollOptionElements((arr) => {
            const newArr = [...arr];
            newArr.splice((indexOfChildren), 1);
            return newArr;
        });
        if (!isSavedRef.current) setIsImageAdderActive(true);
    }

    const handleSavePollOption = () => {
        // Guardar la image en el backend
        if (!descriptionRef.current?.value) return notificationsSocket.emit('sendErrorMessage', ERROR.NO_DESCRIPTION);
        setPollData((pollArr) => [...pollArr, {
            description: descriptionRef?.current?.value,
            index: pollArr.length
        }] as pollImagesType[]);
        setIsImageAdderActive(true);
        setIsSaved(true);
        return null;
    }

    const handleCancel = () => {
        setPollOptionElements((arr) => {
            const tempArr = [...arr];
            tempArr.pop();
            return tempArr;
        });
        setIsImageAdderActive(true);
    }

    useEffect(() => {
        isSavedRef.current = isSaved;
    }, [isSaved]);

    return (
        <div className={postCreatorPollAreaStyles.pollTextContainer} ref={pollTextContainerRef}>
            {
                !isSaved ? <>

                    <input type='text' ref={descriptionRef}
                        className={postCreatorPollAreaStyles.pollOptionText}
                        placeholder='Enter poll option...'
                        maxLength={200} />
                    <div className={postCreatorPollAreaStyles.pollOptionsAcceptCancel}>
                        <span onClick={handleCancel}
                            role='button'
                            tabIndex={0}> cancel </span>
                        <span onClick={handleSavePollOption}
                            role='button'
                            tabIndex={0}> save </span>
                    </div>
                </> : <>
                    {
                        pollData && <span className={`fas fa-times ${postCreatorPollAreaStyles.takeOffPollPicture}`}
                            role='button'
                            tabIndex={0}
                            onClick={handleTakeOffPollOption} />
                    }
                    <div className={postCreatorPollAreaStyles.pollImageDescription}>{descriptionRef.current?.value}</div>
                </>
            }
        </div>
    )
}
