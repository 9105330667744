/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import React from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDiceD20 } from '@fortawesome/free-solid-svg-icons';
import postsStyle from './postsSectionHomeStyles.module.css';
import timeSincePosted from '../../../utilities/utils';
import { PostType } from '../../../universalTypes/universalTypes';

export default function PostsData({ post }: { post: PostType }) {
  return (
    <div className={postsStyle.postsData}>
      <Link to={`/thread/${post.postThreadData?.name}`}>
        <span className={postsStyle.onSpan}>On</span>
        <span className={postsStyle.postThreadNameSpanWrapper}>
          <FontAwesomeIcon icon={faDiceD20} className={postsStyle.postsThreadNameDice} />&nbsp;
          {post.postThreadData?.name}
        </span>
      </Link>
      by
      <Link to={`/profile/${post.postAuthorData?.nickname}`}>
        {` ${post.postAuthorData?.nickname} `}
      </Link>
      •
      {` ${timeSincePosted(post.timestamp) as string}`}
    </div>
  );
}
