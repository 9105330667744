/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import postStyles from './postStyles.module.css';
import PostBody from './postBody/postBody';
import usePostQuery from './postCustomHooks/usePost';
import PostLoadingTemplate from '../sharedComponents/postFooter/postFooterOptionsComponents/postLoadingTemplate/postLoadingTemplate';
import EditPost from './editPost/editPost';
import MainWrapper from '../sharedComponents/mainWrapper/mainWrapper';
import DeletePost from '../sharedComponents/reactPortals/deletePost/deletePost';
import ReportPost from '../sharedComponents/reactPortals/reportPost/reportPost';
import ZoomImagePortal from '../sharedComponents/reactPortals/zoomImagePortal/zoomImagePortal';
import ThreadInfo from '../sharedComponents/threadInfo/threadInfo';
import { ThreadsType } from '../postCreator/postCreatorTypes';
import PostAutorAndThread from '../sharedComponents/postHeader/postAuthorAndThread/postAuthorAndThread';
import PostVotes from '../sharedComponents/postHeader/postVotes/postVotes';
import PostTitle from '../sharedComponents/postHeader/postTitle/postTitle';
import PostFooterOptions from '../sharedComponents/postFooter/postFooterUtils/postFooterUtils';
import CommentsSection from '../sharedComponents/commentsSection/commentsSection';
import ThreadInfoLoading from '../sharedComponents/threadInfo/threadInfoLoadingTemplate/threadInfoLoading';

export default function Post() {
  const { threadName = '', postId = '', edit = 'false' } = useParams();
  const [isEditable, setIsEditable] = useState(JSON.parse(edit));
  const [postThread, setPostThread] = useState<ThreadsType | null>(null);
  const [visible, setVisible] = useState(true);
  const [visible2, setVisible2] = useState(false);
  const ac = new AbortController();
  const { data: post, isError, error, refetch, isLoading, remove } = usePostQuery(threadName, postId, ac, 'post');
  useEffect(() => {
    if (post?.postThreadData)
      setPostThread(post?.postThreadData);
  }, [post]); // si veo que esto no anda, hay que borrarlo
  useEffect(() => () => remove(), []);
  if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);
  if (isError) return <div>Error is: {(error as Error).message}</div>;

  return <MainWrapper /* optionalClass={postStyles.mobileClassForMainWrapperInPosts} */>
    <div id={postStyles.postWrapper}>
      <div id={postStyles.postGrid}>
        <div id={postStyles.postContainerDiv}>
          <div>
            {
              post ? (
                <>
                  <PostVotes post={post} refetch={refetch} />
                  <PostAutorAndThread post={post} />
                  <PostTitle post={post} />
                  {
                    !isEditable ?
                      <>
                        <PostBody post={post} />
                        <PostFooterOptions post={post} refetch={refetch} setIsEditable={setIsEditable} />
                      </>
                      : <EditPost post={post} refetch={refetch} setIsEditable={setIsEditable} remove={remove} />
                  }
                </>
              ) : <PostLoadingTemplate />
            }
          </div>
        </div>
        {
          postThread &&
          <div id={postStyles.threadInfoInSmartphones} onClick={() => {
            setVisible(false);
            setVisible2(true);
          }}
            role='button'
            tabIndex={0}>
            {
              visible && <div id={postStyles.touchForThreadInfo}>Touch for Thread Info</div>
            }
            {
              visible2 && <ThreadInfo
                postThread={postThread}
                setPostThread={setPostThread}
                isThisBeingUsedFromAThread={false} />
            }
          </div>
        }
        {post && <CommentsSection post={post} />}
      </div>
      {
        !postThread ? <div id={postStyles.threadInfoInSmartphones2}><ThreadInfoLoading /> </div> :
          <div id={postStyles.threadInfoInSmartphones2}>
            <ThreadInfo
              postThread={postThread}
              setPostThread={setPostThread}
              isThisBeingUsedFromAThread={false} />
          </div>
      }
    </div>
    {
      !isLoading && post && (
        <>
          <DeletePost
            post={post}
            typeOfPost='POST'
            setPostsList={null}
            calledFrom='post' />
          <ReportPost post={post} />
          <ZoomImagePortal queryKey="zoomImagePortal" />
        </>
      )
    }
  </MainWrapper >
}