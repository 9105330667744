import { API_URL } from "../../../../utilities/utils";
import { notificationsSocket } from "../../../nav/notifications/notifications";

/* eslint-disable no-underscore-dangle */
export default function sendCard(typeOfImage: any, context: any, setIsAvailableForSending: any, isSpoiler: any, isNSFW: any, navigate: any) {
    setIsAvailableForSending(false);
    const fd = new FormData();
    switch (typeOfImage.type) {
        case 'image':
            fd.append('title', context?.postTitle || '');
            fd.append('threadId', context?.postThread._id || '');
            fd.append('image', typeOfImage.image as string);
            fd.append('spoiler', isSpoiler.toString());
            fd.append('nsfw', isNSFW.toString());
            fd.append('sticky', 'false');

            fetch(`${API_URL}/cardCreator/create-new-card-image`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Authorization": `Bearer ${window.localStorage.token}`
                },
                body: fd
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message);
                    navigate(`/thread/${response.postThreadName}/card/${response.titleId}`);
                })
                .catch(err => {
                    notificationsSocket.emit('sendErrorMessage', err.message);
                    setIsAvailableForSending(true);
                });
            break;

        case 'video':
            fd.append('title', context?.postTitle || '');
            fd.append('threadId', context?.postThread._id || '');
            fd.append('video', typeOfImage.image as string);
            fd.append('spoiler', isSpoiler.toString());
            fd.append('nsfw', isNSFW.toString());
            fd.append('sticky', 'false');

            fetch(`${API_URL}/cardCreator/create-new-card-video`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Authorization": `Bearer ${window.localStorage.token}`
                },
                body: fd
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message);
                    navigate(`/thread/${response.postThreadName}/card/${response.titleId}`);
                })
                .catch(err => {
                    notificationsSocket.emit('sendErrorMessage', err.message);
                    setIsAvailableForSending(true);
                });
            break;

        case 'imageUrl':
            fetch(`${API_URL}/cardCreator/create-new-card-imageUrl`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Authorization": `Bearer ${window.localStorage.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: context?.postTitle,
                    threadId: context?.postThread._id,
                    imageUrl: typeOfImage.image,
                    spoiler: isSpoiler,
                    nsfw: isNSFW,
                    sticky: false,
                })
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message);
                    navigate(`/thread/${response.postThreadName}/card/${response.titleId}`);
                })
                .catch(err => {
                    notificationsSocket.emit('sendErrorMessage', err.message);
                    setIsAvailableForSending(true);
                });
            break;

        case 'videoUrl':
            fetch(`${API_URL}/cardCreator/create-new-card-videoUrl`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Authorization": `Bearer ${window.localStorage.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: context?.postTitle,
                    threadId: context?.postThread._id,
                    videoUrl: typeOfImage.image,
                    spoiler: isSpoiler,
                    nsfw: isNSFW,
                    sticky: false,
                })
            })
                .then(response => response.json())
                .then(response => {
                    if (response.error) throw new Error(response.message);
                    navigate(`/thread/${response.postThreadName}/card/${response.titleId}`);
                })
                .catch(err => {
                    notificationsSocket.emit('sendErrorMessage', err.message);
                    setIsAvailableForSending(true);
                    // con.setNot({ ver: true, mensaje: e.message });
                });
            break;

        case 'youtubeUrl':
            fetch(`${API_URL}/cardCreator/create-new-card-youtubeUrl`, {
                method: 'POST',
                mode: 'cors',
                headers: {
                    "Authorization": `Bearer ${window.localStorage.token}`,
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    title: context?.postTitle,
                    threadId: context?.postThread._id,
                    youtube: JSON.stringify(typeOfImage.image),
                    spoiler: isSpoiler,
                    nsfw: isNSFW,
                    sticky: false,
                })
            })
                .then(response => response.json())
                .then(response => {
                    console.log(response);
                    if (response.error) throw new Error(response.message);
                    navigate(`/thread/${response.postThreadName}/card/${response.titleId}`);
                })
                .catch(err => {
                    notificationsSocket.emit('sendErrorMessage', err.message);
                    setIsAvailableForSending(true);
                    // con.setNot({ ver: true, mensaje: e.message });
                });
            break;

        default:
    }

    /* setIsAvailableForSending(false);
        const fd = new FormData();
        fd.append('title', context?.postTitle || '');
        fd.append('threadId', context?.postThread._id || '');
        let createNewCardUrl;
        let headersData;
        if (typeOfImage.type === 'image') {
            fd.append('image', typeOfImage.image as Blob);
            createNewCardUrl = '/cardCreator/create-new-card-image';
            headersData = {
                "Authorization": `Bearer ${window.localStorage.token}`
            };
        }
        if (typeOfImage.type === 'imageUrl') {
            fd.append('imageUrl', typeOfImage.image as string);
            createNewCardUrl = '/cardCreator/create-new-card-imageUrl';
            headersData = {
                "Authorization": `Bearer ${window.localStorage.token}`,
                'Content-Type': 'application/json'
            };
        }
        if (typeOfImage.type === 'youtubeUrl') {
            fd.append('youtubeUrl', JSON.stringify(typeOfImage.image));
            createNewCardUrl = '/cardCreator/create-new-card-youtubeUrl';
            headersData = {
                "Authorization": `Bearer ${window.localStorage.token}`,
                'Content-Type': 'application/json'
            };
        }
        if (typeOfImage.type === 'video') {
            fd.append('video', typeOfImage.image as Blob);
            createNewCardUrl = '/cardCreator/create-new-card-videoUrl';
            headersData = {
                "Authorization": `Bearer ${window.localStorage.token}`,
                'Content-Type': 'application/json'
            };
        }
        fd.append('spoiler', isSpoiler.toString());
        fd.append('nsfw', isNSFW.toString());
        fd.append('sticky', 'false');
        fetch(`${API_URL}${createNewCardUrl}`, {
            method: 'POST',
            mode: 'cors',
            headers: headersData,
            body: fd
        })
            .then(response => response.json())
            .then(response => {
                if (response.error) throw new Error(response.message);
                navigate(`/thread/${response.postThreadName}/card/${response.titleId}`);
            })
            .catch(err => {
                notificationsSocket.emit('sendErrorMessage', err.message);
                setIsAvailableForSending(true);
                // con.setNot({ ver: true, mensaje: e.message });
            }) */
}