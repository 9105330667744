import { useQuery } from "react-query";
import { API_URL } from "../../../../utilities/utils";
import { NotificationsType } from "./notificationsType";

async function fetchNotifications(ac: AbortController, isThisUserTheProfileOwner: boolean) {
    if (!isThisUserTheProfileOwner) return null;
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: { Accept: 'application/json', Authorization: `Bearer ${token}` },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(`${API_URL}/user-profile/get-this-user-notifications`, fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as NotificationsType[];
}

const useUserProfileNotifications = (ac: AbortController, isThisUserTheProfileOwner: boolean) => useQuery('userProfileNotifications',
    () => fetchNotifications(ac, isThisUserTheProfileOwner),
    { refetchOnWindowFocus: false });

export default useUserProfileNotifications;