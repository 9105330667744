/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';
import userProfileStyles from '../userProfileStyles.module.css';
import useUserProfilePublicationsQuery from '../userProfilePublications/useUserProfilePublicationsQuery';
import { zoomInImages } from '../../../utilities/utils';
import { UserProfileType } from '../userProfileTypes';
import CommentsTextArea from '../../sharedComponents/commentsSection/postCommentTextarea/commentsArea/commentsTextarea';
import PostComments from '../../sharedComponents/commentsSection/postComments/postComments';
import BlockUserInCommentsPortal from '../../sharedComponents/reactPortals/blockUserPortal/blockUserInCommentsPortal';
import ReportComment from '../../sharedComponents/reactPortals/reportComment/reportComment';

export const CommentsSectionContext = createContext<any>(null);

export default function UserProfilePublicationsWrapper({ userProfile }: { userProfile: UserProfileType }) {
    const queryClient = useQueryClient();
    const ac = new AbortController();
    const { data: comments, refetch, remove } = useUserProfilePublicationsQuery(userProfile._id, ac);
    const [commentsList, setCommentsList] = useState<any[]>([]);
    const [currentComment, setCurrentComment] = useState<any>();
    useEffect(() => {
        zoomInImages(queryClient);
    });
    useEffect(() => {
        if (comments?.length)
            setCommentsList((comments));
    }, [comments]);
    useEffect(() => {
        refetch();
    }, [userProfile]);
    return (
        <CommentsSectionContext.Provider value={{ queryClient, refetch, remove, setCommentsList, commentsList, userProfile, setCurrentComment }}>
            <div id={userProfileStyles.userProfilePublicationsWrapper}>
                {userProfile.isThisUserTheProfileOwner && <CommentsTextArea isBeingCalledFromProfile />}
                <PostComments userProfile={userProfile} isBeingCalledFromProfile />
                {
                    currentComment && <>
                        <BlockUserInCommentsPortal comment={currentComment} refetch={refetch} />
                        <ReportComment comment={currentComment} isThisBeingCalledFromUserProfile />
                    </>
                }
            </div>
        </CommentsSectionContext.Provider>
    )
}
