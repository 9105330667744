import React from 'react';
import postVotesStyles from '../../../postHeader/postVotes/postVotesStyles.module.css';
import postVotesPathDString from '../../../postHeader/postVotes/postVotesPathDString';
import postLoadingTemplate from './postLoadingTemplateStyles.module.css';

export default function PostLoadingTemplate() {
    return (
        <div id={postLoadingTemplate.postLoadingTemplateContainer}>
            <div id={postVotesStyles.postVotesContainer}>
                <svg version="1.1" width="22" height="22" viewBox="0 0 24 24" >
                    <path
                        fill='transparent'
                        stroke='#5d666b'
                        d={postVotesPathDString} />
                </svg>
                <div className={`${postVotesStyles.numberOfVotes}`}>0</div>
                <svg version="1.1" width="22" height="22" viewBox="0 0 24 24"  >
                    <path
                        fill='transparent'
                        stroke='#5d666b'
                        d={postVotesPathDString} />
                </svg>
            </div>
            <div id={postLoadingTemplate.postLoadingTemplateAutorAndThread} />
            <div id={postLoadingTemplate.postLoadingTemplateTitle} />
            <div id={postLoadingTemplate.postLoadingTemplateBody}>
                <div />
                <div />
            </div>
        </div>
    );
}
