import React from 'react';
import cardBodyStyles from './cardBodyStyles.module.css';
import CardBodyNSFW from './cardBodyNSFW';
import { PostType } from '../../../../universalTypes/universalTypes';

export default function CardBodyYoutube({ card }: { card: PostType }) {
    return (
        <div className={cardBodyStyles.cardYoutube} >
            {(card.isNSFW || card.isSpoiler) && <CardBodyNSFW isNSFW={card.isNSFW} />}
            <iframe
                height='480'
                src={card.youtube?.embed}
                loading="lazy"
                allow="autoplay; encrypted-media"
                allowFullScreen
                title={card.title}
                className={`${card.isNSFW || card.isSpoiler ? cardBodyStyles.nsfwFilter : ''}`} />
        </div>
    )
}
