/* eslint-disable import/no-cycle */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { memo } from 'react';
import { useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { API_URL } from "../../../../utilities/utils";
import BasePortal from "../basePortal";
import blockUserPortalStyles from './blockUserPortalStyles.module.css';
import imgTrashbin from '../../../../assets/images/trashbin10.png';
import maqueta from '../../../../assets/images/maqueta.png';
import { notificationsSocket } from '../../../nav/notifications/notifications';
import { UserProfileType } from '../../../userProfile/userProfileTypes';

function BlockUserFromUserProfileWall({ userProfile }: { userProfile: UserProfileType }) {
    const queryClient = useQueryClient();
    const navigate = useNavigate();
    const handleBlockUser = () => {
        fetch(`${API_URL}/user/process-block-user`,
            {
                method: 'post',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({ userToBlockId: userProfile._id }),
            })
            .then(e => e.json())
            .then((e: Record<string, unknown>) => {
                if (e.error) throw new Error(e.message as string);
                queryClient.setQueryData("blockUserFromUserProfileWall", false);
                navigate('/');
            })
            .catch((error) => notificationsSocket.emit('sendErrorMessage', error.message));;
    }
    const handleCancel = () => queryClient.setQueryData("blockUserFromUserProfileWall", false);

    return <BasePortal queryKey='blockUserFromUserProfileWall'>
        <div id={blockUserPortalStyles.blockUserPortalWrapper}>
            <img
                src={imgTrashbin || maqueta}
                alt='imagenTrashbin'
                loading='lazy'
                decoding='async'
            />
            <div>
                <p>YOU ARE ABOUT TO <span>BLOCK</span> USER <span style={{ color: 'var(--gamerthRed)' }}>{userProfile.nickname}</span> </p>
                <p> <span>You will not be able to see any posts, cards, polls, threads or comments from this user</span> </p>
                <p>Do you wish to continue?</p>
            </div>
            <div id={blockUserPortalStyles.cancelAndDeleteButtonsWrapper}>
                <div
                    id={blockUserPortalStyles.blockUserPortalCancelButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleCancel}>no, wait!</div>
                <div
                    id={blockUserPortalStyles.blockUserPortalConfirmButton}
                    role='button'
                    tabIndex={0}
                    onClick={handleBlockUser}>yeah, to hell with this a-hole!</div>
            </div>
        </div>
    </BasePortal>
}

function areEqual(prevProps: Readonly<{ userProfile: UserProfileType }>, nextProps: Readonly<{ userProfile: UserProfileType }>) {
    return prevProps.userProfile._id === nextProps.userProfile._id;
}

export default memo(BlockUserFromUserProfileWall, areEqual);