/* eslint-disable jsx-a11y/media-has-caption */
import React from 'react';
import { Link } from "react-router-dom";
import cardsStyle from '../cardsSectionHomeStyles.module.css';
import SecondCardHomeTitleNSFW from "./twoSmallCardsHomeTitleNSFW";
import { SecondAndThirdCardsHomeProps } from "./twoSmallCardsHomeTypes";

export default function TwoSmallCardsHomeSwitchImageTypeOrVideo({ card }: SecondAndThirdCardsHomeProps) {
    switch (true) {
        case !!card.youtube || !!card.imageUrl:
            return (
                <Link to={`/thread/${card.postThreadName}/card/${card.titleId}`}>
                    {(card.isNSFW || card.isSpoiler) && <SecondCardHomeTitleNSFW isNSFW={card.isNSFW} />}
                    <img
                        className={card.isNSFW || card.isSpoiler ? cardsStyle.nsfwFilter : ''}
                        src={card.youtube ? card.youtube.lowResThumbnailUrl : card.imageUrl}
                        alt={card.title}
                        loading="lazy"
                        decoding="async"
                        title={card.title}
                    />
                    <figcaption>{card.title}</figcaption>
                </Link>
            );
        default:
            return (
                <Link to={`/thread/${card.postThreadName}/card/${card.titleId}`}>
                    {(card.isNSFW || card.isSpoiler) && <SecondCardHomeTitleNSFW isNSFW={card.isNSFW} />}
                    <video
                        className={`${card.isNSFW || card.isSpoiler ? cardsStyle.nsfwFilter : ''}`}
                        src={card.videoUrl}
                        preload='auto'
                        title={card.title}
                    />
                    <figcaption>{card.title}</figcaption>
                </Link>
            );
    }
};