/* eslint-disable react/no-array-index-key */
import React from 'react';
import { Link } from 'react-router-dom';
import userProfileStyles from '../userProfileStyles.module.css';
import { UserProfileType } from '../userProfileTypes';

export default function UserProfilePictures({ userProfile }: { userProfile: UserProfileType }) {
    return (
        <div className={userProfileStyles.userProfilePictures}>
            <div>Pictures</div>
            {
                userProfile.userProfileCollectionOfPictures?.map((item: any, index: number) => <div key={index}>
                    <img src={item.image}
                        alt='imageAlt' loading="lazy" decoding="async" />
                </div>)
            }
        </div >
    )
}
