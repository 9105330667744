import { useQuery } from 'react-query';
import { API_URL } from '../../../../utilities/utils';
import { OrderType, PostType } from '../../../../universalTypes/universalTypes';

function getRequestURL(order: OrderType) {
    console.log("QUE DIABLOS TRAE API_URL????? -->", API_URL);
    console.log("Y QUE TRAE process.env.GAMERTH_API_URL?? -->", process.env.GAMERTH_API_URL);
    switch (order.order) {
        case 'fetchNew':
            return `${API_URL}/home`;
        case 'fetchHot':
            return `${API_URL}/home/hot`;
        case 'fetchTop':
            return `${API_URL}/home/top`;
        case 'search':
            return `${API_URL}/home/search-posts/${order.value}`;
        default:
            return `${API_URL}/home`;
    }
}

async function fetchPosts(ac: AbortController, order: OrderType) {
    const token = window.localStorage.token as string;
    const fetchOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            Accept: 'application/json',
            Authorization: `Bearer ${token}`
        },
        signal: ac.signal,
    } as RequestInit;
    const response = await fetch(getRequestURL(order), fetchOptions);
    const parsedResponse = await response.json();
    if (parsedResponse.message) throw new Error(parsedResponse.message);
    return parsedResponse as PostType[];
}

const usePostsHomeQuery = (ac: AbortController, order: OrderType) =>
    useQuery(['homeSectionQuery', order.order, order.value],
        () => fetchPosts(ac, order),
        { refetchOnWindowFocus: false });

export default usePostsHomeQuery;