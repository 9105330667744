/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import navStyle from '../navStyle.module.css';
import Login from '../login/login';
import { UserType } from './userType';

export default function User({ user }: { user: UserType }) {
  const [visible, setVisible] = useState(false);
  const userMenuRef = useRef<HTMLDivElement>(null);
  const menuWrapperRef = useRef<HTMLDivElement>(null);

  const handleClick = () => {
    setVisible(e => !e);
  }

  const handleEscape = (e: KeyboardEvent) => {
    if (e.key === "Escape") {
      setVisible(false);
    };
  };
  const handleMenuOff = (e: MouseEvent) => {
    const { target } = e;
    if (!menuWrapperRef.current?.contains(target as Node)) {
      setVisible(false);
    }
  }
  useEffect(() => {
    document.body.addEventListener('keydown', handleEscape);
    document.body.addEventListener('click', handleMenuOff);
    return () => {
      document.body.removeEventListener('keydown', handleEscape);
      document.body.removeEventListener('click', handleMenuOff);
    };
  }, []);

  const handleLogOut = () => {
    localStorage.removeItem('token');
    sessionStorage.removeItem('token');
    document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
    window.location.reload();
    /* const token = localStorage.getItem('token') || sessionStorage.getItem('token');
    if (token) {
      // Notificar al servidor (opcional)
      fetch('/user/logout-and-invalidate-token', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`
        }
      })
        .then(response => response.json())
        .then(response => {
          if (response.error) throw new Error(response.message);
          if (response.ok) {
            localStorage.removeItem('token');
            sessionStorage.removeItem('token');
            document.cookie = "token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
            console.log('Logged out successfully');
          }
        })
        .catch(err => console.log(err.message)); */
  }

  return (
    user ? (
      <div id={navStyle.user} onClick={handleClick} role='button' tabIndex={0} ref={menuWrapperRef}>
        <img id={navStyle.userImage}
          src={user?.lowResThumbnail}
          loading="lazy"
          decoding="async"
          alt="userImage"
        />
        <div>
          <div id={navStyle.userName}>{user?.nickname}</div>
          <div id={navStyle.info}>{user?.info?.rank}</div>
        </div>
        {
          visible && <div id={navStyle.userMenu} ref={userMenuRef}>
            <span className={`${navStyle.topBot} ${navStyle.topBotBorder}`} />
            <span className="top-bot top-bot-border" />
            <Link id={navStyle.firstChild} to={`/profile/${user.nickname}`}>profile</Link>
            <Link id={navStyle.middleChild} to="/create-new-post">create new post</Link>
            <Link id={navStyle.middleChild} to="/create-new-thread">create new thread</Link>
            <div id={navStyle.lastChild} onClick={handleLogOut} tabIndex={0} role='button'>Log Out</div>
          </div>
        }
      </div >
    )
      : <Login />
  );
}
