/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { API_URL } from '../../../utilities/utils';
import postInfoStyles from './threadInfo.module.css';
import { ThreadsType } from '../../postCreator/postCreatorTypes';
import { notificationsSocket } from '../../nav/notifications/notifications';

export function threadDateOfCreation(thread: Record<string, any>) {
    const monthNames = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December"
    ];
    return `${monthNames[new Date(thread?.timestamp).getMonth()]} 
    ${new Date(thread?.timestamp).getDate()}, 
    ${new Date(thread?.timestamp).getFullYear()}`;
}


export function postInfoIsNSFW(thread: Record<string, any>, postInfoStyles: Record<string, any>) {
    if (thread.isNSFW) return <span id={postInfoStyles.threadInfoNsfw}> NSFW </span>
    return 'Safe for work';
}

export function postInfoIsAutorOrMember(postThread: ThreadsType, setPostThread: any) {
    if (!postThread) return null;
    const handleJoinAndUnjoin = (join: boolean) => {
        fetch(`${API_URL}/thread/joinOrUnjoinThread`,
            {
                method: 'POST',
                mode: 'cors',
                headers: {
                    'Content-Type': 'application/json',
                    "authorization": `Bearer ${window.localStorage.token}`
                },
                body: JSON.stringify({
                    postThreadId: postThread._id,
                    isJoining: join
                })
            })
            .then(e => e.json())
            .then(e => {
                if (e.error) throw new Error(e.message);
                setPostThread(e.thread);
            })
            .catch(err => notificationsSocket.emit('sendErrorMessage', err.message));
    }

    switch (true) {
        case postThread.isThisUserTheAdmin:
            return <div id={postInfoStyles.threadInfoOwner}>owner</div>
        case postThread.isThisUserAMember:
            return <div id={postInfoStyles.threadInfoMember}
                onClick={() => handleJoinAndUnjoin(false)}
                role='button'
                tabIndex={0}>member</div>
        default: return <div
            id={postInfoStyles.threadInfoJoin}
            onClick={() => handleJoinAndUnjoin(true)}
            role='button'
            tabIndex={0}>join</div>;
    }
}