/* eslint-disable jsx-a11y/no-noninteractive-element-to-interactive-role */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable import/no-cycle */
/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom';
import { faClock, faDiceD20, faPoll } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useQueryClient } from 'react-query';
import { PostType } from '../../../universalTypes/universalTypes';
import fivePostsInCardsSection from './fivePostsInCardsSection.module.css';
import useFivePostsQuery from './useGetFivePostsQuery';
import FivePostsInCardsSectionLoading from './fivePostsInCardsSectionLoading';
import ZoomImagePortal from '../../sharedComponents/reactPortals/zoomImagePortal/zoomImagePortal';

export default function FivePostsInCardsSection(): any {
    const queryClient = useQueryClient();
    const [postsList, setPostsList] = useState<PostType[]>();
    const [order, setOrder] = useState({ order: 'fetchNew' }); // maybe i'll use this in the future, i still don't know
    const postImage = useRef<HTMLImageElement>(null);
    const ac = new AbortController();
    const { data: fivePosts } = useFivePostsQuery(ac, order);
    useEffect(() => {
        setPostsList(fivePosts);
    }, [fivePosts]);
    const handleZoomImage = (post: PostType) => queryClient.setQueryData('zoomImagePortal', { isVisible: true, imgSrc: post.thumbnail });

    const handleNSFWorSpoilerToggle = (event: React.MouseEvent) => {
        event.preventDefault();
        const target = event.currentTarget;
        const imageElement = target.parentElement?.previousElementSibling?.children[0];
        if(!imageElement) return;
        imageElement.classList.remove(imageElement.classList[1]);
    }

    const isNsfwOrSpoiler = (post: PostType) => {
        switch (true) {
            case post.isNSFW && post.isSpoiler:
                return <>
                    <span className={fivePostsInCardsSection.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                        {post.isNSFW && 'NSFW'}
                    </span>&nbsp;
                    <span className={fivePostsInCardsSection.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                        {post.isSpoiler && 'SPOILER'}
                    </span>
                </>
            case post.isNSFW:
                return <span className={fivePostsInCardsSection.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                    {post.isNSFW && 'NSFW'}
                </span>
            case post.isSpoiler:
                return <span className={fivePostsInCardsSection.postHomeNsfwSign} onClick={handleNSFWorSpoilerToggle} role='button' tabIndex={0}>
                    {post.isSpoiler && 'SPOILER'}
                </span>
            default: return null;
        }
    }

    return <section id={fivePostsInCardsSection.fivePostsSectionGrid}>
        <div><FontAwesomeIcon icon={faClock} /> Recent Posts</div>
        <div id={fivePostsInCardsSection.fivePostsGridWrapper}>
            {
                postsList ? postsList.map((post) =>
                    <div className={fivePostsInCardsSection.postGrid} key={post._id}>
                        <div>
                        <img className={`${fivePostsInCardsSection.postHomeImage} 
                          ${(post.isNSFW || post.isSpoiler) &&
                          fivePostsInCardsSection.postHomeImageNsfwFilter}`}
                          loading="lazy" 
                          decoding="async" 
                          src={post.lowResThumbnail} 
                          alt={post.lowResThumbnail} 
                          onClick={() => handleZoomImage(post)} 
                          tabIndex={0} 
                          role='button' 
                          ref={postImage} />
                          </div>
                        <Link to={`/thread/${post.postThreadData?.name}${post.isPost ? '/post/' : '/poll/'}${post.titleId}`} title={post.title}>{post.title}&nbsp;
                        {!post.isPost && <FontAwesomeIcon icon={faPoll} title='This is a poll' />} {isNsfwOrSpoiler(post)}</Link>
                        <div>On <Link className={fivePostsInCardsSection.spanThread}
                            to={`/thread/${post.postThreadData?.name}`}> <FontAwesomeIcon icon={faDiceD20}
                                className={fivePostsInCardsSection.postThreadNameDice} />&nbsp;
                            {post.postThreadData.name}</Link> by  <Link className={fivePostsInCardsSection.spanUser}
                                to={`/profile/${post.postAuthorData?.nickname}`}>{post.postAuthorData.nickname}</Link>
                        </div>
                    </div>
                ) : <FivePostsInCardsSectionLoading />
            }
        </div>
        <ZoomImagePortal queryKey="zoomImagePortal" />
    </section>

}
