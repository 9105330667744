import { useQuery } from 'react-query';
import { API_URL } from '../../../../utilities/utils';
import { PostCommentsType } from './postCommentsType';

const fetchPostComments = async (postId: string, hasLocationHash:boolean) => {
    const ac = new AbortController();
    const response = await fetch(
        hasLocationHash ? 
        `${API_URL}/post/get-post-one-comment/${postId}/${window.location.hash.substring(1)}`:
        `${API_URL}/post/get-post-comments/${postId}`,
        {
            method: 'get',
            mode: 'cors',
            headers: {
                Accept: 'application/json',
                authorization: `Bearer ${window.localStorage.token as string}`
            },
            signal: ac.signal
        },
    );
    const parsedResponse = await response.json();
    if (!parsedResponse) throw new Error("NO COMMENTS!");
    if (parsedResponse.message) throw new Error(parsedResponse.message);;
    return {
            comments: parsedResponse.comments as PostCommentsType[],
            totalPostComments: parsedResponse.totalPostComments as number
           } as { comments: PostCommentsType[], totalPostComments: number };
};

const usePostCommentsQuery = (postId: string, hasLocationHash: boolean) =>
    useQuery('postComments',
        () => fetchPostComments(postId, hasLocationHash),
        { refetchOnWindowFocus: false}
    );

export default usePostCommentsQuery;