/* eslint-disable import/no-cycle */
/* eslint-disable react/jsx-no-constructed-context-values */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-underscore-dangle */
import React, { createContext, useEffect, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component';
import { UserProfileType } from '../../userProfileTypes';
import UserProfileDontHaveAny from '../../../sharedComponents/userProfileDontHaveAny/userProfileDontHaveAny';
import { PostPollCardOrThreadContextType, PostType } from '../../../../universalTypes/universalTypes';
import DeletePost from '../../../sharedComponents/reactPortals/deletePost/deletePost';
import BlockUserPortal from '../../../sharedComponents/reactPortals/blockUserPortal/blockUserPortal';
import BanUserFromThreadPortal from '../../../sharedComponents/reactPortals/banUserFromThreadPortal/banUserFromThreadPortal';
import ReportPost from '../../../sharedComponents/reactPortals/reportPost/reportPost';
import PostHomeSectionEndOfList from '../../../homeSection/postsSectionHome/postHomeSectionEndOfList/postHomeSectionEndOfList';
import Post from '../../../homeSection/postsSectionHome/postHome/postHome';
import Card from '../../../cardsSection/card/card';
import PostsSectionHomeLoading from '../../../homeSection/postsSectionHome/postsSectionHomeLoading/postsSectionHomeLoading';
import userProfileBookmarksStyles from './userProfileBookmarks.module.css';
import useUserProfileBookmarkedPosts, { fetchUserProfileBookmarks } from './userProfileBookmarksUtils';
import userProfileStyles from '../../userProfileStyles.module.css';


export const UserProfileBookmarksContext = createContext<PostPollCardOrThreadContextType>(null);

export default function UserProfileBookmarks({ userProfile }: { userProfile: UserProfileType }) {
    const [postsList, setPostsList] = useState<PostType[]>();
    const [hasMore, setHasMore] = useState<boolean>(true);
    const [page, setPage] = useState(1);
    const [currentPost, setCurrentPost] = useState<PostType | null>(null);
    const ac = new AbortController();
    const { data, refetch, remove, isFetching } = useUserProfileBookmarkedPosts(ac, userProfile?._id as string);
    useEffect(() => setPostsList(() => data && [...data]), [data]);
    useEffect(() => {
        setPage(1);
        setHasMore(true);
    }, []);
    useEffect(() => {
        if (postsList?.length) setPage((page) => page + 1);
    }, [postsList]);
    const typeOfPost = () => {
        if (currentPost?.isPost) return 'POST';
        if (currentPost?.isPoll) return 'POLL';
        return 'CARD';
    }
    useEffect(() => () => remove(), []);

    if (process.env.NODE_ENV === 'production') useEffect(() => () => ac.abort(), []);

    useEffect(() => console.log("hasMore???--->", hasMore), [hasMore]);
    useEffect(() => console.log("page???--->", page), [page]);
    if (isFetching) return <div id={userProfileStyles.isFetching} className={userProfileStyles.isFetchingBookmarks} ><PostsSectionHomeLoading /></div>
    if (!postsList?.length) return <div id={userProfileStyles.isFetching}>
        <UserProfileDontHaveAny userProfile={userProfile as UserProfileType}
            isOwnerMessage="You don't have any bookmarked posts..."
            isNotOwnerMessage="This is not your profile..." />
    </div>
    // const contexto = useMemo(() => ({setCurrentPost, currentPost, refetch}), [])

    return (
        <div id={userProfileBookmarksStyles.bookmarksWrapper}>
            <UserProfileBookmarksContext.Provider value={{ setCurrentPost, currentPost, refetch }}>
                <InfiniteScroll
                    style={{ borderRadius: '.5rem', overflow: 'initial' }}
                    dataLength={postsList?.length || 0}
                    next={() => fetchUserProfileBookmarks(ac, page, setHasMore, setPostsList, userProfile?._id)}
                    hasMore={hasMore}
                    loader={<p>Loading...</p>}
                    endMessage={<PostHomeSectionEndOfList />}
                >
                    {
                        postsList?.map((item) => (
                            !item.isCard ? <Post post={item} key={item._id} /> :
                                <Card card={item} key={item._id} />
                        ))
                    }
                    {
                        currentPost && (
                            <>
                                <DeletePost
                                    post={currentPost}
                                    typeOfPost={typeOfPost()}
                                    setPostsList={setPostsList} calledFrom='thread' />
                                <BlockUserPortal currentPost={currentPost} refetch={refetch} />
                                <BanUserFromThreadPortal currentPost={currentPost} />
                                <ReportPost post={currentPost} />
                            </>
                        )
                    }
                </InfiniteScroll>
            </UserProfileBookmarksContext.Provider>
        </div >
    )
}
